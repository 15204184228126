import React, { useState, useEffect, useRef, useContext } from "react";
import { getProductDetails, updateWishlist, addToCart, addToOccasion, uploadMedia } from "../../helper/backend-methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProductSlider from "./Product-Slider-01";
import { isAuthenticated } from "../../common";
import Modal from "../../components/Modal"
import BreadCrumbs from "../../components/BreadCrumbs";
import Skeleton from 'react-loading-skeleton'
import ReactImageGallery from "react-image-gallery";
import ScrollContainer from "react-indiana-drag-scroll"; 
import noImage from "../../assets/images/no-image.jpg"
import cartImage from "../../assets/images/cart-image.png"
import infoIcon from "../../assets/images/info-icon.png"
import clickIcon from "../../assets/images/click-icon.png"
import calendarIcon from "../../assets/images/calendar.png"
import "../../assets/css/--page-product-detail.css"
import '../../assets/css/--arrow-buttons.css'
import 'react-loading-skeleton/dist/skeleton.css'
import "react-image-gallery/styles/css/image-gallery.css";
import "react-multi-carousel/lib/styles.css";
import { GlobalContext } from "../../context/GlobalContext";
import _T from "../../locale/product";
import Error404 from "../../components/Error404";
import SEO from "../../components/SEO";

function ProductDetail(props) {
    let params = useParams();
    const productID = params.id
    const { cartCount,updateCartCount } = useContext(GlobalContext);
    const isAuthorized = isAuthenticated();
    const [images, setImages] = useState([]);
    const [product, setProduct] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [customizeableProducts, setCustomizeableProducts] = useState([]);
    const [occassionList, setOccassionList] = useState([]);
    const [loading, setLoading] = useState(true)
    const [quantity, setQuantity] = useState(1);
    const [tab, setTab] = useState(1);
    const [IsInWishlist, setIsInWishlist] = useState(false);
    const [alternativeProductModal, setAlternativeProductModal] = useState(false);
    const [showAlternativeOption, setShowAlternativeOption] = useState(false);
    const [selectedColorAlternative, setSelectedColorAlternative] = useState(false);
    const isCakeProduct = useRef(false);
    const [cakeVariantValues,setCakeVariantValues] = useState({ size: 0, color: 0, stuffing: 0, image: null, imageId: 0, imagePreview: null, imageError: '', text: '', price: 0, loading: false });
    
    const [selectedOccasion, setSelectedOccasion] = useState(0);
    const [cartModal, setCartModal] = useState(false);
    const [infoOccasionModal, setInfoOccasionModal] = useState(false);
    const [occasionModal, setOccasionModal] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [notFound , setNotFound] = useState(false);
    const navigate = useNavigate();
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        { title: _T("Home"), link: "/" },
        { title: _T("Products"), link: "/products" },
        { title: _T("Product"), link: "" },
    ]);
    useEffect(() => {
        setLoading(true)
        setSelectedColorAlternative(false);
        getProductDetails({ productId: productID }).then(({ data }) => {
            if (data.status) {
                let newImages = []
                let p = data.result.product
                let canSubscribe = p.CanSubscribe
                isCakeProduct.current = p.IsCakeProduct
                if(p.IsCakeProduct){
                    const size = p.ProductAttributeDescription.find((a)=>a.NameEn === "Size").Values[0]
                    const color = p.ProductAttributeDescription.find((a)=>a.NameEn === "Color").Values[0].Id
                    const stuffing = p.ProductAttributeDescription.find((a)=>a.NameEn === "Stuffing").Values[0].Id
                    setCakeVariantValues({ size: size.Id, color: color, stuffing: stuffing, image: 0, imagePreview: 0, text: '', price: size.Price })
                }
                if(canSubscribe){
                    navigate(`/subscription/product/${productID}/${p.Name ? p.Name.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : _T("product")}`)
                }
                else{
                    if (p.PictureModels.length) {
                        p.PictureModels.map((img) => {
                            newImages.push({
                                original: img.FullSizeImageUrl,
                                thumbnail: img.ImageUrl,
                            })
                        })
                    }
                    else {
                        newImages = [{ original: noImage, thumbnail: noImage }]
                    }
                    setProduct(p)
                    setBreadcrumbItems([ { title: _T("Home"), link: "/" }, { title: _T("Products"), link: "/products" }, { title: p.Name, link: "" } ])
                    setIsInWishlist(p.IsInWishlist);
                    setRelatedProducts(data.result.relatedProducts)
                    setCustomizeableProducts(data.result.product.CustomizeableProducts && data.result.product.CustomizeableProducts.length ? data.result.product.CustomizeableProducts : [])
                    setOccassionList(data.result.occassionList ? data.result.occassionList.result : [])
                    setImages([...newImages])
                    setLoading(false)
                }
            }
            else{
                if(data.response.ResponseCode===404){
                    setNotFound(true)
                }
            }
        }).catch((e) => {
            console.log(e);
        })
    }, [productID])


    const addRemoveWishlist = () => {
        updateWishlist({ ProductId: productID, Add: !IsInWishlist }).then(({data})=>{
            if(data.status){
                setIsInWishlist(!IsInWishlist)
            }
        }).catch((e)=>{
            console.log(e)
        })
    }

    const addItemToCart = () => {
        setAlternativeProductModal(false);
        setShowAlternativeOption(false);
        let alternativeSelection = false;
        if(selectedColorAlternative){
            const selectedAlternativeVariant = product.Colors.filter((item)=> item.AssociatedProductId === selectedColorAlternative)[0]
            alternativeSelection = {
                "AlternateColor": selectedAlternativeVariant.Name,
                "IsAlternateAvailable": true,
                "ProductId": selectedAlternativeVariant.AssociatedProductId,
                "Send": false
            }
        }
        let data = null;
        if(!isCakeProduct.current){
            data = {
                Quantity : quantity , 
                ProductId: productID, 
                ...(selectedColorAlternative && {AlternateVariation: alternativeSelection}), 
            }
        }
        else{
            const size = product.ProductAttributeDescription.find((a)=>a.NameEn === "Size")
            const selectedSize = size.Values.find((a)=>a.Id === cakeVariantValues.size)
            const color = product.ProductAttributeDescription.find((a)=>a.NameEn === "Color")
            const selectedColor = color.Values.find((a)=>a.Id === cakeVariantValues.color)
            const stuffing = product.ProductAttributeDescription.find((a)=>a.NameEn === "Stuffing")
            const selectedStuffing = stuffing.Values.find((a)=>a.Id === cakeVariantValues.stuffing)
            const cakeText = product.ProductAttributeDescription.find((a)=>a.NameEn === "Cake Text")
            data = {
                Quantity : quantity , 
                ProductId: productID, 
                AlternateVariation: {},
                CheckoutProductAttributes: [
                    { Type:"Stuffing", ProductAttributeId:stuffing.ProductAttributeId, Price:selectedStuffing.Price, Name:selectedStuffing.Name },
                    { Type:"Color", ProductAttributeId:color.ProductAttributeId, Price:selectedColor.Price, ColorSquaresRgb:selectedColor.ColorSquaresRgb, Name:selectedColor.Name  },
                    { Type:"Size", ProductAttributeId:size.ProductAttributeId, Price:selectedSize.Price,  Name:selectedSize.Name },
                    { Type:"Cake Text", ProductAttributeId:cakeText.ProductAttributeId, Name: cakeVariantValues.text },
                    { ProductAttributePictureId: cakeVariantValues.imageId }
                ]
            }
        }
        addToCart(data).then(({data})=>{
            setSelectedColorAlternative(false);
            if(data.status){
                setCartModal(true)
                updateCartCount(cartCount+1)
            }
        }).catch((e)=>{
            console.log(e)
        })
    }

    const handleAcceptedFiles = e => {
        const files = e.target.files
        let allowed_extensions = ["jpeg", "jpg", "png", "gif"];
        let fileExt = files[0].name.split('.').pop();
        setCakeVariantValues((prev)=> { return { ...prev, loading: true }})
        if (allowed_extensions.includes(fileExt)) {
            const reader = new FileReader();
            if (files) {
                reader.readAsDataURL(files[0]);
            } else {
                setCakeVariantValues((prev)=> { return { ...prev, image: null, imageId: 0, imagePreview: null, imageError: 'Your image could not be uploaded.', loading: false }})
            }
            reader.onloadend = () => {
                uploadCakeImage(files)
            };
        }
        else {
            setCakeVariantValues((prev)=> { return { ...prev, image: null, imageId: 0, imagePreview: null, imageError: 'Allowed formats are jpeg, jpg, png', loading: false }})
            document.getElementById("cakeImage").value = "";
        }
    }

    const uploadCakeImage = (image) => {
        const cakeImage = new FormData()
        cakeImage.append("videoUrl", image[0]);
        cakeImage.append("IsVideo", false);
        uploadMedia(cakeImage).then(({data})=>{
            if(data.status){
                setCakeVariantValues((prev)=> { return { ...prev, image: image[0], imageId: data.result.Id, imagePreview: data.result.Url, imageError: '', loading: false }})
            }
            else{
                setCakeVariantValues((prev)=> { return { ...prev, image: null, imageId: 0, imagePreview: null, imageError: 'Your image could not be uploaded.', loading: false }})
            }
        }).catch((e)=>{
            setCakeVariantValues((prev)=> { return { ...prev, image: null, imageId: 0, imagePreview: null, imageError: 'Your image could not be uploaded.', loading: false }})
        })
    }

    const addProductToOccasion = (occasionId) => {
        setInfoOccasionModal(false);
        addToOccasion({ ProductId: parseInt(productID), OccassionId: occasionId }).then(({data})=>{
            if(data.status){
                setOccasionModal(true)
                setOccassionList(occassionList.map((item)=> item.Id === occasionId ? {...item, IsAdded: true} : item ))
            }
        }).catch((e)=>{
            console.log(e)
        })
    }
    

    return (
        notFound?
        <Error404/>:
        loading ?
        <section id="productSingle" className="pb-5">
            <BreadCrumbs pageTile="Product" breadcrumbItems={breadcrumbItems} />
            <div className="wrapper d-flex justify-between flex-wrap">
                <div className="single-product-gallery">
                    <Skeleton height={window.innerWidth < 1025 ? "250px" : "500px"} />
                </div>
                <div className="single-product-content">
                    <Skeleton className="dir-escape" height="50px" style={{ maxWidth: "500px", width: "100%" }} />
                    <Skeleton className="dir-escape" height="20px" style={{ maxWidth: "200px", width: "100%", marginBottom: "20px" }} />
                    <Skeleton className="dir-escape" height="30px" style={{ maxWidth: "300px", width: "100%", marginBottom: "30px" }} />
                    <Skeleton className="dir-escape" count={5} />
                    <Skeleton className="dir-escape" height="50px" style={{ maxWidth: "500px", width: "100%", marginTop: "50px", marginBottom: "30px" }} />
                    <Skeleton className="dir-escape" count={3} style={{ maxWidth: "150px", width: "100%" }} />
                </div>
            </div>
        </section> :
        <section id="productSingle">
            <BreadCrumbs pageTile="Product" breadcrumbItems={breadcrumbItems} />
            <SEO 
                metaTmetaTitle={product.MetaTitle ? product.MetaTitle : product.Name}
                metaDescription={product.MetaDescription ? product.MetaDescription : product.ShortDescription}
                metaKeywords={product.MetaKeywords ? product.MetaKeywords : product.Name.split(" ").join(".")}
                ogTitle={product.MetaTitle ? product.MetaTitle : product.Name}
                ogType="product"
                ogUrl={`https://wardstation.com/product/${productID}/${product.Name.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase()}`}
                ogImage={images[0].original}
                twTitle={product.MetaTitle ? product.MetaTitle : product.Name}
                twDescription={product.MetaDescription ? product.MetaDescription : product.ShortDescription}
                twImage={images[0].original}
            />
            <div className="wrapper d-flex justify-between flex-wrap">
                <div className="single-product-gallery">
                    <ReactImageGallery items={images} thumbnailPosition={"bottom"} showPlayButton={false} showBullets={false} />
                </div>
                <div className="single-product-content">
                    <div className="single-product-header d-inline-flex justify-between">
                        <h1 className="single-product-title fw-800 mt-0 mb-3 mr-2 fs-38 text-secondary ff-heading">{product.Name}</h1>
                    </div>
                    <div className="d-flex justify-between align-items-start">
                        <div className="product-price">
                            <h2 className="single-product-price text-secondary fw-400 fs-24 m-0"><span className="text-secondary">SAR <span className="text-primary">{ isCakeProduct.current ? cakeVariantValues.price : product.ProductPrice.Price} </span> { product.ProductPrice.IsDiscounted && product.ProductPrice.OldPrice ? <del className="single-product-old-price text-gray-300 fw-500 fs-20 mt-0 mb-0 ml-2"><s>SAR {product.ProductPrice.OldPrice}</s></del> : null }</span></h2>
                            <small className="d-block text-gray-400 fs-12 fw-400">{_T("(VAT included)")}</small>
                        </div>                        
                        {
                            product.ProductPrice.IsDiscounted && product.ProductPrice.DiscountPercentage ?
                            <span className="tag d-inline-flex align-items-center"><i className="ri-price-tag-3-fill fs-14 mr-2"></i> {product.ProductPrice.DiscountPercentage}%</span> : null
                        }
                    </div>
                    <div className="divider mt-4 mb-4"></div>
                    <p className="single-product-short-description fs-16 fw-400 text-gray-400">{product.ShortDescription}</p>
                    {
                        product.HasColor && !isCakeProduct.current ?
                        <div className="product-color-selector">
                        <p className="fs-14 fw-500 text-secondary">{_T("Select a Color:")}</p>
                        <div className="color-options">
                        {
                            product.Colors.map((color,index)=>{
                                return <Link className="color-option" key={index} to={"/product/"+color.AssociatedProductId} style={{ backgroundColor: color.ColorSquaresRgb }}></Link>
                            })
                        }
                            </div>
                        </div> : null
                    }
                    {
                        isCakeProduct.current ?
                        <div className="cake-variants mt-4">
                            <p className="fs-14 fw-400 text-gray-500 mb-2">{_T("Select Size of the Cake")}</p>
                            <div className="cake-variant">
                                {
                                    product.ProductAttributeDescription.find((a)=>a.NameEn === "Size").Values.map((v,i)=>{
                                        return <div className="single-variant" key={i}>
                                            <input type="radio" value={v.Name} id={v.Name+i} checked={cakeVariantValues.size === v.Id} onChange={(e)=>{ if(e.target.checked){ setCakeVariantValues((p)=>{ return {...p,size: v.Id, price: v.Price} }) } }} name="sizeVariant"/>
                                            <label htmlFor={v.Name+i} className="mb-3"><span className="variant-name">{v.Name}</span>{ v.Price ? <span className="variant-price">SAR {v.Price}</span> : null }</label>
                                        </div>
                                    })
                                }
                            </div>
                            <p className="fs-14 fw-400 text-gray-500 mb-2">{_T("Select Color of your Cake")}</p>
                            <div className="cake-variant">
                            {
                                    product.ProductAttributeDescription.find((a)=>a.NameEn === "Color").Values.map((v,i)=>{
                                        return <div className="single-variant" key={i}>
                                            <input type="radio" value={v.Name} id={v.Name+i} checked={cakeVariantValues.color === v.Id} onChange={(e)=>{ if(e.target.checked){ setCakeVariantValues((p)=>{ return {...p,color: v.Id} }) } }} name="colorVariant"/>
                                            <label htmlFor={v.Name+i} className="mb-3"><span className="variant-name">{v.Name}</span><span className="variant-color" style={{ backgroundColor: v.ColorSquaresRgb}} ></span></label>
                                        </div>
                                    })
                                }
                            </div>
                            <p className="fs-14 fw-400 text-gray-500 mb-2">{_T("Select Stuffing of your Cake")}</p>
                            <div className="cake-variant">
                                {
                                    product.ProductAttributeDescription.find((a)=>a.NameEn === "Stuffing").Values.map((v,i)=>{
                                        return <div className="single-variant" key={i}>
                                            <input type="radio" value={v.Name} id={v.Name+i} checked={cakeVariantValues.stuffing === v.Id} onChange={(e)=>{ if(e.target.checked){ setCakeVariantValues((p)=>{ return {...p,stuffing: v.Id} }) } }} name="stuffingVariant"/>
                                            <label htmlFor={v.Name+i} className="mb-3"><span className="variant-name">{v.Name}</span>{ v.Price ? <span className="variant-price">SAR {v.Price}</span> : null }</label>
                                        </div>
                                    })
                                }
                            </div>
                            <p className="fs-14 fw-400 text-gray-500 mb-2">{_T("Upload a picture you want to print on this cake")}</p>
                            <div className="upload-control">
                                {
                                    cakeVariantValues.imagePreview ?
                                    <div className="control-trigger justify-between cursor-normal" htmlFor="cakeImage">
                                        <div className="d-flex align-items-center" onClick={()=>{ setPreviewModal(true) }}>
                                            <i className="ri-eye-line"></i>
                                            <span className="control-text">Preview Image</span>
                                        </div>
                                        <span className="ri-close-line fs-18" onClick={()=>{ setCakeVariantValues((prev)=> { return { ...prev, image: null, imageId: 0, imagePreview: null, imageError: '' }}) }}></span>
                                    </div> :
                                    <label className="control-trigger" htmlFor="cakeImage">
                                        <i className="ri-image-2-line"></i>
                                        <span className="control-text">{ cakeVariantValues.loading ? _T("Please wait!") : _T("Upload Picture") }</span>
                                        <input type="file" id="cakeImage" className="d-none" disabled={cakeVariantValues.loading} accept="image/png, image/jpeg, image/jpg" onChange={handleAcceptedFiles}/>
                                    </label>
                                }
                            </div>
                            {
                                cakeVariantValues.imageError ? <p className="fs-12 fw-400 text-flushed">{cakeVariantValues.imageError}</p> : null
                            }
                            <p className="fs-14 fw-400 text-gray-500 mb-2">{_T("Enter the text you want on your cake")}</p>
                            <div className="form-group">
                                <input value={cakeVariantValues.text} placeholder={_T("Cake Text")} onChange={(e)=>{ setCakeVariantValues((p)=>{ return {...p, text: (e.target.value.slice(0,30))} }) }} />
                                <small className="d-block text-right mt-1 text-gray-400 fs-12">{cakeVariantValues.text.length}/30</small>
                            </div>
                        </div> : null
                    }
                    <div className="d-flex mb-3">
                        {
                            (product.StockDetails && product.StockDetails === "Out of stock") || isCakeProduct.current ?
                            null :
                            <div className="product-quantity-container d-inline-flex align-items-center mr-3">
                                <button className="product-quantity-btn" onClick={() => { quantity <= 1 ? setQuantity(1) : setQuantity(quantity - 1) }} >-</button>
                                <input className="product-quantity-field" value={quantity} placeholder="1" readOnly />
                                <button className="product-quantity-btn" onClick={() => { product.StockQuantity !== 0 ? quantity >= product.StockQuantity ? setQuantity(product.StockQuantity) : setQuantity(quantity + 1) : quantity >= 100 ? setQuantity(100) : setQuantity(quantity + 1) }} >+</button>
                            </div>
                        }
                        {
                            product.StockDetails && product.StockDetails === "Out of stock" ?
                            <button type={"button"} disabled={true} className="btn btn-primary mr-3">{_T("Out of Stock")}</button> :
                            isCakeProduct.current ?
                            <button type={"button"} className="btn btn-secondary mr-3" onClick={()=>{ addItemToCart() }} >{_T("Add to Cart")} (SAR {cakeVariantValues.price})</button> :
                            <button type={"button"} className="btn btn-primary mr-3" onClick={()=>{ product.HasColor ? setAlternativeProductModal(true) : addItemToCart() }} >{_T("Add to Cart")}</button>
                        }
                        {
                            isAuthorized ?
                            <button className="wishlist-custom-btn cursor-pointer" onClick={addRemoveWishlist}>
                                { IsInWishlist ?  < i className="ri-heart-3-fill text-secondary"></i>  :  < i className="ri-heart-3-line text-secondary"></i>  }
                            </button> : null
                        }
                    </div>
                    <div className="divider mt-4 mb-4"></div>
                    {
                        product.InfoBoxEnable ?
                        <div className="product-info-box bg-secondary pl-4 pr-4 pt-3 pb-3 mb-4">
                            <div className="info-box-header d-flex align-items-center mb-2">
                                <i className="ri-information-line mr-2 fs-20 text-primary"></i><h5 className="m-0 fs-14 text-primary fw-400">{product.InfoBoxTitle}</h5>
                            </div>
                            <div className="info-box-content text-light fs-14 fw-400">
                                {product.InfoBoxMessage}
                            </div>
                        </div> : null
                    }
                    
                    {
                        occassionList.length ?
                        <div className="product-occasions">
                            <div className="d-flex justify-between w-100">
                                <p className="mt-0 mb-3 w-100 fs-14 fw-500 text-secondary">{_T("Add to Occasion:")}</p>
                            </div>
                            <ScrollContainer className="scroll-container" horizontal={true} vertical={false}>
                                <div className="product-occasions-wrapper d-flex pl-1 pr-1">
                                    {
                                        occassionList.map((item,key)=>{
                                            return <label key={key} onClick={()=>{ if(!item.IsAdded){ product.DaysRequiredForDelivery ? (function () { setInfoOccasionModal(true); setSelectedOccasion(item.Id); })() : addProductToOccasion(item.Id); setSelectedOccasion(item.Id); } }} className={ item.IsAdded ? "product-occasion-single active" : "product-occasion-single"}>
                                                <img src={item.ImageUrl ? item.ImageUrl : calendarIcon} onError={(e) => { e.target.onerror = null; e.target.src = calendarIcon }} alt="Occasion Icon" draggable={false}/>
                                                <p className="fs-12 fw-400 mt-2 mb-0 text-center">{item.Name}</p>
                                            </label>
                                        })
                                    }
                                </div>
                            </ScrollContainer>
                        </div> : null
                    }
                    <h4 className="single-product-SKU text-gray-500 fw-500 fs-14 mt-0 mb-2 dir-switch"><span className="mr-2">SKU:</span>{product.Sku}</h4>
                    <div className="d-flex align-items-center fs-14 flex-wrap">
                        <span className="mr-2">{_T("Share:")}</span>
                        <a href={`http://www.facebook.com/share.php?u=${encodeURIComponent(window.location.href)}&title=${product.Name}`} rel="noreferrer" target="_blank" className="link">{_T("Facebook")}</a>,&nbsp;
                        <a href={`https://twitter.com/intent/tweet?text=${product.Name}&url=${encodeURIComponent(window.location.href)}`} rel="noreferrer" target="_blank" className="link">{_T("Twitter")}</a>,&nbsp;
                        <a href={`https://wa.me/966554255420?text=${encodeURIComponent(_T('I need to know more about the following product')+'\nhttps://wardstation.com/product/'+productID+'/'+ product.Name.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase())}\n\n`} rel="noreferrer" target="_blank" className="link">{_T("Whatsapp")}</a>
                    </div>
                </div>
            </div>
            <div className="wrapper additional-details mb-5">
                <div className="single-product-tabs">
                    <div className="tab-headings d-inline-flex align-items-center">
                        <h4 className={tab === 1 ? "single-heading active" : "single-heading"} onClick={() => { setTab(1) }}>{_T("Description")}</h4>
                        <h4 className={tab === 2 ? "single-heading active" : "single-heading"} onClick={() => { setTab(2) }}>{_T("Specification")}</h4>
                    </div>
                    <div className={tab === 1 ? "single-content" : "single-content d-none"}>
                        {
                            product.FullDescription && product.FullDescription.length > 4 ? <div dangerouslySetInnerHTML={{__html: product.FullDescription}} ></div> : <div>{_T("No description available")}</div>
                        }
                    </div>
                    <div className={tab === 2 ? "single-content" : "single-content d-none"} >
                        <div className="additional-info d-flex align-items-center mb-2">
                            <p className="fw-500 fs-14 text-gray-500 mt-0 mb-0 mr-2">SKU:</p>
                            <p className="fw-400 fs-16 text-secondary m-0">{product.Sku}</p>
                        </div>
                        <div className="additional-info d-flex align-items-center mb-2">
                            <p className="fw-500 fs-14 text-gray-500 mt-0 mb-0 mr-2">{_T("Brand:")}</p>
                            <p className="fw-400 fs-16 text-secondary m-0">{ product.Manufacturer && product.Manufacturer.Name ? product.Manufacturer.Name : "Not Available" }</p>
                        </div>
                        {
                            product.HasDimensions && product.SizeIconImageUrl && product.SizeIconImageUrl.length ?
                            <div className="additional-info size-info d-inline-block mt-4">
                                <img src={product.SizeIconImageUrl} alt="Product Size"/>
                                <span className="product-height">{product.Width} cm</span>
                                <span className="product-width">{product.Height} cm</span>
                            </div> : null
                        }
                    </div>
                </div>
            </div>
            {
                customizeableProducts && customizeableProducts.length ?
                <div className="related-products">
                    <ProductSlider data={{ Products: customizeableProducts, TagName: _T("Customizable") +" "+ _T("Products") }} showSeeAll={false} className={"slider mt-5 mb-0"} hasBgImage={false} hasBgColor={true} bgColor={"#fef8e9"}/>
                </div> : null
            }
            {
                relatedProducts && relatedProducts.length ?
                <div className="related-products">
                    <ProductSlider data={{ Products: relatedProducts, TagName: _T("Recommended Products") }} showSeeAll={false} className={"slider mt-0 mb-0"} hasBgImage={false} hasBgColor={true} bgColor={"#fef8e9"}/>
                </div> : null
            }

            {
                isCakeProduct.current ?
                <Modal className="cake-image-preview-modal" isOpen={previewModal} onClose={()=>{ setPreviewModal(false) }}>
                    <div className="text-center">
                        <img src={cakeVariantValues.imagePreview} alt="Cake Preview" className="mx-auto w-100"/>
                    </div>
                </Modal> : null
            }

            <Modal className="info-occasion-modal" isOpen={infoOccasionModal} modalSize="small">
                <div className="text-center">
                    <img src={infoIcon} alt="Info Icon" className="mx-auto" width={50}/>
                    <h4 className="text-gray-500 fs-18 fw-400 mb-0 mt-2">{_T("Alert!")}</h4>
                    <p className="text-gray-400 fs-16 fw-400 text-center mt-2">{_T("This product will take")} {product.DaysRequiredForDelivery} {_T("days for delivery")}</p>
                    <button className="btn btn-secondary w-100 mb-2" type="button" onClick={()=>{ addProductToOccasion(selectedOccasion) }}>{_T("Okay")}</button>
                </div>
            </Modal>

            <Modal className="add-occasion-modal" isOpen={occasionModal} modalSize="small">
                <div className="text-center">
                    <img src={cartImage} alt="Add Occasion" className="mx-auto"/>
                    <p className="text-gray-400 fs-16 fw-400 text-center">{_T("The item has been added to your selected occasion")}</p>
                    <button className="btn btn-primary w-100 mb-2" type="button" onClick={()=>{ setOccasionModal(false); setSelectedOccasion(0) }}>{_T("Continue Shopping")}</button>
                    <Link className="btn btn-secondary w-100" to={"/account/occasion/"+selectedOccasion}>{_T('View Occasion')}</Link>
                </div>
            </Modal>

            <Modal className="cart-modal" isOpen={cartModal} modalSize="small">
                <div className="text-center">
                    <img src={cartImage} alt="Cart Icon" className="mx-auto" width={110}/>
                    <p className="text-gray-400 fs-16 fw-400 text-center">{_T("The item has been added to your shopping cart")}</p>
                    <button className="btn btn-primary w-100 mb-2" type="button" onClick={()=>{ setCartModal(false) }}>{_T("Continue Shopping")}</button>
                    <Link className="btn btn-secondary w-100" to={"/cart"}>{_T("Checkout")}</Link>
                </div>
            </Modal>

            <Modal className="select-alternative-modal" isOpen={alternativeProductModal} modalSize="small" onClose={()=>{ setAlternativeProductModal(false); setShowAlternativeOption(false); setSelectedColorAlternative(false); }} >
                {
                    !showAlternativeOption ?
                    <div className="text-center">
                        <img src={infoIcon} alt="Info Icon" className="mx-auto" width={50}/>
                        <p className="text-gray-400 fs-16 fw-400 text-center mb-0">{_T("If the item is not available")}</p>
                        <p className="text-gray-400 fs-16 fw-400 text-center mt-0">{_T("What would you like to do?")}</p>
                        <button className="btn btn-primary w-100 mb-2" type="button" onClick={()=>{ addItemToCart() }}>{_T("Remove Item")}</button>
                        <button className="btn btn-secondary w-100" type="button" onClick={()=>{ setShowAlternativeOption(true) }}>{_T("Select Alternative")}</button>
                    </div> :
                    <div className="text-center">
                        <img src={clickIcon} alt="Click Icon" className="mx-auto" width={50}/>
                        <p className="text-gray-400 fs-16 fw-400 text-center">{_T("Select Alternative Color")}</p>
                        {
                            product.HasColor ?
                            <div className="product-color-selector">
                                <div className="color-options">
                                    {
                                        product.Colors.map((color,index)=>{
                                            return <span onClick={()=>{ setSelectedColorAlternative(color.AssociatedProductId) }} className={`color-option ${color.AssociatedProductId === selectedColorAlternative ? "selected" : ""} `} key={index} style={{ backgroundColor: color.ColorSquaresRgb }}></span>
                                        })
                                    }
                                </div>
                            </div> : null
                        }
                        <button className="btn btn-secondary w-100" disabled={!selectedColorAlternative} type="button" onClick={addItemToCart}>{_T("Confirm")}</button>
                    </div>
                }
            </Modal>
        </section>
    )
}

export default ProductDetail