function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "My Saved Cards": {
            "en": "My Saved Cards",
            "ar": "بطاقاتي المحفوظة"
        },
        "You currently have no payment cards.": {
            "en": "You currently have no payment cards.",
            "ar": "ليس لديك حاليا بطاقات الدفع."
        },
        "Payment Card Deleted": {
            "en": "Payment Card Deleted",
            "ar": "تم حذف بطاقة الدفع"
        },
        "Your Card has been deleted!": {
            "en": "Your Card has been deleted!",
            "ar": "تم حذف بطاقتك!"
        },
        "Delete Failed": {
            "en": "Delete Failed",
            "ar": "فشل حذف البطاقة"
        },
        "Your Card could not be deleted!": {
            "en": "Your Card could not be deleted!",
            "ar": "لا يمكن حذف بطاقتك!"
        },
        "Payment Card": {
            "en": "Payment Card",
            "ar": "بطاقة الدفع"
        },
        "Your Card has been updated!": {
            "en": "Your Card has been updated!",
            "ar": "تم تحديث بطاقتك!"
        },
        "Update Failed": {
            "en": "Update Failed",
            "ar": "فشل التحديث"
        },
        "Your Card could not be updated!": {
            "en": "Your Card could not be updated!",
            "ar": "لا يمكن حذف بطاقتك!"
        },
        "Card Holder Name": {
            "en": "Card Holder Name",
            "ar": "إسم صاحب البطاقة"
        },
        "Expiry Date": {
            "en": "Expiry Date",
            "ar": "تاريخ الانتهاء"
        },
        "Delete": {
            "en": "Delete",
            "ar": "مسح"
        }
    }

    return translations[text] ? translations[text][locale] : text;
}
export default _T;