function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "About Us | Online Flowers Shop | Wardstation": {
            "en": "About Us | Online Flowers Shop | Wardstation",
            "ar": "عن | متجر الزهور عبر الإنترنت | ورد ستيشن"
        },
        "Wardstation, where you can find a wide range of beautiful flowers and thoughtful gifts. Explore our stunning bouquets, delicious chocolates, and delightful cakes, and find the perfect way to show love and appreciation for any occasion. With quick delivery available across KSA, experience the convenience of gifting with Wardstation. Order now!": {
            "en": "Wardstation, where you can find a wide range of beautiful flowers and thoughtful gifts. Explore our stunning bouquets, delicious chocolates, and delightful cakes, and find the perfect way to show love and appreciation for any occasion. With quick delivery available across KSA, experience the convenience of gifting with Wardstation. Order now!",
            "ar": "ورد ستيشن، حيث يمكنك العثور على مجموعة واسعة من الزهور الجميلة والهدايا العميقة المعنى. استكشف باقاتنا الرائعة، والشوكولاتة اللذيذة، والكعك اللذيذ، وابحث عن الطريقة المثالية لتظهر الحب والتقدير في أي مناسبة. مع التوصيل السريع المتاح في جميع أنحاء المملكة العربية السعودية، اختبر سهولة إهداء الهدايا مع ورد ستيشن. اطلب الآن"
        },
        "flower shop, gift shop, flowers delivery, chocolates, cakes, Wardstation, KSA, online gifts shop": {
            "en": "flower shop, gift shop, flowers delivery, chocolates, cakes, Wardstation, KSA, online gifts shop",
            "ar": "متجر زهور، متجر هدايا، توصيل الزهور، شوكولاتة، كعك، ورد ستيشن، المملكة العربية السعودية، متجر هدايا عبر الإنترنت"
        },
        "About Us": {
            "en": "About Us",
            "ar": "معلومات عنا"
        },
        "Who we are": {
            "en": "Who we are",
            "ar": "من نحن"
        },
        "Wardstation is an online flowers and gifting company; we pride ourselves on creating a seamless experience for our customers and making sending flowers and gifts as enjoyable as receiving them. We source our flowers daily from the best growers and farmers around the world to create one of a kind stunning arrangements and plants that fit every occasion. We partner with local and international brands to offer a wide range of gifts including chocolate, perfumes, cakes and more, coupled with our arrangements to create the perfect gift. We also partner with exciting and creative designers to give them the opportunity to express themselves by creating their own exquisite bouquets and showcase them on our store. we guarantee same-day delivery across the board through our own refrigerated fleet to ensure our arrangements arrive fresh to the customer.": {
            "en": "Wardstation is an online flowers and gifting company; we pride ourselves on creating a seamless experience for our customers and making sending flowers and gifts as enjoyable as receiving them. We source our flowers daily from the best growers and farmers around the world to create one of a kind stunning arrangements and plants that fit every occasion. We partner with local and international brands to offer a wide range of gifts including chocolate, perfumes, cakes and more, coupled with our arrangements to create the perfect gift. We also partner with exciting and creative designers to give them the opportunity to express themselves by creating their own exquisite bouquets and showcase them on our store. we guarantee same-day delivery across the board through our own refrigerated fleet to ensure our arrangements arrive fresh to the customer.",
            "ar": "وردستيشن هي شركة زهور وهدايا عبر الإنترنت ، ونحن نفخر بأنفسنا لأننا نخلق تجربة سلسة لعملائنا وجعل إرسال الزهور والهدايا أمرًا ممتعًا مثل استقبالهم. زهورنا تأتي يوميًا من أفضل المزارعين والمزارعين حول العالم لإنشاء ترتيبات ونباتات مذهلة فريدة من نوعها تناسب كل مناسبة. نتشارك مع العلامات التجارية المحلية والعالمية لتقديم مجموعة واسعة من الهدايا بما في ذلك الشوكولاتة والعطور والكعك والمزيد ، إلى جانب ترتيباتنا لإنشاء الهدية المثالية. نتشارك أيضًا مع مصممين مثيرين ومبدعين لمنحهم الفرصة للتعبير عن أنفسهم من خلال إنشاء باقات رائعة خاصة بهم وعرضها في متجرنا. نحن نضمن التسليم في نفس اليوم من خلال المركبات المبردة لضمان اكتمال أفضل تجربة للعميل."
        },
        "Our Mission": {
            "en": "Our Mission",
            "ar": "مهمتنا"
        },
        "To capture new moments of appreciation to a wider range of people, by being creative in the ease of access, reliability and premium quality of our products.": {
            "en": "To capture new moments of appreciation to a wider range of people, by being creative in the ease of access, reliability and premium quality of our products.",
            "ar": "لالتقاط اللحظات الجديرة بالتقدير للجميع ، من خلال الإبداع وسهولة الوصول والموثوقية والجودة العالية لمنتجاتنا ."
        },
        "Our Vision": {
            "en": "Our Vision",
            "ar": "رؤيتنا"
        },
        "To create a world where people cease every moment to express their feelings and bringing more happiness by making flowers part of every gift": {
            "en": "To create a world where people cease every moment to express their feelings and bringing more happiness by making flowers part of every gift",
            "ar": "الخلق عالم يستقطع فيه الناس كل لحظة للتعبير عن مشاعرهم وجلب المزيد من السعادة من خلال جعل الزهور جزءا من كل هدية."
        },
        "Home": {
            "en": "Home",
            "ar": "الصفحة الرئيسية"
        }
    }

    return translations[text] ? translations[text][locale] : text;
}
export default _T;