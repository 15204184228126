import React from "react";
import "../../assets/css/--component-account-favourites.css"
import FavouriteCard from "./favourite-card";
import { getFavorites } from "../../helper/backend-methods";
import { useEffect,useState } from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import ErrorMsg from "../../components/ErrorMessage";
import { googleTagInitialize, pixelPageEvent, scrollToTop } from "../../common";
import { Toaster } from "../../components/Toaster";
import _T from "../../locale/favourite";
function Favourites(params) {
    const [favouritesData,setFavouritesData] = useState({})
    const [loading, setLoading] = useState(true)
    const [update, setUpdate] = useState(1)

    useEffect(() => {
        document.title = 'Wishlist | Wardstation';
        scrollToTop();
        setLoading(true);
        getFavorites().then(({ data }) => {
            if (data.status) {
                setFavouritesData(data.result.WishlistItems)
            }
            setLoading(false)   
        }).catch(e => {
            setLoading(false)  
            Toaster("error",_T("Request Failed"),_T("We could not fetch your wishlist!"))
        })
    }, [update])

    useEffect(()=>{
        pixelPageEvent();
        googleTagInitialize();
    },[])

    return(
        <div className="favourites">
            <div className="favourite-container">
                <div className="account-header">
                    <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'>{_T("Favourites")}</h1>  
                </div>
                <div className="favourite-cards d-flex flex-wrap">
                {
                    !loading ? 
                    favouritesData.length ?
                    favouritesData.map((item,index)=>{
                        return <FavouriteCard key={index} product={item.Product} setUpdate={setUpdate}/>
                    }) : <ErrorMsg text={_T("You currently have no items in your wishlist.")}/> :
                    [...Array(6)].map((item,index)=>{
                        return <div key={index} className="favourite-card fav-card-skeleton"><Skeleton height={"100%"} /></div>
                    })
                }
                </div>
            </div>
        </div>
    )
}
export default Favourites