import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ReactPixel from 'react-facebook-pixel';
import { GlobalContextProvider } from "./context/GlobalContext";

if(process.env.REACT_APP_ENABLE_FACEBOOK_PIXEL && process.env.REACT_APP_ENABLE_FACEBOOK_PIXEL === "YES"){
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL);
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<GlobalContextProvider><App/></GlobalContextProvider>);