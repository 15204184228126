import React, { memo, useState } from "react";
import { useNavigate } from 'react-router-dom'
import Carousel from 'react-multi-carousel';
import { useRef } from 'react';
import Skeleton from "react-loading-skeleton";
import { getLocale } from "../../common";
import defaultImage from "../../assets/images/no-image.jpg"
import _T from "../../locale/home";

function ShopOccasion(props) {
    const { isLoading, occasions, hasBgImage, hasBgColor, bgImage, bgColor } = props
    const xAxisStart = useRef(0)
    const carouselRef = useRef(null);
    const isEn = getLocale() === "en" ? true : false;
    const navigate = useNavigate();
    const responsivness = {
        10000: {
            breakpoint: { max: 10000, min: 1400 },
            items: 6,
        },
        1400: {
            breakpoint: { max: 1400, min: 1100 },
            items: 5,
        },
        1100: {
            breakpoint: { max: 1100, min: 900 },
            items: 4,
        },
        900: {
            breakpoint: { max: 900, min: 600 },
            items: 3,
        },
        700: {
            breakpoint: { max: 700, min: 450 },
            items: 2,
            partialVisibilityGutter: 200,
        },
        450: {
            breakpoint: { max: 450, min: 0 },
            items: 2,
            partialVisibilityGutter: 0,
        },
    };
    const itemClicked = (e,link) =>{
        if(Math.abs(xAxisStart.current - e.clientX) < 20){
            navigate(link)
        }
        xAxisStart.current = 0
    }

    const handlePrevious = () => {
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };
    
    return (
        !isLoading && occasions.length ?
        <section className='occasion-section general-occasions' style={{ backgroundImage: hasBgImage ? `url(${bgImage})` : 'none', backgroundColor: hasBgColor ? bgColor : '#fff' }}>
            <div className='wrapper d-flex align-items-center justify-between'>
                <div className='section-header mb-4'>
                    <h2 className='ff-heading text-secondary fs-36 fw-900 has-arrow-start'>{_T("Shop By Occasions")}</h2>
                </div>
                {
                    isEn ?
                    <div className="carousel-controls mb-4">
                        <button onClick={handlePrevious} className='navigation-control navigation-previous'><i className="ri-arrow-left-s-line"></i></button>
                        <button onClick={handleNext} className='navigation-control navigation-next'><i className="ri-arrow-right-s-line"></i></button>
                    </div> :
                    <div className="carousel-controls mb-4">
                        <button onClick={handlePrevious} className='navigation-control navigation-previous'><i className="ri-arrow-right-s-line"></i></button>
                        <button onClick={handleNext} className='navigation-control navigation-next'><i className="ri-arrow-left-s-line"></i></button>
                    </div>
                }
            </div>

            <div className='wrapper occasion-carousal'>
                <Carousel
                    ref={carouselRef}
                    responsive={responsivness}
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite={false}
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={!isEn}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable

                >
                    {
                        props.isLoading ?
                            [...Array(10)].map((item, key) => (
                                <div className="upcoming-occasion-spacer" key={key}>
                                    <div className="upcoming-occasion-container cursor-pointer">
                                        <Skeleton className="upcoming-occasion-loader"></Skeleton>
                                    </div>
                                </div>
                            )) :
                            occasions.map((occasion, key) => (
                                <div className="upcoming-occasion-spacer" key={key}>
                                    <div className="upcoming-occasion-container shop-occasion">
                                        <div className="upcoming-occasion-header cursor-pointer" onMouseDown={(e)=>{ xAxisStart.current = e.clientX }} onMouseUp={(e)=>itemClicked(e,`/occasion-products/${occasion.Id}/${occasion.Name ? occasion.Name.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : _T("occasion")}`)}>
                                            <div className="upcoming-occasion-img text-center">
                                                <img src={ isEn ? occasion.PicturURL : occasion.PicturURL_Ar} alt={occasion.Name} onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }} draggable={false}/>
                                            </div>
                                        </div>
                                        <div className="upcoming-occasion-footer">
                                            <p className="text-secondary fw-500 cursor-pointer" onMouseDown={(e)=>{ xAxisStart.current = e.clientX }} onMouseUp={(e)=>itemClicked(e,`/occasion-products/${occasion.Id}/${occasion.Name ? occasion.Name.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : _T("occasion")}`)}>{occasion.Name}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                    }
                </Carousel>
            </div>
        </section> : null
    )
}
export default ShopOccasion;