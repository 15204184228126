import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { cancelSubscription, getSubcriptionOrderDetail } from "../../helper/backend-methods";
import subBannerImg from '../../assets/images/Group 74512.png'
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { Link } from "react-router-dom";
import { convertDateTimeWithLocale, scrollToTop } from "../../common";
import _T from "../../locale/subscription";
import { Toaster } from "../../components/Toaster";

function SubscriptionDetailCard() {
    let params = useParams();
    const subId = params.id
    const [subData, setSubData] = useState()
    const [subProduct, setSubProduct] = useState([])
    const [subOrders, setSubOrders] = useState([])
    const [loading, setLoading] = useState(true)
    const [btnLoading, setBtnLoading] = useState(false)
    const [update, setUpdate] = useState(0)
    useEffect(() => {
        document.title = 'Subscription Details | Wardstation';
        scrollToTop();
        getSubcriptionOrderDetail({ subscriptionId: subId }).then(({ data }) => {
            setSubData(data.result.Subscription)
            setSubProduct(data.result.Subscription.products)
            setSubOrders(data.result.Orders)
            setLoading(false)
            setBtnLoading(false)
        })
    }, [update])

    const onClickCancel = () => {
        cancelSubscription("?subscriptionid="+subId).then(({data})=>{
            if(data.status){
                Toaster("success", _T("Subscription Cancelled"), _T("Your subscription has been cancelled"));
                setUpdate((prev)=>{ return prev + 1});
            }
            else{
                Toaster("error", _T("Request Failed"), _T("Subscription could not be cancelled"))
                setBtnLoading(false)
            }
        }).catch((e)=>{
            Toaster("error", _T("Request Failed"), _T("Subscription could not be cancelled"))
            setBtnLoading(false)
        })
    }
    return (
        <div className="sub-detail-card">
            <div className="account-header d-flex align-items-center justify-between">
                <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'>{_T("Subscription Details")}</h1>
            </div>
            {
                loading ?
                <>
                    <div className="sub-detail-heading-wrapper d-flex justify-between align-items-center flex-wrap">
                        <div className="account-sub-card-heading">
                            <p className="fs-16 fw-400 text-secondary m-0">{_T("Id:")}</p>
                            <p className="fs-16 fw-400 text-primary m-0"><Skeleton/></p>
                        </div>
                        <div className="account-sub-card-heading heading-period">
                            <p className="fs-16 fw-400 text-secondary m-0">{_T("Period:")}</p>
                            <p className="fs-16 fw-400 text-primary m-0"><Skeleton/></p>
                        </div>
                        <div className="account-sub-card-heading">
                            <p className="fs-16 fw-400 text-secondary m-0">{_T("Deliveries:")}</p>
                            <p className="fs-16 fw-400 text-primary m-0"><Skeleton/></p>
                        </div>
                        <div className="account-sub-card-heading heading-remaining">
                            <p className="fs-16 fw-400 text-secondary m-0">{_T("Remaining:")}</p>
                            <p className="fs-16 fw-400 text-primary m-0"><Skeleton/></p>
                        </div>
                        <div className="account-sub-card-heading">
                            <p className="fs-16 fw-400 text-secondary m-0">{_T("Intervals:")}</p>
                            <p className="fs-16 fw-400 text-primary m-0"><Skeleton/></p>
                        </div>
                    </div>
                    <div className="sub-plan-banner mt-3">
                        <div className="sub-plan-container d-flex align-items-center flex-wrap">
                            <div className="sub-plan-right">
                                <img src={subBannerImg} alt="Subscription Banner" />
                            </div>
                            <div className="sub-plan-left">
                                <p className="fs-14 fw-400 text-gray-100 m-0">{_T("Your subscription plan start from")}</p>
                                <p className="fs-16 fw-500 text-primary m-0"><Skeleton width={150}/></p>
                                <p className="fs-14 fw-800 text-gray-100  m-0"><Skeleton width={150}/></p>
                                <p className="fs-16 fw-500 text-primary m-0">{_T("Payment:")} <Skeleton width={80}/></p>
                            </div>
                        </div>
                    </div>
                    <div className="sub-product-detail-header-wrapper mt-3">
                        <div className="sub-product-detail-header d-flex justify-between ">
                            <div className="sub-prod-heading">
                                <p className="fs-16 fw-400 text-secondary">{_T("Subscribed Product")}</p>
                            </div>
                            <div className="sub-prod-heading">
                                <p className="fs-16 fw-400 text-secondary text-right">{_T("Quantity")}</p>
                            </div>
                            <div className="sub-prod-heading">
                                <p className="fs-16 fw-400 text-secondary text-right">{_T("Total")}</p>
                            </div>
                        </div>
                        <div className="underline mt-3 mb-3"></div>
                        <div className="sub-product-detail-container d-flex justify-between align-items-center flex-wrap">
                            {
                                [...Array(3)].map((item, key) => {
                                    return <React.Fragment key={key}>
                                        <div className="sub-product-content">
                                            <div className="product-img-name d-flex align-items-center">
                                                <div className="sub-prod-img mr-2">
                                                <Skeleton width={100} height={100}/>
                                                </div>
                                                <div className="sub-product-name-color">
                                                    <p className="fs-12 fw-400 m-0 text-secondary"><Skeleton width={80} height={20}/></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sub-product-content">
                                            <p className="fs-14 fw-400 text-secondary text-right"><Skeleton width={20}/></p>
                                        </div>
                                        <div className="sub-product-content">
                                            <p className="fs-14 fw-400 text-primary text-right"><Skeleton width={80}/></p>
                                        </div>
                                    </React.Fragment>
                                })
                            }
                        </div>
                    </div>
                    <div className="sub-product-order-listing">
                        <div className="sub-account-header d-flex align-items-center justify-between mt-3">
                            <h1 className='fs-20 text-gray-500 fw-500 mt-0 mb-0'>{_T("Orders")}</h1>
                        </div>
                        {
                            [...Array(3)].map((item, key) => {
                                return <div className={"sub-product-order-listing-details-wrapper mt-3"} key={key}>
                                <div className="sub-product-order-listing-details d-flex justify-between align-items-center flex-wrap">
                                    <div className="sub-listing-content">
                                        <p className="fs-14 fw-400 text-secondary m-0">{_T("Order No:")}</p>
                                        <p className="fs-14 fw-400 text-primary m-0"><Skeleton/></p>
                                    </div>
                                    <div className="sub-listing-content listing-date">
                                        <p className="fs-14 fw-400 text-secondary m-0">{_T("Date:")}</p>
                                        <p className="fs-14 fw-400 text-primary m-0"><Skeleton/></p>
                                    </div>
                                    <div className="sub-listing-content">
                                        <p className="fs-14 fw-400 text-secondary m-0">{_T("Status:")}</p>
                                        <p className="fs-14 fw-400 text-primary m-0"><Skeleton/></p>
                                    </div>
                                    <div className="sub-listing-content listing-btn text-right">
                                        <button type="button" className="btn btn-primary">{_T("View Details")}</button>
                                    </div>
                                </div>
                            </div>
                            })
                        }
                    </div>
                </> :
                <>
                    <div className="sub-detail-heading-wrapper d-flex justify-between align-items-center flex-wrap">
                        <div className="account-sub-card-heading">
                            <p className="fs-16 fw-400 text-secondary m-0">{_T("Id:")}</p>
                            <p className="fs-16 fw-400 text-primary m-0">{subData.Id}</p>
                        </div>
                        <div className="account-sub-card-heading">
                            <p className="fs-16 fw-400 text-secondary m-0">{_T("Period:")}</p>
                            <p className="fs-16 fw-400 text-primary m-0">{_T(subData.PeriodType)}</p>
                        </div>
                        <div className="account-sub-card-heading">
                            <p className="fs-16 fw-400 text-secondary m-0">{_T("Deliveries:")}</p>
                            <p className="fs-16 fw-400 text-primary m-0">{subData.TotalDeliveries}</p>
                        </div>
                        <div className="account-sub-card-heading">
                            <p className="fs-16 fw-400 text-secondary m-0">{_T("Remaining:")}</p>
                            <p className="fs-16 fw-400 text-primary m-0">{subData.RemainingDeliveries}</p>
                        </div>
                        <div className="account-sub-card-heading">
                            <p className="fs-16 fw-400 text-secondary m-0">{_T("Intervals:")}</p>
                            <p className="fs-16 fw-400 text-primary m-0">{subData.Intervals}</p>
                        </div>
                    </div>
                    <div className="sub-plan-banner mt-3">
                        <div className="sub-plan-container d-flex align-items-center flex-wrap">
                            <div className="sub-plan-right d-inline-flex mr-4">
                                <img src={subBannerImg} alt="Subscription Banner" />
                            </div>
                            <div className="sub-plan-left">
                                <p className="fs-14 fw-400 text-gray-100 m-0">{_T("Your subscription plan start from")}</p>
                                <p className="fs-16 fw-500 text-primary m-0">{convertDateTimeWithLocale(moment(subData.StartDateUTC).format("MMM Do"))} To {convertDateTimeWithLocale(moment(subData.EndDateUTC).format("MMM Do"))}</p>
                                <p className="fs-14 fw-400 text-gray-100  m-0">{subData.TimeSlot}</p>
                                <p className="fs-16 fw-500 text-primary m-0">{_T("Payment:")} {_T(subData.PaymentMethod)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="sub-product-detail-header-wrapper mt-3">
                        <div className="sub-product-detail-header d-flex justify-between ">
                            <div className="sub-prod-heading">
                                <p className="fs-16 fw-400 text-secondary">{_T("Subscribed Product")}</p>
                            </div>
                            <div className="sub-prod-heading">
                                <p className="fs-16 fw-400 text-secondary text-right">{_T("Quantity")}</p>
                            </div>
                            <div className="sub-prod-heading">
                                <p className="fs-16 fw-400 text-secondary text-right">{_T("Total")}</p>
                            </div>
                        </div>
                        <div className="underline mt-3 mb-3"></div>
                        <div className="sub-product-detail-container d-flex justify-between align-items-center flex-wrap">
                            {
                                subProduct.map((item, key) => {
                                    return <React.Fragment key={key}>
                                        <div className="sub-product-content">
                                            <div className="product-img-name d-flex align-items-center">
                                                <div className="sub-prod-img mr-2">
                                                    <img src={item.PictureURL} alt="" />
                                                </div>
                                                <div className="sub-product-name-color">
                                                    <p className="fs-12 fw-400 m-0 text-secondary">{item.ProductName}</p>
                                                    {item.AlternateColor ? <p className="fs-14 fw-400 m-0 text-secondary">{_T("Color:")} {_T("Red")}</p> : <></>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sub-product-content">
                                            <p className="fs-14 fw-400 text-secondary text-right sub-prod-qty text-en">{item.Quantity}</p>
                                        </div>
                                        <div className="sub-product-content">
                                            <p className="fs-14 fw-400 text-primary text-right sub-prod-price text-en">SAR {item.Price}</p>
                                        </div>
                                    </React.Fragment>
                                })
                            }
                        </div>
                    </div>
                    <div className="sub-product-order-listing">
                        <div className="sub-account-header d-flex align-items-center justify-between mt-3">
                            <h1 className='fs-20 text-gray-500 fw-500 mt-0 mb-0'>{_T("Orders")}</h1>
                        </div>
                        {
                            subOrders.map((item, key) => {
                                return <div className={item.OrderStatus === "NotCreated" ? "sub-product-order-listing-details-wrapper gray-out mt-3" : "sub-product-order-listing-details-wrapper mt-3"} key={key}>
                                <div className="sub-product-order-listing-details d-flex justify-between align-items-center flex-wrap">
                                    <div className="sub-listing-content">
                                        <p className="fs-14 fw-400 text-secondary m-0">{_T("Order No:")}</p>
                                        <p className="fs-14 fw-400 text-primary m-0">{item.OrderNo ? item.OrderNo : "N/A"}</p>
                                    </div>
                                    <div className="sub-listing-content listing-date">
                                        <p className="fs-14 fw-400 text-secondary m-0">{_T("Date:")}</p>
                                        <p className="fs-14 fw-400 text-primary m-0">{item.ExpectedDelivery ? convertDateTimeWithLocale(moment(item.ExpectedDelivery).format("Do MMM YYYY")) : "N/A"}</p>
                                    </div>
                                    <div className="sub-listing-content">
                                        <p className="fs-14 fw-400 text-secondary m-0">{_T("Status:")}</p>
                                        <p className="fs-14 fw-400 text-primary m-0">{item.OrderStatus ? _T(item.OrderStatus) : ""}</p>

                                    </div>
                                    <div className="sub-listing-content listing-btn text-right">
                                        {
                                            item.OrderStatus === "NotCreated" ? 
                                            <button type="button" disabled={true} className="btn btn-disabled">{_T("View Details")}</button>:
                                            <Link to={"/account/orders/"+item.Id} className="btn btn-primary">{_T("View Details")}</Link>
                                        }
                                    </div>
                                </div>
                            </div>
                            })
                        }
                    </div>
                    
                    {
                        !subData.IsCancel && subData.RemainingDeliveries > 0 ?
                        <button className="btn btn-danger mt-4" disabled={btnLoading} type="button" onClick={()=>{ setBtnLoading(true); onClickCancel(); }}>{_T("Cancel Subscription")}</button> : null
                    }
                </>
            }
        </div>
    )
}

export default SubscriptionDetailCard