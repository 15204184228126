import React, { createContext, useState } from 'react';
const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const [warehouseId, setWarehouseId] = useState(0);
  const [warehouseList, setWarehouseList] = useState([]);
  const [occasionId, setOccasionId] = useState(0);
  const [cartCount, setCartCount] = useState(0);
  const [brandList, setBrandList] = useState([]);
  const [consent, setConsent] = useState({ identity: false, tracking: false, page: false });

  const updateSelectedWarehouse = (value) => {
    setWarehouseId(value)
  };
  const updateWarehouseList = (value) => {
    setWarehouseList(value)
  };
  const updateActiveOccasion = (value) => {
    setOccasionId(value)
  };
  const updateCartCount = (value) => {
    setCartCount(value)
  };
  const updateBrandList = (value) => {
    setBrandList(value)
  };
  const updateConsent = (value) => {
    setConsent(value)
  };

  return (
    <GlobalContext.Provider value={{ warehouseId, warehouseList, occasionId, cartCount, brandList, consent, updateSelectedWarehouse, updateWarehouseList, updateActiveOccasion, updateCartCount, updateConsent, updateBrandList }}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalContextProvider };