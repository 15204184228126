import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { convertDateTimeWithLocale } from '../../common';
import moment from 'moment/moment';
import _T from '../../locale/blog';

const BlogCard = ({ blog }) => {
  const [characterLimit, setCharacterLimit] = useState(() => {
    if (window.innerWidth <= 768 && window.innerWidth > 580) {
      return 175;
    } else if (window.innerWidth <= 580) {
      return 80;
    } else {
      return 300;
    }
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768 && window.innerWidth > 580) {
        setCharacterLimit(175);
      } else if (window.innerWidth <= 580) {
        setCharacterLimit(80);
      } else {
        setCharacterLimit(300);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function sanitizeHtml(input) {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.body.textContent || '';
  }

  return (
    <div className="blog-card">
      <Link to={`/blog/${blog.Id}/${blog.Title ? blog.Title.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : _T("post")}`}>
        <div className="blog-image">
          <img src={blog.PictureUrl} alt="BlogImage" />
        </div>
      </Link>
      <div className="blog-details">
        <Link to={`/blog/${blog.Id}/${blog.Title ? blog.Title.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : _T("post")}`}>
          <h3 className='mt-0 mb-2'>{blog.Title}</h3>
        </Link>
        <p className='mt-0 mb-2 blog-date'><i className="ri-time-line mr-1"></i>{convertDateTimeWithLocale(moment(blog.CreatedOnUtc).format("MMM Do YYYY"))}</p>
        <p className='mt-0 mb-2'>{blog.BodyOverview && blog.BodyOverview.length ? blog.BodyOverview.length > characterLimit ? sanitizeHtml(blog.BodyOverview.slice(0, characterLimit) + "...") : sanitizeHtml(blog.BodyOverview) : ""}</p>
      </div>
    </div>
  );
};

export default BlogCard;
