import React from "react";
import SubBanner from "../../assets/images/subscription-banner.png"
import { getLocale } from "../../common";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

function Subscription ({data,isLoading}){
    const locale = getLocale()
    const SubscriptionBanner = isLoading ? [] : data;
    const navigate = useNavigate();
    const itemClicked = () =>{
        navigate("/subscription/")
    }

    
    return(
        isLoading ?
        <section className="subscription-banner">
            <div className="wrapper">
                <Skeleton className="subscription-banner-loading w-100" />
            </div>
        </section> :
        SubscriptionBanner && SubscriptionBanner.length && SubscriptionBanner[0] ?
        <section className="subscription-banner cursor-pointer" onClick={itemClicked}>
            <div className="wrapper">
                <img className="w-100 d-block" src={locale === "ar" ? SubscriptionBanner[0].PictureURL_Ar : SubscriptionBanner[0].PictureURL} alt="Subscription Banner" />
            </div>
        </section> : null
    )
}

export default Subscription