import React,{useEffect, useState} from "react";
import '../../assets/css/--component-account-personal-info.css'
import PersonalInfoCard from "./personal-info-card";
import { googleTagInitialize, pixelPageEvent, scrollToTop } from "../../common";

function PersonalInfo(){
    useEffect(()=>{
        document.title = 'Profile | Wardstation';
        scrollToTop();
    },[])
    
    useEffect(()=>{
        pixelPageEvent();
        googleTagInitialize();
    },[])
    
    return(
        <div className="main-container">
            <PersonalInfoCard/>
        </div>
    )
}

export default PersonalInfo