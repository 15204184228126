import React, { useEffect, useState } from 'react';
import BlogCard from './blogCard';
import '../../assets/css/--page-blog.css';
import "../../assets/css/--page-additional.css";
import BreadCrumbs from "../../components/BreadCrumbs"
import { getAllBlogs } from '../../helper/backend-methods';
import Skeleton from 'react-loading-skeleton';
import _T from '../../locale/blog';
import { TablePagination } from '../../components/TablePagination';
import ErrorMsg from '../../components/ErrorMessage';
import SEO from '../../components/SEO';

const BlogComponent = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const limit = 5;


  const breadcrumbItems = [
    { title: _T("Home"), link: "/" },
    { title: _T("Blogs"), link: "" },
  ]
  useEffect(() => {
    fetchBlogs();
  }, [])

  const fetchBlogs = (pageSize = 5, pageIndex = 0) => {
    setLoading(true)
    setBlogs([])
    getAllBlogs({ pageSize: pageSize, pageIndex: pageIndex }).then(({ data }) => {
      if (data.status) {
        setBlogs(data && data.result.res ? data.result.res : [])
        setTotal(data && data.result.totalBlogCount ? data.result.totalBlogCount : 0)
      }
      setLoading(false)
    }).catch((e) => {
      console.log(e)
    })
  }

  const onPaginationChange = (currentPage) => {
    if (page !== currentPage) {
      setPage(currentPage);
      fetchBlogs(limit, 1 * (currentPage - 1))
    }
  }

  return (
    <section className='additional-page'>
      <div className="page-header text-center underline">
        <h1 className="has-arrow-start ff-heading has-arrow-white fw-900">{_T("Blogs")}</h1>
      </div>
      <BreadCrumbs pageTile="Blog" breadcrumbItems={breadcrumbItems} />
      <SEO metaTitle={_T("Blogs | Flowers and Gifts Shop | Wardstation")} metaDescription={_T("Explore insightful articles and latest updates on flowers, gifts, and celebrations on Wardstation's blog. Stay informed and inspired with our valuable content.")} metaKeywords={_T("flowers, gifts, celebrations, blog, articles, Wardstation")} />
      <div className="blog-container wrapper">
        {
          !loading ?
            blogs && blogs.length ?
              <>
                <div className="page-content blogs-main">
                  {
                    blogs.map(blog => (
                      <BlogCard key={blog.Id} blog={blog} loading={loading} />
                    ))
                  }
                </div>
                <TablePagination pageSize={limit} page={page} currentRecords={blogs.length} total={total} onPaginationChange={onPaginationChange} loading={loading} />
              </> : <ErrorMsg text={_T("Blogs not found")} />
            :
            [...Array(6)].map((item, key) => {
              return (
                <div key={key}>
                  <Skeleton className='mb-3' height={264} borderRadius={10} />
                </div>
              )
            })
        }
      </div>
    </section>
  );
};

export default BlogComponent;
