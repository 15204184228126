function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "Privacy Policy": {
            "en": "Privacy Policy",
            "ar": "سياسة الخصوصية"
        },
        "This privacy policy sets out how WardStation uses and protects any information that you give WardStation when you use this website. WardStation is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement. WardStation may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.": {
            "en": "This privacy policy sets out how WardStation uses and protects any information that you give WardStation when you use this website. WardStation is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement. WardStation may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.",
            "ar": "تحدد سياسة الخصوصية هذه كيفية استخدام ورد ستيشن وحماية أي معلومات تقدمها إلى ورد ستيشن عند استخدامك لهذا الموقع. تلتزم ورد ستيشن بضمان حماية خصوصيتك. إذا طلبنا منك تقديم معلومات معينة يمكن من خلالها التعرف عليك عند استخدام هذا الموقع، فيمكنك التأكد من أنه سيتم استخدامها فقط وفقا لبيان الخصوصية هذا. قد تقوم ورد ستيشن بتغيير هذه السياسة من وقت لآخر عن طريق تحديث هذه الصفحة. يجب عليك مراجعة هذه الصفحة من وقت لآخر للتأكد من أنك راض عن أي تغييرات."
        },
        "What We Collect": {
            "en": "What We Collect",
            "ar": "ما نجمعه"
        },
        "We may collect the following information:": {
            "en": "We may collect the following information:",
            "ar": "قد نجمع المعلومات التالية:"
        },
        "Name": {
            "en": "Name",
            "ar": "اسم"
        },
        "Home": {
            "en": "Home",
            "ar": "الصفحة الرئيسية"
        },
        "Contact information including email address": {
            "en": "Contact information including email address",
            "ar": "معلومات الاتصال بما في ذلك عنوان البريد الإلكتروني"
        },
        "Demographic information such as postcode, preferences and interests": {
            "en": "Demographic information such as postcode, preferences and interests",
            "ar": "المعلومات الديموغرافية مثل الرمز البريدي والتفضيلات والاهتمامات"
        },
        "Other information relevant to customer surveys and/or offers": {
            "en": "Other information relevant to customer surveys and/or offers",
            "ar": "معلومات أخرى ذات صلة بمسوحات العملاء و/أو العروض"
        },
        "What We Do With The Information We Gather": {
            "en": "What We Do With The Information We Gather",
            "ar": "ماذا ستفعل بالمعلومات التي نجمعها"
        },
        "We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:": {
            "en": "We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:",
            "ar": "نطلب هذه المعلومات لفهم احتياجاتك وتزويدك بخدمة أفضل ، وخاصة للأسباب التالية:"
        },
        "Internal record keeping.": {
            "en": "Internal record keeping",
            "ar": "حفظ السجلات الداخلية."
        },
        "We may use the information to improve our products and services.": {
            "en": "We may use the information to improve our products and services.",
            "ar": "قد نستخدم المعلومات لتحسين منتجاتنا وخدماتنا."
        },
        "We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.": {
            "en": "We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.",
            "ar": "قد نرسل بشكل دوري رسائل بريد إلكتروني ترويجية حول منتجات جديدة، أو عروض خاصة، أو معلومات أخرى نعتقد أنك قد تجدها مثيرة للاهتمام باستخدام عنوان البريد الإلكتروني الذي قدمته."
        },
        "From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.": {
            "en": "From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.",
            "ar": "من وقت لآخر ، قد نستخدم معلوماتك أيضًا للاتصال بك لأغراض أبحاث السوق. قد نتواصل معك عن طريق البريد الإلكتروني أو الهاتف أو الفاكس أو البريد. قد نستخدم المعلومات لتخصيص موقع الويب وفقًا لمصالحك."
        },
        "Security": {
            "en": "Security",
            "ar": "الحماية"
        },
        "We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.": {
            "en": "We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.",
            "ar": "نحن ملتزمون بضمان أن معلوماتك آمنة. من أجل منع الوصول أو الكشف غير المصرح به، قمنا بوضع إجراءات إلكترونية وإدارية مناسبة لحماية وتأمين المعلومات التي نجمعها عبر الإنترنت."
        },
        "How We Use Cookies": {
            "en": "How We Use Cookies",
            "ar": "كيف نستخدم ملفات تعريف الارتباط ؟"
        },
        "A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.": {
            "en": "A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.",
            "ar": "ملف تعريف الارتباط هو ملف صغير يطلب وضع الإذن على محرك الأقراص الثابتة لجهاز الكمبيوتر الخاص بك. بمجرد موافقتك ، تتم إضافة الملف ويساعد ملف تعريف الارتباط على تحليل حركة مرور الويب أو يتيح لك معرفة وقت زيارة موقع معين. تسمح ملفات تعريف الارتباط بتطبيقات الويب للرد عليك كفرد. يمكن لتطبيق الويب تخصيص عملياته حسب احتياجاتك من خلال جمع المعلومات وتذكرها حول تفضيلاتك."
        },
        "We use traffic log cookies to identify which pages are being used. This helps us analyse data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.": {
            "en": "We use traffic log cookies to identify which pages are being used. This helps us analyse data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.",
            "ar": "نستخدم ملفات تعريف الارتباط لحركة المرور لتحديد الصفحات التي يتم استخدامها. يساعدنا ذلك في تحليل البيانات حول حركة مرور صفحة الويب وتحسين موقعنا على الويب من أجل تخصيصها لتلبية احتياجات العملاء. نستخدم هذه المعلومات فقط لأغراض التحليل الإحصائي ، ثم تتم إزالة البيانات من النظام."
        },
        "Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.": {
            "en": "Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.",
            "ar": "بشكل عام ، تساعدنا ملفات تعريف الارتباط في تزويدك بموقع ويب أفضل ، من خلال تمكيننا من مراقبة الصفحات التي تجدها مفيدة ولا تفعل ذلك. لا يتيح لنا ملف تعريف الارتباط بأي حال من الأحوال الوصول إلى جهاز الكمبيوتر الخاص بك أو أي معلومات عنك ، بخلاف البيانات التي تختار مشاركتها معنا. يمكنك اختيار لقبول أو رفض ملفات تعريف الارتباط. تقبل معظم متصفحات الويب تلقائيًا ملفات تعريف الارتباط ، ولكن يمكنك عادةً تعديل إعداد المتصفح الخاص بك لتراجع ملفات تعريف الارتباط إذا كنت تفضل ذلك. قد يمنعك هذا من الاستفادة الكاملة من الموقع."
        },
        "Links to Other Websites": {
            "en": "Links to Other Websites",
            "ar": "روابط إلى مواقع الويب الأخرى"
        },
        "Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.": {
            "en": "Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.",
            "ar": "قد يحتوي موقعنا على روابط لمواقع الويب الأخرى ذات الاهتمام. ومع ذلك ، بمجرد استخدام هذه الروابط لمغادرة موقعنا ، يجب أن تلاحظ أنه ليس لدينا أي تحكم في هذا الموقع الآخر. لذلك ، لا يمكننا أن نكون مسؤولين عن حماية وخصوصية أي معلومات تقدمها أثناء زيارة مثل هذه المواقع ولا تخضع هذه المواقع لبيان الخصوصية هذا. يجب عليك توخي الحذر والنظر في بيان الخصوصية المطبق على الموقع المعني."
        },
        "User deletion": {
            "en": "User deletion",
            "ar": "حذف المستخدم"
        },
        "The user may request for profile deletion by contacting customer support via email at  support@wardstation.com": {
            "en": "The user may request for profile deletion by contacting customer support via email at  support@wardstation.com",
            "ar": "يتم طلب حذف الملف الشخصي عن طريق الاتصال بدعم العملاء عبر البريد الإلكتروني على support@wardstation.com"
        },
        "In the request, please specify your email id used to create account. We will confirm receipt of your request within 10 days. We may require specific information from you to help us verify your identity and process your request. If we are unable to verify your identity, we may deny your requests to know or delete.": {
            "en": "In the request, please specify your email id used to create account. We will confirm receipt of your request within 10 days. We may require specific information from you to help us verify your identity and process your request. If we are unable to verify your identity, we may deny your requests to know or delete.",
            "ar": "يرجى تحديد معرف البريد الإلكتروني المستخدم لإنشاء حساب. سوف نؤكد استلام طلبك في غضون 10 أيام. قد نطلب منك معلومات محددة لمساعدتنا على التحقق من هويتك ومعالجة طلبك. إذا لم نتمكن من التحقق من هويتك ، فقد نرفض طلب الحذف."
        },
        "Privacy Policy | Wardstation": {
            "en": "Privacy Policy | Wardstation",
            "ar": "سياسة الخصوصية | ورد ستيشن"
        },
        "privacy policy, data privacy, personal information, data collection, data usage, data disclosure": {
            "en": "privacy policy, data privacy, personal information, data collection, data usage, data disclosure",
            "ar": "سياسة الخصوصية, خصوصية البيانات, المعلومات الشخصية, جمع البيانات, استخدام البيانات, كشف البيانات"
        },
        "Protecting your privacy is important to us. Read our privacy policy to understand how we collect, use, and disclose your personal information when you use our service. Fast delivery available across KSA. Order now!": {
            "en": "Protecting your privacy is important to us. Read our privacy policy to understand how we collect, use, and disclose your personal information when you use our service. Fast delivery available across KSA. Order now!",
            "ar": "حماية خصوصيتك مهمة بالنسبة لنا. اقرأ سياسة الخصوصية الخاصة بنا لفهم كيفية جمعنا واستخدامنا وكشفنا لمعلوماتك الشخصية عند استخدام خدمتنا. يتوفر التوصيل السريع في جميع أنحاء المملكة العربية السعودية. اطلب الآن!"
        },
    }


    return translations[text] ? translations[text][locale] : text;
}
export default _T;