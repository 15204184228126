import React, { useState, useRef, useEffect, useContext } from "react";
import moment from 'moment/moment';
import RightCart from './shopping-cart'
import CartCoupon from "./cart-coupon";
import CartSummary from "./cart-summary";
import AddressCard from '../Account/address-card'
import DatePicker from 'react-datepicker';
import { getAvailableSlotByDate, getCartProducts, getAvailableSlot, postNewCard, updateDefaultAdress, cartCheckout, getOrderStatus, getAreas, updateArea, updatePaymentCard, deletePaymentCard, uploadMedia, uploadGiftCard, removeGiftCard, getFonts, addBadgeInformation, removeBadgeInformation, getLastOrderStatus, getGiftCard, getOperationWarehouse } from "../../helper/backend-methods";
import { getCardDetails, getAddressList } from "../../helper/backend-methods";
import Skeleton from "react-loading-skeleton";
import { Frames, CardNumber, ExpiryDate, Cvv } from 'frames-react';
import ErrorMsg from "../../components/ErrorMessage";
import { cardIcons } from "../../data/payment-cards";
import '../../assets/css/--page-cart.css'
import 'react-datepicker/dist/react-datepicker.css';
import Modal from "../../components/Modal";
import ErrorIcon from '../../assets/images/error-icon.png'
import Calender from '../../assets/images/calender.png'
import EmptyCartIcon from '../../assets/images/cart-empty.png'
import giftCardBackgroundImage from '../../assets/images/gift-card-background.png'
import { Link } from "react-router-dom";
import CartCheckout from "./cart-checkout";
import { getActiveStore, getCurrentUser, getLocale, isAuthenticated, scrollToTop, pixelPageEvent, pixelTrackEvent, updateActiveStore, googleTagInitialize } from "../../common";
import { GlobalContext } from "../../context/GlobalContext";
import SubscriptionComplete from "./cart-subscription-complete";
import QRCode from "react-qr-code";
import ProductSlider from "../Products/Product-Slider-01";
import ProcessLoader from "../../components/ProcessLoader";
import html2canvas from 'html2canvas';
import _T from "../../locale/cart";
import _TR from "../../locale/checkout";
import NewCartAddress from "./cart-new-address";
import SEO from "../../components/SEO";

function CartMain() {
    const { updateCartCount } = useContext(GlobalContext);

    const [showLoading,setShowLoading]= useState(false);
    const [cartUpdate, setCartUpdate] = useState(0)
    const [voucher, setVoucher] = useState([])
    const [cartItem, setCartItem] = useState([])
    const [cartSummary, setCartSummary] = useState([])
    const [cartOccasion, setCartOccasion] = useState(null)
    const [isOpen , setIsOpen] = useState(false)
    // const [cartRecommended, setCartRecommended] = useState([])
    
    const [hasBadge, setHasBadge] = useState(false)
    const [hasBadgeProducts, setHasBadgeProducts] = useState(false)
    const [badgeInfo, setBadgeInfo] = useState({ font: '', text: '', date: moment().format("YYYY-MM-DD") })
    const [badgeError, setBadgeError] = useState({ font: '', text: '', date: '' })
    const [badgeModal, setBadgeModal] = useState(false)
    const [fonts, setFonts] = useState([])
    
    const [hasGiftCard, setHasGiftCard] = useState(false)
    const [giftCard, setGiftCard] = useState({ recipient: '', sender: '', message: '', url: '', imageId: null, imagePreview: null, videoId: null, videoPreview: null, anonymous: false, loading: false})
    const [giftCardErrors, setGiftCardErrors] = useState({ message: '', url: '', imageId: '', videoId: '' })
    const [giftCardModal, setGiftCardModal] = useState(false)
    const [giftCardPreview, setGiftCardPreview] = useState(false)
    const giftCardBackground = useRef(giftCardBackgroundImage)
    const giftCardFontColor = useRef('')
    const giftCardRef = useRef(null);
    const canvasRef = useRef(null);


    const [addressListLoading, setAddressListLoading] = useState(true);
    const [updateAddressList, setUpdateAddressList] = useState(0);
    const [addressList,setAddressList] = useState([])
    const [selectedArea,setSelectedArea] = useState(0)
    
    
    const datePickerRef = useRef(null);
    const [dateSlotsLoading, setDateSlotsLoading] = useState(true);
    const [dateSlots, setDateSlots] = useState([]);
    const [minDate, setMinDate] = useState(new Date());
    const [startDate, setStartDate] = useState("");
    const [selectedDateSlot, setSelectedDateSlot] = useState(null);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);


    const isSubscription = useRef(false);
    const [cardListLoading, setCardListLoading] = useState(true);
    const [updateCardList, setUpdateCardList] = useState(0);
    const cardLoading = useRef(false);
    const [cardList, setCardList] = useState([])
    const [cardExpiryModal, setCardExpiryModal] = useState(false)
    const [newCardAdded, setNewCardewCardAdded] = useState(false)
    const [previousCardCVV, setPreviousCardCVV] = useState("");
    const [isPreviousCardCVVValid, setIsPreviousCardCVVValid] = useState(false);
    const [showPreviousCardCVVValid, setShowPreviousCardCVVValid] = useState(false);
    const [selectedCard, setSelectedCard] = useState(-1)
    const selectedCardRef = useRef(-1)
    const [showCardNumberError, setShowCardNumberError] = useState(false);
    const [isCardNumberValid, setIsCardNumberValid] = useState(false);
    const [showExpiryError, setShowExpiryError] = useState(false);
    const [isCardExpiryValid, setIsCardExpiryValid] = useState(false);
    const [showCVCError, setShowCVCError] = useState(false);
    const [isCardCVCValid, setIsCardCVCValid] = useState(false);
    const [selectedCardIcon, setSelectedCardIcon] = useState(cardIcons["undefined"]);
    const [existCard , setExistCard] = useState("")
    const [openModalExistCard , setOpenModalExistCard] = useState(false)



    const eventEnded = useRef(false);
    const [ckoObject, setCkoObject] = useState(null);
    const [activeStore, setActiveStore] = useState(getActiveStore())
    const [storeAreas, setStoreAreas] = useState([])
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [checkoutComplete, setCheckoutComplete] = useState(0);
    const checkoutResponse = useRef(null);
    const [modalCVV, setModalCVV] = useState(false);
    const [modal3DSecure, setModal3DSecure] = useState(false);
    const [url3DSecure, setUrl3DSecure] = useState("");
    const [cartEmpty, setCartEmpty] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [errors, setErrors] = useState({ addressSelected: false, dateTimeSlotSelect: false, paymentInformation: false })
    const [nextLoading, setNextLoading] = useState(false)
    const [loading, setLoading] = useState(true);
    const [showPhoneError, setShowPhoneError] = useState(false);
    const [checkoutStatusCode , setCheckoutStatusCode] = useState();
    const [checkoutStatusMessage , setCheckoutStausMessage] = useState("")
    const [responseMsg , setResponseMsg] = useState(false)


    const { warehouseList, updateWarehouseList } = useContext(GlobalContext)
    const warehouse = getActiveStore();
    const [storeActive, setStoreActive] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null)
    const [regionPopup, setRegionPopup] = useState(false);
    const isEn = getLocale() === "en";


    const stepDetails = [
        // { icon: <i className="ri-mail-line"></i>, text: 'Message' },
        { icon: <i className="ri-truck-line"></i>, text: _T('Address' )},
        { icon: <i className="ri-time-line"></i>, text: _T('Delivery Time') },
        { icon: <i className="ri-bank-card-line"></i>, text: _T('Payment') },
    ];

    const cartSteps = {
        "1": _T("Proceed to Delivery"),
        "2": _T("Proceed to Payment"),
        "3": _T("Checkout")
    }


    const fetchOperationalWarehouse = () => {
        getOperationWarehouse().then(({ data }) => {
            if(data.status){
                updateWarehouseList(data.result.Data)
                setRegionPopup(true)
            }
            else{
                localStorage.removeItem("guestToken");
                localStorage.removeItem("authToken");
                localStorage.removeItem("userInfo");
                localStorage.removeItem("activeStore");
                window.location.reload();
            } 
            setLoading(false)
        }).catch(()=>{
            localStorage.removeItem("guestToken");
            localStorage.removeItem("authToken");
            localStorage.removeItem("userInfo");
            localStorage.removeItem("activeStore");
            window.location.reload();
        })
    };

    const updateSelectedWarehouse = () => {
        setRegionPopup(false);
        getOperationWarehouse({ name: isEn ? selectedRegion.NameEn : selectedRegion.NameAr, operationWarehouseId: selectedRegion.WarehouseId, alternateNames:selectedRegion.alternateNames }).then(({ data }) => {
            if(data.status){
                updateActiveStore(selectedRegion)
                setStoreActive(selectedRegion)
                window.location.reload();
            }
            else{
                localStorage.removeItem("guestToken");
                localStorage.removeItem("authToken");
                localStorage.removeItem("userInfo");
                localStorage.removeItem("activeStore");
                window.location.reload();
            } 
        }).catch(()=>{
            localStorage.removeItem("guestToken");
            localStorage.removeItem("authToken");
            localStorage.removeItem("userInfo");
            localStorage.removeItem("activeStore");
            window.location.reload();
        })
    }

    const getTimeDifference = d => {
        const currentDate = moment().toISOString();
        const startTime = new Date(d);
        const endTime = new Date(currentDate);
        const difference = endTime.getTime() - startTime.getTime();
        const resultInMinutes = Math.round(difference / 60000);
        return resultInMinutes;
    };

    useEffect(() => {
        document.title = "Cart | Wardstation"
        scrollToTop();
        getCartProducts().then(({ data }) => {
            if (data.status) {
                if(data.result.CartItems.length){
                    pixelTrackEvent("InitiateCheckout",{ content_ids: data.result.CartItems.map((ci)=> ci.Id), content_type: 'product', num_items: data.result.CartItems.length, value: data.result.OrderTotal, currency: 'SAR' })
                }
                setCartEmpty(data.result.CartItems.length ? false : true)
                setCartItem(data.result.CartItems)
                isSubscription.current = data.result.IsSubscription;
                updateCartCount(data.result.CartItemCount)
                setCartSummary({ subTotal: data.result.SubTotal, shipping: data.result.OrderShippingTotalInclTax, vat: data.result.OrderTaxTotal, discount: data.result.DiscountAmount, total: data.result.OrderTotal })
                setVoucher(data.result.AppliedCouponCodes)
                // setHasGiftCard(data.result.GiftCard.Message ? true : false)
                // if(data.result.GiftCard.Message){
                //     const gc = data.result.GiftCard;
                //     setGiftCard({ recipient: gc.To ? gc.To : '', sender: gc.From ? gc.From : '', message: gc.Message, url: '', imageId: gc.GiftCardPictureId, imagePreview: gc.GiftCardPicture, videoId: gc.GiftCardVideoId, videoPreview: gc.GiftCardVideo, anonymous: false, loading: false})
                // }
                if(data.result.CartBdgeAttributeDescription && data.result.CartBdgeAttributeDescription.length > 2){
                    setHasBadge(true);
                    setBadgeInfo({ font: data.result.CartBdgeAttributeDescription[2], text: data.result.CartBdgeAttributeDescription[0], date: moment(data.result.CartBdgeAttributeDescription[1]).format("YYYY-MM-DD") })
                }
                setHasBadgeProducts(data.result.CartItems.filter((i)=>i.Product.IsBadge))
                // setCartRecommended(data.result.CustomizeableProductCartItems)
                setCartOccasion(data.result.peronalOccasion)
                // giftCardBackground.current = data.result.GiftCard && data.result.GiftCard.BackgroundImageUrlBase64 ? 'data:image/png;base64,'+data.result.GiftCard.BackgroundImageUrlBase64 : giftCardBackgroundImage
                // giftCardFontColor.current = data.result.GiftCard && data.result.GiftCard.FontColor ? data.result.GiftCard.FontColor : "#000000"
                let maxDaysRequired = 0;
                for (let i = 0; i < data.result.CartItems.length; i++) {
                    let item = data.result.CartItems[i].Product;
                    if (maxDaysRequired < item.DaysRequiredForDelivery) {
                        maxDaysRequired = item.DaysRequiredForDelivery
                    }
                }
                const minimumCustomDate = new Date(); // Today
                minimumCustomDate.setDate(minimumCustomDate.getDate() + maxDaysRequired + 7); // adding maxDaysRequired and additonal 7 days to start min date from next current date
                setMinDate(minimumCustomDate)
                getAvailableSlot({ "DaysRequiredForDelivery": maxDaysRequired }).then(({ data }) => {
                    if (data.status) {
                        setDateSlots(data.result)
                        setSelectedDateSlot(data.result[0])
                        setSelectedTimeSlot(data.result[0].TimeSlots[0])
                    }
                    setDateSlotsLoading(false)
                }).catch((e)=>{
                    setDateSlotsLoading(false)
                })
                setLoading(false)
            }
            setShowLoading(false)
        })
    }, [cartUpdate])

    useEffect(() => {
        getGiftCard().then(({data}) =>{
            if(data && data.status && data.result){
                setHasGiftCard(data.result.GiftCard.Message ? true : false)
                if(data.result.GiftCard.Message){
                    const gc = data.result.GiftCard;
                    setGiftCard({ recipient: gc.To ? gc.To : '', sender: gc.From ? gc.From : '', message: gc.Message, url: '', imageId: gc.GiftCardPictureId, imagePreview: gc.GiftCardPicture, videoId: gc.GiftCardVideoId, videoPreview: gc.GiftCardVideo, anonymous: false, loading: false})
                }
                giftCardBackground.current = data.result.GiftCard && data.result.GiftCard.BackgroundImageUrlBase64 ? 'data:image/png;base64,'+data.result.GiftCard.BackgroundImageUrlBase64 : giftCardBackgroundImage
                giftCardFontColor.current = data.result.GiftCard && data.result.GiftCard.FontColor ? data.result.GiftCard.FontColor : "#000000"
            }
        }).catch((e) =>{
            console.log(e)
        })
    },[])

    useEffect(() => {
        if(isAuthenticated()){
            fetchCards();
        }
    }, [updateCardList])

    useEffect(()=>{
        if(isAuthenticated()){
            fetchAddresses()
        }
        else{
            setAddressListLoading(false)
        }
    },[updateAddressList])


    useEffect(() => {
        if(ckoObject){
            const debounce = setTimeout(() => {
                updatePaymentCardStatus(ckoObject);
            }, 1000)
            return () => clearTimeout(debounce)
        }
    }, [ckoObject])

    useEffect(()=>{
        fetchAreasByStore()
        fetchFonts()
        pixelPageEvent();
        googleTagInitialize();
    },[])

    const fetchFonts = () => {
        getFonts().then(({data})=>{
            if(data.status){
                setFonts(data.result)
            }
        })
    }

    const validateBadgeDetails = (e) => {
        e.preventDefault();
        let flag = false;
        const error = { font: '', text: '', date: '' };
        // const [badgeError, setBadgeError] = useState({ font: '', text: '', date: '' })
        if(!badgeInfo.text || badgeInfo.text.replaceAll(/\s/g, '').length < 1){
            error.text = _T('Please provide a name');
            flag = true;
        }
        if(!badgeInfo.date || badgeInfo.date.replaceAll(/\s/g, '').length < 1 || !moment(badgeInfo.date).isValid()){
            error.date = _T('Please select a date');
            flag = true;
        }
        if(!badgeInfo.font || badgeInfo.font.replaceAll(/\s/g, '').length < 1){
            error.font = _T('Please select a font');
            flag = true;
        }
        setBadgeError(error);
        if(!flag){
            addBadgeInformation({ productId: hasBadgeProducts[0].ProductId, Name: badgeInfo.text, DateTime: moment(badgeInfo.date).format("YYYY-MM-DD hh:mm:ss.000"), Font: badgeInfo.font }).then(({data})=>{
                setHasBadge(true);
                setBadgeModal(false);
            })
        }
    }

    const removeCurrentBadge = () => {
        removeBadgeInformation("?cartItemId="+hasBadgeProducts[0].Id).then(({data})=>{
            setHasBadge(false);
            setBadgeInfo({ font: '', text: '', date: moment().format("YYYY-MM-DD") })
            setBadgeError({ font: '', text: '', date: '' })
        
        })
    }

    const fetchAreasByStore = () => {
        getAreas({storeId: activeStore ? activeStore.WarehouseId : 4}).then(({data})=>{
            if(data.status){
                setStoreAreas(data && data.result ? data.result : [])
            }
            setShowLoading(false)
        })
    }

    const updateSelectedArea = (e) => {
        let value = isNaN(e) ? e.target.value : e
        setSelectedArea(value);
        setAddressListLoading(true)
        setShowLoading(true)
        updateArea({ StoreId: activeStore ? activeStore.WarehouseId : 4, AreaId: value}).then(({data})=>{
            if(data.status){
                setUpdateAddressList(updateAddressList+1);
            }
            else{
                setAddressListLoading(false)
            }
        })
    }

    const fetchAddresses = () => {
        setAddressListLoading(true)
        getAddressList().then(({data})=>{
            if(data.status){
                setAddressList(data.result.addressList)
                setSelectedArea(data.result.areaId)
                setAddressListLoading(false)
                setShowLoading(false)
            }
        })
    }

    const markAddressDefault = (Id) => {
        setAddressListLoading(true)
        setShowLoading(true)
        updateDefaultAdress("?addressId=" + Id).then((data)=>{
            if(data.status){
                setUpdateAddressList(updateAddressList+1)
            }
        })
    }

    function toLocalISOString(date) {
        const pad = function(num) {
            return (num < 10 ? '0' : '') + num;
        };
        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T01:00:00.000Z'
    }

    const fetchTimeSlots = (data) => {
        setStartDate(data)
        console.log(data)
        setShowLoading(true)
        getAvailableSlotByDate({"Date":toLocalISOString(data)}).then(({data})=>{
            if(data.status){
                if(data.result && data.result.length && data.result[0].TimeSlots && data.result[0].TimeSlots.length){
                    setSelectedDateSlot(data.result[0])
                    setSelectedTimeSlot(data.result[0].TimeSlots[0])
                }
                else{
                    setSelectedDateSlot(null)
                    setSelectedTimeSlot(null)
                }
            }
            setShowLoading(false)
        })
    }

    const updateSteps = (step) => {
        setCurrentStep(step);
    };

    const fetchCards = () => {
        setCardListLoading(true)
        setErrors((prev)=>{ return { ...prev, paymentInformation: false } })
        getCardDetails().then(({ data }) => {
            if (data.status) {
                cardLoading.current = false;
                if(data.result.length){
                    setCardList(data.result)
                    const defaultCard = data.result.find((i)=> i.Selected);
                    if(defaultCard && defaultCard.Used){
                        setSelectedCard(defaultCard.Id);
                        selectedCardRef.current =  defaultCard.Id;
                    }
                    else if(defaultCard && !defaultCard.Used){
                        if(getTimeDifference(defaultCard.Expiry_On) > 14){
                            setCardExpiryModal(true)
                            deleteCard(defaultCard.Id)
                        }
                        else{
                            setSelectedCard(defaultCard.Id);
                            selectedCardRef.current = defaultCard.Id;
                        }
                    }
                    else{
                        setSelectedCard(-1)
                        selectedCardRef.current = -1
                    }
                }
                else{
                    setCardList([])
                    setNewCardewCardAdded(false)
                    setSelectedCard(-1)
                    selectedCardRef.current = -1;
                }
                setCardListLoading(false)
            }
            setShowLoading(false)
        })
    }

    const deleteCard = (cardId) => {
        if(!cardLoading.current){
            setShowLoading(true)
            cardLoading.current = true;
            deletePaymentCard("?id="+cardId).then(({data})=>{
                if(data.status){
                    setCardListLoading(true)
                    setUpdateCardList(updateCardList+1)
                }
                else{
                    cardLoading.current = false;
                }
            })
        }
    }

    const saveNewCard = (cardData) => {
        if(!cardLoading.current){
            setShowLoading(true)
            cardLoading.current = true;
            postNewCard(cardData).then(({data})=>{
                if(data.status){
                    setNewCardewCardAdded(false)
                    setCardListLoading(true)
                    setUpdateCardList(updateCardList+1)
                }
                else{
                    setExistCard(data.response.ResponseMessage)
                    setOpenModalExistCard(true)
                    setShowLoading(false)
                    setCardListLoading(true)
                    setUpdateCardList(updateCardList+1)
                    cardLoading.current = false;
                }
            })
        }
    }

    const markCardSelected = (cardId) => {
        if(!cardLoading.current){
            setShowLoading(true)
            cardLoading.current = true;
            setSelectedCard(cardId);
            selectedCardRef.current = cardId
            const error = { addressSelected: false, dateTimeSlotSelect: false, paymentInformation: false }
            error.paymentInformation = false
            setErrors(error)
            updatePaymentCard("?cardId="+cardId).then(({data})=>{
                if(data.status){
                    setCardListLoading(true)
                    setUpdateCardList(updateCardList + 1)
                    cardLoading.current = false;
                }
                else{
                    cardLoading.current = false;
                }
            })
        }
    }

    const updatePaymentCardStatus = (data) => {
        scrollToTop(100)
        getOrderStatus(`?ckoId=${data.ckoId}&orderId=${data.orderId}&cardId=${data.cardId}`).then(({data})=>{
            if(data.status && data.result && data.result.Status === "Captured"){
                    pixelTrackEvent("Purchase",{ content_ids: cartItem.map((ci)=> ci.Id), content_type: 'product', num_items: cartItem.length, value: cartSummary.total, currency: 'SAR' })
                    setCheckoutComplete("success")
                    setCheckoutLoading(false)
                    updateCartCount(0)
            }
            else{
                const cardEx = data.result.CardExpired
                const statusCode = data.result.StatusCode
                if(cardEx === true){
                    deleteCard(selectedCardRef.current)
                    setResponseMsg(true)
                }
                setCheckoutStatusCode(statusCode)
                setCheckoutComplete("failed")
                setCheckoutLoading(false)
                // getLastOrderStatus()
            }
        }).catch((e)=>{
            // getLastOrderStatus()
            setCheckoutComplete("failed")
            setCheckoutLoading(false)
        })
    }

    const isValidUrl = (string) => {
        try {
            const newUrl = new URL(string);
            return newUrl.protocol === 'http:' || newUrl.protocol === 'https:' ? true : false;
        } catch (err) {
            return false;
        }
    }

    const convertGiftCardToImage = (e) => {
        e.preventDefault();
        setGiftCard((prev)=>{ return { ...prev, loading: true }})
        html2canvas(giftCardRef.current,{ useCORS: true }).then((canvas) => {
            canvas.toBlob((blob) => {
                const media = new FormData()
                media.append("From", giftCard.sender);
                media.append("To", giftCard.recipient);
                media.append("Message", giftCard.message);
                media.append("GiftCard", blob, 'image.png');
                const hasURL = giftCard.url && giftCard.url.length ? true : false;
                if(!hasURL && giftCard.imageId){
                    media.append("GiftCardPictureId", giftCard.imageId);
                }
                if(!hasURL && giftCard.videoId){
                    media.append("GiftCardPictureId", giftCard.videoId);
                }
                finalizeGiftCard(media);
            })
        }).catch((e)=>{
            const hasURL = giftCard.url && giftCard.url.length ? true : false;
            if(!hasURL && giftCard.imageId){
                setGiftCardErrors((prev)=>{ return { ...prev, imageId: _T('Your image could not be uploaded.') }})
            }
            if(!hasURL && giftCard.videoId){
                setGiftCardErrors((prev)=>{ return { ...prev, videoId: _T('Your video could not be uploaded.') }})
            }
            setGiftCard((prev)=>{ return { ...prev, loading: false }})
            setGiftCardPreview(false)
        })
    };
    
    const finalizeGiftCard = (formdata) => {
        const hasURL = giftCard.url && giftCard.url.length ? true : false; 
        setShowLoading(true);
        uploadGiftCard(formdata).then(({data})=>{
            if(data.status){
                setGiftCardModal(false)
                setGiftCardPreview(false)
                setGiftCard((prev)=>{ return { ...prev, loading: false }})
                setHasGiftCard(true)
            }
            else{
                if(!hasURL && giftCard.imageId){
                    setGiftCardErrors((prev)=>{ return { ...prev, imageId: _T('Your image could not be uploaded.') }})
                }
                if(!hasURL && giftCard.videoId){
                    setGiftCardErrors((prev)=>{ return { ...prev, videoId: _T('Your video could not be uploaded.') }})
                }
                setGiftCard((prev)=>{ return { ...prev, loading: false }})
                setGiftCardPreview(false)
            }
            setShowLoading(false);
        }).catch((error)=>{
            if(!hasURL && giftCard.imageId){
                setGiftCardErrors((prev)=>{ return { ...prev, imageId: _T('Your image could not be uploaded.') }})
            }
            if(!hasURL && giftCard.videoId){
                setGiftCardErrors((prev)=>{ return { ...prev, videoId: _T('Your video could not be uploaded.') }})
            }
            setGiftCard((prev)=>{ return { ...prev, loading: false }})
            setGiftCardPreview(false)
            setShowLoading(false);
        })
    } 

    const removeCurrentGiftCard = () => {
        setShowLoading(true);
        removeGiftCard().then(({data})=>{
            setHasGiftCard(false)
            setGiftCard({ recipient: '', sender: '', message: '', url: '', imageId: null, imagePreview: null, videoId: null, videoPreview: null, anonymous: false, loading: false})
            setGiftCardErrors({ message: '', url: '', imageId: '', videoId: '' })
            setShowLoading(false);
        })
    }
      
    const validateGiftCard = (e) => {
        e.preventDefault();
        let flag = false;
        const error = { message: '', url: '', imageId: '', videoId: '' }
        if (!giftCard.message || giftCard.message.replaceAll(/\s/g, '').length < 1) {
            error.message = _T('Gift card message is required to proceed');
            flag = true
        }
        if (giftCard.url && giftCard.url.length && !isValidUrl(giftCard.url)) {
            error.url = _T('Please provide a valid link');
            flag = true
        }
        setGiftCardErrors(error)
        if (!flag) {
            setGiftCardPreview(true);
        }
    }

    const nextStep = (e) => {
        e.preventDefault();
        scrollToTop(250);
        if(currentStep === 1){
            setNextLoading(true)
            const error = { addressSelected: false, dateTimeSlotSelect: false, paymentInformation: false }
            if(addressList && addressList.length){
                const defaultAddress = addressList.find((a)=> a.Default)
                if(defaultAddress){
                    updateSteps(currentStep + 1)
                    setNextLoading(false)
                }
                else{
                    error.addressSelected = true
                    setErrors(error)
                    setNextLoading(false)
                }
            }
            else{
                error.addressSelected = true
                setErrors(error)
                setNextLoading(false)
            }
        }
        if(currentStep === 2){
            setNextLoading(true);
            const error = { addressSelected: false, dateTimeSlotSelect: false, paymentInformation: false }
            if(!selectedDateSlot || !selectedTimeSlot){
                error.dateTimeSlotSelect = true
                setErrors(error)
                setNextLoading(false)
            }
            else{
                updateSteps(currentStep + 1)
                setNextLoading(false)
            }
        }
        if(currentStep === 3){
            if(hasPhoneNumber()){
                setNextLoading(true)
                const error = { addressSelected: false, dateTimeSlotSelect: false, paymentInformation: false }
                if(selectedCard === null || selectedCard === 0 || selectedCard === -1){
                    error.paymentInformation = true
                    setErrors(error)
                    setNextLoading(false)
                }
                else{
                    const c = cardList.find((item)=> item.Id === selectedCard);
                    const isCardUsed = c.Used;
                    const checkoutData = {
                        Wallet: true,
                        OrderTimeSlot: { Date: selectedDateSlot.Date, TimeSlotId: selectedTimeSlot.Id },
                        PaymentMethod: "Card",
                        isApplePay: false,
                        DontKnowAddress: addressList.find((a)=>a.Default).DontKnowAddress,
                        CardId: selectedCardRef.current,
                        ...( !isCardUsed && { token: c.token } ),
                        ...( !isSubscription.current && cartOccasion && cartOccasion.OccasionId && { "OccasionId": cartOccasion.OccasionId } ),
                        ...( isSubscription.current  && { "SubscriptionDetail":{ "PeriodType":"Weeks", "Intervals":0, "TotalDeliveries":cartItem[0].Quantity, "ProductId":cartItem[0].ProductId, "Quantity":cartItem[0].Quantity, "Subscription":true } } )
                    }
                    if(isCardUsed){
                        setModalCVV(true)
                        setNextLoading(false)
                    }
                    else if(!isCardUsed){
                        if(getTimeDifference(c.Expiry_On) > 14){
                            setCardExpiryModal(true)
                            deleteCard(c.Id)
                        }
                        else{
                            scrollToTop(100)
                            finishCheckout(checkoutData)
                        }
                    }
                    else{
                        scrollToTop(100)
                        finishCheckout(checkoutData)
                    }
                }
            }
            else{
                setShowPhoneError(true)
            }
        }
    }

    const finishCheckout = (checkoutData) => {
        scrollToTop(200);
        setCheckoutLoading(true)
        cartCheckout(checkoutData).then(({data})=>{
            checkoutResponse.current = data.result
            if(data.status){
                // checkout Success
                pixelTrackEvent("Purchase",{ content_ids: cartItem.map((ci)=> ci.Id), content_type: 'product', num_items: cartItem.length, value: cartSummary.total, currency: 'SAR' })
                setCheckoutComplete("success")
                setCheckoutLoading(false)
                setTimeout(() => { scrollToTop(200); }, 1000);
                updateCartCount(0)
            }
            else{
                if(data.response.ResponseMessage === "operation_warehouse_not_found") {
                    setIsOpen(true)
                    localStorage.removeItem("activeStore");
                    fetchOperationalWarehouse()
                }
                else{
                    const CheckoutResponse = data.result.CheckoutResponse
                    const CardDetails = data.result.CardDetails
                    if(CheckoutResponse && CheckoutResponse.Href && CheckoutResponse.Href.length && CheckoutResponse.Status && CheckoutResponse.Status !== "Failed"){
                        setUrl3DSecure(CheckoutResponse.Href)
                        setModal3DSecure(true)
                        setTimeout(() => { scrollToTop(200); }, 1000);
                    }
                    else{
                        // checkout Failed
                        // getLastOrderStatus()
                        // (CheckoutResponse && CheckoutResponse.CardExpired === true) ||
                        if(CardDetails && CardDetails.CardExpired === true){
                            deleteCard(CardDetails.CardId);
                            setCardExpiryModal(true)
                        }
                        else if(CheckoutResponse && CheckoutResponse.CardExpired === true){
                            deleteCard(CardDetails.CardId);
                            setCheckoutComplete("failed")
                            setResponseMsg(true);
                            setCheckoutStatusCode(CheckoutResponse.StatusCode)
                            setCheckoutStausMessage(CheckoutResponse.StatusMessage)
                        }
                        else{
                            setCheckoutComplete("failed")
                            setCheckoutStatusCode(CheckoutResponse.StatusCode)
                            setCheckoutStausMessage(CheckoutResponse.StatusMessage)
                            
                        }
                        setCheckoutLoading(false)
                        setTimeout(() => { scrollToTop(0); }, 1000);
                    }
                }
               
            }
            setNextLoading(false)
        })
    }

    const checkoutWithCVV = () => {
        setModalCVV(false);
        scrollToTop(250);
        const checkoutData = {
            Wallet: true,
            OrderTimeSlot: { Date: selectedDateSlot.Date, TimeSlotId: selectedTimeSlot.Id },
            PaymentMethod: "Card",
            isApplePay: false,
            DontKnowAddress: addressList.find((a)=>a.Default).DontKnowAddress,
            CardId: selectedCardRef.current,
            cvv: previousCardCVV,
            ...( cartOccasion && cartOccasion.OccasionId && { "OccasionId": cartOccasion.OccasionId } ),
            ...( isSubscription.current  && { "SubscriptionDetail":{ "PeriodType":"Weeks", "Intervals":0, "TotalDeliveries":cartItem[0].Quantity, "ProductId":cartItem[0].ProductId, "Quantity":cartItem[0].Quantity, "Subscription":true } } )
        }
        scrollToTop(100)
        finishCheckout(checkoutData)
    }

    const handleAcceptedFiles = (e,type) => {
        const files = e.target.files
        let fileExt = files[0].name.split('.').pop();
        if(type==="video"){
            let allowed_extensions = ["mp4", "mov", "avi", "3gp"];
            setGiftCard((prev)=> { return { ...prev, loading: true }})
            if (allowed_extensions.includes(fileExt)) {
                const reader = new FileReader();
                if (files) {
                    reader.readAsDataURL(files[0]);
                } else {
                    setGiftCard((prev)=> { return { ...prev, videoId: null, videoPreview: null, loading: false }})
                    setGiftCardErrors((prev)=>{ return { ...prev, videoId: _T('Your video could not be uploaded.') }})
                }
                reader.onloadend = () => {
                    uploadMediaFile(files,true)
                };
            }
            else {
                setGiftCard((prev)=> { return { ...prev, videoId: null, videoPreview: null, loading: false }})
                setGiftCardErrors((prev)=>{ return { ...prev, videoId: _T('Allowed formats are mp4, mov, avi, 3gp') }})
                document.getElementById("giftCardVideo").value = "";
            }
        }
        else{
            let allowed_extensions = ["jpeg", "jpg", "png"];
            setGiftCard((prev)=> { return { ...prev, loading: true }})
            if (allowed_extensions.includes(fileExt)) {
                const reader = new FileReader();
                if (files) {
                    reader.readAsDataURL(files[0]);
                } else {
                    setGiftCard((prev)=> { return { ...prev, imageId: null, imagePreview: null, loading: false }})
                    setGiftCardErrors((prev)=>{ return { ...prev, imageId: _T('Your image could not be uploaded.') }})
                }
                reader.onloadend = () => {
                    uploadMediaFile(files)
                };
            }
            else {
                setGiftCard((prev)=> { return { ...prev, imageId: null, imagePreview: null, loading: false }})
                setGiftCardErrors((prev)=>{ return { ...prev, imageId: _T('Allowed formats are jpeg, jpg, png') }})
                document.getElementById("cakeImage").value = "";
            }
        }
    }

    const uploadMediaFile = (file, isVideo = false) => {
        const media = new FormData()
        media.append("videoUrl", file[0]);
        media.append("IsVideo", isVideo);
        setShowLoading(true);
        uploadMedia(media).then(({data})=>{
            if(data.status){
                if(isVideo){
                    setGiftCard((prev)=> { return { ...prev, videoId: data.result.Id, videoPreview: data.result.Url, loading: false }})
                    setGiftCardErrors((prev)=>{ return { ...prev, videoId: '' }})
                }
                else{
                    setGiftCard((prev)=> { return { ...prev, imageId: data.result.Id, imagePreview: data.result.Url, loading: false }})
                    setGiftCardErrors((prev)=>{ return { ...prev, imageId: '' }})
                }
            }
            else{
                if(isVideo){
                    setGiftCard((prev)=> { return { ...prev, videoId: null, videoPreview: null, loading: false }})
                    setGiftCardErrors((prev)=>{ return { ...prev, videoId: _T('Your image could not be uploaded.') }})
                }
                else{
                    setGiftCard((prev)=> { return { ...prev, imageId: null, imagePreview: null, loading: false }})
                    setGiftCardErrors((prev)=>{ return { ...prev, imageId: _T('Your image could not be uploaded.') }})
                }
            }
            setShowLoading(false);
        }).catch((e)=>{
            if(isVideo){
                setGiftCard((prev)=> { return { ...prev, videoId: null, videoPreview: null, loading: false }})
                setGiftCardErrors((prev)=>{ return { ...prev, videoId: _T('Your image could not be uploaded.') }})
            }
            else{
                setGiftCard((prev)=> { return { ...prev, imageId: null, imagePreview: null, loading: false }})
                setGiftCardErrors((prev)=>{ return { ...prev, imageId: _T('Your image could not be uploaded.') }})
            }
            setShowLoading(false);
        })
    }

    const formSubmit = (e) => {
        Frames.submitCard();
    }

    window.addEventListener( "message",(event) => {
        if(event.data.type === "checkout-payment-status" && !eventEnded.current){
            eventEnded.current = true;
            scrollToTop(200);
            let ckoSessionId = new URLSearchParams(event.data.endpoint.split("?")[1]);
            setCkoObject({ckoId: ckoSessionId.get('cko-session-id'), orderId: checkoutResponse.current.Id, cardId: selectedCardRef.current })
            setModal3DSecure(false)
        }
    })

    const cardTokenizationComplete = (event) => {
        saveNewCard(event)
    }

    const onFrameValidationChanged = (e) => {
        switch (e.element) {
            case "card-number":
                setIsCardNumberValid(e.isValid)
                setShowCardNumberError(!e.isEmpty)
                break;
            case "expiry-date":
                setIsCardExpiryValid(e.isValid)
                setShowExpiryError(!e.isEmpty)
                break;
            case "cvv":
                setIsCardCVCValid(e.isValid)
                setShowCVCError(!e.isEmpty)
                break;
            default:
                break;
        }
    }

    const changeCardIcon = (e) => {
        if (e.scheme) {
            const src = e.scheme.toLowerCase().replaceAll(" ", "-")
            setSelectedCardIcon(cardIcons[src] ? cardIcons[src] : cardIcons["undefined"])
        }
        else {
            setSelectedCardIcon(cardIcons["undefined"])
        }
    }

    const previousCardCVVChange = (e,min) =>{
        const value = (e.target.value.replace(/[^0-9]/g, '')).slice(0,min)
        setPreviousCardCVV(value)
        setIsPreviousCardCVVValid(value.length === min)
        setShowPreviousCardCVVValid(value.length > 0)
    }

    const hasPhoneNumber = () => {
        const user = getCurrentUser();
        return user.phone && user.phone.length > 4 ? true : false;
    }


    return (
        <>
        <ProcessLoader isLoading={showLoading}/>
        <SEO metaTitle={_T("Review Your Cart | Wardstation")} metaDescription={_T("Review and manage your selected items in the cart at Wardstation. Add or remove products, and proceed to checkout with ease. Fast delivery available across KSA. Order now!")} metaKeywords={_T("shopping cart, manage cart, review cart, cart items, add to cart, remove from cart, checkout")} />
        {
            isOpen?
            <div className="wrapper"><ErrorMsg text={_T("Payment Failed! The selected delivery city is unavailable.")}/></div>:
            <div className="wrapper">
            {
                cartEmpty ?
                <div className="cart-empty-container">
                    <div className="section-content text-center">
                        <img src={EmptyCartIcon} alt="Cart Empty"/>
                        <h2 className="fs-22 fw-500 text-secondary">{_T("Your cart is empty!")}</h2>
                        <p className="fs-16 fw-400 text-gray-400">{_T("Looks like you haven't made your choice yet. Get started by adding items.")}</p>
                        <Link to={"/products"} className="btn btn-secondary">{_T("Go to Store")}</Link>
                    </div>
                </div> :
                <div className={`cart-container ${checkoutLoading || checkoutComplete !== 0 ? "d-none" : ""}`}>
                    <div className="cart-left">
                        {  
                            loading ? 
                            <Skeleton height={150}/>:
                            <div className="container">
                                <div className="steps">
                                    {
                                        stepDetails.map((step, index) => (
                                            <div key={index} className={`circle ${index < currentStep ? 'active' : ''}`}>
                                                {step.icon} <p className="delivery-msg fs-14 fw-400">{step.text}</p>
                                            </div>
                                        ))
                                    }
                                    <div className="progress-bar">
                                        <span className="indicator" style={{ width: `${((currentStep - 1) / 2) * 100}%`, }}></span>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="cart-sections">
                            <section className={`single-section section-address ${currentStep === 1 ? "" : "d-none"}`}>
                                <div className={`section-header d-flex align-items-center justify-between mt-3 ${isAuthenticated() ? "mb-4" : ""}`}>
                                    <h2 className="fs-16 fw-500 text-gray-500">{_T("Your Address")}</h2>
                                    {
                                        isAuthenticated() ?
                                        loading || addressListLoading ?
                                        <Skeleton height={45} width={150}/> :
                                        <NewCartAddress onUpdate={()=>{ setUpdateAddressList((prev)=>{ return prev + 1 }) }} storeAreas={storeAreas} selectedArea={selectedArea}/> :
                                        <Link className="btn btn-secondary" to={ window.location.pathname.length < 1 ?  "/login" : "/login?redirect="+encodeURIComponent(window.location.pathname) }>Add Address</Link>
                                    }
                                </div>
                                {
                                    isAuthenticated() ?
                                    loading || addressListLoading ?
                                    <Skeleton height={42} className="w-100"/> : 
                                    <div className="form-group">
                                        <select id="selectedArea" value={selectedArea} onChange={(e)=>{ updateSelectedArea(parseInt(e.target.value)) }}>
                                            <option value={0} disabled={true}>{_T("Select Area")}</option>
                                            {
                                                storeAreas.map((item,key)=>{
                                                    return <option key={key} value={item.Id}>{item.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div> : null
                                }
                                <div className="section-body d-flex justify-between flex-wrap mt-3">
                                    {
                                        addressListLoading ?
                                        [...Array(3)].map((item, key) => {
                                            return <div key={key} className="address-card-skeleton address-card address-card-loader">
                                                <Skeleton className='mt-3' height={"100%"} />
                                            </div>
                                        }) :
                                        addressList.length ?
                                        addressList.map((item,key)=>{
                                            return <AddressCard item={item} key={key} editableCard={false} methodDefault={markAddressDefault}/>
                                        }) : <ErrorMsg text={_T("You haven't created any addresses")}/> 
                                    }
                                </div>
                                <div>
                                    { errors.addressSelected ? <ErrorMsg text={_T("Please select an address to proceed.")} /> : null }
                                </div>
                                
                            </section>
                            <section className={`single-section section-delivery ${currentStep === 2 ? "" : "d-none"}`}>
                                <div className="section-header d-flex align-items-center justify-between mt-3">
                                    <h2 className="fs-16 fw-500 text-gray-500 m-0">{_T("Select a Delivery Date")}</h2>
                                </div>
                                { cartOccasion ? null : dateSlots[0] && dateSlots[0].Date && moment(new Date()).format("DDMMMYYY") !== moment(dateSlots[0].Date).format("DDMMMYYY") ? <p className="fs-14 fw-400 text-primary m-0">{_T("Your order can not be delivered before")} { moment(dateSlots[0].Date).format("Do MMMM YYYY") }{_T(", as one of the items in your cart requires time for preparation.")}</p> : null }
                                {
                                    !dateSlotsLoading?
                                    <>
                                        <div className="date-section-body mt-4">
                                            {
                                                dateSlots.map((slot, index) => {
                                                    return <div className={`select-date ${selectedDateSlot && slot.Date === selectedDateSlot.Date ? "selected" : ""}`} key={index} onClick={() => { setSelectedDateSlot(slot); if(slot.TimeSlots[0]){ setSelectedTimeSlot(slot.TimeSlots[0]); setErrors((prev)=>{ return { ...prev, dateTimeSlotSelect: "" } })} else{ setSelectedTimeSlot(null)}  }}>
                                                        <label>
                                                            <p className="slot-date text-primary m-0 fs-22 fw-500">{slot.Date ? moment(slot.Date).format("DD") : "-"}</p>
                                                            <p className="slot-month">{slot.Date ? _T(moment(slot.Date).format("MMMM")) : "-"}</p>
                                                            <p className="underline"></p>
                                                            <p className="slot-day">{slot.Day}</p>
                                                        </label>
                                                    </div>
                                                })
                                            }
                                            <div className={`calendar-card cursor-pointer ${ selectedDateSlot && selectedDateSlot.Date ? moment(startDate).format("DDMMMYYY") === moment(selectedDateSlot.Date).format("DDMMMYYY") ? "selected" : "" : "" }`}  onClick={()=>{datePickerRef.current.setOpen(true);}} >
                                                <div className="icon-container">
                                                    <img src={Calender} alt="Calendar Icon"/>
                                                    <p>{_T("Calendar")}</p>
                                                </div>
                                                <DatePicker placeholderText={_T("Pick a date")} ref={datePickerRef} dateFormat="yyyy-MM-dd" minDate={minDate} selected={startDate} onChange={fetchTimeSlots}/>
                                            </div>
                                        </div>
                                        <div className="section-header d-flex align-items-center justify-between mt-4">
                                            <h2 className="fs-16 fw-500 text-gray-500 m-0">{_T("Select a Delivery Time")}</h2>
                                        </div>
                                        <div className="date-section-body mt-3">
                                            {
                                                selectedDateSlot ?
                                                selectedDateSlot.TimeSlots.map((slot, index) => {
                                                    return <div key={index} className={`select-date time-slot ${slot.Id === selectedTimeSlot.Id ? "selected" : ""}`} onClick={() => { setSelectedTimeSlot(slot); setErrors((prev)=>{ return { ...prev, dateTimeSlotSelect: "" } }) }}  >
                                                        <label><p className="text-secondary m-0 fs-14 fw-500">{slot.StartTime}<span className="d-inline-block mx-2">-</span>{slot.EndTime}</p></label>
                                                    </div>
                                                }) : null
                                            }
                                        </div>
                                        { !selectedDateSlot || !( selectedDateSlot && selectedDateSlot.TimeSlots.length ) ?  <ErrorMsg text={_T("No time slots are available for this date.")} /> : null }
                                        { errors.dateTimeSlotSelect ? <ErrorMsg text={_T("Please select a date and time slot.")} /> : null }
                                    </> :
                                    <>
                                        <div className="date-section-body mt-4">
                                            {
                                                [...Array(6)].map((slot, index) => {
                                                    return <div className={`select-date`} key={index}>
                                                        <Skeleton className="w-100" height={180}/>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className="section-header d-flex align-items-center justify-between mt-4">
                                            <h2 className="fs-16 fw-500 text-gray-500 m-0">{_T("Select a Delivery Time")}</h2>
                                        </div>
                                        <div className="date-section-body mt-3">
                                            {
                                                [...Array(6)].map((slot, index) => {
                                                    return <div key={index} className={`select-date time-slot`}>
                                                        <Skeleton className="w-100" height={50}/>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </>
                                }
    
                            </section>
                            <section className={`single-section section-payment ${currentStep === 3 ? "" : "d-none"}`}>
                                <div className="section-header d-flex align-items-center justify-between">
                                    <h2 className="fs-16 fw-500 text-gray-500">{_T("Payment Method")}</h2>
                                </div>
                                <div className="section-body">
                                    <button type="button" className="btn btn-secondary text-primary">{_T("Credit / Debit Card")}</button>
                                </div>
                                <div className="section-header d-flex align-items-center justify-between">
                                    <h2 className="fs-16 fw-500 text-gray-500">{_T("Saved Cards")}</h2>
                                </div>
                                <div className="section-body">
                                    <div className="saved-cards">
                                        {
                                            cardList.map((cardData, key) => {
                                                return <div className={`cart-payment-option ${cardData.Id === selectedCard ? "active" : ""}`} key={key}>
                                                    <input id={"payment"+cardData.Id} type="radio" className="input-radio" checked={ cardData.Id === selectedCard } onChange={(e)=>{if(e.target.checked){ markCardSelected(cardData.Id) }}} />
                                                    <label htmlFor={"payment"+cardData.Id}><span>{ [...Array(12)].map((i,k)=> <i key={k} className="ri-checkbox-blank-circle-fill"></i> ) }</span> {cardData.Last_four}</label>
                                                    <img className="card-icon" src={cardIcons[cardData.Scheme.toLowerCase().replaceAll(" ", "-")] ? cardIcons[cardData.Scheme.toLowerCase().replaceAll(" ", "-")] : cardIcons["undefined"]} alt="" />
                                                </div>
                                            })
                                        }
                                        {
                                            !newCardAdded ?
                                            <div className={`cart-payment-option ${selectedCard === 0 ? "active" : ""}`}>
                                                <input id={"payment"+0} type="radio" className="input-radio" checked={ 0 === selectedCard } onChange={(e)=>{if(e.target.checked){ setSelectedCard(0); selectedCardRef.current = 0 }}} />
                                                <label htmlFor={"payment"+0}>{_T("Add new card")}</label>
                                                <img className="card-icon" src={cardIcons["undefined"]} alt="" />
                                            </div> : null
                                        }
                                    </div>
                                    {
                                        selectedCard === 0 ?
                                        <div className="new-card mt-3">
                                            <h3 className="fs-16 fw-500 text-gray-500 d-inline-block mt-0 mb-0">{_T("Add New Card")}</h3>
                                            <p className="fs-14 fw-400 text-gray-400 mt-0 mb-2">{_T("Please provide the following details to add a new card")}</p>
                                            <Frames
                                                config={{
                                                    publicKey: process.env.REACT_APP_CHECKOUT_PUBLIC_KEY,
                                                    debugMode: false,
                                                    localization: {
                                                        cardNumberPlaceholder: _T("Card Number"),
                                                        expiryMonthPlaceholder: 'MM',
                                                        expiryYearPlaceholder: 'YY',
                                                        cvvPlaceholder: 'CVV',
                                                    },
                                                    style: {
                                                        base: {
                                                            fontSize: '14px',
                                                            width: "100%",
                                                            margin: "0px",
                                                            border: "1px solid #d6d5d5",
                                                            padding: "0px 12px",
                                                            outline: "none",
                                                            boxShadow: "none",
                                                            borderRadius: "5px",
                                                            background: "#fff",
                                                            color: "#393939",
                                                            height: "42px;",
                                                            textAlign: "left"
                                                        },
                                                        autofill: {
                                                            background: "none",
                                                        },
                                                        hover: {
                                                            outline: "none",
                                                            color: "#393939",
                                                        },
                                                        focus: {
                                                            outline: "none",
                                                            color: "#393939",
                                                            border: "1px solid #C5AA64",
                                                        },
                                                        valid: {
                                                            outline: "none",
                                                            color: "#393939",
                                                        },
                                                        invalid: {
                                                            outline: "none",
                                                            border: "1px solid #DC5252",
                                                            color: "#DC5252",
                                                        },
                                                        placeholder: {
                                                            base: {
                                                            color: "#595959",
                                                            fontSize: '14px',
                                                            fontWeight: "400",
                                                            textAlign: "left"
                                                            },
                                                            focus: {
                                                            color: "#444",
                                                            border: "1px solid #d6d5d5",
                                                            },
                                                        },
                                                    },
                                                }}
                                                ready={() => {}}
                                                frameActivated={(e) => {}}
                                                frameFocus={(e) => {}}
                                                frameBlur={(e) => {}}
                                                frameValidationChanged={onFrameValidationChanged}
                                                paymentMethodChanged={(e) => {}}
                                                cardSubmitted={() => {}}
                                                cardTokenized={cardTokenizationComplete}
                                                cardTokenizationFailed={(e) => {}}
                                                cardBinChanged={(e) => {changeCardIcon(e)}}
                                            >
                                                <div className="d-flex justify-between flex-wrap">
                                                    <div className="checkout-form-content mt-2 w-100 position-relative">
                                                        <label className="fw-500 fs-14 m-0 ">{_T("Card Number")} <span className="text-primary">*</span></label>
                                                        <CardNumber/>
                                                        <div className="card-icon"><img src={selectedCardIcon} alt=""/></div>
                                                        { showCardNumberError && !isCardNumberValid ? <span className="d-flex align-items-center text-flushed fs-12 fw-500 mt-2"><i className="ri-information-line mr-1 fs-16"></i>{_T("Card number not valid")}</span> : null }
                                                    </div>
                                                    <div className="checkout-form-content mt-2">
                                                        <label className="fw-500 fs-14 m-0 ">{_T("Card Expiry")} <span className="text-primary">*</span></label>
                                                        <ExpiryDate/>
                                                        { showExpiryError && !isCardExpiryValid ? <span className="d-flex align-items-center text-flushed fs-12 fw-500 mt-2"><i className="ri-information-line mr-1 fs-16"></i> {_T("Card expiry not valid")}</span> : null }
                                                    </div>
                                                    <div className="checkout-form-content mt-2">
                                                        <label className="fw-500 fs-14 m-0 ">{_T("Card")} CVV/CVC <span className="text-primary">*</span></label>
                                                        <Cvv/>
                                                        { showCVCError && !isCardCVCValid ? <span className="d-flex align-items-center text-flushed fs-12 fw-500 mt-2"><i className="ri-information-line mr-1 fs-16"></i> {_T("Card")} CVV/CVC {_T("not valid")}</span> : null }
                                                    </div>
                                                </div>
                                            </Frames>
                                            <button type="button" disabled={ !(isCardCVCValid && isCardExpiryValid && isCardNumberValid) } className="btn btn-primary mt-3" onClick={formSubmit}>{_T("Add Card")}</button>
                                            </div> : null
                                    }
                                    { errors.paymentInformation ? <ErrorMsg text={_T("Please provide payment information to proceed.")} /> : null }
                                </div>
                                <Modal title={`${_T("Card")} CVV/CVC`} isOpen={modalCVV} className="cvv-modal" onClose={()=>{ setModalCVV(false); }}>
                                    <p className="fs-14 fw-400 text-gray-400 m-0">{_T("Please provide your card")} CVV/CVC {_T("to proceed with your payment.")}</p>
                                    {
                                        cardList.filter((item)=>item.Id === selectedCard).map((c,i)=>{
                                            return <div className="card-cvv-input-container" key={i}>
                                                <img className="card-icon" src={cardIcons[c.Scheme.toLowerCase().replaceAll(" ", "-")] ? cardIcons[c.Scheme.toLowerCase().replaceAll(" ", "-")] : cardIcons["undefined"]} alt="Card"/>
                                                <div className="selected-card-body">
                                                    <h4 className="fs-14 fw-500 text-gray-500 m-0">{c.Scheme} **** {c.Last_four}</h4>
                                                    <p className="fs-14 fw-400 text-gray-400 m-0">{_T("Expiry")} {c.Expiry_Month}/{c.Expiry_year}</p>
                                                </div>
                                                <input className="cvv-input" placeholder="CVV" value={previousCardCVV} onChange={(e)=>{previousCardCVVChange(e,c.Scheme.toLowerCase().replaceAll(" ", "-") === "american-express" ? 4 : 3)}} type={'text'}/>
                                            </div>
                                        })
                                    }
                                    {
                                        showPreviousCardCVVValid && !isPreviousCardCVVValid ? 
                                        <span className="cvv-error d-flex align-items-center text-flushed fs-12 fw-500 mt-2 mb-3"><i className="ri-information-line mr-1 fs-16"></i>  {_T("Card")} CVV/CVC {_T("not valid")} </span> : null
                                    }
                                    <button className="btn btn-primary w-100" type="button" disabled={!isPreviousCardCVVValid} onClick={checkoutWithCVV}>{_T("Finish Checkout")}</button>
                                </Modal>
                            </section>
                        </div>
                    </div>
                    <div className="cart-right">
                        <RightCart setCurrentStep={setCurrentStep} loading={loading} items={cartItem} update={cartUpdate} triggerLoading={setShowLoading} setUpdate={setCartUpdate} isSubscription={isSubscription.current} />
                        <CartCoupon voucher={voucher} loading={loading} update={cartUpdate} setUpdate={setCartUpdate} />
                        {
                            !loading && isAuthenticated() && hasBadgeProducts.length ?
                            <div className="cart-selected-option mt-4">
                                <i className="ri-award-line card-icon"></i>
                                <div className="card-content">
                                    <h5 className="card-title">{_T("Add Your Touch")}</h5>
                                    {
                                        hasBadge ?
                                        <p className="cursor-pointer" onClick={()=>{ setBadgeModal(true); }}>{_T("Preview your badge")}</p> :
                                        <p>{_T("Badge Details")}</p>
                                    }
                                </div>
                                {
                                    hasBadge ?
                                    <div className="card-action">
                                        <button type="button" onClick={()=>{ setBadgeModal(true); }}><i className="ri-settings-3-line fs-16 mr-2"></i> {_T("Change")}</button>
                                        <span className="seperator">|</span>
                                        <button type="button" className="text-flushed" onClick={()=>{ removeCurrentBadge() }}><i className="ri-delete-bin-line fs-16 mr-2"></i>{_T("Remove")}</button>
                                    </div> :
                                    <div className="card-action">
                                        <button type="button" onClick={()=>{ setBadgeModal(true); }}><i className="ri-add-circle-line fs-16 mr-2"></i>{_T("Add Badge")}</button>
                                    </div>
                                }
                            </div> : null
                        }
                        {
                            !loading && isAuthenticated() ?
                            <div className="cart-selected-option mt-4">
                                <i className="ri-gift-line card-icon"></i>
                                <div className="card-content">
                                    <h5 className="card-title">{_T("Gift Card")}</h5>
                                    {
                                        hasGiftCard ?
                                        <p className="cursor-pointer" onClick={()=>{ setGiftCardPreview(true); setGiftCardModal(true); }}>{_T("Preview your gift card")}</p> :
                                        <p>{_T("Add your message")}</p>
                                    }
                                </div>
                                {
                                    hasGiftCard ?
                                    <div className="card-action">
                                        <button type="button" onClick={()=>{ setGiftCardModal(true); setGiftCardPreview(false); }}><i className="ri-settings-3-line fs-16 mr-2"></i>{_T("Change")}</button>
                                        <span className="seperator">|</span>
                                        <button type="button" className="text-flushed" onClick={()=>{ removeCurrentGiftCard() }}><i className="ri-delete-bin-line fs-16 mr-2"></i>{_T("Remove")}</button>
                                    </div> :
                                    <div className="card-action">
                                        <button type="button" onClick={()=>{ setGiftCardModal(true); setGiftCardPreview(false); }}><i className="ri-gift-line fs-16 mr-2"></i> {_T("Add card")}</button>
                                    </div>
                                }
                            </div> : null
                        }
                        {
                            currentStep > 1 ?
                            addressList.filter((a)=> a.Default).map((a,i)=>{
                                return <div className="cart-selected-option mt-4" key={i}>
                                    <i className="ri-truck-line card-icon"></i>
                                    <div className="card-content">
                                        <h5 className="card-title">{_T("Delivery Address")}</h5>
                                        <p>{a.Address1}, {a.Address2}</p>
                                    </div>
                                    <div className="card-action">
                                        <button type="button" onClick={()=>{ setCurrentStep(1) }}><i className="ri-settings-3-line fs-16 mr-2"></i> {_T("Change")}</button>
                                    </div>
                                </div>    
                            }) : null
                        }
                        {
                            currentStep > 2 ?
                            <div className="cart-selected-option mt-4">
                                <i className="ri-time-line card-icon"></i>
                                <div className="card-content">
                                    <h5 className="card-title">{_T("Delivery Date and Time")}</h5>
                                    <p>{selectedTimeSlot.StartTime} - {selectedTimeSlot.EndTime} on {moment(selectedDateSlot.Date).format("DD MMM YYYY")}</p>
                                </div>
                                <div className="card-action">
                                    <button type="button" onClick={()=>{ setCurrentStep(2) }}><i className="ri-settings-3-line fs-16 mr-2"></i> {_T("Change")}</button>
                                </div>
                            </div>  : null
                        }
                        <CartSummary loading={loading} data={cartSummary} update={cartUpdate} setUpdate={setCartUpdate} />
                        {
                            isAuthenticated() ?
                            <div className="check-out-btn mt-4 d-flex justify-between">
                                <button id="prev" className="btn btn-primary w-100 mr-2 p-0" disabled={currentStep === 1} onClick={() =>{updateSteps(currentStep - 1); scrollToTop(100)}}>{_T("Previous")}</button>
                                <button id="next" className="btn btn-primary w-100 p-0" disabled={nextLoading} onClick={nextStep}>{ nextLoading ? _T("Please Wait...") : cartSteps[currentStep]}</button>
                            </div> :
                            <div className="check-out-btn mt-4 d-flex justify-between">
                                <Link to={ window.location.pathname.length < 1 ?  "/login" : "/login?redirect="+encodeURIComponent(window.location.pathname) } className="btn btn-secondary w-100 p-0">{_T("Sign-In to Continue")}</Link>
                            </div>
                        }
                    </div>
                </div>
            }
    
            {
                checkoutComplete === "success" ?
                isSubscription.current ?
                <SubscriptionComplete subscriptionId={checkoutResponse.current.SubscriptionId}/> :
                <CartCheckout checkout={{address: addressList, selectedCard: selectedCard, cards: cardList, dateSlot: selectedDateSlot, timeSlot: selectedTimeSlot, checkout: checkoutResponse.current, coupon: voucher, item: cartItem, summary: cartSummary}}/> : 
                checkoutComplete === "failed" ?
                <div className="payment-loading text-center">
                    <img src={ErrorIcon} alt="Payment Card" className="mx-auto" width={80}/>
                    <h2 className="fs-22 fw-500 text-secondary">{_T("Payment Failed")}</h2>
                    {
                        checkoutStatusCode===null || checkoutStatusCode==="404"?
                        <p className="fs-16 fw-400 text-gray-400">{_TR(checkoutStatusMessage)}</p>
                        :
                        <>
                        <p className="fs-16 fw-400 text-gray-400">{_T("Error Code - ")+checkoutStatusCode}</p>
                        <p className="fs-16 fw-400 text-gray-400">{_TR(checkoutStatusCode)}</p>
                        </>
                    }
                    {
                        responseMsg?
                        <p className="fs-16 fw-400 text-gray-400">{_T("Your payment cannot be processed with the current card. We've remove it from saved cards, please add a new one to continue. Apologies for the inconvenience.")}</p>:null
                    }
                    <button className="btn btn-primary w-10 p-0" onClick={() => {window.location.reload()}}>{_T("Ok")}</button>
                </div>: null
            }
            
    
            <Modal isOpen={cardExpiryModal || openModalExistCard} className="expiry-modal" modalSize={"small"} onClose={()=>{setCardExpiryModal(false);setOpenModalExistCard(false)}}>
                <div className="text-center">
                    <img src={ErrorIcon} alt="Payment Card" className="mx-auto" width={80}/>
                    {
                        openModalExistCard?
                        <p className="text-gray-400 fs-16 fw-400 text-center">{_T(existCard)}</p>
                        :
                        <p className="text-gray-400 fs-16 fw-400 text-center">{_T("We are Sorry to inform your that we have remove your card due to card session expired In order to place an oder via creadit/Debit card you have to add card again")}</p>
    
                    }
                    <button className="btn btn-secondary w-100" type="button" onClick={()=>{setCardExpiryModal(false); setOpenModalExistCard(false)}}>{_T("Continue")}</button>
                </div>
            </Modal>
            <Modal isOpen={badgeModal} title={_T("Badge Details")} className="badge-modal" onClose={()=>setBadgeModal(false)}>
                <section className={`section-message`}>
                    <form onSubmit={validateBadgeDetails}>
                        <div className="section-body">
                            <div className="form-content mb-2">
                                <label className="fs-14 fw-400 text-gray-500 mb-1 d-inline-block" htmlFor="badgeName">{_T("Name")}</label>
                                <input id="badgeName" className="fs-16" value={badgeInfo.text} type="text" placeholder={_T("Enter Name")} onChange={(e) => setBadgeInfo((prev)=>{ return { ...prev, text: e.target.value.slice(0, 50)}})} />
                                { badgeError.text ? <p className="mt-1 mb-0 text-flushed fs-12">{badgeError.text}</p> : null }
                            </div>
                            <div className="form-content mb-2">
                                <label className="fs-14 fw-400 text-gray-500 mb-1 d-inline-block" htmlFor="badgeDate">{_T("Date")}</label>
                                <input id="badgeDate" className="fs-16" value={badgeInfo.date} type="date" min={moment().format("YYYY-MM-DD")} placeholder="Enter Date" onChange={(e) => setBadgeInfo((prev)=>{ return { ...prev, date: e.target.value }})} />
                                { badgeError.date ? <p className="mt-1 mb-0 text-flushed fs-12">{badgeError.date}</p> : null }
                            </div>
                            <div className="form-content mb-2">
                                <label className="fs-14 fw-400 text-gray-500 mb-1 d-inline-block" htmlFor="badgeFont">{_T("Font")}</label>
                                <select id="badgeFont" className="fs-16" value={badgeInfo.font} onChange={(e)=>{setBadgeInfo((prev)=>{ return { ...prev, font: e.target.value }})}}>
                                    <option value={''}>{_T("Select Font")}</option>
                                    {
                                        fonts.map((f,i)=>{
                                            return <option value={f} key={i}>{f}</option>
                                        })
                                    }
                                </select>
                                { badgeError.font ? <p className="mt-1 mb-0 text-flushed fs-12">{badgeError.font}</p> : null }
                            </div>
                            <button className="btn btn-secondary w-100 mt-3" type="submit">{_T("Save Badge Details")}</button>
                        </div>
                    </form> 
                </section>
            </Modal>
            <Modal isOpen={giftCardModal} title={_T("Gift Card")} className="gift-card-modal" onClose={()=>setGiftCardModal(false)}>
                <section className={`section-message`}>
                    {
                        !giftCardPreview ?
                        <form onSubmit={validateGiftCard}>
                            <div className="section-body">
                                <div className="form-content mb-2">
                                    <label className="fs-14 fw-400 text-gray-500 mb-1 d-inline-block" htmlFor="giftCardRecipient">{_T("Recipient")}</label>
                                    <input id="giftCardRecipient" className="fs-16" value={giftCard.recipient} type="text" placeholder={_T("Enter Recipient Name")} onChange={(e) => setGiftCard((prev)=>{ return { ...prev, recipient: e.target.value.slice(0, 50)}})} />
                                </div>
                                <div className="form-content mb-2">
                                    <label className="fs-14 fw-400 text-gray-500 mb-1 d-flex justify-between align-items-center" htmlFor="giftCardMessage"><span>{_T("Message")} <span className="text-primary">*</span></span><span className="fs-12 fw-500 text-secondary">{giftCard.message.length}/77</span></label>
                                    <textarea id="giftCardMessage" className="fs-16" value={giftCard.message} rows="3" placeholder={_T("Write your message...")} onChange={(e) => setGiftCard((prev)=>{ return { ...prev, message: e.target.value.slice(0, 77)}})}></textarea>
                                    { giftCardErrors.message ? <p className="mt-1 mb-0 text-flushed fs-12">{giftCardErrors.message}</p> : null }
                                </div>
                                {
                                    !giftCard.anonymous ?
                                    <div className="form-content mb-2">
                                        <label className="fs-14 fw-400 text-gray-500 mb-1 d-inline-block" htmlFor="giftCardSender">{_T("From")}</label>
                                        <input id="giftCardSender" className="fs-16" value={giftCard.sender} type="text" placeholder={_T("From")} onChange={(e) => setGiftCard((prev)=>{ return { ...prev, sender: e.target.value.slice(0, 50)}})} />
                                    </div> : null
                                }
                                <div className="form-content d-flex align-items-center justify-between mb-2 mt-3">
                                    <label className="fs-14 fw-400 text-gray-400 mb-1 d-inline-block">{_T("Send Anonymously")}</label>
                                    <div className="control-input">
                                        <label className="switch-control">
                                            <input type="checkbox" checked={giftCard.anonymous} onChange={(e) => setGiftCard((prev)=>{ return { ...prev, anonymous: e.target.checked }})}/>
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                </div>
                                { 
                                    giftCard.videoId || giftCard.imageId ? null : 
                                    <>
                                        <h4 className="fs-18 fw-400 text-secondary mt-3 mb-3">{_T("Share Your Feelings With")}</h4>
                                        <div className="form-content mb-2 has-icon">
                                            <i className="ri-link"></i> 
                                            <input id="giftCardURL" className="fs-16" value={giftCard.url} type={"text"} placeholder={_T("Paste a link to a song/video")} onChange={(e) => setGiftCard((prev)=>{ return { ...prev, url: e.target.value.slice(0, 150)}})} />
                                        </div>
                                        { giftCardErrors.url ? <p className="mt-1 mb-0 text-flushed fs-12">{giftCardErrors.url}</p> : null }
                                    </> 
                                }
                                {
                                    giftCard.videoId || giftCard.url ? null : 
                                    <div className="upload-control">
                                        {
                                            giftCard.imagePreview && giftCard.imageId ?
                                            <div className="control-trigger justify-between cursor-normal" htmlFor="cakeImage">
                                                <div className="d-flex align-items-center" onClick={()=>{ }}>
                                                    <i className="ri-gallery-line"></i>
                                                    <span className="control-text">{_T("Image Selected")}</span>
                                                </div>
                                                <span className="ri-close-line fs-18" onClick={()=>{ setGiftCard((prev)=> { return { ...prev, imageId: null, imagePreview: null }}) }}></span>
                                            </div> :
                                            <label className="control-trigger" htmlFor="giftCardImage">
                                                <i className="ri-image-2-fill"></i>
                                                <span className="control-text">{_T("Upload Picture")}</span>
                                                <input type="file" id="giftCardImage" className="d-none" accept="image/png, image/jpeg, image/jpg" onChange={(e)=>{handleAcceptedFiles(e,'image')}}/>
                                            </label>
                                        }
                                        { giftCardErrors.imageId ? <p className="mt-1 mb-0 text-flushed fs-12">{giftCardErrors.imageId}</p> : null }
                                    </div>
                                }
                                {/* { giftCard.videoId || giftCard.imageId || giftCard.url ? null : <p className="fs-16 fw-400 text-secondary mt-2 mb-2 text-center">Or</p>  }
                                {
                                    giftCard.imageId || giftCard.url ? null : 
                                    <div className="upload-control">
                                        {
                                            giftCard.videoPreview && giftCard.videoId ?
                                            <div className="control-trigger justify-between cursor-normal" htmlFor="cakeImage">
                                                <div className="d-flex align-items-center" onClick={()=>{ }}>
                                                    <i className="ri-eye-line"></i>
                                                    <span className="control-text">Preview Image</span>
                                                </div>
                                                <span className="ri-close-line fs-18" onClick={()=>{ setGiftCard((prev)=> { return { ...prev, videoId: null, videoPreview: null }}) }}></span>
                                            </div>  :
                                            <label className="control-trigger" htmlFor="giftCardVideo">
                                                <i className="ri-video-fill"></i>
                                                <span className="control-text">Upload Video</span>
                                                <input type="file" id="giftCardVideo" className="d-none" accept="video/mp4,video/mov,video/avi,video/3gp" onChange={(e)=>{handleAcceptedFiles(e,'video')}}/>
                                            </label>
                                        }
                                        { giftCardErrors.videoId ? <p className="mt-1 mb-0 text-flushed fs-12">{giftCardErrors.videoId}</p> : null }
                                        <p className="text-gray-300 fw-400 fs-14 mb-0">The picture/video content provided will be printed in the form of a QR code in the gift card</p>
                                    </div>
                                } */}
                                <button className="btn btn-secondary w-100 mt-3" type="submit">{_T("Preview Gift Card")}</button>
                            </div>
                        </form> :
                        <form onSubmit={convertGiftCardToImage}>
                            <div id="giftCardPreview" ref={giftCardRef}>
                                <img src={giftCardBackground.current} alt="gift-card-background" className="card-background w-100"/>
                                <div className="gift-card-content">
                                    { giftCard.recipient ? <p className="gift-card-recipient" style={{color: giftCardFontColor.current}}>To: {giftCard.recipient}</p> : null }
                                    { giftCard.message ? <p className="gift-card-message" style={{color: giftCardFontColor.current}}>{giftCard.message}</p> : null }
                                    { giftCard.sender ? <p className="gift-card-sender" style={{color: giftCardFontColor.current}}>{giftCard.sender}</p> : null }
                                    {
                                        giftCard.url && giftCard.url.length ?
                                        <><span style={{color: giftCardFontColor.current}}>{_T("Scan with Love")}</span><div className="qr-wrapper"><QRCode size={180} value={giftCard.url} /></div></>:
                                        giftCard.imagePreview ?
                                        <><span style={{color: giftCardFontColor.current}}>{_T("Scan with Love")}</span><div className="qr-wrapper"><QRCode size={180} value={giftCard.imagePreview} /></div></> : null
                                    }
                                </div>
                            </div>
                            <canvas ref={canvasRef} style={{ display: 'none' }} />
                            <button className="btn btn-outline-secondary w-100 mb-2" type="button" onClick={()=>{ setGiftCardPreview(false) }}>{_T("Update / Edit Gift Card")}</button>
                            <button className="btn btn-primary w-100" disabled={giftCard.loading} type="submit">{_T("Save Gift Card")}</button>
                        </form>
                    }
                </section>
            </Modal>
            <Modal isOpen={modal3DSecure} className="secure-modal">
                { modal3DSecure ? <iframe src={url3DSecure} title={_T("Payment Confirmation")}></iframe> : null }
            </Modal>
    
            <Modal isOpen={showPhoneError} className="expiry-modal" modalSize={"small"}>
                <div className="text-center">
                    <img src={ErrorIcon} alt="Error" className="mx-auto" width={80}/>
                    <p className="text-gray-400 fs-16 fw-400 text-center">{_T("Your mobile number is required in order to process your payment. Please update your mobile number from your profile")}</p>
                    <Link to={"/account/profile"} className="btn btn-primary">{_T("Update")}</Link>
                </div>
            </Modal>
    
            {
                checkoutLoading && checkoutComplete === 0 ? 
                <div className="payment-loading text-center">
                    <div className="payment-loading-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    <h2 className="fs-22 fw-500 text-secondary">{_T("Please Wait")}</h2>
                    <p className="fs-16 fw-400 text-gray-400">{_T("Please wait while your payment is being processed")}</p>
                </div> : null
            }
            </div>
        }
          <Modal className={"region-modal"} isOpen={regionPopup} title="Choose City for Delivery">
                <div className="region-option-container mt-5 mb-4">
                    {
                        warehouseList.map((item, index) => {
                            return <div className={`form-content ${selectedRegion && selectedRegion.WarehouseId === item.WarehouseId ? "active" : ""} `} key={index}>
                                <label htmlFor={"region" + index}>
                                    <input type="radio" name="region" id={"region" + index} checked={selectedRegion && selectedRegion.WarehouseId === item.WarehouseId} value={item.WarehouseId} onChange={(e) => { if (e.target.checked) { setSelectedRegion(item) } }} />
                                    <i className="ri-map-pin-line region-icon"></i>
                                    <h4>{isEn ? item.NameEn : item.NameAr}</h4>
                                </label>
                            </div>
                        })
                    }
                </div>
                <button disabled={(storeActive && selectedRegion && selectedRegion.WarehouseId === storeActive.WarehouseId) || !selectedRegion} type="button" onClick={updateSelectedWarehouse} className="btn btn-primary w-100 mt-4">{_T("Update Region")}</button>
            </Modal>
        {/* {
            cartRecommended && cartRecommended.length && !checkoutLoading && checkoutComplete === 0 ?
            <div className="related-products">
                <ProductSlider data={{ Products: cartRecommended, TagName: _T("Customizable Products" )}} className={"slider mt-5"} hasBgImage={false} hasBgColor={true} bgColor={"#fef8e9"} onAddToCart={()=>{ setCartUpdate(cartUpdate+1); scrollToTop(120); }}/>
            </div> : null
        } */}
        </>
    )
}
export default CartMain