import { useNavigate } from 'react-router-dom'
import Carousel from 'react-multi-carousel';
import { useRef } from 'react';
import { getLocale } from '../../common';
import Skeleton from 'react-loading-skeleton';
import defaultImage from "../../assets/images/default-category-image.png"
import _T from '../../locale/home';

export default function Brand(props) {
    const xAxisStart = useRef(0)
    const brands = props.brand
    const navigate = useNavigate();
    const locale = getLocale()
    const carouselRef = useRef(null);

    const responsivness = {
        10000: {
            breakpoint: { max: 10000, min: 1200 },
            items: 8,
        },
        1200: {
            breakpoint: { max: 1200, min: 1024 },
            items: 6,
        },
        1024: {
            breakpoint: { max: 1024, min: 850 },
            items: 5,
        },
        850: {
            breakpoint: { max: 850, min: 700 },
            items: 4,
        },
        700: {
            breakpoint: { max: 700, min: 0 },
            items: 3,
        },
    };

    const itemClicked = (e, link) => {
        if (Math.abs(xAxisStart.current - e.clientX) < 20) {
            navigate(link)
        }
        xAxisStart.current = 0
    }

    const handlePrevious = () => {
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    return (
        <section className='brand-section'>
            <div className='wrapper d-flex align-items-center justify-between'>
                <div className='section-header mb-4'>
                    <h2 className='ff-heading text-secondary fs-36 fw-900 has-arrow-start'>{_T("Shop By Brands")}</h2>
                </div>
                {
                    locale === "en" ?
                    <div className="carousel-controls mb-4">
                        <button onClick={handlePrevious} className='navigation-control navigation-previous'><i className="ri-arrow-left-s-line"></i></button>
                        <button onClick={handleNext} className='navigation-control navigation-next'><i className="ri-arrow-right-s-line"></i></button>
                    </div> :
                    <div className="carousel-controls mb-4">
                        <button onClick={handlePrevious} className='navigation-control navigation-previous'><i className="ri-arrow-right-s-line"></i></button>
                        <button onClick={handleNext} className='navigation-control navigation-next'><i className="ri-arrow-left-s-line"></i></button>
                    </div>
                }
            </div>

            <div className='wrapper'>
                <Carousel ref={carouselRef} responsive={responsivness} additionalTransfrom={0} arrows={false} autoPlaySpeed={3000} centerMode={false} className="" containerClass="container-with-dots" dotListClass="" draggable focusOnSelect={false} infinite={false} itemClass="" keyBoardControl minimumTouchDrag={80} pauseOnHover renderArrowsWhenDisabled={false} renderButtonGroupOutside={false} renderDotsOutside={false} rewind={false} rewindWithAnimation={false} rtl={locale === "ar"} shouldResetAutoplay showDots={false} sliderClass="" slidesToSlide={1} swipeable>
                    {
                        props.isLoading ?
                            [...Array(10)].map((item, key) => (
                                <div className='single-brand cursor-pointer' key={key}>
                                    <div className='brand-container text-center'>
                                        <Skeleton height={120} width={120} circle={true} className='mb-3 brand-image-loader'></Skeleton>
                                        <Skeleton height={20} width={90}></Skeleton>
                                    </div>
                                </div>
                            )) :
                            brands.map((brand, key) => (
                                <div onMouseDown={(e) => { xAxisStart.current = e.clientX }} onMouseUp={(e) => itemClicked(e, `/brand-products/${brand.Id}/${brand.Name ? brand.Name.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : _T("brands")}` )} className='single-brand cursor-pointer' key={key}>
                                    <div className='brand-container text-center'>
                                        <div className='brand-image'>
                                            <img src={brand.PicturURL} onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }} alt={brand.Name} draggable={false} />
                                        </div>
                                        <h3 className='brand-heading mb-0'>{locale === "ar" ? brand.NameAr : brand.Name}</h3>
                                    </div>
                                </div>
                            ))
                    }
                </Carousel>
            </div>
        </section>
    )
}