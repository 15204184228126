import ProductCard from '../../components/Product-Card-01';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import _T from '../../locale/product';

export default function ProductGrid(props) {
    const { isLoading, data, hasBgImage, hasBgColor, bgImage, bgColor, className, showSeeAll } = props

    return (
        <section className={`products-grid ${className}`} style={{ backgroundImage: hasBgImage ? `url(${bgImage})` : 'none', backgroundColor: hasBgColor ? bgColor : '#fff' }}>
            <div className='wrapper'>
                <div className={`section-header ${showSeeAll ? "d-flex align-items-center justify-between" : ""}`}>
                    <h2 className='ff-heading text-secondary fs-36 fw-900 has-arrow-start text-capitalize'>{data.TagName}</h2>
                    {
                        showSeeAll ?
                        <Link to={`tagged-products/${data.TagId}/${data.TagName ? data.TagName.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : "tag"}`} className='decoration-none text-secondary fs-14 fw-500'>{_T("See All")}</Link> : null
                    }
                </div>
                <p className="mt-0 mb-3 fs-18 fw-400 text-gray-500">{data.TagDescription}</p>
            </div>
            <div className={`wrapper grid-container`}>
                {
                    props.isLoading ?
                        [...Array(10)].map((item, key) => (
                            <div className='item-wrapper' key={key}>
                                <div className="single-product product-style-01">
                                    <Skeleton className='w-100'></Skeleton>
                                </div>
                            </div>
                        )) :
                        data.Products.map((item, key) => (
                            <div className='item-wrapper' key={key}>
                                <ProductCard item={item} isLoading={isLoading} setToggleClass={() => { }} />
                            </div>
                        ))
                }
            </div>
        </section>
    )
}