function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "Home": {
            "en": "Home",
            "ar": "الصفحة الرئيسية"
        },
        "Brands": {
            "en": "Brands",
            "ar": "الماركات"
        },
        "Products": {
            "en": "Products",
            "ar": "المنتجات"
        },
        "About": {
            "en": "About",
            "ar": "نبذة عنا"
        },
        "Categories": {
            "en": "Categories",
            "ar": "الفئات"
        },
        "Occasions": {
            "en": "Occasions",
            "ar": "المناسبات"
        },
        "Contact Us": {
            "en": "Contact Us",
            "ar": "اتصل بنا"
        },
        "Deliver to": {
            "en": "Deliver to",
            "ar": "التوصيل إلى"
        },
        "Select Language": {
            "en": "Select Language",
            "ar": "اختيار اللغة"
        },
        "View in": {
            "en": "View in",
            "ar": "عرض"
        },
        "View in English": {
            "en": "View in English",
            "ar": "عرض باللغة الإنجليزية"
        },
        "View in Arabic": {
            "en": "View in Arabic",
            "ar": "عرض باللغة العربية"
        },
        "Choose City": {
            "en": "Choose City",
            "ar": "اختر المدينة"
        },
        "Search Products...": {
            "en": "Search Products...",
            "ar": "البحث عن المنتجات..."
        },
        "Update Language": {
            "en": "Update Language",
            "ar": "تحديث اللغة"
        },
        "* By changing your current city, you may lose your selection inside the cart.": {
            "en": "* By changing your current city, you may lose your selection inside the cart.",
            "ar": "* تغيير مدينتك الحالية قد يسبب فقدان المنتجات في العربة"
        },
        "Select City": {
            "en": "Select City",
            "ar": "اختر مدينة"
        },
        "Save upto 25% off on your first purchase": {
            "en": "Save upto 25% off on your first purchase",
            "ar": "وفر حتى 25 ٪ خصم على الطلب الأول"
        },
        "Show all products": {
            "en": "Show all products",
            "ar": "عرض جميع المنتجات"
        },
        "Sorry, we could not find any products for you.": {
            "en": "Sorry, we could not find any products for you.",
            "ar": "آسف ، لم نتمكن من العثور على أي منتجات لك."
        },
        "Blog": {
            "en": "Blog",
            "ar": "مدونة"
        },
        "Blogs": {
            "en": "Blogs",
            "ar": "المدونات"
        }
    }

    return translations[text] ? translations[text][locale] : text;
}
export default _T;