import Carousel from 'react-multi-carousel';
import ProductCard from '../../components/Product-Card-01';
import { useRef, useState } from 'react';
import { getLocale } from '../../common';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import _T from '../../locale/product';
export default function ProductSlider(props) {
    const { isLoading, data, hasBgImage, hasBgColor, bgImage, bgColor, className, showSeeAll, isPrimary = false } = props
    const locale = getLocale()
    const [ toggleClass, setToggleClass ] = useState(false);
    const carouselRef = useRef(null);
    const responsive = {
        10000: {
            breakpoint: { max: 10000, min: 1190 },
            items: 4,
        },
        1160: {
            breakpoint: { max: 1160, min: 880 },
            items: 3,
        },
        880: {
            breakpoint: { max: 880, min: 621 },
            items: 3,
        },
        621: {
            breakpoint: { max: 621, min: 375 },
            items: 2,
        },
        375: {
            breakpoint: { max: 375, min: 0 },
            items: 1,
        },
    };


    const handlePrevious = () => {
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    return (
        <section className={`products-slider ${className}`} style={{ backgroundImage: hasBgImage ? `url(${bgImage})` : 'none', backgroundColor: hasBgColor ? bgColor : '#fff' }}>
            <div className='wrapper'>
                <div className={`section-header ${showSeeAll ? "d-flex align-items-center justify-between" : ""}`}>
                    <div>
                        {
                            isPrimary ?
                            <h1 className='ff-heading text-secondary fs-36 fw-900 text-capitalize m-0'>{data.TagName}</h1> :
                            <h2 className='ff-heading text-secondary fs-36 fw-900 text-capitalize m-0'>{data.TagName}</h2>
                        }
                        <p className="m-0 fs-16 fw-400 text-gray-500">{data.TagDescription}</p>
                    </div>
                    {
                        showSeeAll ?
                        <Link to={`tagged-products/${data.TagId}/${data.TagName ? data.TagName.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : "tag"}`} className='decoration-none text-secondary fs-14 fw-500'>{_T("See All")}</Link> : <></>
                    }
                </div>
            </div>
            <div className={`wrapper mt-5 carousal-wrapperx carousel-with-bullets ${toggleClass ? "remove-transformation" : ""}`}>
                <Carousel
                    ref={carouselRef}
                    responsive={responsive}
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    partialVisible
                    containerClass="product-container-with-dots"
                    dotListClass="product-carousel-bullets"
                    draggable
                    focusOnSelect={false}
                    infinite={false}
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={true}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={locale === "ar"}
                    shouldResetAutoplay
                    showDots={true}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >

                    {
                        props.isLoading ?
                            [...Array(10)].map((item, key) => (
                                <div className='item-wrapper' key={key}>
                                    <div className="single-product product-style-01">
                                        <Skeleton className='w-100'></Skeleton>
                                    </div>
                                </div>
                            )) :
                            data.Products.map((item, key)=>(
                                <div className='item-wrapper' key={key}>
                                    <ProductCard item={item} isLoading={isLoading} setToggleClass={setToggleClass} />
                                </div>
                            ))
                    }
                </Carousel>
            </div>
            <div className={`wrapper`}>
                {
                    locale === "en" ?
                        <div className="product-card-carousel-controls mt-5 mb-0 text=right">
                            <button onClick={handlePrevious} className='product-card-navigation-control navigation-previous'><i className="ri-arrow-left-s-line"></i></button>
                            <button onClick={handleNext} className='product-card-navigation-control navigation-next'><i className="ri-arrow-right-s-line"></i></button>
                        </div> :
                        <div className="product-card-carousel-controls mt-5 mb-0 text=right">
                            <button onClick={handlePrevious} className='product-card-navigation-control navigation-previous'><i className="ri-arrow-right-s-line"></i></button>
                            <button onClick={handleNext} className='product-card-navigation-control navigation-next'><i className="ri-arrow-left-s-line"></i></button>
                        </div>
                }
            </div>
        </section>
    )
}