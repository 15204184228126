import React from "react";
import { Navigate } from "react-router-dom";


export const PrivateRoute = (props) => {
    const accessToken = localStorage.getItem("authToken")
    const isAuthenticated = accessToken && accessToken.length ? true : false;
	return isAuthenticated ? props.children : <Navigate to={ window.location.pathname.length > 1 ? "/login?redirect="+encodeURIComponent(window.location.pathname) : "/login"} />;
};


export const AuthRoute = (props) => {
    const accessToken = localStorage.getItem("authToken")
    const isAuthenticated = accessToken && accessToken.length ? true : false;
	return isAuthenticated ? <Navigate to="/account/profile" /> : props.children;
};

