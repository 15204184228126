import React, { useState } from "react";
import { delCartProduct, updateCart } from "../../helper/backend-methods";
import noImg from '../../assets/images/no-image.jpg'
import _T from "../../locale/cart";

function CartProduct(props) {
    const { item, loading, isSubscription, triggerLoading } = props;
    const [loadingBtn, setLoadingBtn] = useState(false)
    // const update = props.update
    // const setUpdate = props.setUpdate
    // const { update, setUpdate } = props;
    // const loading = props.loading
    // const [quantity, setQuantity] = useState(items.Quantity);
    // const [qtyLoading, setQtyLoading] = useState(false);

    const deleteItem = () => {
        setLoadingBtn(true)
        triggerLoading(true)
        delCartProduct("?cartItemId=" + item.Id).then(({ data }) => {
            if (data.status) {
                props.setUpdate(props.update + 1)
                setLoadingBtn(false)
                props.setCurrentStep(2)
            }
            else {
                triggerLoading(false)
                setLoadingBtn(false)
            }
        }).catch((e) => {
            console.log(e);
            setLoadingBtn(false)
            triggerLoading(true)
        })
    }
    const updateQuantity = (qty) => {
        triggerLoading(true)
        updateCart({ CartItemId: item.Id, Quantity: qty }).then(({ data }) => {
            if (data.status) {
                props.setUpdate(props.update + 1)
            }
            else {
                triggerLoading(false)
            }
        }).catch((e) => {
            triggerLoading(false)
            console.log(e);
        })
    }

    return (
        <div className={`shopping-cart-products d-flex align-items-center mt-3 ${item.Product.IsFreeProductAdded ? "free-product" : item.Product.IsCakeProduct ? "cake-product" : ""}`}>
            <div className="cart-product-img d-flex">
                <img src={item.Product.PicturURL} alt={item.Product.Name} onError={(e) => { e.target.onerror = null; e.target.src = noImg }}/>
            </div>
            <div className="cart-product-details ml-3">
                <p className="fs-14 fw-500 text-secondary m-1 product-title">{item.Product.Name}</p>
                <span className="product-tag"><i className="ri-gift-line mr-1 fs-16"></i> {_T("Free Gift")}</span>
                <p className="free-tag fs-12 fw-500 text-primary m-0">{item.Product.ShortDescription}</p>
                {
                    item.CartAlternateProductDTO && item.CartAlternateProductDTO.length ?
                        <span className="product-attribute fs-14 fw-400 text-gray-500 m-1">{_T("Alternative Color:")} <span className="ml-2" style={{ backgroundColor: item.CartAlternateProductDTO[0].Color.ColorSquaresRgb }}></span></span> : null
                }
                {
                    item.Product.IsCakeProduct ?
                        <>
                            <span className="product-attribute fs-14 fw-400 text-gray-500 m-1 d-block">{_T(item.Product.ProductAttributeDescription[0].Type)}: <b className="ml-1 fw-400">{item.Product.ProductAttributeDescription[0].Name}</b></span>
                            <span className="product-attribute fs-14 fw-400 text-gray-500 m-1 d-block">{_T(item.Product.ProductAttributeDescription[1].Type)}: <b className="ml-1 fw-400">{item.Product.ProductAttributeDescription[1].Name}</b></span>
                        </> : null
                }
                <p className="product-price fs-14 fw-400 text-secondary m-1 text-en">SAR {item.Product.Price.toFixed(2)}</p>
            </div>
            <div className="cart-product-actions">
                <div className={`del-btn text-right cursor-pointer `} onClick={() => { deleteItem() }}>
                    {loadingBtn ? <i className="ri-loader-line do-spin d-inline-flex"></i> : <i className="ri-delete-bin-line text-flushed"></i>}
                </div>
                {
                    item.Product.IsCakeProduct ?
                        <div className="product-quantity-container text-right border-none">
                            <span className="product-attribute fs-14 fw-400 text-gray-500 m-1 d-block w-100">{_T(item.Product.ProductAttributeDescription[2].Type)}: <b className="ml-1 fw-400">{item.Product.ProductAttributeDescription[2].Name}</b></span>
                        </div> :
                        <div className="product-quantity-container d-inline-flex align-items-center">
                            <button className="product-quantity-btn text-gray-400" disabled={isSubscription} onClick={() => { item.Product.Quantity <= 1 ? console.log(0) : updateQuantity(item.Product.Quantity - 1) }} >-</button>
                            <input className="product-quantity-field" value={item.Product.Quantity} placeholder="1" readOnly />
                            <button className="product-quantity-btn text-gray-400" disabled={isSubscription} onClick={() => { item.Product.Quantity >= item.Product.TotalStockQuantity ? console.log("quantity is exceeding") : updateQuantity(item.Product.Quantity + 1) }} >+</button>
                        </div>
                }
            </div>
        </div>
    )
}
export default CartProduct