import React, { useEffect } from "react";
import Loader from "../../components/Loader"
import { scrollToTop } from "../../common";
function Logout() {

   useEffect(()=>{
      scrollToTop();
      localStorage.removeItem("authToken")
      localStorage.removeItem("userInfo")
      window.location = "/login"
   },[])

   return (
      <section>
         <button className="btn btn-alternative">Please wait while we're logging you out</button>
         <Loader></Loader>
      </section>
   )
}
export default Logout