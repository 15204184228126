import React, { useEffect } from "react";
import "../../assets/css/--page-additional.css";
import BreadCrumbs from "../../components/BreadCrumbs"
import { googleTagInitialize, pixelPageEvent, scrollToTop } from "../../common";
import _T from "../../locale/about";
import SEO from "../../components/SEO";
function AboutUs() {
    useEffect(()=>{
        scrollToTop();
        pixelPageEvent();
        googleTagInitialize();
    },[])
    const breadcrumbItems = [
        { title: _T("Home"), link: "/" },
        { title: _T("About Us"), link: "" },
    ]
    return (
    <section className="additional-page">
        <div className="page-header text-center underline">
            <h1 className="has-arrow-start ff-heading has-arrow-white fw-900">{_T("About Us")}</h1>
        </div>
        <BreadCrumbs pageTile="About" breadcrumbItems={breadcrumbItems} />
        <SEO metaTitle={_T("About Us | Online Flowers Shop | Wardstation")} metaDescription={_T("Wardstation, where you can find a wide range of beautiful flowers and thoughtful gifts. Explore our stunning bouquets, delicious chocolates, and delightful cakes, and find the perfect way to show love and appreciation for any occasion. With quick delivery available across KSA, experience the convenience of gifting with Wardstation. Order now!")} metaKeywords={_T("flower shop, gift shop, flowers delivery, chocolates, cakes, Wardstation, KSA, online gifts shop")} />
        <div className="wrapper">
            <div className="page-content"> 
                <h2>{_T("Who we are")}</h2>
                <p>{_T("Wardstation is an online flowers and gifting company; we pride ourselves on creating a seamless experience for our customers and making sending flowers and gifts as enjoyable as receiving them. We source our flowers daily from the best growers and farmers around the world to create one of a kind stunning arrangements and plants that fit every occasion. We partner with local and international brands to offer a wide range of gifts including chocolate, perfumes, cakes and more, coupled with our arrangements to create the perfect gift. We also partner with exciting and creative designers to give them the opportunity to express themselves by creating their own exquisite bouquets and showcase them on our store. we guarantee same-day delivery across the board through our own refrigerated fleet to ensure our arrangements arrive fresh to the customer.")}</p>
                <h2>{_T("Our Mission")}</h2>
                <p>{_T("To capture new moments of appreciation to a wider range of people, by being creative in the ease of access, reliability and premium quality of our products.")}</p>
                <h2>{_T("Our Vision")}</h2>
                <p>{_T("To create a world where people cease every moment to express their feelings and bringing more happiness by making flowers part of every gift")}</p>
            </div>
        </div>
    </section>
    )
}
export default AboutUs