import "../assets/css/--component-loader.css"
function Loader(){
    return (
        <div id="primaryLoader">
            <div className="loader">
                <div className="spinnerBlock">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    )
}
export default Loader;