import React, { useState,useEffect, useRef } from "react";
import defaultImage from "../assets/images/default-category-image.png"
import { Link, useNavigate } from "react-router-dom";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Configure, useInstantSearch } from 'react-instantsearch-hooks-web';
import { getActiveStore, getLocale, pixelTrackEvent } from "../common";
import _T from "../locale/header";

function Search(){
    const [isAlgoliaInitialized , setIsAlgoliaInitialized] = useState(false)
    const [searchClient , setSearchClient] = useState(null)
    const [ isFocused, setIsFocused ] = useState(false);
    const [ showSearchBox, setShowSearchBox ] = useState(false);
    const [ language, setLanguage ] = useState("en")
    const warehouseId = getActiveStore() ? getActiveStore().WarehouseId : 4;
    const history = useNavigate();


    useEffect(()=>{
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            setLanguage(lang)
        }
    },[])

    useEffect(()=>{
        if(!isAlgoliaInitialized){
            initializeAlgolia();
        } 
    },[])

    const initializeAlgolia = () =>{
        const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_ENV, process.env.REACT_APP_ALGOLIA_ENV_KEY);
        setSearchClient(algoliaClient)
        setIsAlgoliaInitialized(true)
    }

    const onSearchSumbit= (e) =>{
        const value = e.target[0].value
        if (value.replaceAll(/\s/g, '').length) {
            history('/products?keyword='+value);
            pixelTrackEvent("Search", {search_string: value })
        }
        else{
            history('/products');
        }
    };

    const transformItems = (items, { results }) => {
        return items.map((item, index) => ({
          ...item,
          searchQuery: results.query,
        }));
    };
    
    return (
        <>
        <div className={ showSearchBox ? "header-search toggle-search" : "header-search" }>
            {
                isAlgoliaInitialized?
                <InstantSearch searchClient={searchClient} indexName={process.env.REACT_APP_ALGOLIA_SEARCH_INDEX} >
                <Configure hitsPerPage={5} filters={`warehouse_id:${warehouseId} AND is_product_show:true`}/>
                    <button onClick={()=>{setShowSearchBox(!showSearchBox)}} className="search-toggle" type="button"><i className="ri-search-2-line"></i></button>
                    <button onClick={()=>{setShowSearchBox(!showSearchBox)}} className="search-close" type="button"><i className="ri-close-line"></i></button>
                    <SearchBox placeholder={_T("Search Products...")} onSubmit={onSearchSumbit} searchAsYouType={true} onBlur={()=>{ setTimeout(() => { setIsFocused(false) }, 300); }} onFocus={()=>{ setIsFocused(true) }}/>
                    {
                        isFocused ? 
                        <EmptyQueryBoundary fallback={<NoResults language={language}/>}>
                            <Hits hitComponent={SearchResult} transformItems={transformItems}/>
                        </EmptyQueryBoundary>
                        : <></>
                    }
                </InstantSearch> : null
            }
        </div>
        </>
    )
}
function SearchResult({hit}){
    const [ language, setLanguage ] = useState(getLocale());
    const productSlug = useRef( language === "ar" ? hit.productname_ar ? hit.productname_ar.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : hit.productname_en.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : hit.productname_en.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() )
    return (
        <>
        
            <Link to={`/product/${hit.id}/${productSlug.current}`} className="search-product-result">
                <div className="search-product-image">
                    <img src={hit.picture_url} alt="" onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }}/>
                </div>
                <div className="search-product-info">
                    <h2>{ language === "ar" ? hit.productname_ar ? hit.productname_ar : hit.productname_en : hit.productname_en }</h2>
                </div>
                <div className="search-product-price">
                    <span className="single-price">
                        SAR<span className="text-secondary"> {hit.current_price.toFixed(2)}</span>
                    </span>
                </div>
            </Link>
            { hit.searchQuery.length ? <Link to={"/products?keyword="+hit.searchQuery} onClick={()=>{ pixelTrackEvent("Search", {search_string: hit.searchQuery }) }} className="showMoreLink" >{_T("Show all products")}</Link> : <></> }
        </>
    )
}
function EmptyQueryBoundary({ children, fallback }) {
    const { indexUiState, results } = useInstantSearch();
    if (!results.__isArtificial && results.nbHits === 0) {
      return fallback;
    }
    if (!indexUiState.query) {
        return null;
    }
    return <div className={results.nbHits > 5 ? "show" : "hide"}>{children}</div>;
}
function NoResults({language}) {
    return (<div className="ais-Hits">
        <ol className="ais-Hits-list">
            <li className="ais-Hits-item"><span className="search-product-result">{_T("Sorry, we could not find any products for you.")}</span></li>
        </ol>
    </div>);
}
export default Search;