import React, { useEffect, useState } from "react";
import cartImage from "../../assets/images/cart-image.png"
import Modal from "../../components/Modal"
import infoIcon from "../../assets/images/info-icon.png"
import clickIcon from "../../assets/images/click-icon.png"
import { addToCart, delOccasionCartItems } from "../../helper/backend-methods";
import { NavLink } from "react-router-dom";
import _T from "../../locale/occasion";
import { Toaster } from "../../components/Toaster";
function OccasionProductCard(props) {
    const productData = props.productData
    const occasionID = props.occasionId
    const onUpdate = props.onUpdate
    const [loadingButton, setLoadingButton] = useState(false)
    const [cartModal, setCartModal] = useState(false);
    const [alternativeProductModal, setAlternativeProductModal] = useState(false);
    const [showAlternativeOption, setShowAlternativeOption] = useState(false);
    const [selectedColorAlternative, setSelectedColorAlternative] = useState(false);
    // const key = props.index

    const [InStock,setInStock] = useState(true)
    useEffect(() => {
      if (productData.StockDetail && productData.StockDetail === "Out of Stock") {
        setInStock(false)
      }
      else{
        setInStock(true)
      }
    
    }, [])

    const addItemToCart = () => {
       
        setLoadingButton(true)
        setAlternativeProductModal(false);
        setShowAlternativeOption(false);
        let alternativeSelection = false;
        if(selectedColorAlternative && productData.Colors){

            const selectedAlternativeVariant = productData.Colors.filter((item)=> item.AssociatedProductId === selectedColorAlternative)[0]
            alternativeSelection = {
                "AlternateColor": selectedAlternativeVariant.Name,
                "IsAlternateAvailable": true,
                "ProductId": selectedAlternativeVariant.AssociatedProductId,
                "Send": false
            }
        }
        addToCart({Quantity : 1 , ProductId: productData.Id, OccassionId: occasionID, ...(selectedColorAlternative && {AlternateVariation: alternativeSelection}) }).then(({data})=>{
            setSelectedColorAlternative(false);
            if(data.status){
                setCartModal(true)
            }
            setLoadingButton(false)
        }).catch((e)=>{
            console.log(e)
            setLoadingButton(false)
        })
    }
    
    
    const deleteOccasionItem = ()=>{
        delOccasionCartItems({occassionId:occasionID, productId:productData.Id}).then(({data})=>{
            if (data.status) {
                onUpdate()
                Toaster("success", _T("Product Removed"), _T("Product has been removed from the occasion!"))
            }
        }).catch((e)=>{
            Toaster("error", _T("Removed Failed"), _T("Product hasn't been removed from the occasion!"))
            console.log(e);
        })
    }

    return <div className="occasion-product-card d-flex align-items-center bg-wrapper justify-between position-relative">
        <div className="occasion-product-image">
            <img src={productData.ImageUrl} alt="product"></img>
        </div>
        <div className="occasion-product-desc ml-2">
            <h2 className="text-secondary fs-18 fw-500">{productData.Name}</h2>
            <p className="text-primary fs-16 fw-500">{productData.StockDetail ? productData.StockDetail : "In Stock"}</p>
            <p className="fs-16 text-secondary fw-400 text-en">SAR {Math.round((productData.Price*100))/100}</p>
        </div>
        <div className="occasion-product-buttons mt-4">
        <button className="product-action btn btn-primary w-100" disabled={ !InStock || loadingButton } onClick={()=>{loadingButton? console.log("loading") : productData.HasColor ? (function(){setAlternativeProductModal(true); })() : addItemToCart() }}> { InStock ? <><i className="ri-shopping-cart-line mr-1"></i>{ loadingButton ? _T("Please Wait!") : _T("Add to Cart")}</> : _T("Out of Stock") }</button>
        </div>
        <span className="position-absolute product-del-btn d-flex justify-center align-items-center" onClick={deleteOccasionItem}><i className="ri-delete-bin-line"></i></span>
        
        
        <Modal className="cart-modal" isOpen={cartModal} modalSize="small">
            <div className="text-center">
                <img src={cartImage} alt="Cart Icon" className="mx-auto" width={110}/>
                <p className="text-gray-400 fs-16 fw-400 text-center mt-3 mb-3">{_T("The item has been added to your shopping cart")}</p>
                <button className="btn btn-primary w-100 mb-2" type="button" onClick={()=>{ setCartModal(false);  }}>{_T("Continue Shopping")}</button>
                <NavLink className="btn btn-secondary w-100" to={"/cart"}>{_T("Checkout")}</NavLink>
            </div>
        </Modal>


        <Modal className="select-alternative-modal" isOpen={alternativeProductModal} modalSize="small" onClose={()=>{ setAlternativeProductModal(false); setShowAlternativeOption(false); setSelectedColorAlternative(false); }} >
            {
                !showAlternativeOption ?
                <div className="text-center">
                    <img src={infoIcon} alt="Info Icon" className="mx-auto" width={50}/>
                    <p className="text-gray-400 fs-16 fw-400 text-center mb-0 mt-3">{_T("If the item is not available")}</p>
                    <p className="text-gray-400 fs-16 fw-400 text-center mt-0 mb-3">{_T("What would you like to do?")}</p>
                    <button className="btn btn-primary w-100 mb-2" type="button" onClick={()=>{ addItemToCart() }}>{_T("Remove Item")}</button>
                    <button className="btn btn-secondary w-100" type="button" onClick={()=>{ setShowAlternativeOption(true) }}>{_T("Select Alternative")}</button>
                </div> :
                <div className="text-center">
                    <img src={clickIcon} alt="Click Icon" className="mx-auto" width={50}/>
                    <p className="text-gray-400 fs-16 fw-400 text-center">{_T("Select Alternative Color")}</p>
                    {
                        productData.HasColor && productData.Colors?
                        <div className="product-color-selector">
                            <div className="color-options">
                                {
                                        productData.Colors.map((color,index)=>{
                                        return <span onClick={()=>{ setSelectedColorAlternative(color.AssociatedProductId) }} className={`color-option ${color.AssociatedProductId === selectedColorAlternative ? _T("selected") : ""} `} key={index} style={{ backgroundColor: color.ColorSquaresRgb }}></span>
                                    })
                                }
                            </div>
                        </div> : <></>
                    }
                    <button className="btn btn-secondary w-100" disabled={!selectedColorAlternative} type="button" onClick={addItemToCart}>{_T("Confirm")}</button>
                </div>
            }
        </Modal>
    
    </div>
    
}
export default OccasionProductCard