import { Link } from "react-router-dom";
// import ErrorImage from "../assets/images/503.png"
// import ErrorImageAR from "../assets/images/503-ar.png"
// import "../assets/css/--component-404.css"
import { useEffect, useState } from "react";
import { analyticsPageEvent } from "./Analytics";

function Maintenance(props){
    // const [ content, setContent ] = useState(translations.maintenance["en"])
    // useEffect(() => {
    //     const lang = localStorage.getItem("LANG")
    //     if(lang && lang.length){
    //         setContent(translations.maintenance[lang] ? translations.maintenance[lang] : translations.maintenance["en"])
    //     }
    // }, []);
    

    return (
        <section className="error-page">
            <div className="wrapper d-flex align-items-center">
                <div className="error-content">
                    {/* <h1 className="fs-36 fw-800 text-primary mt-0 mb-1">{content.heading}</h1> */}
                    {/* <p className="fs-18 fw-400 text-gray-400 m-0">{content.message}</p> */}
                </div>
                <div className="error-image">
                    {/* <img src={ErrorImage} alt=""/> */}
                </div>
            </div>
        </section>
    )
}
export default Maintenance;