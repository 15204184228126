function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "20087": {
            "en": "CVV is invalid",
            "ar": "الرمز غير صحيح",
        },
        "20054": {
            "en": "Your Card is Expired",
            "ar": "انتهت صلاحية بطاقتك",
        },
        "20051": {
            "en": "Insufficient Funds",
            "ar": "أموال غير كافية",
        },
        "20046": {
            "en": "Transaction declined by bank",
            "ar": "تم رفض العملية من قبل البنك",
        },
        "20056": {
            "en": "Invalid card number",
            "ar": "رقم البطاقة غير صحيح",
        },
        "10000": {
            "en": "Payment Successful",
            "ar": "تم الدفع بنجاح",
        },
        "20103": {
            "en": "The payment has been declined by your bank. Please try again with a different card or contact your bank for further support.",
            "ar": "تم رفض الدفع من قبل البنك. يرجى المحاولة مرة أخرى باستخدام بطاقة مختلفة أو الاتصال بالبنك للحصول على دعم إضافي.",
        },
        "20002": {
            "en": "The payment has been declined by your bank. Please try again with a different card or contact your bank for further support.",
            "ar": "تم رفض الدفع من قبل البنك. يرجى المحاولة مرة أخرى باستخدام بطاقة مختلفة أو الاتصال بالبنك للحصول على دعم إضافي.",
        },
        "20005": {
            "en": "The payment has been declined by your bank. Please try again with a different card or contact your bank for further support.",
            "ar": "تم رفض الدفع من قبل البنك. يرجى المحاولة مرة أخرى باستخدام بطاقة مختلفة أو الاتصال بالبنك للحصول على دعم إضافي.",
        },
        "20010": {
            "en": "Partial value approved",
            "ar": "تم الموافقة على قيمة جزئية",
        },
        "20012": {
            "en": "Invalid transaction",
            "ar": "عملية غير صحيحة",
        },
        "20013": {
            "en": "Invalid transaction",
            "ar": "عملية غير صحيحة",
        },
        "20014": {
            "en": "Invalid card number",
            "ar": "رقم البطاقة غير صحيح",
        },
        "20017": {
            "en": "Customer cancellation",
            "ar": "إلغاء من قبل العميل",
        },
        "20018": {
            "en": "Customer dispute",
            "ar": "نزاع من قبل العميل",
        },
        "20019": {
            "en": "Re-enter transaction or transaction has expired",
            "ar": "أعد إدخال العملية أو انتهت صلاحية العملية",
        },
        "20020": {
            "en": "Invalid response",
            "ar": "استجابة غير صحيحة",
        },
        "20031": {
            "en": "Bank not supported by Switch",
            "ar": "البنك غير معتمد من قبل الشبكة",
        },
        "20032": {
            "en": "Completed partially",
            "ar": "اكتملت جزئيا",
        },
        "20038": {
            "en": "Allowable PIN tries exceeded",
            "ar": "تجاوزت محاولات إدخال رمز التحقق الشخصي المسموح به",
        },
        "20039": {
            "en": "No CREDIT Account",
            "ar": "لا يوجد حساب ائتمان",
        },
        "20052": {
            "en": "No cheque account",
            "ar": "لا يوجد حساب شيكات",
        },
        "20053": {
            "en": "No savings account",
            "ar": "لا يوجد حساب توفير",
        },
        "20054": {
            "en": "Expired card",
            "ar": "انتهت صلاحية البطاقة",
        },
        "20055": {
            "en": "Incorrect PIN (invalid Amex CVV)",
            "ar": "رمز التحقق الشخصي غير صحيح (CVV للأمريكان إكسبريس)",
        },
        "20057": {
            "en": "Transaction not permitted to cardholder",
            "ar": "العملية غير مسموح بها لحامل البطاقة",
        },
        "20061": {
            "en": "Activity amount limit exceeded",
            "ar": "تجاوز حد النشاط المسموح به",
        },
        "20062": {
            "en": "Restricted card",
            "ar": "بطاقة مقيدة",
        },
        "20065": {
            "en": "Exceeds Withdrawal Frequency Limit",
            "ar": "تجاوز حد تكرار السحب",
        },
        "20059": {
            "en": "The payment has been declined by your bank. Please try again with a different card or contact your bank for further support",
            "ar": "تم رفض الدفع من قبل البنك. يرجى المحاولة مرة أخرى باستخدام بطاقة مختلفة أو الاتصال بالبنك للحصول على دعم إضافي",
        },
        "20063": {
            "en": "Security violation",
            "ar": "انتهاك أمان",
        },
        "20068": {
            "en": "The payment failed due to a technical issue. Please try again with the same card or use a different card",
            "ar": "فشلت الدفع بسبب مشكلة تقنية. يرجى المحاولة مرة أخرى باستخدام نفس البطاقة أو استخدام بطاقة مختلفة",
        },
        "20154": {
            "en": "The payment declined due to Strong Customer Authentication. Please try again with the same card, or use a different card",
            "ar": "تم رفض الدفع بسبب التحقق القوي للعميل. يرجى المحاولة مرة أخرى باستخدام نفس البطاقة أو استخدام بطاقة مختلفة",
        },
        "200R1": {
            "en": "The cardholder has canceled this subscription",
            "ar": "ألغى حامل البطاقة هذا الاشتراك",
        },
        "20151": {
            "en": "Cardholder failed 3DS authentication",
            "ar": "فشل حامل البطاقة في التحقق الثلاثي الأبعاد",
        },
        "200N7": {
            "en": "Decline for CVV2 failure",
            "ar": "رفض بسبب فشل التحقق من الرمز السري CVV2",
        },
        "200R3": {
            "en": "Issuer initiated a stop payment (revocation order) for all authorizations",
            "ar": "بدأ البنك المانح في إيقاف الدفع (أمر إلغاء) لجميع التفويضات",
        },
        "20001": {
            "en": "Refer to card issuer",
            "ar": "الرجاء الرجوع إلى المانح الخاص بالبطاقة",
        },
        "20109": {
            "en": "Authorization was previously reversed or voided; the capture amount is larger than the initial authorization amount",
            "ar": "تم عكس أو إلغاء التفويض سابقًا؛ مبلغ الاستيلاء أكبر من مبلغ التفويض الأولي",
        },
        "30043": {
            "en": "Stolen card",
            "ar": "بطاقة مسروقة",
        },
        "30015": {
            "en": "No such issuer",
            "ar": "لا يوجد مانح مثل هذا",
        },
        "20183": {
            "en": "Security",
            "ar": "أمان",
        },
        "20182": {
            "en": "Policy",
            "ar": "سياسة",
        },
        "30041": {
            "en": "Lost card",
            "ar": "بطاقة مفقودة",
        },
        "20078": {
            "en": "Blocked card",
            "ar": "بطاقة محظورة",
        },
        "20082": {
            "en": "Security",
            "ar": "أمان",
        },
        'token_required': {
            "en": "cvv_required",
            "ar": "الرمز التحقق الخاص بالبطاقة مطلوب",
        },
        "cvv_invalid": {
            "en": "cvv_invalid",
            "ar": "الرمز غير صحيح",
        },
        "invalid_payment_method": {
            "en": "Invalid payment method",
            "ar": "طريقة دفع غير صحيحة",
        },
        "internal_server_error": {
            "en": "Internal Server Error",
            "ar": "خطأ داخلي في الخادم",
        },
        "request_result_not_found": {
            "en": "Request result not found",
            "ar": "نتيجة الطلب غير موجودة",
        },
        "payment_method_not_supported": {
            "en": "The payment has been declined by your bank. Please try again with a different card or contact your bank for further support.",
            "ar": "تم رفض الدفع من قبل البنك. يرجى المحاولة مرة أخرى باستخدام بطاقة مختلفة أو الاتصال بالبنك للحصول على دعم إضافي.",
        },
        "token_used": {
            "en": "token_used",
            "ar": "الرمز المميز (Token) مستخدم",
        },
        "token_invalid": {
            "en": "token_invalid",
            "ar": "الرمز المميز (Token) غير صحيح",
        },
        "token_in_use": {
            "en": "token_in_use",
            "ar": "الرمز المميز (Token) قيد الاستخدام",
        },
        "token_expired": {
            "en": "token_expired",
            "ar": "انتهت صلاحية الرمز المميز (Token)",
        },
        "3ds_payment_required": {
            "en": "3ds_payment_required",
            "ar": "مطلوب الدفع باستخدام تقنية التحقق الثلاثي الأبعاد (3DS)",
        },
        "card_expiry_month_required": {
            "en": "card_expiry_month_required",
            "ar": "الشهر الانتهاء للبطاقة مطلوب",
        },
        "card_expiry_month_invalid": {
            "en": "card_expiry_month_invalid",
            "ar": "الشهر الانتهاء للبطاقة غير صحيح",
        },
        "card_expired": {
            "en": "card_expired",
            "ar": "انتهت صلاحية البطاقة",
        },
        "card_authorization_failed": {
            "en": "card_authorization_failed",
            "ar": "فشلت الموافقة على البطاقة",
        },
        "capture_value_greater_than_remaining_authorized": {
            "en": "capture_value_greater_than_remaining_authorized",
            "ar": "قيمة التسجيل تتجاوز القيمة المصرح بها المتبقية",
        },
        "card_expiry_year_required": {
            "en": "card_expiry_year_required",
            "ar": "السنة الانتهاء للبطاقة مطلوبة",
        },
        "card_expiry_year_invalid": {
            "en": "card_expiry_year_invalid",
            "ar": "السنة الانتهاء للبطاقة غير صحيحة",
        },
        "card_not_found": {
            "en": "card_not_found",
            "ar": "البطاقة غير موجودة",
        },
        "card_number_invalid": {
            "en": "card_number_invalid",
            "ar": "رقم البطاقة غير صحيح",
        },
        "card_number_required": {
            "en": "card_number_required",
            "ar": "رقم البطاقة مطلوب",
        },
        "currency_invalid": {
            "en": "currency_invalid",
            "ar": "العملة غير صحيحة",
        },
        "currency_required": {
            "en": "currency_required",
            "ar": "العملة مطلوبة",
        },
        "expiry_date_format_invalid": {
            "en": "expiry_date_format_invalid",
            "ar": "تنسيق تاريخ الانتهاء غير صحيح",
        },
        "payment_expired": {
            "en": "payment_expired",
            "ar": "انتهت صلاحية الدفعة",
        },
        "payment_invalid": {
            "en": "payment_invalid",
            "ar": "الدفعة غير صحيحة",
        },
        "default": {
            "en": "Your payment could not be processed. Please try again!",
            "ar": "تعذر معالجة الدفع الخاص بك. يرجى المحاولة مرة أخرى!",
        }
    }
    return translations[text] ? translations[text][locale] : translations["default"][locale];
}
export default _T;