import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderDetails, cancelOrder, reOrder, cancelOrderReason, cancelSubscription } from "../../helper/backend-methods";
import moment from 'moment/moment';
import Skeleton from "react-loading-skeleton";
import { convertDateTimeWithLocale, googleTagInitialize, pixelPageEvent, scrollToTop } from "../../common";
import ErrorIcon from '../../assets/images/error-icon.png'
import { Toaster } from "../../components/Toaster";
import _T from "../../locale/order";
import Modal from "../../components/Modal";

function OrderDetail() {
    let params = useParams();
    const orderID = params.id
    const history = useNavigate();
    const [orderData, setOrderData] = useState("")
    const [productData, setProductData] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingOrderCancel, setLoadingOrderCancel] = useState(false)
    const [orderSteps , setOrderSteps] = useState([])
    const [isCancellable, setIsCancellable] = useState(false);
    const [update, setUpdate] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [orderCancelReason , setOrderCancelReason] = useState([])
    const [selectedCancelReason, setSelectedCancelReason] = useState(null);
    const [isCancelReasonSelected, setIsCancelReasonSelected] = useState(false);

    useEffect(()=>{
        pixelPageEvent();
        googleTagInitialize();
    },[])

    useEffect(() => {
        document.title = 'Order Details | Wardstation';
        scrollToTop();
        getOrderDetails({ orderId: orderID }).then(({ data }) => {
            if (data.status) {
                setOrderData(data.result)
                setProductData(data.result.products)
                setOrderSteps(data.result.OrderTrace)
                const isOutForDelivery = data.result.OrderTrace.find((step)=>{ return step.Event === "Out For Delivery" }) ? data.result.OrderTrace.find((step)=>{ return step.Event === "Out For Delivery" }).Occured : false;
                const isProcessing = data.result.OrderTrace.find((step)=>{ return step.Event === "Processing" }) ? data.result.OrderTrace.find((step)=>{ return step.Event === "Processing" }).Occured : false;
                setIsCancellable(!isOutForDelivery && !data.result.IsCancelled && !data.result.IsDelivered)
                setLoading(false)
            }
            else{
                Toaster("error", _T("Request Failed"), _T("We could not find the order information"))
            }
        }).catch((e)=>{
            Toaster("error", _T("Request Failed"), _T("We could not find the order information"))
        })
        getCancelOrderReasons()
    }, [update])

    const getCancelOrderReasons = () => {
        cancelOrderReason().then(({data}) => {
            if(data.status){
                setOrderCancelReason(data.result)
                if(data.result && data.result.length){
                    setSelectedCancelReason(data.result[0].Id)
                    setIsCancelReasonSelected(true)
                }
            }

        }).catch((e) =>{
            console.log("")
        })
    }


    function toLocalISOString(date) {
        const pad = function(num) {
            return (num < 10 ? '0' : '') + num;
        };
        const d = date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) + 'T' +
            pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':00.000Z'
        return d
    }

    const togglePopup = () => {
        setIsOpen(!isOpen);
     };

    const cancelCurrentOrder = () => {
        setLoadingOrderCancel(true)
        cancelOrder({ orderId: orderID,reasonId:selectedCancelReason}).then(({data})=>{
            if (data.status) {
                setUpdate((prev)=>{ return prev + 1})
                setLoadingOrderCancel(false)
                setIsOpen(false)
                Toaster("success", _T("Order Cancelled"), _T("Your order has been cancelled"))
            }
            else{
                setLoadingOrderCancel(false)
                Toaster("error", _T("Request Failed"), _T("Your order could not be cancelled at the moment"))
            }
        }).catch(()=>{
            setLoadingOrderCancel(false)
            Toaster("error", _T("Request Failed"), _T("Your order could not be cancelled at the moment"))
        })
    }

    const orderAgain = () => {
        reOrder("?OrderId="+orderID).then(({data})=>{
            if (data.status) {
                if(data.result && data.result.CartItemCount){
                    history("/cart")
                }
                else{
                    Toaster("error", _T("Request Failed"), _T("Unable to re-order your items"))
                }
            }
            else{
                Toaster("error", _T("Request Failed"), _T("Unable to re-order your items"))
            }
        }).catch(()=>{
            Toaster("error", _T("Request Failed"), _T("Unable to re-order your items"))
        })
    }

    const getActiveTraceOnly = (s,check = false) => {
        if(s.Title === "On Hold"){ 
            if(s.Occured){ 
                return true
            }
            else{ 
                return false
            } 
        }
        else{
            if(check){
                if(s.Occured){ 
                    return true
                }
                else{ 
                    return false
                } 
            }
            else{ 
                return true
            } 
        }
    }

    return (
        loading ?
        <>
            <div className='account-header d-flex align-items-center justify-between'>
                <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'>{_T("Order Details")}</h1>
            </div>
            <div className="header-mini-summary bg-wrapper d-flex justify-between flex-wrap">
                <div className="order-id">
                    <p className="fs-14 fw-400 text-secondary m-0">{_T("Id:")}</p>
                    <p className="fs-16 fw-400 text-primary m-0"><Skeleton/></p>
                </div>
                <div className="order-date">
                    <p className="fs-14 fw-400 text-secondary m-0">{_T("Date:")}</p>
                    <p className="fs-16 fw-400 text-primary m-0"><Skeleton/></p>
                </div>
                <div className="order-total">
                    <p className="fs-14 fw-400 text-secondary m-0">{_T("Total:")}</p>
                    <p className="fs-16 fw-400 text-primary m-0"><Skeleton/></p>
                </div>
                <div className="order-payment">
                    <p className="fs-14 fw-400 text-secondary m-0">{_T("Payment Method:")}</p>
                    <p className="fs-16 fw-400 text-primary m-0"><Skeleton/></p>

                </div>
            </div>
            <div className="order-detail-tracking-order-bg-wrapper mt-3">
                <div className="order-detail-tracking-order">
                    <div className="order-detail-tracking-header text-center">
                        <p className="fs-14 fw-400 text-secondary m-0">{_T("Order will reach at your doorstep")}</p>
                        <h3 className="fs-18 fw-500 text-primary mt-2 mb-0"><Skeleton width={100}/></h3>
                        <p className="fs-14 fw-400 text-secondary mt-2 mb-0"><Skeleton width={150}/></p>
                    </div>
                    <div className="order-detail-tracking-steps">
                        <div className="steps-container">
                            <div className="trace">
                                {
                                      [...Array(5)].map((step, index) => (
                                        <div key={index} className="circle">
                                            <p className={`delivery-msg fs-14 fw-500 mb-0 mt-1`}><Skeleton height={10} width={40}/></p>
                                            <div className="delivery-description">
                                                <p className="delivery-msg-2 fs-12 text-center fw-400 text-gray-200 mt-0 mb-0"><Skeleton height={10} width={80}/></p>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="progress-bar">
                                    <span className="indicator"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="product-detail-summary-wrapper mt-3">
                <div className="product-detail-summary">
                    <div className="product-detail-summary-header d-flex justify-between">
                        <div className="label-product"> <p className="fs-16 fw-500 text-secondary m-0">{_T("Products")}</p> </div>
                        <div className="label-quantity"> <p className="fs-16 fw-500 text-secondary m-0">{_T("Quantity")}</p> </div>
                        <div className="label-price"> <p className="fs-16 fw-500 text-secondary m-0 text-right">{_T("Price")}</p> </div>
                    </div>
                    <div className="underline mt-3 mb-3"></div>
                    {
                         [...Array(5)].map((item, key) => (
                            <div className="product-item-details" key={key}>
                                <div className="item-image-name d-flex align-items-center">
                                    <div className="item-image mr-2">
                                        <Skeleton height={100} width={100}/>
                                    </div>
                                    <div className="item-name-data">
                                        <p className="fs-14 fw-400 text-secondary mb-0"><Skeleton/></p>
                                        
                                            <div className="d-flex align-items-center">
                                                <p className="fs-14 fw-400 text-secondary">{_T("Color:")} </p>
                                                <p className="ml-1"><Skeleton height={10} width={10}/></p>
                                            </div> 
                                    </div>
                                </div>
                                <div className="item-quantity">
                                    <p className="fs-14 fw-400 text-secondary "><Skeleton width={20}/></p>
                                </div>
                                <div className="item-price">
                                    <p className="fs-14 fw-400 text-secondary "><Skeleton width={60}/></p>
                                </div>
                            </div>
                        ))
                    }
                    <div className="underline"></div>
                    <div className="product-detail-summary-total d-flex justify-between align-items-center">
                        <p className="text-secondary fs-18 fw-500 mb-0 mt-2">{_T("Total")}</p>
                        <p className="text-secondary fs-18 fw-500 mb-0 mt-2"><Skeleton width={80} height={20}/></p>
                    </div>
                </div>
            </div>
        </> : <>
            <div className='account-header d-flex align-items-center justify-between'>
                <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'>{_T("Order Details")}</h1>
            </div>
            <div className="header-mini-summary bg-wrapper d-flex justify-between flex-wrap">
                <div className="order-id">
                    <p className="fs-14 fw-400 text-secondary m-0">{_T("Order No:")}</p>
                    <p className="fs-16 fw-400 text-primary m-0">{orderData.Id}</p>
                </div>
                <div className="order-date">
                    <p className="fs-14 fw-400 text-secondary m-0">{_T("Date:")}</p>
                    <p className="fs-16 fw-400 text-primary m-0">{convertDateTimeWithLocale(moment(toLocalISOString(new Date(orderData.OrderDate))).format("MMM Do, YYYY"))}</p>
                </div>
                <div className="order-total">
                    <p className="fs-14 fw-400 text-secondary m-0">{_T("Total:")}</p>
                    <p className="fs-16 fw-400 text-primary m-0 text-en">SAR {orderData.OrderTotal}</p>
                </div>
                <div className="order-payment">
                    <p className="fs-14 fw-400 text-secondary m-0">{_T("Payment Method:")}</p>
                    <p className="fs-16 fw-400 text-primary m-0">{_T(orderData.PaymentMethod)}</p>

                </div>
            </div>
            <div className="order-detail-tracking-order-bg-wrapper mt-3">
                {
                    orderData.IsCancelled ?
                    <div className="order-detail-cancelled text-center">
                        <img src={ErrorIcon} alt="Error" width={64}/>
                        <p className="fs-14 fw-400 text-secondary m-0">{_T("Your order has been cancelled")}</p>
                    </div> :
                    <div className="order-detail-tracking-order">
                        {
                            orderData.IsDelivered ?
                            <div className="order-detail-tracking-header text-center">
                                <p className="fs-14 fw-400 text-secondary m-0">{_T("Order has been Delivered")}</p>
                                <h3 className="fs-18 fw-500 text-primary m-0">{convertDateTimeWithLocale(moment(toLocalISOString(new Date(orderSteps.find((s)=>{ return s.Event === "Complete"}).EventDate))).format("MMM Do, YYYY"))}</h3>
                                <p className="fs-14 fw-400 text-secondary m-0">{moment(toLocalISOString(new Date(orderSteps.find((s)=>{ return s.Event === "Complete"}).EventDate))).format("hh:mm A")}</p>
                            </div> : 
                            <div className="order-detail-tracking-header text-center">
                                <p className="fs-14 fw-400 text-secondary m-0">{_T("Order will reach at your doorstep")}</p>
                                <h3 className="fs-18 fw-500 text-primary m-0">{convertDateTimeWithLocale(moment(toLocalISOString(new Date(orderData.ExpectedDelivery))).format("MMM Do, YYYY"))}</h3>
                                <p className="fs-14 fw-400 text-secondary m-0">{orderData.TimeSlot}</p>
                            </div>
                        }
                        <div className="order-detail-tracking-steps">
                            <div className="steps-container">
                                <div className="trace">
                                    {
                                        orderSteps.filter((s)=>getActiveTraceOnly(s)).map((step, index) => (
                                            <div key={index} className={`circle ${step.Title.replaceAll(" ","").toLowerCase()} ${step.Occured  ? 'active' : ''}`} >
                                                <p className={`delivery-msg fs-14 fw-500 ${step.Occured  ? 'text-primary' : 'text-gray-300'}  mb-0 mt-1`}>{_T(step.Title)}</p>
                                                <div className="delivery-description">
                                                    <p className="delivery-msg-2 fs-12 text-center fw-400 text-gray-200 mt-0 mb-0">{_T(step.Description)}</p>
                                                    {
                                                        step.Occured ?
                                                        <>
                                                            <small className="text-gray-500 fs-12 fw-400 d-block text-center">{convertDateTimeWithLocale(moment(toLocalISOString(new Date(step.EventDate))).format("DD MMM"))}</small>
                                                            <small className="text-gray-500 fs-12 fw-400 d-block text-center">{moment(toLocalISOString(new Date(step.EventDate))).format("hh:mm")} {_T(moment(toLocalISOString(new Date(step.EventDate))).format("A"))}</small>
                                                        </> : null
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div className="progress-bar">
                                        <span className="indicator" style={{ width: ((orderSteps.filter((s)=>getActiveTraceOnly(s,true)).length - 1)/(orderSteps.filter((s)=>getActiveTraceOnly(s)).length - 1)*100).toFixed(2)+"%"}}></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="product-detail-summary-wrapper mt-3">
                <div className="product-detail-summary">
                    <div className="product-detail-summary-header d-flex justify-between">
                        <div className="label-product"> <p className="fs-16 fw-500 text-secondary m-0">{_T("Products")}</p> </div>
                        <div className="label-quantity"> <p className="fs-16 fw-500 text-secondary m-0">{_T("Quantity")}</p> </div>
                        <div className="label-price"> <p className="fs-16 fw-500 text-secondary m-0 text-right">{_T("Price")}</p> </div>
                    </div>
                    <div className="underline mt-3 mb-3"></div>
                    {
                        productData.map((item, key) => (
                            <div className={`product-item-details ${item.IsFreeProductAdded ? "free-product" : ""}`} key={key}>
                                <div className="item-image-name d-flex align-items-center">
                                    <div className="item-image mr-3">
                                        <img src={item.PictureURL} alt="" />
                                    </div>
                                    <div className="item-name-data">
                                        <p className="fs-14 fw-400 text-secondary m-0">{item.ProductName}</p>
                                        {
                                            item.IsFreeProductAdded ?
                                            <span className="product-tag"><i className="ri-gift-line mr-2"></i> {_T("Free Gift")}</span> : null
                                        }
                                        {
                                            item.AlternateColor ?
                                            <div className="d-flex align-items-center">
                                                <p className="fs-14 fw-400 text-secondary m-0"> {_T("Color:")}</p>
                                                <p className="has-color ml-1 mt-0 mb-0" style={{ backgroundColor: item.AlternateColor }}></p>
                                            </div> : <></>
                                        }
                                    </div>
                                </div>
                                <div className="item-quantity">
                                    <p className="fs-14 fw-400 text-secondary m-0 text-en">{item.Quantity}</p>
                                </div>
                                <div className="item-price">
                                    <p className="fs-14 fw-400 text-secondary m-0 text-right text-en">SAR {item.Price.toFixed(2)}</p>
                                </div>
                            </div>
                        ))
                    }
                    <div className="underline"></div>
                    <div className="product-detail-summary-total d-flex justify-between align-items-center">
                        <p className="text-secondary fs-18 fw-500 mb-0 mt-3">{_T("Total")}</p>
                        <p className="text-secondary fs-18 fw-500 mb-0 mt-3 text-en">SAR {orderData.OrderTotal.toFixed(2)}</p>
                    </div>
                </div>
            </div>
            {
                isCancellable ?
                <div className="d-flex justify-end mt-3">
                    <button className="btn btn-primary" onClick={togglePopup}>{_T("Cancel Order")}</button>
                </div> : null
            }
            {
                orderData.IsSubscriptionOrder !== true ?
                orderData.IsDelivered || orderData.IsCancelled ?
                <div className="d-flex justify-end mt-3">
                    <button className="btn btn-secondary" onClick={orderAgain}>{_T("Re-Order")}</button>
                </div> : null : null
            }
            <Modal title={_T("Reason to Cancel")} isOpen={isOpen} onClose={() => { setIsOpen(false); }} className="cancelOrder-modal">
              {
                    orderCancelReason.map((cancelData, key) => (
                        <div className="d-flex justify-between pb-2 pt-2 mt-2 mb-2" key={key}>
                            <label htmlFor={"payment" + cancelData.Id} className="text-gray-500 fw-400 fs-16">{cancelData.CancelNote}</label>
                            <div className="radio-container">
                                <input id={"payment" + cancelData.Id} type="radio" className="input-radio" checked={selectedCancelReason === cancelData.Id} onChange={() => {setSelectedCancelReason(cancelData.Id)}}/>
                                <label htmlFor={"payment" + cancelData.Id} className="m-0"></label>
                            </div>
                        </div>
                    ))
                }
                {
                    loadingOrderCancel?
                    <div className="btn-group mt-3">
                        <button className="btn btn-disabled w-100 mb-2">{_T("Please wait!")}</button>
                    </div>
                    :
                    <div className="btn-group mt-3">
                        <button className="btn btn-secondary w-100 mb-2" onClick={cancelCurrentOrder} disabled={!isCancelReasonSelected}>{_T("Cancel Order")}</button>
                    </div>
                }
            </Modal>
        </>
    )
}

export default OrderDetail