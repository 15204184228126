import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { protectedRoutes, publicRoutes, baseRoutes } from "./routes/";
import {PrivateRoute,AuthRoute} from "./routes/route";
import PublicLayout from "./layout/Public-Layout/";
import ProtectedLayout from "./layout/Protected-Layout/";
import AuthLayout from "./layout/Auth-Layout/";
import 'remixicon/fonts/remixicon.css'
import { app, getOperationWarehouse } from "./helper/backend-methods";
// import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import Loader from "./components/Loader";
import { GlobalContext,  } from "./context/GlobalContext";
import { getActiveStore, getLocale, updateActiveStore } from "./common";
import Modal from "./components/Modal";
import _T from "./locale/home";
import Error404 from "./components/Error404";


function App() {
  const { warehouseList, updateWarehouseList, updateConsent } = useContext(GlobalContext)
  const warehouse = getActiveStore();
  const [checkConsent, setCheckConsent] = useState(false)
  const [consentClass, setConsentClass] = useState(false)
  const [consentIdentity, setConsentIdentity] = useState(true)
  const [consentTracking, setConsentTracking] = useState(true)
  const [consentPage, setConsentPage] = useState(true)
  const [managePreference, setManagePreference] = useState(false)
  const [consentObject, setConsentObject] = useState({"identity": false, "tracking":false, "page": false})
  const [config, setConfig] = useState({ Maintenance: false })
  const [regionPopup, setRegionPopup] = useState(false);
  const isEn = getLocale() === "en";
  const [storeActive, setStoreActive] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null)
  const [loading, setLoading] = useState(true)


  useEffect(()=>{
    if(!warehouse){
      getOperationWarehouse().then(({data})=>{
        if(data.status){
          updateWarehouseList(data.result.Data)
          setRegionPopup(true)
        }
        else{
          localStorage.removeItem("guestToken");
          localStorage.removeItem("authToken");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("activeStore");
          window.location.reload();
        }
        setLoading(false)
      }).catch(()=>{
        localStorage.removeItem("guestToken");
        localStorage.removeItem("authToken");
        localStorage.removeItem("userInfo");
        localStorage.removeItem("activeStore");
        window.location.reload();
      })
    }
    else{
      setLoading(false)
    }
    const isIOS = /iPhone|iPad|Mac|iPod/i.test(navigator.userAgent);
    if (isIOS) {
      document.body.classList.add('ios-device');
    } else {
      document.body.classList.remove('ios-device');
    }
  },[])

  const updateSelectedWarehouse = () => {
    setRegionPopup(false);
    getOperationWarehouse({ name: isEn ? selectedRegion.NameEn : selectedRegion.NameAr, operationWarehouseId: selectedRegion.WarehouseId, alternateNames:selectedRegion.alternateNames}).then(({data})=>{
      if(data.status){
        updateActiveStore(selectedRegion)
        setStoreActive(selectedRegion)
        window.location.reload(); 
      } 
      else{
        localStorage.removeItem("guestToken");
        localStorage.removeItem("authToken");
        localStorage.removeItem("userInfo");
        localStorage.removeItem("activeStore");
        window.location.reload();
      } 
    }).catch(()=>{
      localStorage.removeItem("guestToken");
      localStorage.removeItem("authToken");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("activeStore");
      window.location.reload();
    })
  }


  useEffect(()=>{
    const consent = localStorage.getItem("consent");
    if(!consent || consent.length < 1){
      promptForConsent();
    }
    else{
      try{
        if(JSON.parse(consent) && (JSON.parse(consent).identity || JSON.parse(consent).identity === false) && (JSON.parse(consent).tracking || JSON.parse(consent).tracking === false) && (JSON.parse(consent).page || JSON.parse(consent).page === false)){
          const c = JSON.parse(consent);
          setConsentObject({identity: c.identity, tracking: c.tracking, page: c.page})
          updateConsent({identity: c.identity, tracking: c.tracking, page: c.page})
        }
        else{
          promptForConsent();
        }
      }
      catch (e){
        promptForConsent();
      }
    }
  },[])

  const promptForConsent = () => {
    localStorage.removeItem("consent")
    setCheckConsent(true)
    setTimeout(() => {
        setConsentClass(true)
    }, 1000);
  }

  const acceptConsent = (identity=true,tracking=true,page=true) =>{
    const permissions = JSON.stringify({"identity": identity, "tracking":tracking, "page": page})
    setConsentObject({"identity": identity, "tracking":tracking, "page": page})
    localStorage.setItem("consent",permissions);
    updateConsent(permissions)
    setConsentClass(false);
    setTimeout(() => {
      setCheckConsent(false)
    }, 1000);
  }

  return (
    <BrowserRouter>

      <Modal className={"region-modal"} isOpen={regionPopup} title="Choose City for Delivery">
          <div className="region-option-container mt-5 mb-4">
              {
                  warehouseList.map((item,index)=>{
                      return <div className={`form-content ${selectedRegion && selectedRegion.WarehouseId === item.WarehouseId ? "active" : ""} `} key={index}>
                          <label htmlFor={"region"+index}>
                              <input type="radio" name="region" id={"region"+index} checked={ selectedRegion && selectedRegion.WarehouseId === item.WarehouseId } value={item.WarehouseId} onChange={(e)=>{ if(e.target.checked){ setSelectedRegion(item) } }} />
                              {
                                item.warehouseIcon===null?
                                <i className="ri-map-pin-line region-icon"></i>:<img className="cityIcon" src={item.warehouseIcon} alt="Icon"/>
                              }
                              <h4>{ isEn ? item.NameEn : item.NameAr }</h4>
                          </label>
                      </div>
                  })
              }
          </div>
          <button disabled={ (storeActive && selectedRegion && selectedRegion.WarehouseId === storeActive.WarehouseId) || !selectedRegion } type="button" onClick={updateSelectedWarehouse} className="btn btn-primary w-100 mt-4">{_T("Update Region")}</button>
      </Modal>
    
      { 
        loading ? 
        <Loader/> : 
        <Routes>
          {
            baseRoutes.map((route, idx) => (
              <Route exact key={`open-${idx}`} path={route.path} element={<AuthRoute><AuthLayout><route.element consent={consentObject}/></AuthLayout></AuthRoute>}/>
            ))
          }
          {
            publicRoutes.map((route, idx) => (
              <Route exact key={`public-${idx}`} path={route.path} element={<PublicLayout hideHeaderFooter={route.hideHeaderFooter ? true : false}><route.element consent={consentObject}/></PublicLayout>}/>
            ))
          }
          {
            protectedRoutes.map((route, idx) => (
              <Route exact key={`protected-${idx}`} path={route.path} element={<PrivateRoute><ProtectedLayout><route.element consent={consentObject}/></ProtectedLayout></PrivateRoute>}/>
            ))
          }
          <Route exact path="*" element={<Error404 consent={consentObject}/>}/>
        </Routes>
      }


        {/* for tracking purposes */}
        {
          checkConsent && !config.Maintenance ? 
          <div className={`consent-popup ${consentClass ? "active" : ""}`}>
            {
              !managePreference ?
              <div className="popup-wrapper">
                {/* <button className="popup-close"><i className="ri-close-line"></i></button> */}
                <p className="popup-text text-secondary">{_T("This website tracks your activities to enhance your shopping experience. The website tracks your activities, such as page views, product searches, and purchases, to provide you with a personalized experience and better understand how you use the website. All data collected is anonymous and will be used solely for the purpose of improving the website and it's services. By clicking 'Accept,' you consent to the collection and use of this information.")}</p>
                <div className="popup-footer">
                  <button className="btn btn-primary mr-2" onClick={()=>{acceptConsent()}}>{_T("Accept")}</button>
                  <button className="btn btn-secondary" type="button" onClick={()=>setManagePreference(true)}>{_T("Manage Preference")}</button>
                </div>
              </div> : 
              <div className="popup-wrapper">
                <div>
                    <h4 className="text-secondary fs-18 fw-500 m-0">{_T("Manage Preferences")}</h4>
                    <p className="text-gray-400 fs-14 fw-400 mt-0 mb-2">{_T("Please configure the options to enable or disable your tracking preferences on the website.")}</p>
                    <div className="field-group">
                        <input type="checkbox" id="consentIdentity" className="input-checkbox" checked={consentIdentity} onChange={(e)=>{setConsentIdentity(e.target.checked)}}/>
                        <label htmlFor="consentIdentity" className="text-secondary fs-14 fw-400">{_T("Allow tracking my identity on the website")}</label>
                    </div>
                    <div className="field-group">
                        <input type="checkbox" id="consentTracking" className="input-checkbox" checked={consentTracking} onChange={(e)=>{setConsentTracking(e.target.checked)}}/>
                        <label htmlFor="consentTracking" className="text-secondary fs-14 fw-400">{_T("Allow tracking my interactions on the website")}</label>
                    </div>
                    <div className="field-group">
                        <input type="checkbox" id="consentPage" className="input-checkbox" checked={consentPage} onChange={(e)=>{setConsentPage(e.target.checked)}}/>
                        <label htmlFor="consentPage" className="text-secondary fs-14 fw-400">{_T("Allow tracking my page visits on the website")}</label>
                    </div>
                </div>
                <div className="popup-footer mt-3">
                  <button className="btn btn-primary" onClick={()=>{acceptConsent(consentIdentity,consentTracking,consentPage)}}>{_T("Continue")}</button>
                </div>
              </div>
            }
          </div> : null
        }
    </BrowserRouter>
  );
}
export default App;