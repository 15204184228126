import { useNavigate } from 'react-router-dom'
import Carousel from 'react-multi-carousel';
import { useRef } from 'react';
import { getLocale } from '../../common';
import Skeleton from "react-loading-skeleton";
import 'react-alice-carousel/lib/alice-carousel.css';
import defaultImage from "../../assets/images/default-category-image.png"
import _T from '../../locale/home'
export default function Category(props) {
    const xAxisStart = useRef(0)
    const category = props.category
    const locale = getLocale()
    const navigate = useNavigate();

    const carouselRef = useRef(null);

    const responsivness = {
        10000: {
            breakpoint: { max: 10000, min: 900 },
            items: 5,
        },
        900: {
            breakpoint: { max: 900, min: 450 },
            items: 4,
        },
        450: {
            breakpoint: { max: 450, min: 0 },
            items: 3,
        },
    };

    const itemClicked = (e, link) => {
        if (Math.abs(xAxisStart.current - e.clientX) < 20) {
            navigate(link)
        }
        xAxisStart.current = 0
    }

    const handlePrevious = () => {
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    return (
        <section className='category-section'>
            <div className='wrapper d-flex align-items-center justify-between'>
                <div className='section-header mb-4'>
                    <h2 className='ff-heading text-secondary fs-36 fw-900 has-arrow-start'>{_T("Explore Categories")}</h2>
                </div>
                {
                    locale === "en" ?
                    <div className="carousel-controls mb-4">
                        <button onClick={handlePrevious} className='navigation-control navigation-previous'><i className="ri-arrow-left-s-line"></i></button>
                        <button onClick={handleNext} className='navigation-control navigation-next'><i className="ri-arrow-right-s-line"></i></button>
                    </div> : 
                    <div className="carousel-controls mb-4">
                        <button onClick={handlePrevious} className='navigation-control navigation-previous'><i className="ri-arrow-right-s-line"></i></button>
                        <button onClick={handleNext} className='navigation-control navigation-next'><i className="ri-arrow-left-s-line"></i></button>
                    </div>
                }
            </div>
            <div className='wrapper'>
                <Carousel
                    ref={carouselRef}
                    responsive={responsivness}
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="hidden-dots"
                    dotListClass="d-none"
                    draggable
                    focusOnSelect={false}
                    infinite={false}
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={locale === "ar"}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable

                >

                    {
                        props.isLoading ?
                            [...Array(10)].map((item, key) => (
                                <div className='single-category cursor-pointer' key={key}>
                                    <div className='category-container text-center'>
                                        <Skeleton height={205} width={205} borderRadius={24} className='mb-3 category-image-loader'></Skeleton>
                                        <Skeleton height={20} width={90}></Skeleton>
                                    </div>
                                </div>
                            )) :
                            category.map((category, key) => (
                                <div onMouseDown={(e) => { xAxisStart.current = e.clientX }} onMouseUp={(e) => itemClicked(e, `/category-products/${category.Id}/${category.Name ? category.Name.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : _T("brands")}`)} className='single-category cursor-pointer' key={key}>
                                    <div className='category-container text-center'>
                                        <div className='category-image'>
                                            <img src={locale === "ar" ? category.PicturURL_Ar : category.PicturURL} onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }} alt={category.Name} draggable={false} />
                                            <h3 className='category-heading mb-0'>{locale === "ar" ? category.NameAr : category.Name}</h3>
                                        </div>  
                                    </div>
                                </div>
                            ))
                    }
                </Carousel>
            </div>
        </section>
    )
}