import Carousel from 'react-multi-carousel'
import slide from '../../assets/images/slide.jpg'
import { Link, useNavigate } from 'react-router-dom'
import 'react-multi-carousel/lib/styles.css';
import { useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton'
import _T from '../../locale/home';
import { getLocale } from '../../common';
function BannerSlider(props) {
    const isLoading = props.isLoading
    const slides = props.slides
    const locale = getLocale()
    const history = useNavigate();
    const xAxisStart = useRef(0)
    const carouselRef = useRef(null);


    const itemClicked = (e, link) => {
        if (Math.abs(xAxisStart.current - e.clientX) < 20) {
            history(link)
        }
        xAxisStart.current = 0
    }
    const handlePrevious = () => {
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    return (
        !isLoading && (slides && slides.length) ?
            <section className='banner-slider-section'>
                <div className='wrapper'>
                    {
                        isLoading ?
                            <Skeleton className='single-slide-loader' style={{ maxWidth: "100%", width: "100%" }} />
                            :
                            <Carousel
                                ref={carouselRef}
                                arrows={false} renderButtonGroupOutside={false}
                                responsive={{ desktop: { breakpoint: { max: 10000, min: 100 }, items: 1 }, }}
                                sliderclassName=""
                                containerClass="banner-container-with-dots"
                                dotListClass="banner-carousel-bullets"
                                showDots={true}
                                slidesToSlide={1}
                                swipeable
                                rtl={locale === "ar"}
                            // 
                            >
                                {
                                    slides.map((slide, key) => (
                                        <div key={key} className='single-slide w-100' onMouseDown={(e) => { xAxisStart.current = e.clientX }} onMouseUp={(e) => { if (slide.Link && slide.Link.length) { itemClicked(e, slide.Link) } }}>
                                            <img draggable={false} className='w-100 banner-slides prevent-select cursor-pointer' src={locale === "en" ? slide.PictureURL : slide.PictureURL_Ar} alt='' />
                                        </div>

                                    ))
                                }
                            </Carousel>
                    }
                </div>
                    {
                        slides.length?
                        locale === 'ar' ?
                            <div id='banner-carousel'>
                                <div className="banner-carousel-controls mb-4">
                                    <button onClick={handlePrevious} className='banner-navigation-control banner-navigation-previous'><i className="ri-arrow-right-s-line"></i></button>
                                    <button onClick={handleNext} className='banner-navigation-control banner-navigation-next'><i className="ri-arrow-left-s-line"></i></button>
                                </div>
                            </div>
                            :
                            <div id='banner-carousel'>
                                <div className="banner-carousel-controls mb-4">
                                    <button onClick={handlePrevious} className='banner-navigation-control banner-navigation-previous'><i className="ri-arrow-left-s-line"></i></button>
                                    <button onClick={handleNext} className='banner-navigation-control banner-navigation-next'><i className="ri-arrow-right-s-line"></i></button>
                                </div>
                            </div>
                            :<></>
                    }
            </section> : null
    )
}
export default BannerSlider;