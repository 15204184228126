import React from "react";
import moment from "moment/moment";
import { NavLink } from "react-router-dom";
import _T from "../../locale/occasion";
import { convertDateTimeWithLocale } from "../../common";
function OccasionOrderCard(props) {
    const orderData = props.orderData;
    // const key = props.index
    return (
    <div className="occasion-order-card bg-wrapper d-flex justify-between flex-wrap">
          <div className="occasion-order-no">
              <h2 className="text-secondary fw-500 fs-16">{_T("Order No")}</h2>
              <p className="text-primary fw-500 fs-16 m-0">{orderData.OrderNo}</p>
          </div>

          <div className="occasion-order-date">
              <h2 className="text-secondary fw-500 fs-16">{_T("Order Date")}</h2>
              <p className="text-primary fw-500 fs-16 m-0">{convertDateTimeWithLocale(moment(orderData.OrderDate).format("MMM Do YYYY"))}</p>
          </div>

          <div className="occasion-order-status">
              <h2 className="text-secondary fw-500 fs-16">{_T("Status")}</h2>
                <NavLink to={"/account/orders/"+orderData.Id} className="d-flex align-items-center"><p className="text-primary fw-500 fs-16 m-0">{_T(orderData.OrderStatus)}</p> <i className="ri-arrow-right-s-line fs-22 text-primary"></i></NavLink>
          </div>
    </div>)
    
}
export default OccasionOrderCard