import React from "react"
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getOccasionById, getOccasionOrders } from "../../helper/backend-methods";
import moment from "moment/moment";
import OccasionProductCard from "./occasion-product-card";
import OccasionOrderCard from "./occasion-order-card";
import Skeleton from "react-loading-skeleton";
import { convertDateTimeWithLocale, googleTagInitialize, pixelPageEvent, scrollToTop } from "../../common";
import _T from "../../locale/occasion";

function OccasionDetails() {
    let params = useParams();
    const occasionID = params.id
    const [occasionData, setOccasionData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [productsData, setProductsData] = useState([])
    const [ordersData, setOrdersData] = useState(null)
    const [tab, setTab] = useState(1)
    const [update, setUpdate] = useState(0)

    useEffect(()=>{
        pixelPageEvent();
        googleTagInitialize();
    },[])

    useEffect(() => {
        document.title = 'Occasion Details | Wardstation';
        scrollToTop();
        getOccasionById({ Id: occasionID }).then(({ data }) => {
            if (data.status) {
                setOccasionData(data.result.result)
                setProductsData(data.result.PersonalOccasionProduct)
                setOrdersData(data.result.orderItemResDTO)
                setLoading(false)
            }
        }).catch((e) => {
            console.log(e)
        })
        getOccasionOrders({ occasionId: occasionID }).then(({data})=>{
            console.log(data)
        })
    }, [update])

    return <section className="occasion-details">
        <div className="occasion-details-main">
            <div className="single-occasion-container bg-secondary">
                {
                    !loading ? 
                    <div className="w-100 d-flex align-items-center">
                        <div className="single-occasion-img">
                            <img src={occasionData.ImageUrl} alt="occasion-img"></img>
                        </div>
                        <div className="ml-4">
                            <div className="single-occasion-name">
                                <h2 className="text-primary fw-500 fs-24"><span>{occasionData.Name}</span></h2>
                            </div>
                            <div className="single-occasion-type">
                                <p className="text-light fw-400 fs-16"><span >{occasionData.Type}</span></p>
                            </div>
                            <div className="single-occasion-date">
                                <p className="text-light fw-400 fs-16"> <span >{convertDateTimeWithLocale(moment(occasionData.OccasionDate).format("MMM Do YYYY"))}</span></p>
                            </div>
                        </div>
                    </div> : 
                    <div className="user-top-info d-flex align-items-center">
                    <label htmlFor="profilePic"><Skeleton className="single-occasion-img" circle={true}/></label>
                    <div className="user-name ml-3 w-30">
                        <Skeleton className="w-60" height={24} />
                        <Skeleton className="mt-2 w-60" height={24} />
                        <Skeleton className="mt-2 w-70" height={24}/>
                    </div>
                </div>
                    
                }
            </div>
            <div className="bg-wrapper d-flex  flex-wrap occasion-details-tab-bar mt-2">
                <div className={`tab-item d-flex align-items-center cursor-pointer ${tab === 1 ? "active" : ""}`} onClick={() => { setTab(1) }}><img className='mr-1' src={""} alt="" />{_T("Items")}</div>
                <div className={`tab-item ml-4 d-flex align-items-center cursor-pointer ${tab === 2 ? "active" : ""}`} onClick={() => { setTab(2) }}><img className='mr-1' src={""} alt="" />{_T("Orders")}</div>
            </div>
            {
                tab === 1 ?
                <div className="occasion-product-details mt-4">
                    {
                        loading ? 
                        [...Array(5)].map((item, key) => {
                            return <Skeleton key={key} className='mt-3 w-100' height={"150px"} />
                        }) :
                        productsData.length ?
                        <div className="occasion-product-cards">
                            {
                                productsData.map((item, index) => {
                                    return <OccasionProductCard key={index} productData={item} occasionId={occasionID} onUpdate={() => { setUpdate(update + 1) }} />
                                }) 
                            }
                        </div> : <p className="btn btn-alternative w-100">< i className="ri-information-line mr-2 fs-18"></i>{_T("You currently have no products associated with this occasion.")}</p>
                    }
                </div> : <></>
            }
            {
                tab === 2 ?
                <div className="occasion-order-details mt-4">
                    {
                    loading ? 
                    [...Array(5)].map((item, key) => {
                        return <Skeleton key={key} className='mt-3 w-100' height={"150px"} />
                    }) :
                    ordersData && ordersData.length ?
                    <div className="occasion-order-cards">
                        {
                            ordersData.map((item, index) => {
                                return <OccasionOrderCard key={index} orderData={item} />
                            })
                        }
                    </div> : <p className="btn btn-alternative w-100">< i className="ri-information-line mr-2 fs-18"></i>{_T("You currently have no orders associated with this occasion.")}</p>
                    }
                </div> : <></>
            }
        </div>
    </section>
}
export default OccasionDetails