import { Link } from "react-router-dom";
import ErrorImage from "../assets/images/error404.png"
import ErrorImage_ar from "../assets/images/error404-ar.png"
import "../assets/css/--component-error404.css";
import { useEffect, useState } from "react";
import { getLocale } from "../common";
import _T from "../locale/error404";
// import { analyticsPageEvent } from "./Analytics";
function Error404(props) {
    const locale = getLocale();
    // useEffect(() => {
    //     if(props.consent.page){
    //         analyticsPageEvent("Error 404");
    //     }
    // }, []);
    return (
        <section className="error-page">
            <div className="wrapper error-container">
                <div className="error-content">
                    <h1 className="fs-36 fw-800 text-secondary mt-0 mb-1">{_T("Page Not Found")}</h1>
                    <p className="fs-18 fw-400 text-gray-400 mt-0 mb-3">{_T("We are very sorry for inconvenience. It seems you are trying to access a page that either has been deleted or does not exist.")}</p>
                    <Link className="btn btn-primary" to={"/"}>{_T("Visit Home Page")}</Link>
                </div>
                <div className="error-image">
                    <img className="w-100" src={locale === "ar" ? ErrorImage_ar: ErrorImage} alt="Error404"/>
                </div>
            </div>
        </section>
    )
}
export default Error404;