function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "Transaction History": {
            "en": "Transaction History",
            "ar": "تاريخ المعاملات"
        },
        "Order Id": {
            "en": "Order Id",
            "ar": "رقم الطلب"
        },
        "Date": {
            "en": "Date",
            "ar": "التاريخ"
        },
        "Amount": {
            "en": "Amount",
            "ar": "الكمية"
        },
        "Payment": {
            "en": "Payment",
            "ar": "الدفع"
        },
        "There are currently no transaction records for your account.": {
            "en": "There are currently no transaction records for your account.",
            "ar": "لا توجد حاليًا أي سجلات معاملات لحسابك."
        },
        "card": {
            "en": "card",
            "ar": "بطاقة"
        },
    }

    return translations[text] ? translations[text][locale] : text;
}
export default _T;