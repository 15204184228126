function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "My Orders": {
            "en": "My Orders",
            "ar": "طلباتي"
        },
        "Current": {
            "en": "Current",
            "ar": "الطلب الحالي"
        },
        "Past": {
            "en": "Past",
            "ar": "الطلب السابق"
        },
        "Cancelled": {
            "en": "Cancelled",
            "ar": "الطلب الملغي"
        },
        "You currently have no cancelled orders in your account.": {
            "en": "You currently have no cancelled orders in your account.",
            "ar": "ليس لديك حاليًا أي طلبات ملغية في حسابك."
        },
        "You currently have no past orders in your account.": {
            "en": "You currently have no past orders in your account.",
            "ar": "ليس لديك حاليًا أي طلبات سابقة في حسابك."
        },
        "You currently have no pending orders in your account.": {
            "en": "You currently have no pending orders in your account.",
            "ar": "ليس لديك حاليًا أي طلبات معلقة في حسابك."
        },
        "Order No:": {
            "en": "Order No:",
            "ar": "رقم الطلب:"
        },
        "Please wait!": {
            "en": "Please wait!",
            "ar": "انتظر من فضلك!"
        },
        "Order Date:": {
            "en": "Order Date:",
            "ar": "تاريخ الطلب:"
        },
        "Expected Delivery:": {
            "en": "Expected Delivery:",
            "ar": "الوقت المتوقع للتوصيل:"
        },
        "View Details": {
            "en": "View Details",
            "ar": "عرض التفاصيل"
        },
        "Order Details": {
            "en": "Order Details",
            "ar": "تفاصيل الطلب"
        },
        "Id:": {
            "en": "Id:",
            "ar": "بطاقة تعريف:"
        },
        "Date:": {
            "en": "Date:",
            "ar": "تاريخ:"
        },
        "Total:": {
            "en": "Total:",
            "ar": "المجموع:"
        },
        "Total": {
            "en": "Total",
            "ar": "المجموع"
        },
        "Payment Method:": {
            "en": "Payment Method:",
            "ar": "طريقة الدفع:"
        },
        "Order will reach at your doorstep": {
            "en": "Order will reach at your doorstep",
            "ar": "سوف يصل الطلب إلى بيتك"
        },
        "Products": {
            "en": "Products",
            "ar": "المنتجات"
        },
        "Quantity": {
            "en": "Quantity",
            "ar": "الكمية"
        },
        "Price": {
            "en": "Price",
            "ar": "السعر"
        },
        "Color:": {
            "en": "Color:",
            "ar": "اللون:"
        },
        "Your order has been cancelled": {
            "en": "Your order has been cancelled",
            "ar": "تم إلغاء طلبك"
        },
        "Free Gift": {
            "en": "Free Gift",
            "ar": "هدية مجانية"
        },
        "You currently have no cancelled orders in your account": {
            "en": "You currently have no cancelled orders in your account",
            "ar": "ليس لديك حاليًا أي طلبات إلغاء في حسابك."
        },
        "You currently have no past orders in your account": {
            "en": "You currently have no past orders in your account",
            "ar": "ليس لديك حاليًا أي أوامر سابقة في حسابك."
        },
        "You currently have no pending orders in your account": {
            "en": "You currently have no pending orders in your account",
            "ar": "ليس لديك حاليًا أي طلبات معلقة في حسابك."
        },
        "Request Failed": {
            "en": "Request Failed",
            "ar": "الطلب فشل"
        },
        "We could not find the order information": {
            "en": "We could not find the order information",
            "ar": "لم نتمكن من العثور على معلومات الطلب"
        },
        "Order Cancelled": {
            "en": "Order Cancelled",
            "ar": "تم الغاء الطلب"
        },
        "Your order could not be cancelled at the moment": {
            "en": "Your order could not be cancelled at the moment",
            "ar": "لا يمكن إلغاء طلبك في الوقت الحالي"
        },
        "Unable to re-order your items": {
            "en": "Unable to re-order your items",
            "ar": "لايمكن إعادة طلب المنتجات"
        },
        "Cancel Order": {
            "en": "Cancel Order",
            "ar": "الغاء الطلب"
        },
        "Reason to Cancel": {
            "en": "Reason to Cancel",
            "ar": "سبب الإلغاء"
        },
        "Re-Order": {
            "en": "Re-Order",
            "ar": "إعادة الطلب"
        },
        "Order placed": {
            "en": "Order placed",
            "ar": "تم الطلب"
        },
        "Order has been placed": {
            "en": "Order has been placed",
            "ar": "تم الطلب"
        },
        "Processing": {
            "en": "Processing",
            "ar": "يتم تحضيره"
        },
        "Order is in Processing": {
            "en": "Order is in Processing",
            "ar": "الطلب يتم تحضيره"
        },
        "On Hold": {
            "en": "On Hold",
            "ar": "في الانتظار"
        },
        "Order is on hold": {
            "en": "Order is on hold",
            "ar": "الطلب معلق"
        },
        "Out For Delivery": {
            "en": "Out For Delivery",
            "ar": "خارج للتوصيل"
        },
        "Rider is on the way": {
            "en": "Rider is on the way",
            "ar": "السائق في الطرق"
        },
        "Complete": {
            "en": "Complete",
            "ar": "مكتمل"
        },
        "Order Completed": {
            "en": "Order Completed",
            "ar": "تم اكتمال الطلب"
        },
        "Delivered": {
            "en": "Delivered",
            "ar": "تم التوصيل"
        },
        "January": {
            "en": "January",
            "ar": "يناير"
        },
        "February": {
            "en": "February",
            "ar": "فبراير"
        },
        "March": {
            "en": "March",
            "ar": "مارس"
        },
        "April": {
            "en": "April",
            "ar": "أبريل"
        },
        "May": {
            "en": "May",
            "ar": "مايو"
        },
        "June": {
            "en": "June",
            "ar": "يونيو"
        },
        "July": {
            "en": "July",
            "ar": "يوليو"
        },
        "August": {
            "en": "August",
            "ar": "أغسطس"
        },
        "September": {
            "en": "September",
            "ar": "سبتمبر"
        },
        "October": {
            "en": "October",
            "ar": "أكتوبر"
        },
        "November": {
            "en": "November",
            "ar": "نوفمبر"
        },
        "December": {
            "en": "December",
            "ar": "ديسمبر"
        },
        "Assigned": {
            "en": "Assigned",
            "ar": "تم التعيين",
        },
        "OnHold": {
            "en": "On Hold",
            "ar": "قيد الانتظار",
        },
        "Packed": {
            "en": "Packed",
            "ar": "معبأ",
        },
        "OutForDelivery": {
            "en": "Out For Delivery",
            "ar": "في طريقه إلى الوصول",
        },
        "Pending": {
            "en": "Pending",
            "ar": "معلق",
        },
        "Confirmed": {
            "en": "Confirmed",
            "ar": "مؤكد",
        },
        "Picked": {
            "en": "Picked",
            "ar": "تم التقاطه",
        },
        "Shipped": {
            "en": "Shipped",
            "ar": "تم شحنه",
        },
        "NotCreated": {
            "en": "Not Created",
            "ar": "غير تم إنشاؤه",
        },
        "Credit Card": {
            "en": "Credit Card",
            "ar": "بطاقة إئتمان",
        },
        "Card": {
            "en": "Card",
            "ar": "بطاقة"
        },
        "Debit Card": {
            "en": "Debit Card",
            "ar": "بطاقة ائتمان"
        },
        "Apple Pay": {
            "en": "Apple Pay",
            "ar": "أبل باي"
        },
        "AM": {
            "en": "AM",
            "ar": "ص"
        },
        "PM": {
            "en": "PM",
            "ar": "م"
        }
    }

    return translations[text] ? translations[text][locale] : text;
}
export default _T;