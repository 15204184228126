import React, { memo, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import '../../assets/css/--page-auth.css'
import AuthBanner from '../../assets/images/login-image.png'
import TopFlower from '../../assets/images/top-flower (1).png'
import BottomFlower from '../../assets/images/bottom-flower .png'
import google from '../../assets/images/social-icons/google.png'
import apple from '../../assets/images/social-icons/apple.png'
import facebook from '../../assets/images/social-icons/facebook.png'
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { app, checkReferral, postReferral, socialLogin } from '../../helper/backend-methods'
import { postLogin } from "../../helper/backend-methods";
import { v4 as uuidv4 } from 'uuid';
import { googleTagInitialize, pixelPageEvent, scrollToTop } from "../../common";
import { LoginSocialFacebook } from "reactjs-social-login";
import _T from "../../locale/authentication";
import SEO from "../../components/SEO";

function SignIn() {
    const [ search, setSearch] = useSearchParams();
    const [isVisible, setIsVisible] = useState(false)
    const [redirect, setRedirect] = useState(false);
    const [useremail, setUseremail] = useState("")
    const [userpassword, setUserpassword] = useState("")
    const [serverError, setServerError] = useState('');
    const [loading, setLoading] = useState(false);
    const [referralCode, setReferralCode] = useState("")
    const [referralCodeApplied, setReferralCodeApplied] = useState(false)
    const [referralLoading, setReferralLoading] = useState(false);
    const [showReferral, setShowReferral] = useState(false);
    const [errors, setErrors] = useState({ useremail: "", userpassword: "", referralCode: "" })
    const history = useNavigate();

    useEffect(()=>{
        pixelPageEvent();
        googleTagInitialize();
    },[])

    useEffect(() => {
        document.title = 'Sign-in | Wardstation';
        scrollToTop();
        if (search) {
            if (search.get('redirect') && search.get('redirect') !== "") {
                setRedirect(search.get('redirect'))
            }
        }
    }, [])

    const validateSubmit = (e) => {
        e.preventDefault();
        const errors = { useremail: "", userpassword: "", referralCode: "" };
        let flag = false;

        if (!useremail || useremail.replaceAll(/\s/g, '').length < 3) {
            errors.useremail = _T("Please provide a valid email address");
            flag = true
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(useremail)) {
            errors.useremail = _T("Please provide a valid email address");
            flag = true
        }
        if (!userpassword || userpassword.replaceAll(/\s/g, '').length < 6) {
            errors.userpassword = _T("Please provide your account password");
            flag = true
        }
        setErrors(errors);
        if (!flag) {
            setLoading(true)
            setServerError("")
            postLogin({ Email: useremail, Password: userpassword, deviceId: generateKey() }).then(({ data }) => {
                console.log(data)
                if (data.status) {
                    // analyticsIdentityEvent(data.Data.UserInfo.CustomerId,{email: data.Data.UserInfo.Email, username: data.Data.UserInfo.FullName, guid: data.Data.UserInfo.CustomerGuid})
                    let customer = data.result.Customer
                    localStorage.setItem("authToken", data.result.Token.AccessToken)
                    localStorage.setItem("userInfo", JSON.stringify({ username: customer.Username, email: customer.Email, phone: customer.Phone }))
                    if (redirect) {
                        history(redirect);
                    }
                    else {
                        history('/');
                    }
                }
                else {
                    setServerError(_T("Account email or password is invalid."))
                    setLoading(false)
                }
            }).catch((e) => {
                setServerError(_T("Account email or password is invalid."))
                setLoading(false)
            })
        }
    }
    const generateKey = () => {
        const key = localStorage.getItem("identifier")
        if (key && key.length) {
            return key;
        }
        else {
            const identifier = uuidv4()
            localStorage.setItem("identifier", identifier)
            localStorage.removeItem("cart");
            // analyticsIdentityEvent("Entered as Guest",{ screen_name: "Welcome", guest_id: identifier })
            return identifier;
        }
    }

    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();
    const signInWithGoogle = () => {
        setLoading(true)
        setServerError("")
        signInWithPopup(auth, provider).then((result) => {
            socialLogin({ Token: result.user.accessToken, DeviceId: generateKey() }).then(({ data }) => {
                if (data.status) {
                    let customer = data.result.UserInfo
                    localStorage.setItem("authToken", data.result.TokenResponse.AccessToken)
                    localStorage.setItem("userInfo", JSON.stringify({ username: customer.Username, email: customer.Email, phone: customer.Phone }))
                    if (data.result.IsReferralCodeScreenOpen) {
                        setShowReferral(true)
                    }
                    else {
                        if (redirect) {
                            history(redirect);
                        }
                        else {
                            history('/');
                        }
                    }
                    setLoading(false)
                    // analyticsIdentityEvent(data.Data.UserInfo.CustomerId,{email: data.Data.UserInfo.Email, username: data.Data.UserInfo.FullName, guid: data.Data.UserInfo.CustomerGuid})

                }
                else {
                    if (data.response.ResponseMessage === "email_already_registered") {
                        setServerError(_T("An account is already registered with this email."))
                    }
                    else if (data.response.ResponseMessage === "Phone_already_registered") {
                        setServerError(_T("An account is already registered with this phone."))
                    }
                    else if (data.response.ResponseMessage === "account_already_registered") {
                        setServerError(_T("An account is already registered with this email."))
                    }
                    else{
                        setServerError(_T("Failed to login using google"))
                    }
                    setLoading(false)
                }
            }).catch((e) => {
                setServerError(_T("Failed to login using google"))
                setLoading(false)
                console.log(e);
            })
        }).catch((e) => {
            setServerError(_T("Failed to login using google"))
            setLoading(false)
            console.log(e);
        })
    }

    const signInWithFacebook = () => {
        const auth = getAuth(app);
        const provider = new FacebookAuthProvider();
        setServerError("")
        signInWithPopup(auth, provider).then((result) => {
            console.log(result)
        })
        // const data = {
        //     "email": response.data.email,
        //     "Name": response.data.name,
        //     "Picture": response.data.picture.data.url,
        //     "signInProvider": response.provider
        // }
        // const provider = new FacebookAuthProvider();
        // signInWithPopup(auth, provider).then((result)=>{
        // socialLogin({ data: data, decrypted: true , socialToken: null}).then(({ data }) => {
        //     if (data.Status) {
        // analyticsIdentityEvent(data.Data.UserInfo.CustomerId,{email: data.Data.UserInfo.Email, username: data.Data.UserInfo.FullName, guid: data.Data.UserInfo.CustomerGuid})
        // localStorage.setItem("authToken", data.Data.Token.Access_Token)
        // localStorage.setItem("userinfo", JSON.stringify(data.Data.UserInfo))
        // if (redirect) {
        //     history(redirect);
        // }
        // else {
        //     history('/');
        // }
        //     }
        //     else {
        //         setServerError("Failed to login using facebook")
        //     }
        // }).catch((e) => {
        //     setServerError("Failed to login using facebook")
        //     console.log(e);
        // })
    }


    const validateReferralCode = (e) => {
        e.preventDefault();
        postReferral({ ReferralCode: referralCode }).then(({ data }) => {
            if (data.status && data.result.insertReferralCodeSucessfully) {
                history("/account/profile")
            }
            else {
                setReferralCodeApplied(false);
                setErrors((prev) => { return { ...prev, referralCode: _T('Please provide a valid referral code') } })
            }
            setReferralLoading(false);
        }).catch((e) => {
            setReferralCodeApplied(false);
            setErrors((prev) => { return { ...prev, referralCode: _T('Please provide a valid referral code') } })
            setReferralLoading(false);
        })
    }

    const applyReferralCode = () => {
        setReferralLoading(true);
        checkReferral({ ReferralCode: referralCode }).then(({ data }) => {
            if (data.status && data.result.isReferralCodeApplied) {
                setReferralCodeApplied(true);
                setErrors((prev) => { return { ...prev, referralCode: '' } })
            }
            else {
                setReferralCodeApplied(false);
                setErrors((prev) => { return { ...prev, referralCode: _T('Please provide a valid referral code') } })
            }
            setReferralLoading(false);
        }).catch((e) => {
            setReferralCodeApplied(false);
            setErrors((prev) => { return { ...prev, referralCode: _T('Please provide a valid referral code') } })
            setReferralLoading(false);
        })
    }

    return (
        <div className="auth-container d-flex justify-center">
            <SEO metaTitle={_T("Sign In to Your Account | Wardstation")} metaDescription={_T("Sign in to your Wardstation account for convenient access to order history, tracking, and personalized recommendations. Fast delivery available across KSA. Order now!")} metaKeywords={_T("account login, sign in, login, user login, login to account, sign into account")} />
            <div className="auth-left">
                <Link to={"/"} className="btn btn-outline-primary guest-btn"><i className="ri-home-4-line mr-1 fs-16"></i>{_T("Go Home")}</Link>
                <img className="top-flower" src={TopFlower} alt="" />
                <img className="bottom-flower" src={BottomFlower} alt="" />
                {
                    !showReferral ?
                        <form onSubmit={validateSubmit} >
                            <div className="input-content">
                                <h1 className="fs-36 fw-600 text-secondary mt-0 mb-2">{_T("Sign-In")}</h1>
                                <p className="fs-16 text-gray-300 fw-400 mt-0 mb-3">{_T("Please use your email address and password to login to your account")}</p>
                                <div className="form-group">
                                    <label htmlFor="useremail">{_T("Email")}</label>
                                    <input id="useremail" type="email" name="useremail" onChange={(e) => { setUseremail(e.target.value) }} value={useremail} placeholder="mail@example.com" />
                                    {errors.useremail ? <span className="text-flushed fs-12 fw-400">{errors.useremail}</span> : null}
                                </div>
                                <div className="form-group has-button">
                                    <label htmlFor="">{_T("Password")}</label>
                                    <input id="userpassword" type={isVisible ? "text" : "password"} name="userpassword" onChange={(e) => { setUserpassword(e.target.value) }} value={userpassword} placeholder={_T("Enter password")} />
                                    <button onClick={() => { setIsVisible(!isVisible) }} type="button" >{isVisible ? <i className="ri-eye-off-line"></i> : <i className="ri-eye-line"></i>}</button>
                                    {errors.userpassword ? <span className="text-flushed fs-12 fw-400">{errors.userpassword}</span> : null}
                                </div>
                                <div className="forgot-password text-right mb-1">
                                    <Link to={"/forgot-password"} className="text-secondary fs-12 fw-500 decoration-none">{_T("Forgot your password?")}</Link>
                                </div>
                                {
                                    serverError.length ? <p className="text-center text-flushed fs-14 fw-400">{serverError}</p> : <></>
                                }
                                <div className="auth-btn">
                                    <button type="submit" disabled={loading} className="btn btn-secondary w-100">{_T("Login")}</button>
                                </div>
                                <div className="auth-divider">{_T("Or continue with")}</div>
                                <div className="social-login-wrapper  d-flex align-items-center justify-center">
                                    <button type="button" disabled={loading} className="social-login-btn cursor-pointer" onClick={signInWithGoogle} ><img src={google} alt="" /></button>
                                    {/* <button type="button" disabled={loading} className="social-login-btn cursor-pointer" onClick={signInWithFacebook}><img src={facebook} alt="" /></button> */}
                                    {/* <button type="button" className="social-login-btn cursor-pointer" ><img src={apple} alt="" /></button> */}
                                </div>
                                <div className="create-new-account text-center">
                                    <span className="text-gray-200">{_T("Not registered yet?")} <Link to={"/register"} className="link-secondary">{_T("Create an account")}</Link></span>
                                </div>
                            </div>
                        </form> :
                        <form onSubmit={validateReferralCode}>
                            <div className="input-content">
                                <h1 className="fs-36 fw-600 text-secondary mt-0 mb-2">{_T("Referral Code")}</h1>
                                <p className="fs-16 text-gray-300 fw-400 mt-0 mb-3">{_T("If you have a referral code enter here and press on apply button or you can tap on skip button to proceed without referral code.")}</p>
                                <div className="form-group has-button has-text-button">
                                    <label htmlFor="referralCode">{_T("Referral Code")}</label>
                                    <input id="referralCode" type="text" readOnly={referralCodeApplied} name="referralCode" onChange={(e) => { setReferralCode(e.target.value.slice(0, 50)) }} value={referralCode} placeholder={_T("Enter a Referral Code")} />
                                    <button onClick={applyReferralCode} disabled={referralLoading} type="button" >{referralCodeApplied ? <span className="fs-12 text-secondary fs-400">{_T("Applied")}</span> : <span className="fs-12 text-primary fs-400">{_T("Apply")}</span>}</button>
                                    {errors.referralCode ? <span className="text-flushed fs-12 fw-400">{errors.referralCode}</span> : null}
                                </div>
                                <div className="auth-btn">
                                    <button type="submit" disabled={referralLoading || !referralCodeApplied} className="btn btn-secondary w-100">{_T("Confirm")}</button>
                                </div>
                                <div className="create-new-account text-center mt-1">
                                    <span className="text-gray-200">{_T("Don't have a referral code?")} <Link to={"/"} className="link-secondary">{_T("Skip")}</Link></span>
                                </div>
                            </div>
                        </form>
                }
            </div>
            <div className="auth-right" style={{ backgroundImage: `url(${AuthBanner})` }}></div>
        </div>
    )
}

export default memo(SignIn)




