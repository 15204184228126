import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getCartProducts } from "../../helper/backend-methods";
import Skeleton from "react-loading-skeleton";
import _T from "../../locale/cart";

function CartSummary(props) {
    const cartSummary = props.data
    const loading = props.loading
    return (
        loading?
        <div>
            <Skeleton height={"333px"} className="mt-4 w-100"/>
        </div> :
        <div className="shopping-cart-container mt-4">
            <div className="summary-heading d-flex align-items-center mb-3">
                <i className="ri-bill-line fs-20 text-secondary"></i>
                <p className="m-0 fs-18 fw-500 text-secondary ml-1">{_T("Order Summary")}</p>
            </div>
            <span className="underline"></span>
            <div className="summary-details">
                <div className="summary-sub-total d-flex justify-between align-items-center">
                    <p className="fs-14 fw-400 text-gray-500">{_T("Sub Total")} <span className="d-block fs-12 text-gray-300">{_T("Inclusive of VAT")}</span></p>
                    <p className="fs-14 fw-400 text-gray-500 text-en">SAR {cartSummary.subTotal.toFixed(2)}</p>
                </div>
                {
                    cartSummary.discount ?
                    <div className="summary-discount d-flex justify-between align-items-center">
                        <p className="fs-14 fw-500 text-gray-500">{_T("Discount")}</p>
                        <p className="fs-14 fw-400 text-gray-500 text-en">- SAR {cartSummary.discount.toFixed(2)}</p>
                    </div> : <></>
                }
                <div className="summary-vat d-flex justify-between align-items-center">
                    <p className="fs-14 fw-400 text-gray-500">{_T("VAT Included")}</p>
                    <p className="fs-14 fw-400 text-gray-500 text-en">SAR {cartSummary.vat.toFixed(2)}</p>
                </div>
                <div className="summary-shipping d-flex justify-between align-items-center">
                    <p className="fs-14 fw-400 text-gray-500">{_T("Delivery Charges")}</p>
                    <p className="fs-14 fw-400 text-gray-500 text-en">SAR {cartSummary.shipping.toFixed(2)}</p>
                </div>
                <span className="underline"></span>
                <div className="summary-total d-flex justify-between align-items-center">
                    <p className="fs-16 fw-500 text-gray-500">{_T("Total")}</p>
                    <p className="fs-16 fw-500 text-primary text-en">SAR {cartSummary.total.toFixed(2)}</p>
                </div>
            </div>
        </div>
    )
}

export default CartSummary