import React from "react";
import Skeleton from "react-loading-skeleton";


function SubIndexSkeleton() {
    return (
        <div id="subscription">
            <section id="sub-hero-section">
                <div className="sub-hero-banner">
                    <Skeleton className="w-100" height={466}/>
                </div>
            </section>
            <section id="flower-subscription-section">
                <div className="flower-sub-container wrapper d-flex justify-between align-items-center flex-wrap">
                    <div className="flower-sub-left">
                        <div className="flower-sub-content">
                            <h2 className="fs-30 fw-500 text-secondary"><Skeleton className="w-100" height={46}/></h2>
                            <p className="fs-18 fw-300 text-secondary"><Skeleton className="w-100" height={81}/></p>
                        </div>
                    </div>
                    <div className="flower-sub-right mt-3 mb-3">
                        <Skeleton className="w-100" height={523}/>
                    </div>
                </div>
            </section>
            <section id="guide-section">
                <div className="guide-container wrapper">
                    <div className="guide-heading">
                        <h3 className="fs-24 fw-500 text-secondary text-center"><Skeleton width={150} height={60}/></h3>
                    </div>
                    <div className="guide-steps-container d-flex justify-between align-items-center flex-wrap">
                        <div className="guide-box">
                            <div className="guide-box-img text-center">
                                <Skeleton width={80} height={90}/>
                            </div>
                            <div className="guide-box-text">
                                <p className="fs-14 fw-400 text-secondary text-center"> <Skeleton className="w-100" height={44}/></p>
                            </div>
                        </div>
                        <div className="guide-box">
                            <div className="guide-box-img text-center">
                            <Skeleton width={80} height={90}/>
                            </div>
                            <div className="guide-box-text">
                                <p className="fs-14 fw-400 text-secondary text-center"><Skeleton className="w-100" height={44}/></p>
                            </div>
                        </div>
                        <div className="guide-box">
                            <div className="guide-box-img text-center">
                            <Skeleton width={80} height={90}/>
                            </div>
                            <div className="guide-box-text">
                                <p className="fs-14 fw-400 text-secondary text-center"><Skeleton className="w-100" height={44}/></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="sub-product-card-list">
                <div className="sub-product-card-contaier wrapper">
                    <div className="sub-product-card-heading">
                        <h3 className="fs-26 fw-400 text-secondary"><Skeleton width={250} height={39}/></h3>
                    </div>
                    <div className="sub-product-card-list-wrapper-loading">
                        {
                            [...Array(4)].map((item,key)=>{
                                return <div key={key} className="sub-product-card sub-product-card-loading"><Skeleton className="w-100" height={300}/></div>
                            }) 
                        }
                    </div>
                </div>
            </section>
            <section id="sub-faq">
                <div className="sub-product-faq-container wrapper">
                    <div className="sub-product-faq-heading">
                        <h3 className="fs-26 fw-400 text-secondary"><Skeleton width={150} height={39}/></h3>
                    </div>
                    <div className="sub-product-faq-cards mb-3">
                        <Skeleton className="w-100" height={168}/>
                    </div>
                    <div className="sub-product-faq-cards">
                        <Skeleton className="w-100" height={168}/>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SubIndexSkeleton