function _T(text) {
  let locale = localStorage.getItem("locale");
  if (locale && locale.length) {
    locale = locale === "ar" ? "ar" : "en"
  }
  else {
    locale = "en";
  }
  const translations = {
    "WardStation is an online flowers and gifting company that pride itselves on creating a seamless experience for its customers & making sending flowers & gifts as enjoyable as receiving them.": {
      "en": "WardStation is an online flowers and gifting company that pride itselves on creating a seamless experience for its customers & making sending flowers & gifts as enjoyable as receiving them.",
      "ar": "ورد ستيشن هي شركة زهور وهدايا على الإنترنت ؛ نحن نفخر بإنشاء تجربة سلسة لعملائنا وجعل إرسال الزهور والهدايا ممتعة مثل استلامها."
    },
    "Brands": {
      "en": "Brands",
      "ar": "الماركات"
    },
    "About Us": {
      "en": "About Us",
      "ar": "معلومات عنا"
    },
    "Blog": {
      "en": "Blog",
      "ar": "مدونة"
    },
    "Blogs": {
      "en": "Blogs",
      "ar": "المدونات"
    },
    "Contact Us": {
      "en": "Contact Us",
      "ar": "اتصل بنا"
    },
    "FAQ": {
      "en": "FAQ",
      "ar": "الاسئلة الشائعة"
    },
    "FAQs": {
      "en": "FAQs",
      "ar": "الاسئلة الشائعة"
    },
    "Terms & Conditions": {
      "en": "Terms & Conditions",
      "ar": "الشروط والأحكام"
    },
    "View All": {
      "en": "View All",
      "ar": "عرض الكل"
    },
    "Useful links": {
      "en": "Useful links",
      "ar": "روابط مفيدة"
    },
    "Privacy Policy": {
      "en": "Privacy Policy",
      "ar": "سياسة الخصوصية"
    },
    "Follow Us": {
      "en": "Follow Us",
      "ar": "تابعنا"
    },
    "Payment Methods": {
      "en": "Payment Methods",
      "ar": "طرق الدفع"
    },
    "Social Media": {
      "en": "Social Media",
      "ar": "وسائل التواصل الاجتماعي"
    },
    "Download Our App": {
      "en": "Download Our App",
      "ar": "قم بتنزيل تطبيقنا"
    },
    "WardStation": {
      "en": "WardStation",
      "ar": "ورد ستيشن"
    },
    "All rights reserved": {
      "en": "All rights reserved",
      "ar": "كل الحقوق محفوظة"
    }
  }

  return translations[text] ? translations[text][locale] : text;
}
export default _T;