import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import '../../assets/css/--page-auth.css'
import AuthBanner from '../../assets/images/login-image.png'
import TopFlower from '../../assets/images/top-flower (1).png'
import BottomFlower from '../../assets/images/bottom-flower .png'
import google from '../../assets/images/social-icons/google.png'
import apple from '../../assets/images/social-icons/apple.png'
import facebook from '../../assets/images/social-icons/facebook.png'
import { checkReferral, postReferral, postRegister } from "../../helper/backend-methods";
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { app, socialLogin } from '../../helper/backend-methods'
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import 'react-phone-number-input/style.css'
import { v4 as uuidv4 } from 'uuid';
import { googleTagInitialize, pixelPageEvent, pixelTrackEvent, scrollToTop } from "../../common";
import _T from "../../locale/authentication";
import SEO from "../../components/SEO";


function SignUp() {
    const [ search, setSearch] = useSearchParams();
    const [isVisible, setIsVisible] = useState(false)
    const [redirect, setRedirect] = useState(false);
    const [referralCode, setReferralCode] = useState("")
    const [referralCodeApplied, setReferralCodeApplied] = useState(false)
    const [userfirstname, setUserfirstname] = useState("")
    const [userlastname, setUserlastname] = useState("")
    const [useremail, setUseremail] = useState("")
    const [userphone, setUserphone] = useState("+966")
    const [userpassword, setUserpassword] = useState("")
    const [phoneFocused, setPhoneFocused] = useState(false)
    const [serverError, setServerError] = useState('');
    const [phoneError , setPhoneError] = useState(true);
    const [errors, setErrors] = useState({ userfirstname: "", userlastname: "", useremail: "", userphone: "", userpassword: "", referralCode: "" })
    const [loading, setLoading] = useState(false);
    const [referralLoading, setReferralLoading] = useState(false);
    const [showReferral, setShowReferral] = useState(false);
    const history = useNavigate();

    useEffect(() => {
        document.title = 'Sign-up | Wardstation';
        scrollToTop();
        if (search) {
            if (search.get('redirect') && search.get('redirect') !== "") {
                setRedirect(search.get('redirect'))
            }
        }
    }, []);

    useEffect(()=>{
        pixelPageEvent();
        googleTagInitialize();
    },[])

    const validateSubmit = (e) => {
        e.preventDefault();
        const errors = { userfirstname: "", userlastname: "", useremail: "", userphone: "", userpassword: "", referralCode: "" };
        let flag = false;
        if (!userfirstname || userfirstname.replaceAll(/\s/g, '').length < 2) {
            errors.userfirstname = _T("Provide your first name");
            flag = true;
        }
        else if (!/^[A-Za-z\s]*$/.test(userfirstname)) {
            errors.userfirstname = _T("Provide valid first Name");
            flag = true;
        }
        if (!userlastname || userlastname.replaceAll(/\s/g, '').length < 2) {
            errors.userlastname = _T("Provide your last name");
            flag = true;
        }
        else if (!/^[A-Za-z\s]*$/.test(userlastname)) {
            errors.userlastname = _T("Provide valid last name");
            flag = true;
        }
        if (!useremail || useremail.replaceAll(/\s/g, '').length < 3) {
            errors.useremail = _T("Provide valid email adress");
            flag = true;
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(useremail)) {
            errors.useremail = _T("Provide valid email adress");
            flag = true;
        }
        if (!isValidPhoneNumber(userphone)) {
            errors.userphone = _T("Provide valid phone number");
            flag = true;
        }
        
        
        if (!userpassword) {
            errors.userpassword = _T("Provide your valid password");
            flag = true;
        }
        else if (userpassword.replaceAll(/\s/g, '').length < 6) {
            errors.userpassword = _T("Password must have minimum 6 characters");
            flag = true;
        }
        setErrors(errors);
        if (!flag) {
            setLoading(true)
            setServerError('')
            postRegister({ FirstName: userfirstname, LastName: userlastname, Email: useremail, Password: userpassword, Phone: userphone, deviceId: generateKey() }).then(({ data }) => {
                if (data.status) {
                    let customer = data.result.Customer
                    localStorage.setItem("authToken", data.result.Token.AccessToken)
                    localStorage.setItem("userInfo", JSON.stringify({ username: customer.Username, email: customer.Email, phone: customer.Phone }))
                    pixelTrackEvent("CompleteRegistration",{ content_name: 'User Registration', status: 'New User' })
                    setShowReferral(true)
                    // analyticsIdentityEvent(data.Data.UserInfo.CustomerId, { email: data.Data.UserInfo.Email, username: data.Data.UserInfo.FullName, guid: data.Data.UserInfo.CustomerGuid })
                    // if (redirect) {
                    //     history(redirect);
                    // }
                    // else {
                    //     history('/account/profile');
                    // }
                    // history("/account/profile")
                }
                else {
                    if (data.response.ResponseMessage === "email_already_registered") {
                        setServerError(_T("An account is already registered with this email."))
                    }
                    else if (data.response.ResponseMessage === "Phone_already_registered") {
                        setServerError(_T("An account is already registered with this phone."))
                    }
                    else if (data.response.ResponseMessage === "account_already_registered") {
                        setServerError(_T("An account is already registered with this email."))
                    }
                    else{
                        setServerError(_T("An account is already registered."))
                    }
                    setLoading(false)
                }
            }).catch((e) => {
                setServerError(_T("An account is already registered."))
                setLoading(false)
            })
        }
    }
    const generateKey = () => {
        const key = localStorage.getItem("identifier")
        if (key && key.length) {
            return key;
        }
        else {
            const identifier = uuidv4()
            localStorage.setItem("identifier", identifier)
            localStorage.removeItem("cart");
            // analyticsIdentityEvent("Entered as Guest",{ screen_name: "Welcome", guest_id: identifier })
            return identifier;
        }
    }

    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    const signInWithGoogle = () => {
        setLoading(true)
        setServerError("")
        signInWithPopup(auth, provider).then((result) => {
            socialLogin({ Token: result.user.accessToken, DeviceId: generateKey() }).then(({ data }) => {
                if (data.status) {
                    let customer = data.result.UserInfo
                    localStorage.setItem("authToken", data.result.TokenResponse.AccessToken)
                    localStorage.setItem("userInfo", JSON.stringify({ username: customer.Username, email: customer.Email, phone: customer.Phone }))
                    if (data.result.IsReferralCodeScreenOpen) {
                        setShowReferral(true)
                    }
                    else {
                        if (redirect) {
                            history(redirect);
                        }
                        else {
                            history("/account/profile");
                        }
                    }
                    setLoading(false)
                    // analyticsIdentityEvent(data.Data.UserInfo.CustomerId,{email: data.Data.UserInfo.Email, username: data.Data.UserInfo.FullName, guid: data.Data.UserInfo.CustomerGuid})
                    // localStorage.setItem("authToken", data.Data.Token.Access_Token)
                    // localStorage.setItem("userinfo", JSON.stringify(data.Data.UserInfo))
                }
                else {
                    if (data.response.ResponseMessage === "email_already_registered") {
                        setServerError(_T("An account is already registered with this email."))
                    }
                    else if (data.response.ResponseMessage === "Phone_already_registered") {
                        setServerError(_T("An account is already registered with this phone."))
                    }
                    else if (data.response.ResponseMessage === "account_already_registered") {
                        setServerError(_T("An account is already registered with this email."))
                    }
                    else{
                        setServerError(_T("Failed to login using google"))
                    }
                    setLoading(false)
                }
            }).catch((e) => {
                setServerError(_T("Failed to login using google"))
                setLoading(false)
                console.log(e);
            })
        }).catch((e) => {
            setServerError(_T("Failed to login using google"))
            setLoading(false)
            console.log(e);
        })
    }


    const signInWithFacebook = () => {
        const auth = getAuth(app);
        const provider = new FacebookAuthProvider();
        setServerError("")
        signInWithPopup(auth, provider).then((result) => {
            // console.log(result)
        })
    }


    const validateReferralCode = (e) => {
        e.preventDefault();
        postReferral({ ReferralCode: referralCode }).then(({ data }) => {
            if (data.status && data.result.insertReferralCodeSucessfully) {
                if (redirect) {
                    history(redirect);
                }
                else {
                    history("/account/profile")
                }
            }
            else {
                setReferralCodeApplied(false);
                setErrors((prev) => { return { ...prev, referralCode: _T('Please provide a valid referral code') } })
            }
            setReferralLoading(false);
        }).catch((e) => {
            setReferralCodeApplied(false);
            setErrors((prev) => { return { ...prev, referralCode: _T('Please provide a valid referral code') } })
            setReferralLoading(false);
        })
    }

    const applyReferralCode = () => {
        setReferralLoading(true);
        checkReferral({ ReferralCode: referralCode }).then(({ data }) => {
            if (data.status && data.result.isReferralCodeApplied) {
                setReferralCodeApplied(true);
                setErrors((prev) => { return { ...prev, referralCode: '' } })
            }
            else {
                setReferralCodeApplied(false);
                setErrors((prev) => { return { ...prev, referralCode: _T('Please provide a valid referral code') } })
            }
            setReferralLoading(false);
        }).catch((e) => {
            setReferralCodeApplied(false);
            setErrors((prev) => { return { ...prev, referralCode: _T('Please provide a valid referral code') } })
            setReferralLoading(false);
        })

    }

    const handleChange = (e) => {
        const inputValue = e.target.value;

        if (inputValue.startsWith("+966")) {
            setUserphone(inputValue.slice(0, 13));
        }
    };
    

    return (
        <div className="auth-container d-flex justify-center">
            <SEO metaTitle={_T("Sign-Up")} metaDescription={_T("Register your wardstation account to shop for flowers and chocolates")} metaKeywords={_T("Register.Sign-Up")} />
            <div className="auth-left">
                <img className="top-flower" src={TopFlower} alt="" />
                <img className="bottom-flower" src={BottomFlower} alt="" />
                {
                    !showReferral ?
                        <Link to={"/"} className="btn btn-outline-primary guest-btn"><i className="ri-home-4-line mr-1 fs-16"></i> {_T("Go Home")}</Link>
                        : null
                }

                {
                    !showReferral ?
                        <form onSubmit={validateSubmit}>
                            <div className="input-content">
                                <h1 className="fs-36 fw-600 text-secondary mt-0 mb-2">{_T("Create Account")}</h1>
                                <p className="fs-16 text-gray-300 fw-400 mt-0 mb-3">{_T("Please provide the following details to create your account.")}</p>
                                <div className="form-group">
                                    <label htmlFor="userfirstname">{_T("First Name")}<span className="text-primary">*</span></label>
                                    <input id="userfirstname" type="text" name="userfirstname" onChange={(e) => { setUserfirstname(e.target.value.slice(0, 25)) }} value={userfirstname} placeholder={_T("First Name")} />
                                    {errors.userfirstname ? <span className="text-flushed fs-12 fw-400">{errors.userfirstname}</span> : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="userlastname">{_T("Last Name")}<span className="text-primary">*</span></label>
                                    <input id="userlastname" type="text" name="userlastname" onChange={(e) => { setUserlastname(e.target.value.slice(0, 25)) }} value={userlastname} placeholder={_T("Last Name")} />
                                    {errors.userlastname ? <span className="text-flushed fs-12 fw-400">{errors.userlastname}</span> : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">{_T("Email")}</label>
                                    <input id="useremail" type="email" name="useremail" onChange={(e) => { setUseremail(e.target.value.slice(0, 50)) }} value={useremail} placeholder="mail@example.com" />
                                    {errors.useremail ? <span className="text-flushed fs-12 fw-400">{errors.useremail}</span> : null}
                                </div>
                                <div>
                                    <label htmlFor="phonenumber">{_T("Phone Number")}</label>
                                     <PhoneInput className="dir-escape text-left" id="phonenumber" international defaultCountry="SA" placeholder="Enter phone number" onFocus={()=>{ setPhoneFocused(true) }} onBlur={()=>{ setPhoneFocused(false) }} value={userphone} onChange={(e)=>{setUserphone(e); }}
                                      />
                                    {/* <input className="dir-escape text-left" type="text" id="phonenumber" placeholder={_T("Enter phone number")} value={userphone} onChange={handleChange} /> */}
                                    {errors.userphone ? <span className="text-flushed fs-12 fw-400">{errors.userphone}</span> : null}
                                </div>
                                <div className="form-group has-button">
                                    <label htmlFor="password">{_T("Password")}</label>
                                    <input id="userpassword" type={isVisible ? "text" : "password"} name="userpassword" onChange={(e) => { setUserpassword(e.target.value.replaceAll(/\s/g, '')) }} value={userpassword} placeholder={_T("Password")} />
                                    <button onClick={() => { setIsVisible(!isVisible) }} type="button" >{isVisible ? <i className="ri-eye-off-line"></i> : <i className="ri-eye-line"></i>}</button>
                                    {errors.userpassword ? <span className="text-flushed fs-12 fw-400">{errors.userpassword}</span> : null}
                                </div>
                                {serverError.length ? <p className="fs-14 fw-400 text-flushed text-center">{serverError}</p> : <></>}
                                <div className="auth-btn">
                                    <button type="submit" disabled={loading} className="btn btn-secondary w-100">{_T("Create Account")}</button>
                                </div>
                                <div className="auth-divider">{_T("Or continue with")}</div>
                                <div className="social-login-wrapper  d-flex align-items-center justify-center">
                                    <button type="button" disabled={loading} className="social-login-btn cursor-pointer" onClick={signInWithGoogle} ><img src={google} alt="" /></button>
                                    {/* <button type="button" disabled={loading} className="social-login-btn cursor-pointer" onClick={signInWithFacebook}><img src={facebook} alt="" /></button> */}
                                    {/* <button type="button" className="social-login-btn cursor-pointer" ><img src={apple} alt="" /></button> */}
                                </div>
                                <div className="create-new-account text-center">
                                    <span className="text-gray-200">{_T("Already have an account?")} <Link to={"/login"} className="link-secondary">{_T("Sign-In")}</Link></span>
                                </div>
                            </div>
                        </form> :
                        <form onSubmit={validateReferralCode}>
                            <div className="input-content">
                                <h1 className="fs-36 fw-600 text-secondary mt-0 mb-2">{_T("Referral Code")}</h1>
                                <p className="fs-16 text-gray-300 fw-400 mt-0 mb-3">{_T("If you have a referral code enter here and press on apply button or you can tap on skip button to proceed without referral code.")}</p>
                                <div className="form-group has-button has-text-button">
                                    <label htmlFor="referralCode">{_T("Referral Code")}</label>
                                    <input id="referralCode" type="text" readOnly={referralCodeApplied} name="referralCode" onChange={(e) => { setReferralCode(e.target.value.slice(0, 50)) }} value={referralCode} placeholder={_T("Enter a Referral Code")} />
                                    <button onClick={applyReferralCode} disabled={referralLoading} type="button" >{referralCodeApplied ? <span className="fs-12 text-secondary fs-400">{_T("Applied")}</span> : <span className="fs-12 text-primary fs-400">{_T("Apply")}</span>}</button>
                                    {errors.referralCode ? <span className="text-flushed fs-12 fw-400">{errors.referralCode}</span> : null}
                                </div>
                                <div className="auth-btn">
                                    <button type="submit" disabled={referralLoading || !referralCodeApplied} className="btn btn-secondary w-100">{_T("Confirm")}</button>
                                </div>
                                <div className="create-new-account text-center mt-1">
                                    <span className="text-gray-200">{_T("Don't have a referral code?")} <Link to={"/"} className="link-secondary">{_T("Skip")}</Link></span>
                                </div>
                            </div>
                        </form>
                }
            </div>
            <div className="auth-right" style={{ backgroundImage: `url(${AuthBanner})` }}></div>
        </div>
    )
}

export default SignUp