import React from "react";
import { Link } from "react-router-dom";

function PromoBanner (props){
    const { promoImageURL , promoLink, className, isLoading } = props

    const isExternalLink = (url) => {
        const tmp = document.createElement('a');
        tmp.href = url;
        return tmp.host !== window.location.host;
    };

    return(
        <section className={"promo-banner-section"+ className}>
            <div className="wrapper">
                {
                    isExternalLink(promoLink) ?
                    <a href={promoLink} className={"promo-banner"}>
                        <img className="w-100 d-block" src={promoImageURL} alt="Promo Banner" />
                    </a> :
                    <Link to={promoLink} className={"promo-banner"}>
                        <img className="w-100 d-block" src={promoImageURL} alt="Promo Banner" />
                    </Link>
                }
            </div>
        </section>
    )
}


export default PromoBanner