import { del, get,getNew, post, postNew, put, postForm, putForm, apiWrapper } from "./api-methods";
import * as url from "./api-urls"
import { initializeApp } from "firebase/app";

// Authentication
export const postLogin = (params) => apiWrapper(post,url.LOGIN,params)
export const postRegister = (params) => apiWrapper(post,url.REGISTER,params)
export const socialLogin = (params) => apiWrapper(post,url.SOCIAL_LOGIN,params)
export const checkReferral = (params) => apiWrapper(post,url.CHECK_REFERRAL,params)
export const postReferral = (params) => apiWrapper(post,url.POST_REFERRAL,params)
export const forgotPassword = (params) => apiWrapper(post,url.FORGOT_PASSWORD,params)
export const resetPassword = (params) => apiWrapper(post,url.RESET_PASSWORD,params)


//Home Page
export const getNavItems = () => apiWrapper(getNew,url.GETNAVITEMS)
export const getHomeBanner = () => apiWrapper(getNew,url.HOMEBANNER)
export const getCatMan = (params) => apiWrapper(getNew,url.CATMAN,params)
export const getWebHomeTagged = () => apiWrapper(getNew,url.WEBHOMETAGED)
export const getPerOccasions = () => apiWrapper(getNew,url.PER_OCCASIONS)

//PRODUCTS

export const getProductFilters = () => apiWrapper(get,url.GETPRODUCTFILTER)
export const getAllProduct = (params) => apiWrapper(postNew,url.GETALLPRODUCT,params)
export const getProductDetails = (params) => apiWrapper(get,url.GETPRODUCTDETAIL,params)
export const updateWishlist = (params) => apiWrapper(post,url.UPDATEWISHLIST,params)
export const addToOccasion = (params) => apiWrapper(post,url.ADDTOOCCASION,params)
export const uploadMedia = (params) => apiWrapper(postForm,url.UPLOAD_MEDIA,params)
export const getProductsByTag = (params) => apiWrapper(get,url.GET_PRODUCTS_BY_TAG,params)
export const getProductsByCategory = (params) => apiWrapper(get,url.GET_PRODUCTS_BY_CATEGORY,params)
export const getProductsByBrand = (params) => apiWrapper(get,url.GET_PRODUCTS_BY_BRAND,params)

//CART
export const addToCart = (params) => apiWrapper(post,url.ADDPRODUCTCART,params)
export const getCartProducts = () => apiWrapper(get,url.GETCARTPRODUCT)
export const delCartProduct = (params) => apiWrapper(del,url.DELCARTPRODUCT+params)
export const applyCouponCode = (params) => apiWrapper(post,url.APPLYCOUPONCODE+params)
export const removeCouponCode = (params) => apiWrapper(put,url.REMOVECOUPON+params)
export const updateCart = (params) => apiWrapper(put,url.UPDATECART,params) 
export const uploadGiftCard = (params) => apiWrapper(postForm,url.ADDGIFTCARD,params) 
export const getGiftCard = () => apiWrapper(get,url.GETGIFTCARD) 
export const removeGiftCard = () => apiWrapper(del,url.REMOVE_GIFT_CARD) 
export const getFonts = () => apiWrapper(get,url.GET_FONTS) 
export const addBadgeInformation = (params) => apiWrapper(post,url.ADD_BADGE_INFO+"?productId="+params.productId,params) 
export const removeBadgeInformation = (params) => apiWrapper(del,url.REMOVE_BADGE_INFO+params) 
export const getAvailableSlot = (params) => apiWrapper(get,url.GETAVAILABLESLOT,params) 
export const getAvailableSlotByDate = (params) => apiWrapper(get,url.GETAVAILABLESLOTBYDATE,params) 
export const postNewCard = (params) => apiWrapper(post,url.ADD_NEW_CARD,params) 
export const cartCheckout = (data) => apiWrapper(post,url.CART_CHECKOUT,data) 
export const getLastOrderStatus = () => apiWrapper(get,url.LAST_ORDER) 

// PROFILE
export const getProfileInfo = (params) => apiWrapper(get,url.GETPROFILEINFO,params)
export const changePassword = (params) => apiWrapper(post,url.CHANGEPASSWORD,params)
export const getOrderList = (params) => apiWrapper(get,url.GETORDERLIST,params)
export const getOrderDetails = (params) => apiWrapper(get,url.GETORDERDETAILS,params)
export const cancelOrder = (params) => apiWrapper(get,url.CANCEL_ORDER,params)
export const cancelOrderReason = () => apiWrapper(get,url.CANCEL_ORDER_REASON)
export const addCancelOrderReason = (params) => apiWrapper(post,url.ADD_CANCEL_ORDER_REASON,params)
export const reOrder = (params) => apiWrapper(post,url.RE_ORDER+params)
export const getAddressList = (params) => apiWrapper(get,url.GETADDRESSLIST,params)
export const deleteUserAddress = (params) => apiWrapper(del,url.DELETEADDRESS+params)
export const addNewAddress = (params) => apiWrapper(post,url.ADD_NEW_ADDRESS,params)
export const updateCurrentAddress = (params) => apiWrapper(put,url.UPDATE_ADDRESS,params)
export const getPaymentCard = () => apiWrapper(get,url.GETPAYMENTCARDDETAILS)
export const getCardDetails = () => apiWrapper(get,url.GETCARDDETAILS)
export const updatePaymentCard = (params) => apiWrapper(put,url.SET_CARD_DEFAULT+params)
export const getOrderStatus = (params) => apiWrapper(put,url.GET_ORDER_STATUS+params)
export const deletePaymentCard = (params) => apiWrapper(del,url.DELETE_CARD+params)
export const updateProfileInfo = (params) => apiWrapper(post,url.UPDATEPROFILEINFO,params)
export const getTransactionHistory = (params) => apiWrapper(get,url.GETTRANSACTIONHISTORY,params)
export const getFavorites = (params) => apiWrapper(get,url.GETFAVORITES)
export const getUpcomingOccasion = (params) => apiWrapper(get,url.GETUPCOMINGOCCASION)
export const addUpcomingOccasion = (params) => apiWrapper(post,url.ADDUPCOMINGOCCASION,params)
export const updateUpcomingOccasion = (params) => apiWrapper(put,url.UPDATEUPCOMINGOCCASION,params)
export const delUpcomingOccasion = (params) => apiWrapper(del,url.DELETEUPCOMINGOCCASION+params)
export const getOccasionTypes = () => apiWrapper(get,url.GETOCCASIONTYPES)
export const getOccasionById = (params) => apiWrapper(get,url.GETOCCASIONBYID,params)
export const getOccasionOrders = (params) => apiWrapper(get,url.GET_OCCASION_ORDERS,params)
export const delOccasionCartItems = (params) => apiWrapper(del,url.DELOCCASIONCARTITEM,params)
export const getSubscriptionList = (params) => apiWrapper(get,url.GETSUBSCRIPTIONLIST,params)
export const getSubcriptionOrderDetail = (params) => apiWrapper(get,url.GETSUBSCRIPTIONORDERDETAIL,params)
export const updateDefaultAdress = (params) => apiWrapper(put,url.UPDATE_DEFAULT_ADDRESS+params) 
export const getAreas = (params) => apiWrapper(get,url.GET_AREAS,params) 
export const updateArea = (params) => apiWrapper(post,url.UPDATE_AREA,params) 
export const getOperationWarehouse = (params) => apiWrapper(get,url.GET_OPERATION_WAREHOUSE,params) 
export const deleteAccountPermanently = () => apiWrapper(get,url.DELETE_ACCOUNT) 
export const cancelSubscription = (params) => apiWrapper(put,url.CANCEL_SUPSCRIPTION+params) 
// export const deleteUserAddress = (params) => apiWrapper(del,url.DELETEADRESS+params)

//BLOG
export const getAllBlogs = (params) => apiWrapper(get,url.GET_BLOG,params) 
export const getBlogById = (params) => apiWrapper(get,url.BLOG_BYID,params) 


//Firebase config
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_PROJECT_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_PROJECT_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_PROJECT_STORAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_PROJECT_MESSAGE_ID,
  appId: process.env.REACT_APP_FIREBASE_PROJECT_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_PROJECT_MEASUREMENT_ID
};
  // Initialize Firebase
export const app = initializeApp(firebaseConfig);