import React, { useEffect } from "react";
import "../../assets/css/--page-additional.css";
import BreadCrumbs from "../../components/BreadCrumbs"
import { googleTagInitialize, pixelPageEvent, scrollToTop } from "../../common";
import _T from "../../locale/terms and conditions";
import SEO from "../../components/SEO";

function TermsAndConditions() {
    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(()=>{
        pixelPageEvent();
        googleTagInitialize();
    },[]);

    const breadcrumbItems = [
        { title: _T("Home"), link: "/" },
        { title: _T("Terms & Conditions"), link: "" },
    ]
    return (
        <section className="additional-page">
            <div className="page-header text-center underline">
                <h1 className="has-arrow-start fw-900 ff-heading has-arrow-white">{_T("Terms & Conditions")}</h1>
            </div>
            <BreadCrumbs pageTile={_T("Terms and Conditions | Wardstation")} breadcrumbItems={breadcrumbItems} />
            <SEO metaTitle={_T("Terms and Conditions | Wardstation")} metaDescription={_T("Please read these terms and conditions carefully before using the Wardstation website and services. Your access to and use of the service is conditioned on your acceptance of and compliance with these terms. Fast delivery available across KSA. Order now!")} metaKeywords={_T("terms and conditions, terms of service, conditions, terms agreement, legal agreement")} />
            <div className="wrapper">
                <div className="page-content">
                    
                </div>
            </div>
        </section>
    )
}
export default TermsAndConditions;