import { Link } from 'react-router-dom'
import Logo from '../assets/images/logo.png'
import Search from './Search'
import { GlobalContext } from '../context/GlobalContext';
import { useContext } from 'react';
export default function NavBar(props) {
    const { cartCount } = useContext(GlobalContext);
    const {toggle,trigger,data} = props;
    return (
        <div className="navbar">
            <div className="wrapper d-flex align-items-center justify-between">
                <span onClick={()=>{ trigger(!toggle) }} className='cursor-pointer menu-toggle'>< i className="ri-menu-line fs-22 fw-500 text-gray-500"></i></span>
                <Link to={'/'} className='site-logo'><img src={Logo} alt='WardStation'/></Link>
                <Search/>
                <div className='right-menu'>
                    <Link to={'/account/profile'} className='decoration-none d-inline-block'>
                        {/* <i className="ri-user-3-line fs-22 fw-500 text-gray-500 mx-4"></i> */}
                       <i className='fs-20 fw-500 text-gray-500 mx-4'>
                            <svg width="20" height="20" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_2124_210832)">
                                <path d="M23.22 26.4453V23.6673C23.2204 22.9381 23.0772 22.216 22.7985 21.5422C22.5197 20.8684 22.111 20.2561 21.5956 19.7402C21.0802 19.2244 20.4682 18.8151 19.7947 18.5358C19.1211 18.2565 18.3992 18.1126 17.67 18.1123H6.55896C5.82902 18.1114 5.10612 18.2544 4.43152 18.5332C3.75692 18.812 3.1439 19.2211 2.62756 19.737C2.11123 20.253 1.70176 20.8657 1.42249 21.5401C1.14321 22.2145 0.999606 22.9374 1 23.6673V26.4453" stroke="#36454F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12.1158 12.112C13.4008 12.1108 14.6456 11.6641 15.6383 10.8481C16.6309 10.0321 17.31 8.89714 17.5598 7.63662C17.8095 6.3761 17.6145 5.06798 17.008 3.93509C16.4015 2.8022 15.421 1.91463 14.2335 1.42356C13.046 0.932499 11.725 0.868322 10.4954 1.24196C9.26595 1.6156 8.20404 2.40395 7.49057 3.47271C6.7771 4.54147 6.45622 5.82453 6.58261 7.10332C6.709 8.38212 7.27492 9.57754 8.1838 10.486C8.70023 11.002 9.3132 11.4113 9.98788 11.6903C10.6626 11.9693 11.3857 12.1126 12.1158 12.112Z" stroke="#36454F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_2124_210832">
                                <rect width="24.22" height="27.001" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                       </i>
                    </Link>
                    <Link to={'/cart'} id='cartLink' className='decoration-none d-inline-block' data-cart={cartCount}>
                        {/* <i className="ri-shopping-basket-line fs-22 fw-500 text-gray-500"></i> */}
                        <i className='fs-22 fw-500 text-gray-500'>
                            <svg width="20" height="20" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.2909 26.9997H7.43395C4.63395 26.9997 2.68995 26.3147 1.47695 24.9057C0.263948 23.4967 -0.146052 21.3807 0.264948 18.6217L1.27595 10.7767C1.2759 10.758 1.27894 10.7394 1.28495 10.7217C1.4944 9.32147 2.17144 8.0333 3.20595 7.0667C4.0471 6.32661 5.1266 5.91413 6.24695 5.9047C6.42603 4.28157 7.19766 2.78156 8.41402 1.69202C9.63038 0.602475 11.206 0 12.8389 0C14.4719 0 16.0475 0.602475 17.2639 1.69202C18.4802 2.78156 19.2519 4.28157 19.4309 5.9047H19.5059C20.6379 5.91174 21.7268 6.33946 22.5609 7.1047C23.5567 8.07031 24.2283 9.32119 24.4829 10.6847C24.4896 10.7134 24.4946 10.7424 24.4979 10.7717L25.5059 18.6217C25.8749 21.2037 25.4119 23.2637 24.1299 24.7427C23.3791 25.5206 22.4678 26.1254 21.4594 26.5152C20.451 26.905 19.3697 27.0703 18.2909 26.9997ZM3.25595 11.0607L2.24695 18.8897V18.9107C1.92295 21.0707 2.17495 22.6497 2.99495 23.6017C3.81495 24.5537 5.25795 25.0017 7.43695 25.0017H18.2909C19.0817 25.0574 19.8753 24.9472 20.621 24.6782C21.3666 24.4092 22.0479 23.9874 22.6209 23.4397C23.0912 22.7946 23.4157 22.0551 23.5721 21.2723C23.7286 20.4894 23.7131 19.682 23.5269 18.9057V18.8917L22.5209 11.0797C22.3506 10.1384 21.8971 9.27144 21.2209 8.5947C20.7567 8.15678 20.1441 7.91034 19.5059 7.9047H6.26795C5.62366 7.90858 5.00262 8.14591 4.51995 8.5727C3.83373 9.23845 3.38896 10.1139 3.25595 11.0607ZM8.26495 5.9047H17.4149C17.2438 4.81427 16.6888 3.82083 15.85 3.10339C15.0112 2.38595 13.9437 1.99171 12.8399 1.99171C11.7362 1.99171 10.6687 2.38595 9.82988 3.10339C8.99106 3.82083 8.43608 4.81427 8.26495 5.9047Z" fill="#003739"/>
                                <path d="M16.7084 13.4756H16.6494C16.3842 13.4756 16.1298 13.3702 15.9423 13.1827C15.7548 12.9952 15.6494 12.7408 15.6494 12.4756C15.6494 12.2104 15.7548 11.956 15.9423 11.7685C16.1298 11.5809 16.3842 11.4756 16.6494 11.4756H16.7084C16.9736 11.4756 17.228 11.5809 17.4155 11.7685C17.6031 11.956 17.7084 12.2104 17.7084 12.4756C17.7084 12.7408 17.6031 12.9952 17.4155 13.1827C17.228 13.3702 16.9736 13.4756 16.7084 13.4756Z" fill="#003739"/>
                                <path d="M9.12543 13.4756H9.06543C8.80021 13.4756 8.54586 13.3702 8.35832 13.1827C8.17079 12.9952 8.06543 12.7408 8.06543 12.4756C8.06543 12.2104 8.17079 11.956 8.35832 11.7685C8.54586 11.5809 8.80021 11.4756 9.06543 11.4756H9.12543C9.25675 11.4756 9.38679 11.5015 9.50811 11.5517C9.62944 11.602 9.73968 11.6756 9.83254 11.7685C9.9254 11.8613 9.99905 11.9716 10.0493 12.0929C10.0996 12.2142 10.1254 12.3443 10.1254 12.4756C10.1254 12.7408 10.0201 12.9952 9.83254 13.1827C9.645 13.3702 9.39065 13.4756 9.12543 13.4756Z" fill="#003739"/>
                            </svg>
                        </i>
                    </Link>
                </div>
            </div>
        </div>
    )
}