import React from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import _T from '../../locale/order';
import { convertDateTimeWithLocale } from '../../common';

function OrderCard({item}){
    return (
        <div className="order-card bg-wrapper overflow-hidden d-flex justify-between flex-wrap mt-4 pt-0 pl-0 pr-0 pb-0">
            <div className='card-header d-flex justify-between flex-wrap align-items-center w-100' style={{ padding: "1rem"}}>
                <div className='order-card-no'>
                    <p className='label mb-0 mt-0 fs-16 text-secondary'>{_T("Order No:")}</p>
                    <p className='value mb-0 mt-0 fs-16 text-primary'>{item.Id}</p>
                </div>
                <div className='order-card-date'>
                    <p className='label mb-0 mt-0 fs-16 text-secondary'>{_T("Order Date:")}</p>
                    <p className='value mb-0 mt-0 fs-16 text-primary'>{ convertDateTimeWithLocale(moment(item.OrderDate).format("Do MMM YYYY")) }</p>
                </div>
                <div className='order-card-delivery'>
                    <p className='label mb-0 mt-0 fs-16 text-secondary'>{_T("Expected Delivery:")}</p>
                    <p className='value mb-0 mt-0 fs-16 text-primary'>{ item.IsCancelled ? <span className='text-flushed'>{_T("Cancelled")}</span> : item.IsDelivered ?  <span className='text-primary'>{_T("Delivered")}</span> : convertDateTimeWithLocale(moment(item.ExpectedDelivery).format("Do MMM YYYY"))}</p>
                </div>
                <div className='order-card-view-btn text-right'>
                    <Link to={"/account/orders/"+item.Id} className='btn btn-primary padded'>{_T("View Details")}</Link >
                </div>
            </div>
        </div>
    )
}

export default OrderCard