import noImage from '../assets/images/no-image.jpg'
import cartImage from "../assets/images/cart-image.png"
import infoIcon from "../assets/images/info-icon.png"
import clickIcon from "../assets/images/click-icon.png"
import brand from '../assets/images/temp/brand-01.png'
import Modal from "./Modal"
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { addToCart, updateWishlist } from '../helper/backend-methods'
import Skeleton from 'react-loading-skeleton'
import '../assets/css/--component-product-01.css'
import { GlobalContext } from '../context/GlobalContext'
import _T from '../locale/product'
import { formatPrice, isAuthenticated, pixelTrackEvent } from '../common'

export default function ProductCard({ item, isLoading, setToggleClass, onAddToCart }) {
    const product = isLoading ? { Id: 0, IsInWishlist: false, } : item
    const isAuthorized = isAuthenticated();
    const productId = product.Id;
    const { cartCount, updateCartCount } = useContext(GlobalContext);
    const [IsInWishlist, setIsInWishlist] = useState(product ? product.IsInWishlist : false);
    const [inStock, setInStock] = useState(isLoading ? false : product.StockDetails && product.StockDetails === "Out of stock" ? false : true)
    const [loadingButton, setLoadingButton] = useState(false)
    const [cartModal, setCartModal] = useState(false);
    const [alternativeProductModal, setAlternativeProductModal] = useState(false);
    const [showAlternativeOption, setShowAlternativeOption] = useState(false);
    const [selectedColorAlternative, setSelectedColorAlternative] = useState(false);
    const addItemToCart = () => {
        setLoadingButton(true)
        setAlternativeProductModal(false);
        setShowAlternativeOption(false);
        let alternativeSelection = false;
        if (selectedColorAlternative) {
            const selectedAlternativeVariant = product.Colors.filter((item) => item.AssociatedProductId === selectedColorAlternative)[0]
            alternativeSelection = {
                "AlternateColor": selectedAlternativeVariant.Name,
                "IsAlternateAvailable": true,
                "ProductId": selectedAlternativeVariant.AssociatedProductId,
                "Send": false
            }
        }
        setToggleClass(true);
        addToCart({ Quantity: 1, ProductId: productId, ...(selectedColorAlternative && { AlternateVariation: alternativeSelection }) }).then(({ data }) => {
            setSelectedColorAlternative(false);
            if (data.status) {
                updateCartCount(cartCount + 1)
                pixelTrackEvent("AddToCart",{ content_ids: [productId], content_type: 'product', value: product.ProductPrice.Price, currency: 'SAR' })
                if (onAddToCart) {
                    setToggleClass(false);
                    onAddToCart()
                }
                else {
                    setCartModal(true)
                }
            }
            else {
                setToggleClass(false);
            }
            setLoadingButton(false)
        }).catch((e) => {
            setToggleClass(false);
            setLoadingButton(false)
        })
    }

    const addRemoveWishlist = () => {
        updateWishlist({ ProductId: productId, Add: !IsInWishlist }).then(({ data }) => {
            if (data.status) {
                if(IsInWishlist){
                    pixelTrackEvent("AddToWishlist",{ content_ids: [productId], content_type: 'product', value: product.ProductPrice.Price, currency: 'SAR' })
                }
                setIsInWishlist(!IsInWishlist)
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    return (
        isLoading ?
            <Skeleton className="single-product product-style-01" height={480}></Skeleton> :
            <div className="single-product product-style-01">
                {product.IsAttachable ? <span className='product-customizable'>{_T("Customizable")}</span> : null}
                <div className="product-wrapper">
                    <div className="product-header">
                        <Link to={`/product/${product.Id}/${product.Name ? product.Name.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : _T("product")}`} className='product-image-container'>
                            <img className='product-image' src={product.DefaultPictureModel.ImageUrl ? product.DefaultPictureModel.ImageUrl : product.DefaultPictureModel.FullSizeImageUrl} onError={(e) => { e.target.onerror = null; e.target.src = noImage }} alt={product.Name + " | Wardstation"} draggable={false} />
                        </Link>
                        {
                            product.BrandImgae ?
                                <span className='product-brand'>
                                    <img className='product-brand-image' src={product.BrandImgae} alt='Brand' />
                                </span> : null
                        }
                        {product.ProductPrice.IsDiscounted ? <span className='product-tag d-inline-flex align-items-center'> <i className="ri-price-tag-3-fill mr-2"></i> {product.ProductPrice.DiscountPercentage}%</span> : null}
                        {
                            isAuthorized ?
                                <button className='text-btn product-wishlist cursor-pointer' onClick={() => { addRemoveWishlist() }}>
                                    {IsInWishlist ? <i className="ri-heart-3-fill text-secondary"></i> : <i className="ri-heart-3-line text-secondary"></i>}
                                </button> : null
                        }
                    </div>
                    <div className='card-dis-container'>
                        <div className='product-card-01-dis'>
                            <Link to={`/product/${product.Id}/${product.Name ? product.Name.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : _T("product")}`} className='product-title decoration-none d-inline-block'><h3 className='fs-16 fw-500 text-secondary m-0'>{product.Name}</h3></Link>
                            <p className='product-description text-gray-400 fw-400 d-none'>{product.ShortDescription}</p>
                            <div className='product-price'>
                                <span className='current mr-2 text-en'>
                                    <bdi className='text-en'>
                                        <span className='currency d-inline-block mr-1 text-en'>SAR</span>&nbsp;{formatPrice(product.ProductPrice.Price)}
                                    </bdi>
                                </span>
                                {/* {
                                    product.ProductPrice.OldPrice ?
                                        <span className='old text-en'>
                                            <bdi>
                                                <s className='text-en'><span className='currency d-inline-block mr-1 text-en'>SAR</span>&nbsp;{product.ProductPrice.OldPrice}</s>
                                            </bdi>
                                        </span> : null
                                } */}
                            </div>
                        </div>
                        <div className='add-to-cart-product-container'>
                            {
                                item.CanSubscribe ?
                                    <Link to={"/subscription/product/" + productId} className="product-action btn w-100">{_T("View Product")}</Link> :
                                    item.IsCakeProduct ?
                                        <Link to={"/product/" + productId} className="product-action btn w-100" disabled={!inStock || loadingButton}> {inStock ? <><i className="ri-shopping-cart-line"></i>{loadingButton ? _T("Please Wait!") : _T("Add to Cart")}</> : _T("Out of Stock")}</Link> :
                                        <button className="product-action btn w-100" disabled={!inStock || loadingButton} onClick={() => { loadingButton ? console.log("loading") : product.HasColor && product.Colors.filter((item) => item.StockQuantity > 0).length ? (function () { setAlternativeProductModal(true); setToggleClass(true) })() : addItemToCart() }}> {inStock ? <><i className="ri-shopping-cart-line"></i>{loadingButton ? _T("Please Wait!") : _T("Add to Cart")}</> : _T("Out of Stock")}</button>
                            }
                        </div>
                    </div>
                </div>

                <Modal className="cart-modal" isOpen={cartModal} modalSize="small">
                    <div className="text-center">
                        <img src={cartImage} alt="Cart Icon" className="mx-auto" width={110} />
                        <p className="text-gray-400 fs-16 fw-400 text-center">{_T("The item has been added to your shopping cart")}</p>
                        <button className="btn btn-primary w-100 mb-2" type="button" onClick={() => { setCartModal(false); setToggleClass(false) }}>{_T("Continue Shopping")}</button>
                        <Link className="btn btn-secondary w-100" to={"/cart"}>{_T("Checkout")}</Link>
                    </div>
                </Modal>


                <Modal className="select-alternative-modal" isOpen={alternativeProductModal} modalSize="small" onClose={() => { setAlternativeProductModal(false); setShowAlternativeOption(false); setSelectedColorAlternative(false); setToggleClass(false) }} >
                    {
                        !showAlternativeOption ?
                            <div className="text-center">
                                <img src={infoIcon} alt="Info Icon" className="mx-auto" width={50} />
                                <p className="text-gray-400 fs-16 fw-400 text-center mb-0">{_T("If the item is not available")}</p>
                                <p className="text-gray-400 fs-16 fw-400 text-center mt-0">{_T("What would you like to do?")}</p>
                                <button className="btn btn-primary w-100 mb-2" type="button" onClick={() => { addItemToCart() }}>{_T("Remove Item")}</button>
                                <button className="btn btn-secondary w-100" type="button" onClick={() => { setShowAlternativeOption(true) }}>{_T("Select Alternative")}</button>
                            </div> :
                            <div className="text-center">
                                <img src={clickIcon} alt="Click Icon" className="mx-auto" width={50} />
                                <p className="text-gray-400 fs-16 fw-400 text-center">{_T("Select Alternative Color")}</p>
                                {
                                    product.HasColor ?
                                        <div className="product-color-selector">
                                            <div className="color-options">
                                                {
                                                    product.Colors.filter((item) => item.StockQuantity > 0).map((color, index) => {
                                                        return <span onClick={() => { setSelectedColorAlternative(color.AssociatedProductId) }} className={`color-option ${color.AssociatedProductId === selectedColorAlternative ? "selected" : ""} `} key={index} style={{ backgroundColor: color.ColorSquaresRgb }}></span>
                                                    })
                                                }
                                            </div>
                                        </div> : null
                                }
                                <button className="btn btn-secondary w-100" disabled={!selectedColorAlternative} type="button" onClick={addItemToCart}>{_T("Confirm")}</button>
                            </div>
                    }
                </Modal>
            </div>
    )
}