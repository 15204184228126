import Pagination from "react-js-pagination"
import Skeleton from "react-loading-skeleton"
import "../assets/css/--component-tablePagination.css"


export const TablePagination = ({ pageSize, page, currentRecords, total, onPaginationChange, loading }) => {
    return <div className="d-flex justify-center pagination-container">
        <div className="d-flex justify-center mb-5">
            {
                loading ?
                    <Skeleton width={250} height={40} /> :
                    <nav>
                        <Pagination itemClassFirst={"f-btn"} itemClassLast={"l-btn"} activePage={page} itemsCountPerPage={pageSize} totalItemsCount={total} pageRangeDisplayed={3} onChange={onPaginationChange} itemClass={"page-item"} linkClass={"page-link"}  activeClass={"active-page"} innerClass="pagination m-0" />
                    </nav>
            }
        </div>
    </div>
}