import React, { useEffect, useState } from "react";
import "../../assets/css/--component-account-occasion.css";
import OccasionImage01 from "../../assets/images/temp/occasion-01.png";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import Modal from "../../components/Modal";
import {
    delUpcomingOccasion,
    updateUpcomingOccasion,
} from "../../helper/backend-methods";
import { Link, useSearchParams } from "react-router-dom";
import { Toaster } from "../../components/Toaster";
import _T from "../../locale/occasion";
import { convertDateTimeWithLocale } from "../../common";

function OccasionCard(props) {
    const [search, setSearch] = useSearchParams();
    const { occasionDate, occasionImage, occasionName, occasionType,Reminder, onUpdate, Id, occasionTypesList } = props;
    const [startDate, setStartDate] = useState(new Date(occasionDate));
    const [isOpen, setIsOpen] = useState(false);
    const [upcomingOccasionList, setUpcomingOccasionList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [occasionTitle, setOccasionTitle] = useState(occasionName);
    const [occasiontype, setOccasiontype] = useState(occasionType);
    const [error, setError] = useState({});
    const [update, setUpdate] = useState(0);
    const [yearlyReminder, setYearlyReminder] = useState(Reminder);

    useEffect(() => {
        if (search && search.get("update") && search.get("update").length) {
            if (search.get("update") === Id) {
                setIsOpen(true);
            }
        }
    }, []);

    const validateOccasionUpdate = (e) => {
        e.preventDefault();
        const error = { occasionType: false, occasionTitle: false };
        let flag = false;
        if (occasionType === "") {
            flag = true;
            error.occasionType = true;
        }
        if (occasionTitle === "") {
            flag = true;
            error.occasionTitle = true;
        }
        setError(error);
        if (!flag) {
            updateUpcomingOccasion({ Name: occasionTitle, Type: occasiontype, OccasionDate: startDate, YearlyReminder: yearlyReminder, Id: Id }).then(({ data }) => {
                if (data.status) {
                    onUpdate();
                    setIsOpen(false);
                    Toaster("success",_T("Occasion Updated"),_T("Your Occasion has been updated!"))
                }
                else{
                    Toaster("error",_T("Update Failed"),_T("Your Occasion could not be updated!"))
                }
            }).catch((e) => {
                Toaster("error",_T("Update Failed"),_T("Your Occasion could not be updated!"))
                console.log(e);
            });
        }
    };

    const DeleteOccasion = () => {
        delUpcomingOccasion("?OccasionId=" + Id).then(({ data }) => {
            if (data.status) {
                onUpdate();
                setIsOpen(false);
                Toaster("success",_T("Occasion Deleted"),_T("Your Occasion has been deleted!"))
            }
            else{
                Toaster("error",_T("Delete Failed"),_T("Your Occasion could not be deleted!"))
            }
        }).catch((e) => {
            Toaster("error",_T("Delete Failed"),_T("Your Occasion could not be deleted!"))
            console.log(e);
        });
    };

    return (
        <div className="upcoming-occasion-card">
            <div className="upcoming-occasion-container">
                <div className="upcoming-occasion-header cursor-pointer">
                    <Link to={"/account/occasion/" + Id}>
                        <div className="card-tag">
                            {convertDateTimeWithLocale(moment(occasionDate).format("MMM Do YYYY"))}
                        </div>
                        <div className="upcoming-occasion-img text-center">
                            <img src={occasionImage} alt="" />
                        </div>
                    </Link>
                </div>
                <div className="upcoming-occasion-footer">
                    <Link to={"/account/occasion/" + Id}>
                        <p className="text-secondary fw-500 cursor-pointer m-0">{occasionName}</p>
                    </Link>
                    <div className="bottom-content d-flex justify-between mb-2">
                        <div className="occasion-type">
                            <span className="fs-14 fw-400 text-gray-400">{occasionType}</span>
                        </div>
                        <div className="edit-btn">
                            <button className="link fs-14 fw-500" onClick={() => { setIsOpen(true); }}>{_T("Edit")}</button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal title={_T("Update Occasion")} isOpen={isOpen} onClose={() => { setIsOpen(false); }} className="occasion-modal">
                <div className="form-content">
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} minDate={new Date()} startDate={startDate} inline showDisabledMonthNavigation />
                </div>
                <div className="form-content mt-2">
                    <label className="fs-14" htmlFor="title">{_T("Occasion Title")}</label>
                    <input type="text" id="title" name="title" placeholder={_T("Occasion Title")} value={occasionTitle} onChange={(e) => setOccasionTitle(e.target.value)} />
                    { error.occasionTitle ? <p className="text-flushed fs-14 fw-400 mt-1 mb-1">{_T("Occasion Title cannot be empty!")}</p> : <></> }
                </div>
                <div className="form-content mt-2">
                    <label className="fs-14" htmlFor="type">{_T("Occasion Type")}</label>
                    <select name="occasionType" id="type" value={occasionType} onChange={(e) => { setOccasiontype(e.target.value); }}>
                        <option disabled={true} value={""}>{_T("Select an Occasion Type")}</option>
                        {occasionTypesList.map((item, index) => {
                            return <option key={index} value={item.Name}>{item.Name}</option>
                        })}
                    </select>
                    { error.occasionType ? <p className="text-flushed fs-14 fw-400 mt-1 mb-1">{_T("Occasion Type cannot be empty!")}</p> : <></> }
                </div>
                <div className="form-content mt-2">
                    <input id="yearlyReminder" className="input-checkbox" type="checkbox" checked={yearlyReminder} onChange={() => { setYearlyReminder(!yearlyReminder); }}/>
                    <label htmlFor="yearlyReminder">{_T("Yearly Reminder")}</label>
                </div>
                <div className="btn-group mt-3">
                    <button className="btn btn-danger w-100 mb-2" onClick={DeleteOccasion}>{_T("Delete Occasion")}</button>
                    <button className="btn btn-secondary w-100 mb-2" onClick={validateOccasionUpdate}>{_T("Update Occasion")}</button>
                </div>
            </Modal>
        </div>
    );
}
export default OccasionCard;
