import React, { useCallback, useRef, useState } from "react";
import AddressCard from "./address-card";
import '../../assets/css/--component-account-address.css'
import { useEffect } from "react";
import { addNewAddress, deleteUserAddress, getAddressList, getAreas, updateArea, updateCurrentAddress, updateDefaultAdress } from "../../helper/backend-methods";
import Modal from "../../components/Modal";
import Skeleton from "react-loading-skeleton";
import { GoogleMap, useJsApiLoader, Marker, Autocomplete } from '@react-google-maps/api';
import { getActiveStore, getCurrentUser, getLocale, googleTagInitialize, pixelPageEvent, scrollToTop } from "../../common";
import ErrorMsg from "../../components/ErrorMessage";
import { Toaster } from "../../components/Toaster";
import _T from "../../locale/address";
function AccountAddress() {
    const [btnLoading, setBtnLoading] = useState(false)
    const [activeStore, setActiveStore] = useState(getActiveStore())
    const [activeArea, setActiveArea] = useState(0)
    const [storeAreas, setStoreAreas] = useState([])
    const [addresses, setAddresses] = useState([])
    const [apiLoading, setApiLoading] = useState(true);
    const [addressId, setAddressId] = useState(0)
    const [recipientName, setRecipientName] = useState('')
    const [recipientPhone, setRecipientPhone] = useState('+966')
    const [phoneFocused, setPhoneFocused] = useState(false)
    const [selectedArea, setSelectedArea] = useState(0)
    const [recipientAddress, setRecipientAddress] = useState('')
    const [dontKnowAddress, setDontKnowAddress] = useState(false)
    const [boundryError, setBoundryError] = useState(false)
    const isEn = getLocale() === "en";

    const [createAddress, setCreateAddress] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [update, setUpdate] = useState(0)
    const [map, setMap] = useState(null);
    const [autoComplete, setAutoComplete] = useState(null);
    const [mapCenter, setMapCenter] = useState({ lat: -3.745, lng: -38.523 })
    const [mapMarker, setMapMarker] = useState({ lat: -3.745, lng: -38.523 })
    const [mapLoaded, setMapLoaded] = useState(false);
    const searchRef = useRef("");
    const libraries = useRef(['places']);

    const [createAddressError, setCreateAddressError] = useState({ recipientName: false, recipientPhone: false });

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
        libraries: libraries.current
    })
    useEffect(() => {
        fetchAddresses()
    }, [update])

    useEffect(()=>{
        pixelPageEvent();
        googleTagInitialize();
    },[])

    useEffect(() => {
        scrollToTop();
        document.title = 'Address | Wardstation';
        fetchAreasByStore()
    }, [])

    useEffect(() => {
        if (mapLoaded) {
            autoComplete.addListener('place_changed', onPlaceSelect)
        }
    }, [mapLoaded])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
        setAutoComplete(null)
        setMapLoaded(false)
    }, [])



    const onLoad = (map) => {
        setAutoComplete(new window.google.maps.places.Autocomplete(searchRef.current))
        const bounds = new window.google.maps.LatLngBounds(mapCenter);
        map.fitBounds(bounds);
        setMap(map)
        setMapLoaded(true)
    }


    const onPlaceSelect = () => {
        const place = autoComplete.getPlace();
        if (place.geometry.viewport || place.geometry.location) {
            const address = place.formatted_address;
            let cityFound = null;
            if(place.address_components && place.address_components.length){
                cityFound = place.address_components.find((c)=> c.types && c.types.length && c.types.includes("locality") )
            }
            if(cityFound){
                const alternativeCity = activeStore.AlternateNames && activeStore.AlternateNames.length ? activeStore.AlternateNames : [];
                if (alternativeCity.filter((c)=>cityFound.long_name.toLowerCase() === c.toLowerCase()).length) {
                    setRecipientAddress(address)
                    setBoundryError(false)
                }
                else {
                    setBoundryError(true)
                    setRecipientAddress("")
                }
            }
            else{
                setRecipientAddress(address)
                setBoundryError(false)
            }
            setMapMarker({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
            setMapCenter({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
            map.panTo({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
            map.setZoom(15)
        }
    }


    const validateSubmit = (e) => {
        e.preventDefault();
        const error = { recipientName: false, recipientPhone: false };
        let flag = false;
        if (!recipientName || recipientName.replaceAll(/\s/g, '').length < 2) {
            flag = true;
            error.recipientName = true;
        }
        if (!recipientPhone.match(/^((?:\+966|00966)(?:\s?\d{2})(?:\s?\d{7}))$/)) {
            flag = true;
            error.recipientPhone = true;
        }

        setCreateAddressError(error);

        const user = getCurrentUser();
        const warehouse = getActiveStore();
        const areaSelected = storeAreas.find((i) => i.Id === selectedArea) // modal selected area
        const areaActive = storeAreas.find((i) => i.Id === activeArea) // active area
        const addressBody = {
            ...(editMode && { Id: addressId }),
            "FirstName": recipientName,
            "LastName": null,
            "Email": user ? user.email : null,
            "Company": null,
            "CountryCode": null,
            "StateProvinceId": null,
            "County": null,
            "City": warehouse ? isEn ? warehouse.NameEn : warehouse.NameAr : null,
            "AddressType": null,
            "Address1": dontKnowAddress ? areaActive ? areaActive.Name : "" : areaSelected ? areaSelected.Name : "",
            "Address2": dontKnowAddress ? "" : recipientAddress,
            "ZipPostalCode": null,
            "PhoneNumber": recipientPhone,
            "FaxNumber": null,
            "Latitude": dontKnowAddress ? 0 : mapMarker.lat,
            "Longitude": dontKnowAddress ? 0 : mapMarker.lng,
            "DontKnowAddress": dontKnowAddress,
            "CustomAttributes": `<Attributes><AddressAttribute ID=\"3\"><AddressAttributeValue><Value>${dontKnowAddress ? 0 : mapMarker.lat}</Value></AddressAttributeValue></AddressAttribute><AddressAttribute ID=\"4\"><AddressAttributeValue><Value>${dontKnowAddress ? 0 : mapMarker.lng}</Value></AddressAttributeValue></AddressAttribute></Attributes>`
        }
        if (editMode) {
            if (!flag) {
                setBtnLoading(true)
                updateCurrentAddress(addressBody).then((data) => {
                    if (data.status) {
                        setCreateAddress(false);
                        setEditMode(false)
                        setApiLoading(true);
                        if(!dontKnowAddress){
                            updateSelectedArea(selectedArea);
                        }
                        else{
                            setUpdate(update + 1);
                        }
                        setBtnLoading(false)
                        Toaster("success", "Address Updated", "Your address has been updated!")
                    }
                    else {
                        Toaster("error", _T("Update Failed"), _T("Your address could not be updated!"))
                        setBtnLoading(false)
                    }
                }).catch((e) => {
                    Toaster("error", _T("Update Failed"), _T("Your address could not be updated!"))
                    setBtnLoading(false)
                })
            }
        }
        else {
            if (!flag) {
                setBtnLoading(true)
                addNewAddress(addressBody).then((data) => {
                    if (data.status) {
                        setCreateAddress(false);
                        setApiLoading(true);
                        if(!dontKnowAddress){
                            updateSelectedArea(selectedArea);
                        }
                        else{
                            setUpdate(update + 1);
                        }
                        setRecipientName("");
                        setRecipientPhone("+966");
                        setRecipientAddress("");
                        setBtnLoading(false)
                        Toaster("success", _T("Address Created"), _T("Your address has been created!"))
                    }
                    else {
                        setBtnLoading(false)
                        Toaster("error", _T("Request Failed"), _T("Your address could not be created!"))
                    }
                }).catch((e) => {
                    setBtnLoading(false)
                    Toaster("error", _T("Request Failed"), _T("Your address could not be created!"))
                })
            }
        }
    }


    const fetchAddresses = () => {
        getAddressList().then(({ data }) => {
            if (data.status) {
                setAddresses(data.result.addressList)
                setActiveArea(data.result.areaId)
                setSelectedArea(data.result.areaId)
                setApiLoading(false)
            }
            else {
                Toaster("error", _T("Request Failed"), _T("Your addresses could not be fetched!"))
            }
        }).catch((e) => {
            Toaster("error", _T("Request Failed"), _T("Your addresses could not be fetched!"))
        })
    }

    const fetchAreasByStore = () => {
        getAreas({ storeId: activeStore ? activeStore.WarehouseId : 4 }).then(({ data }) => {
            if (data.status) {
                setStoreAreas(data && data.result ? data.result : [])
                if (data && data.result && data.result.length) {
                    setMapCenter({ lat: parseFloat(data.result[0].Latitude), lng: parseFloat(data.result[0].Longitude) })
                    setMapMarker({ lat: parseFloat(data.result[0].Latitude), lng: parseFloat(data.result[0].Longitude) })
                }
            }
            else {
                Toaster("error", _T("Request Failed"), _T("Store areas could not be fetched!"))
            }
        }).catch((e) => {
            Toaster("error", _T("Request Failed"), _T("Store areas could not be fetched!"))
        })
    }


    const deleteAddress = (Id) => {
        deleteUserAddress("?addressId=" + Id).then(({ data }) => {
            if (data.status) {
                setUpdate(update + 1)
                Toaster("success", _T("Address Deleted"), _T("Your address has been deleted!"))
            }
            else {
                Toaster("error", _T("Delete Failed"), _T("Your address could not be deleted!"))
            }
        }).catch((e) => {
            Toaster("error", _T("Delete Failed"), _T("Your address could not be deleted!"))
        })
    }

    const markAddressDefault = (Id) => {
        updateDefaultAdress("?addressId=" + Id).then((data) => {
            if (data.status) {
                setUpdate(update + 1)
                Toaster("success", _T("Address Selected"), _T("Your address has been selected as default!"))
            }
            else {
                Toaster("success", _T("Request Failed"), _T("Your address could not be marked as default!"))
            }
        }).catch((e) => {
            Toaster("success", _T("Request Failed"), _T("Your address could not be marked as default!"))
        })
    }

    const selectForEdit = (Id) => {
        const selectedAddress = addresses.find((item) => item.Id === Id)
        if (selectedAddress) {
            setAddressId(selectedAddress.Id)
            setEditMode(true)
            setRecipientName(selectedAddress.FirstName)
            setRecipientPhone(selectedAddress.PhoneNumber)
            setPhoneFocused(false)
            setRecipientAddress(selectedAddress.Address2)
            setDontKnowAddress(selectedAddress.DontKnowAddress)
            setMapMarker({ lat: parseFloat(selectedAddress.Latitude), lng: parseFloat(selectedAddress.Longitude) })
            setMapCenter({ lat: parseFloat(selectedAddress.Latitude), lng: parseFloat(selectedAddress.Longitude) })
            setCreateAddress(true)
        }
    }

    const resetFormValues = () => {
        setRecipientName('')
        setRecipientPhone('+966')
        setPhoneFocused(false)
        setRecipientAddress('')
        setDontKnowAddress(false)
        setMapMarker(mapCenter)
        setEditMode(false)
        setBoundryError(false)
    }

    const getAddressFromCoordinates = (position) => {
        setBoundryError(false)
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: position }, (results, status) => {
            if (status === 'OK' && results[0]) {
                const address = results[0].formatted_address;
                let cityFound = null;
                if(results[0].address_components && results[0].address_components.length){
                    cityFound = results[0].address_components.find((c)=> c.types && c.types.length && c.types.includes("locality") )
                }
                if(cityFound){
                    const alternativeCity = activeStore.AlternateNames && activeStore.AlternateNames.length ? activeStore.AlternateNames : [];
                    if (alternativeCity.filter((c)=>cityFound.long_name.toLowerCase() === c.toLowerCase()).length) {
                        setRecipientAddress(address)
                        setBoundryError(false)
                    }
                    else {
                        setBoundryError(true)
                        setRecipientAddress("")
                    }
                }
                else{
                    setRecipientAddress(address)
                    setBoundryError(false)
                }
            }
        });
    };

    const updateSelectedArea = (e) => {
        let value = isNaN(e) ? e.target.value : e
        setActiveArea(value);
        setApiLoading(true)
        const area = storeAreas.find((item) => item.Id === parseInt(value));
        if (area) {
            setMapCenter({ lat: parseFloat(area.Latitude), lng: parseFloat(area.Longitude) })
            setMapMarker({ lat: parseFloat(area.Latitude), lng: parseFloat(area.Longitude) })
        }
        updateArea({ StoreId: activeStore ? activeStore.WarehouseId : 4, AreaId: value }).then(({ data }) => {
            if (data.status) {
                setUpdate(update + 1);
            }
            else {
                Toaster("error", _T("Request Failed"), _T("Store area could not be selected!"))
                setApiLoading(false)
            }
        }).catch((e) => {
            Toaster("error", _T("Request Failed"), _T("Store area could not be selected!"))
        })
    }

    const changeAreaModal = (id) => {
        setSelectedArea(parseInt(id))
        const area = storeAreas.find((item) => item.Id === parseInt(id));
        setMapMarker({ lat: parseFloat(area.Latitude), lng: parseFloat(area.Longitude) })
        setMapCenter({ lat: parseFloat(area.Latitude), lng: parseFloat(area.Longitude) })
    }

    const handleChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.startsWith("+966")) {
            setRecipientPhone(inputValue.slice(0, 13));
        }
    };



    return (
        <section id="address-section">
            <div className="account-header d-flex align-items-center justify-between">
                <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'>{_T("My Saved Address")}</h1>
                <button onClick={() => { setCreateAddress(true); setEditMode(false); }} className="btn btn-primary"> <i className="ri-map-pin-line fs-20 mr-1"></i>{_T("Add Address")}</button>
            </div>
            <div className="form-group mb-4">
                <label className="mb-1 d-inline-block fs-16 fw-400 text-gray-400" htmlFor="selectedArea">{_T("Selected Area")}</label>
                <select id="selectedArea" value={activeArea} onChange={updateSelectedArea}>
                    <option value={0} disabled={true}>{_T("Select Area")}</option>
                    {
                        storeAreas.map((item, key) => {
                            return <option key={key} value={item.Id}>{item.Name}</option>
                        })
                    }
                </select>
            </div>
            <div className=" d-flex justify-between flex-wrap">
                {
                    !apiLoading ?
                        addresses.length ?
                            addresses.map((item, key) => {
                                return <AddressCard item={item} editableCard={true} methodDelete={deleteAddress} methodDefault={markAddressDefault} methodUpdate={selectForEdit} key={key} />
                            }) : <ErrorMsg text={_T("You haven't created any addresses")} /> :
                        [...Array(6)].map((item, key) => {
                            return <div key={key} className="address-card-skeleton address-card">
                                <Skeleton className='mt-3' height={"100%"} />
                            </div>
                        })
                }
            </div>

            <Modal className="address-modal" title={editMode ? _T("Update Address") : _T("Add New Address")} isOpen={createAddress} onClose={() => { setCreateAddress(false); setEditMode(false); resetFormValues(); }} >
                <form onSubmit={validateSubmit}>
                    {
                        isLoaded && !dontKnowAddress ?
                            <div className="form-content mb-2">
                                <input placeholder={_T('Search Here')} ref={searchRef} />
                            </div> : null
                    }
                    {
                        isLoaded && !dontKnowAddress ?
                            <GoogleMap clickableIcons={false} onClick={(e) => { setMapMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() }); getAddressFromCoordinates({ lat: e.latLng.lat(), lng: e.latLng.lng() }) }} center={mapCenter} zoom={12} onLoad={onLoad} options={{ zoomControl: false, streetViewControl: false, mapTypeControl: false, maxZoom: 16 }} mapContainerClassName={"map-container"} onUnmount={onUnmount}>
                                <Marker position={mapMarker} />
                            </GoogleMap>
                            : null
                    }
                    {boundryError && !dontKnowAddress ? <p className="text-flushed fs-14 fw-400">{_T("Please select a location within")} { isEn ? activeStore.NameEn : activeStore.NameAr}</p> : <></>}
                    <div className="form-content mb-2">
                        <label className="mb-1 d-inline-block fs-16 fw-400 text-gray-400" htmlFor="recipientName">{_T("Recipient Name")}<span className="text-primary">*</span></label>
                        <input type="text" id="recipientName" value={recipientName} onChange={(e) => { setRecipientName(e.target.value.slice(0, 40)) }} placeholder={_T("Enter name here")} />
                        {createAddressError.recipientName ? <p className="text-flushed fs-14 fw-400 mt-1 mb-1">{_T("please provide recipient name")}</p> : null}
                    </div>
                    <div className={`form-content mb-2 phone-input-container ${phoneFocused ? "focused" : ""}`}>
                        <label className="mb-1 d-inline-block fs-16 fw-400 text-gray-400" htmlFor="recipientPhone">{_T("Recipient Mobile")}<span className="text-primary">*</span></label>
                        <input type="text" className="dir-escape text-left" placeholder={_T("Enter phone number")} value={recipientPhone} onChange={handleChange} />
                        {createAddressError.recipientPhone ? <p className="text-flushed fs-14 fw-400 mt-1 mb-1">{_T("please provide valid recipient mobile")}</p> : null}
                    </div>
                    {
                        !dontKnowAddress ?
                            <div className="form-content mb-2">
                                <label className="mb-1 d-inline-block fs-16 fw-400 text-gray-400" htmlFor="recipientArea">{_T("Recipient Area")}<span className="text-primary">*</span></label>
                                <select id="recipientArea" value={selectedArea} onChange={(e) => { changeAreaModal(e.target.value) }}>
                                    <option value={0} disabled={true}>{_T("Select Recipient Area")}</option>
                                    {
                                        storeAreas.map((item, key) => {
                                            return <option key={key} value={item.Id}>{item.Name}</option>
                                        })
                                    }
                                </select>
                            </div> :
                            null
                    }
                    {
                        !dontKnowAddress ?
                            <div className="form-content mb-2">
                                <label className="mb-1 d-inline-block fs-16 fw-400 text-gray-400" htmlFor="recipientAddress">{_T("Recipient Address")}<span className="text-primary">*</span></label>
                                <textarea id="recipientAddress" value={recipientAddress} onChange={(e) => { setRecipientAddress(e.target.value.slice(0, 100)) }} rows={5} placeholder={_T("Please Enter Address")}></textarea>
                            </div> :
                            null
                    }
                    <div className="dont-know-address-container d-flex align-items-center justify-between">
                        <div className="control-content">
                            <h2 className="fs-16 fw-400 text-secondary m-0">{_T("You don't know the address?")}</h2>
                            <p className="fs-14 fw-400 text-secondary m-0">{_T("We will contact the recipient and get their address")}</p>
                        </div>
                        <div className="control-input">
                            <label className="switch-control">
                                <input type="checkbox" checked={dontKnowAddress} onChange={(e) => { setDontKnowAddress(e.target.checked); }} />
                                <span className="slider"></span>
                            </label>
                        </div>
                    </div>
                    {
                        dontKnowAddress ?
                            <div className="dont-know-notice">
                                <i className="ri-information-line text-light fs-22 mr-3"></i><p className="text-light fs-14 fw-400 m-0">{_T("Delivery time might be impacted in case the recipient cannot be reached")}</p>
                            </div> : null
                    }
                    <button className="btn btn-secondary w-100 mt-4" type="submit" disabled={btnLoading || (boundryError && !dontKnowAddress)}>{editMode ? _T("Update Address") : _T("Save Address")}</button>
                </form>
            </Modal>
        </section>
    )
}
export default AccountAddress