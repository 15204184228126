import React, { useEffect, useState } from "react";
import PaymentCard from "./payment-method-card";
import '../../assets/css/--component-account-payment-method-card.css'
import Skeleton from "react-loading-skeleton";
import { getCardDetails } from "../../helper/backend-methods";
import { googleTagInitialize, pixelPageEvent, scrollToTop } from "../../common";
import _T from "../../locale/payment";

function PaymentMethod() {
    const [cardDetails, setCardDetails] = useState([])
    const [update ,setUpdate] = useState(0)
    const [loading ,setLoading] = useState(true)

    useEffect(()=>{
        pixelPageEvent();
        googleTagInitialize();
    },[])

    useEffect(() => {
        document.title = 'Payments | Wardstation';
        scrollToTop();
        fetchCards();
    }, [update])

    const fetchCards = () => {
        getCardDetails().then(({ data }) => {
            if (data.status) {
                setCardDetails(data.result)
                setLoading(false)
            }
        }).catch(e => {
            console.log(e)
        })
    }


    return (
        <section id="payment-method">
            <div className='account-header d-flex align-items-center justify-between'>
                <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'>{_T("My Saved Cards")}</h1>
            </div>
            <div className="d-flex justify-between flex-wrap">
                {
                    loading?  [...Array(6)].map((item, key) => {
                        return (
                        <div className="payment-card-skeleton payment-method-card" key={key}>
                        <Skeleton key={key} className='mt-3' height={"100%"} />
                        </div>
                        )
                    }) :
                    cardDetails.length ?
                        cardDetails.map((cardData, key) => {
                            return <PaymentCard cardData={cardData} loading={loading} key={key} value={update} update={setUpdate}/>
                        }) :
                        <p className="btn btn-alternative w-100"><i className="ri-information-line mr-2 fs-18"></i>{_T("You currently have no payment cards.")}</p>
                }

            </div>
        </section >
    )
}

export default PaymentMethod