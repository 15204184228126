import React, { useEffect } from "react";
import { useState } from "react";
import { applyCouponCode, getCartProducts, removeCouponCode } from "../../helper/backend-methods";
import Skeleton from "react-loading-skeleton";
import _T from "../../locale/cart";

function CartCoupon(props) {
    const update = props.update
    const setUpdate = props.setUpdate
    const loading = props.loading
    const voucher = props.voucher
    const [couponCode, setCouponCode] = useState("")
    const [couponLoading, setCouponLoading] = useState(false)
    const [error, setError] = useState('')
    
    const validateSubmit = () => {
        let flag = false;
        setCouponLoading(true);
        if(!couponCode || couponCode.trim().length < 1){
            flag = true;
            setError(_T("Please provide a valid coupon code"))
        }
        else{
            setError("")
        }
        if(!flag){
            applyCouponCode("?discountCode=" + couponCode).then(({ data }) => {
                if (data.status) {
                    setUpdate(update + 1)
                    setCouponCode("")
                    setCouponLoading(false);
                    setError("")
                }
                else{
                    setError(_T("Please provide a valid coupon code"))
                    setCouponLoading(false);
                }
            }).catch((e)=>{
                setError(_T("Please provide a valid coupon code"))
                setCouponLoading(false);
            })
        }
        else{
            setCouponLoading(false);
        }
    }
    const removeCoupon = (coupon) => {
        removeCouponCode("?discountCode=" + coupon).then(({ data }) => {
            if (data.status) {
                setUpdate(update + 1)
            }
        })
    }
    return (
        loading ?
        <Skeleton height={166} className="mt-4 w-100"/> :
        <div className="shopping-cart-container mt-4">
            <div className="coupon-heading d-flex align-items-center mb-3">
                <i className="ri-coupon-line fs-20 text-secondary"></i>
                <p className="m-0 fs-18 fw-500 text-secondary ml-1">{_T("Coupon")}</p>
            </div>
            <span className="underline"></span>
            {
                !voucher.length ?
                <div className="coupon-feild d-flex justify-between mt-3">
                    <input className="input-feild" readOnly={couponLoading} onChange={(e) => { setCouponCode(e.target.value) }} type={"text"} value={couponCode} placeholder={_T("Coupon")} />
                    {
                        couponLoading ? 
                        <button className="btn btn-primary" disabled={true}><i className="ri-loader-line do-spin fs-16"></i> </button> :
                        <button className="btn btn-primary" onClick={() => { validateSubmit() }}>{_T("Apply")}</button>
                    }
                </div> :
                <div className="cart-voucher mb-0 pb-0 mt-3">
                    <div className="applied-coupons mb-0">
                        {
                            voucher.map((coupon, key) => {
                                return coupon ?
                                    <span key={key}> <i className="ri-close-line" onClick={() => { removeCoupon(coupon) }}></i>Coupon Applied: "{coupon}"</span> : <i key={key} className="d-none"></i>
                            })
                        }
                    </div>
                </div>
            }
            { error ? <p className="text-flushed fs-12 fw-400">{error}</p> : null }
        </div>
    )
}

export default CartCoupon