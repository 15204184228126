function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "Terms & Conditions": {
            "en": "Terms & Conditions",
            "ar": "الشروط والأحكام"
        },
        "Home": {
            "en": "Home",
            "ar": "الصفحة الرئيسية"
        },
        "Please read these terms and conditions carefully before using the Wardstation website and services. Your access to and use of the service is conditioned on your acceptance of and compliance with these terms. Fast delivery available across KSA. Order now!": {
            "en": "Please read these terms and conditions carefully before using the Wardstation website and services. Your access to and use of the service is conditioned on your acceptance of and compliance with these terms. Fast delivery available across KSA. Order now!",
            "ar": "يرجى قراءة هذه الشروط والأحكام بعناية قبل استخدام موقع ورد ستيشن وخدماته. يشترط استخدام الخدمة والوصول إليها موافقتك على هذه الشروط والالتزام بها. يتوفر التوصيل السريع في جميع أنحاء المملكة العربية السعودية. اطلب الآن!"
        },
        "terms and conditions, terms of service, conditions, terms agreement, legal agreement": {
            "en": "terms and conditions, terms of service, conditions, terms agreement, legal agreement",
            "ar": "الشروط والأحكام, شروط الخدمة, الشروط, اتفاقية قانونية"
        },
        "Terms and Conditions | Wardstation": {
            "en": "Terms and Conditions | Wardstation",
            "ar": "الشروط والأحكام | ورد ستيشن"
        },
    }


    return translations[text] ? translations[text][locale] : text;
}
export default _T;