import { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Modal from "./Modal"
import flag from '../assets/images/KSA-flag.png'
import { getActiveStore, getLocale, updateActiveStore } from "../common";
import { getOperationWarehouse } from "../helper/backend-methods";
import { GlobalContext } from '../context/GlobalContext';
import _T from "../locale/header";

export default function NavItems(props) {
    const { warehouseId, warehouseList, updateWarehouseList } = useContext(GlobalContext);
    const {toggle,trigger,data} = props;
    const isEn = getLocale() === "en";
    const [manufactures, setManufactures] = useState([]);
    const [categories, setCategories] = useState([]);
    const [occasions, setOccasions] = useState([]);
    const [localePopup, setLocalePopup] = useState(false);
    const [regionPopup, setRegionPopup] = useState(false);
    const [storeActive, setStoreActive] = useState(getActiveStore());
    const [selectedLocale, setSelectedLocale] = useState(getLocale())
    const [selectedRegion, setSelectedRegion] = useState(getActiveStore())
    const [toggleBrandActive, setToggleBrandActive] = useState(false)
    const [toggleCategoryActive, setToggleCategoryActive] = useState(false)
    const [toggleOccasionActive, setToggleOccasionActive] = useState(false)

    useEffect(()=>{
        setManufactures(data.Manufacture ? data.Manufacture : [])
        setCategories(data.Category ? data.Category : [])
        setOccasions(data.Occasions ? data.Occasions : [])
    },[data])

    const updateSelectedWarehouse = () => {
        setRegionPopup(false);
        getOperationWarehouse({ name: isEn ? selectedRegion.NameEn : selectedRegion.NameAr, operationWarehouseId: selectedRegion.WarehouseId, alternateNames:selectedRegion.alternateNames }).then(({data})=>{
            if(data.status){
                updateActiveStore(selectedRegion)
                setStoreActive(selectedRegion)
                window.location.reload(); 
            }
            else{
                localStorage.removeItem("guestToken");
                localStorage.removeItem("authToken");
                localStorage.removeItem("userInfo");
                localStorage.removeItem("activeStore");
                window.location.reload();
            } 
        }).catch(()=>{
            localStorage.removeItem("guestToken");
            localStorage.removeItem("authToken");
            localStorage.removeItem("userInfo");
            localStorage.removeItem("activeStore");
            window.location.reload();
          })
    }

    const fetchWarehouseList = () => {
        if(!warehouseList || !warehouseList.length){
            getOperationWarehouse().then(({data})=>{
                if(data.status){
                    updateWarehouseList(data.result.Data)
                    setRegionPopup(true)
                }
                else{
                    localStorage.removeItem("guestToken");
                    localStorage.removeItem("authToken");
                    localStorage.removeItem("userInfo");
                    localStorage.removeItem("activeStore");
                    window.location.reload();
                } 
            }).catch(()=>{
                localStorage.removeItem("guestToken");
                localStorage.removeItem("authToken");
                localStorage.removeItem("userInfo");
                localStorage.removeItem("activeStore");
                window.location.reload();
              })
        }
        else{
            setRegionPopup(true)
        }
    }

    const updateActiveLocale = () => {
        localStorage.setItem("locale",selectedLocale);
        setLocalePopup(false)
        window.location.reload(); 
    }

    return (
        <nav className="nav-items-container">
            <div className="wrapper">
                <span className="menu-toggle cursor-pointer" onClick={()=>{trigger(!toggle)}}><i className="ri-close-line fs-22 fw-400 text-secondary"></i> </span>
                <ul className="nav-items">
                    <li className="nav-item"><NavLink onClick={()=>{trigger(false)}} to={"/"}>{_T("Home")}</NavLink></li>
                    {
                        manufactures.length ?
                        <li className={`nav-item has-children brand-menu ${toggleBrandActive ? "active": ""}`}>
                            <span onClick={()=>{ setToggleBrandActive(!toggleBrandActive) }}>{_T("Brands")}</span>
                            <ul className={`child-menu ${toggleBrandActive ? "active": ""}`}>
                                {
                                    manufactures.map((item,key)=>{
                                        return <li className="child-menu-item" key={key}>
                                            <NavLink onClick={()=>{trigger(false)}} to={`/brand-products/${item.Id}/${item.Name ? item.Name.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : _T("brands")}`}><img src={item.PicturURL} alt={item.Name} />{item.Name}</NavLink>
                                        </li>
                                    })
                                }
                            </ul>
                        </li> : null
                    }
                    {
                        categories.length ?
                        <li className={`nav-item has-children category-menu ${toggleCategoryActive ? "active": ""}`}>
                            <span onClick={()=>{ setToggleCategoryActive(!toggleCategoryActive) }}>{_T("Categories")}</span>
                            <ul className={`child-menu ${toggleCategoryActive ? "active": ""}`}>
                                {
                                    categories.map((item,key)=>{
                                        return <li className="child-menu-item" key={key}>
                                            <NavLink onClick={()=>{trigger(false)}} to={`/category-products/${item.Id}/${item.Name ? item.Name.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : _T("category")}`}><img src={isEn ? item.PicturUrl_En : item.PicturUrl_Ar} alt={item.Name} />{item.Name}</NavLink>
                                        </li>
                                    })
                                }
                            </ul>
                        </li> : null
                    }
                    {
                        occasions.length ?
                        <li className={`nav-item has-children occasion-menu ${toggleOccasionActive ? "active": ""}`}>
                            <span onClick={()=>{ setToggleOccasionActive(!toggleOccasionActive) }}>{_T("Occasions")}</span>
                            <ul className={`child-menu ${toggleOccasionActive ? "active": ""}`}>
                                {
                                    occasions.map((item,key)=>{
                                        return <li className="child-menu-item" key={key}>
                                            <NavLink onClick={()=>{trigger(false)}} to={`/occasion-products/${item.Id}/${item.Name ? item.Name.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : _T("occasion")}`}><img src={isEn ? item.PicturUrl_En : item.PicturUrl_Ar} alt={item.Name} />{item.Name}</NavLink>
                                        </li>
                                    })
                                }
                            </ul>
                        </li> : null
                    }
                    <li className="nav-item"><NavLink onClick={()=>{trigger(false)}} to={"/products"}>{_T("Products")}</NavLink></li>
                    <li className="nav-item"><NavLink onClick={()=>{trigger(false)}} to={"/about"}>{_T("About")}</NavLink></li>
                    <li className="nav-item"><NavLink onClick={()=>{trigger(false)}} to={"/blogs"}>{_T("Blogs")}</NavLink></li>
                </ul>
                <div className="navbar-options">
                    <div className="navbar-region">
                        <img src={flag} className="flag-icon cursor-pointer" alt="KSA" onClick={fetchWarehouseList}/>
                        <p className="selected-region-container">
                            <span className="region-subtitle">{_T("Deliver to")}</span>
                            <span className="selected-region" onClick={fetchWarehouseList}>{ storeActive ? isEn ? storeActive.NameEn : storeActive.NameAr : "City" }</span>
                        </p>
                    </div>
                    <div className="navbar-language">
                        <p className="selected-language-container">
                            <span className="language-subtitle">{_T("View in")}</span>
                            <span className="selected-language" onClick={()=>{setLocalePopup(true)}}>{ isEn ? "English" : "عربي"}</span>
                        </p>
                    </div>
                </div>
                <div className="nav-items-overlay" onClick={()=>{trigger(false)}}></div>
            </div>
            <Modal className={"locale-modal"} isOpen={localePopup} onClose={()=>{ setLocalePopup(false) }} title={_T("Select Language")}>
                <div className="locale-option-container mt-3">
                    <div className="form-content">
                        <input type="radio" className="input-radio" name="locale" id="english" checked={ selectedLocale === "en" } value={"en"} onChange={(e)=>{ setSelectedLocale(e.target.checked ? "en" : "") }} />
                        <label htmlFor="english" className="dir-escape">View in English</label>
                    </div>
                    <div className="form-content">
                        <input type="radio" className="input-radio" name="locale" id="arabic" checked={ selectedLocale === "ar" } value={"ar"} onChange={(e)=>{ setSelectedLocale(e.target.checked ? "ar" : "") }}/>
                        <label htmlFor="arabic" className="font-secondary fw-300">عرض باللغة العربية</label>
                    </div>
                    <button disabled={ getLocale() === selectedLocale } type="button" onClick={updateActiveLocale} className="btn btn-primary w-100 mt-4">{_T("Update Language")}</button>
                </div>
            </Modal>
            <Modal className={"region-modal"} isOpen={regionPopup} onClose={()=>{ setRegionPopup(false) }} title={_T("Choose City")}>
                <div className="region-option-container mt-2 mb-4">
                    {
                        warehouseList.map((item,index)=>{
                            return <div className={`form-content ${selectedRegion && selectedRegion.WarehouseId === item.WarehouseId ? "active" : ""} `} key={index}>
                                <label htmlFor={"region"+index}>
                                    <input type="radio" name="region" id={"region"+index} checked={ selectedRegion && selectedRegion.WarehouseId === item.WarehouseId } value={item.WarehouseId} onChange={(e)=>{ if(e.target.checked){ setSelectedRegion(item) } }} />
                                    {
                                        item.warehouseIcon===null?
                                        <i className="ri-map-pin-line region-icon"></i>
                                        :
                                        <img className="cityIcon" src={item.warehouseIcon} alt="CityIcon"/>
                                    }
                                    <h4>{ isEn ? item.NameEn : item.NameAr }</h4>
                                </label>
                            </div>
                        })
                    }
                </div>
                <p className="fs-14 fw-400 text-gray-500 m-0">{_T("* By changing your current city, you may lose your selection inside the cart.")}</p>
                <button disabled={ (storeActive && selectedRegion && selectedRegion.WarehouseId === storeActive.WarehouseId) || !selectedRegion } type="button" onClick={updateSelectedWarehouse} className="btn btn-primary w-100 mt-4">{_T("Select City")}</button>
            </Modal>
        </nav>
    )
}