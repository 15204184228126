import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Maintenance from "../../components/Maintenance";
import WhatsappIcon from "../../assets/images/whatsapp.png"
function PublicLayout(props){
    return (
        props.maintenance ? <Maintenance/> :
        <>
            {!props.hideHeaderFooter ? <Header/> : <></>}
            {props.children}
            {!props.hideHeaderFooter ? <Footer/> : <></>}
            <a href='https://wa.me/966554255420' target='_blank' rel='noreferrer' className='whatsapp-icon'><img src={WhatsappIcon}/></a>
        </>
    )
}

export default PublicLayout; 