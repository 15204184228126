import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import '../../assets/css/--page-auth.css'
import AuthBanner from '../../assets/images/login-image.png'
import TopFlower from '../../assets/images/top-flower (1).png'
import BottomFlower from '../../assets/images/bottom-flower .png'
import { resetPassword } from "../../helper/backend-methods";
import { getLocale, googleTagInitialize, pixelPageEvent, scrollToTop } from "../../common";
import ProcessLoader from "../../components/ProcessLoader";
import _T from "../../locale/authentication";

function ResetPassword() {
    let params = useParams();
    const guid = params.guid;
    const token = params.token;
    const locale = params.locale;
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [serverError, setServerError] = useState('');
    const [errors, setErrors] = useState({ password: "", confirmPassword: "" })
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [resetComplete, setResetComplete] = useState(false);
    const [unauthorized, setUnauthorized] = useState(false);
    const [localeChanged, setLocaleChanged] = useState(true);

    useEffect(()=>{
        document.title = 'Reset Password | Wardstation';
        scrollToTop();
    },[])

    useEffect(()=>{
        pixelPageEvent();
        googleTagInitialize();
    },[])

    useEffect(()=>{
        setInitialLoading(true)
        const activeLocale = localStorage.getItem("locale")
        if(locale && locale === 'ar'){
            localStorage.setItem("locale","ar")
        }
        else{
            localStorage.setItem("locale","en")
        }
        if(activeLocale !== locale){
            getLocale();
        }
        if(guid.length < 30 || token.length < 30){
            setUnauthorized(true)
            setInitialLoading(false)
        }
        else{
            resetPassword({ CustomerGuid: guid, CustomerToken: token }).then(({ data }) => {
                if(data.status){
                    if(data.response.ResponseMessage === "token_validated"){
                        setUnauthorized(false)
                    }
                    else{
                        setUnauthorized(true)
                    }
                    setInitialLoading(false)
                }
                else{
                    setUnauthorized(true)
                    setInitialLoading(false)
                }
            }).catch(function (error) {
                setUnauthorized(true)
                setInitialLoading(false)
            });
        }
    },[guid,token])

    const validateSubmit = (e) => {
        e.preventDefault();
        const errors = { password: "", confirmPassword: "" };
        let flag = false;
        if (!password) {
            errors.password = _T("Provide a valid new password");
            flag = true;
        }
        else if (password.replaceAll(/\s/g, '').length < 6) {
            errors.password = _T("Password must have minimum 6 characters");
            flag = true;
        }
        if (confirmPassword !== password) {
            errors.confirmPassword = "Passwords do not match";
            flag = true;
        }
        if (!confirmPassword) {
            errors.confirmPassword = _T("Provide a valid password");
            flag = true;
        }
        else if (confirmPassword.replaceAll(/\s/g, '').length < 6) {
            errors.confirmPassword = _T("Password must have minimum 6 characters");
            flag = true;
        }
        setErrors(errors);
        if (!flag) {
            setLoading(true)
            setServerError('')
            resetPassword({ CustomerGuid: guid, CustomerToken: token, CustomerPassword: password }).then(({ data }) => {
                if (data.status) {
                    setResetComplete(true);
                }
                else {
                    setServerError(_T("We could not update your password at this moment. Please try again later!"))
                    setLoading(false)
                }
            }).catch((e) => {
                setServerError(_T("We could not update your password at this moment. Please try again later!"))
                setLoading(false)
            })
        }
    }

    return (
        <div className="auth-container d-flex justify-center">
            <div className="auth-left">
                <img className="top-flower" src={TopFlower} alt="" />
                <img className="bottom-flower" src={BottomFlower} alt="" />
                <form onSubmit={validateSubmit}>
                    {
                        unauthorized ?
                        <div className="input-content">
                            <h1 className="fs-36 fw-600 text-secondary mt-0 mb-2">{_T("Link Expired")}</h1>
                            <p className="fs-16 text-gray-300 fw-400 mt-0 mb-3">{_T("Your reset password link may have expired or has already been used. Please request a new reset link by going to the")} <Link to={"/forgot-password"} className="text-primary decoration-none">{_T("Forgot Password")}</Link> {_T("page.")}</p>
                        </div> :
                        <div className="input-content">
                            <h1 className="fs-36 fw-600 text-secondary mt-0 mb-2">{_T('Reset Password')}</h1>
                            <p className="fs-16 text-gray-300 fw-400 mt-0 mb-3">{_T("Please enter your new password to continue using it.")}</p>
                            <div className="form-group has-button">
                                <label htmlFor="password">{_T("New Password")}</label>
                                <input id="password" readOnly={resetComplete} type={ showPassword ? "text" : "password" } name="password" onChange={(e) => { setPassword(e.target.value.slice(0, 50)) }} value={password} placeholder={_T("New Password")} />
                                <button onClick={() => { setShowPassword(!showPassword) }} type="button" >{showPassword ? <i className="ri-eye-off-line"></i> : <i className="ri-eye-line"></i>}</button>
                                {errors.password ? <span className="text-flushed fs-12 fw-400">{errors.password}</span> : null}
                            </div>
                            <div className="form-group has-button">
                                <label htmlFor="confirmPassword">{_T("Confirm Password")}</label>
                                <input id="confirmPassword" readOnly={resetComplete} type={ showConfirmPassword ? "text" : "password" }  name="confirmPassword" onChange={(e) => { setConfirmPassword(e.target.value.slice(0, 50)) }} value={confirmPassword} placeholder={_T("Repeat New Password")} />
                                <button onClick={() => { setShowConfirmPassword(!showConfirmPassword) }} type="button" >{showConfirmPassword ? <i className="ri-eye-off-line"></i> : <i className="ri-eye-line"></i>}</button>
                                {errors.confirmPassword ? <span className="text-flushed fs-12 fw-400">{errors.confirmPassword}</span> : null}
                            </div>
                            { serverError.length ? <p className="fs-14 fw-400 text-flushed text-center">{serverError}</p> : null }
                            { resetComplete ? <p className="fs-14 fw-400 text-secondary text-left">Your password has been updated. Please sign-in to continue.</p> : null }
                            <div className="auth-btn">
                                {
                                    resetComplete ?
                                    <Link to="/login" className="btn btn-secondary w-100">{_T("Sign-In")}</Link> :
                                    <button type="submit" disabled={loading} className="btn btn-secondary w-100">{_T("Reset Password")}</button>
                                }
                            </div>
                        </div>
                    }
                </form>
            </div>
            <div className="auth-right" style={{ backgroundImage: `url(${AuthBanner})`}}></div>
        </div>
    )
}

export default ResetPassword