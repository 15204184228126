function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "Request Failed": {
            "en": "Request Failed",
            "ar": "الطلب فشل"
        },
        "We could not fetch your wishlist!": {
            "en": "We could not fetch your wishlist!",
            "ar": "لم نتمكن من احضار قائمة أمنياتك!"
        },
        "Favourites": {
            "en": "Favourites",
            "ar": "المفضلة"
        },
        "You currently have no items in your wishlist.": {
            "en": "You currently have no items in your wishlist.",
            "ar": ".ليس لديك حاليًا أي منتجات في قائمة الأمنيات الخاصة بك"
        },
        "Out of stock": {
            "en": "Out of stock",
            "ar": "نفدت الكمية"
        },
        "Could not remove the product from wishlist!": {
            "en": "Could not remove the product from wishlist!",
            "ar": "لا يمكن إزالة المنتج من قائمة الأمنيات!"
        },
        "Customizable": {
            "en": "Customizable",
            "ar": "قابل للتخصيص"
        },
        "Low stock": {
            "en": "Low stock",
            "ar": "كمية قليلة"
        },
        "In Stock": {
            "en": "In Stock",
            "ar": "في الأوراق المالية"
        },
        "Please Wait!": {
            "en": "Please Wait!",
            "ar": "انتظر من فضلك!"
        },
        "Add to Cart": {
            "en": "Add to Cart",
            "ar": "أضف إلى السلة"
        },
        "The item has been added to your shopping cart": {
            "en": "The item has been added to your shopping cart",
            "ar": "تمت إضافة العنصر إلى عربة التسوق الخاصة بك"
        },
        "Continue Shopping": {
            "en": "Continue Shopping",
            "ar": "مواصلة التسوق"
        },
        "If the item is not available": {
            "en": "If the item is not available",
            "ar": "إذا كان المنتج غير متوفر"
        },
        "What would you like to do?": {
            "en": "What would you like to do?",
            "ar": "ماذا تريد ان تفعل؟"
        },
        "Remove Item": {
            "en": "Remove Item",
            "ar": "حذف المنتج"
        },
        "Select Alternative": {
            "en": "Select Alternative",
            "ar": "حدد البديل"
        },
        "Select Alternative Color": {
            "en": "Select Alternative Color",
            "ar": "حدد اللون البديل"
        },
        "Confirm": {
            "en": "Confirm",
            "ar": "تأكيد"
        },
        "Checkout": {
            "en": "Checkout",
            "ar": "الدفع"
        }
    }

    return translations[text] ? translations[text][locale] : text;
}
export default _T;