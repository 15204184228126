import React, { useEffect, useState } from "react";
import Subbanner from '../../assets/images/Subscription_banner.png'
import Subbanner_ar from '../../assets/images/Subscription_banner_2.png'
import '../../assets/css/--page-subscription.css'
import purpleFlower from '../../assets/images/Flower_subscription_section.png'
import img1 from '../../assets/images/1_Easy_To_Use.png'
import img2 from '../../assets/images/2_Date.png'
import img3 from '../../assets/images/3_Pay.png'
import {getProductsByTag, getWebHomeTagged } from "../../helper/backend-methods";
import SubIndexSkeleton from "./index-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import SubProductCard from "./Sub-Product-Card";
import { getLocale, googleTagInitialize, pixelPageEvent, scrollToTop } from "../../common";
import _T from "../../locale/subscription";
import SEO from "../../components/SEO";

function Subscription() {
    const locale = getLocale();
    const [subProduct, setSubProduct] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        document.title = "Subscription | Wardstation"
        setTimeout(() => { scrollToTop(); }, 500);
        getWebHomeTagged().then(({ data }) => {
            if (data.status && data.result && data.result.Subscription && data.result.Subscription.TagId) {
                getProductsByTag({ tagId:data.result.Subscription.TagId, pageIndex: 0, pageSize: 100 }).then(({data})=>{
                    if (data.status) {
                        setSubProduct(data.result.Data && data.result.Data.length ? data.result.Data : [])
                        setLoading(false)
                    }
                    else{
                        setLoading(false)
                    }
                }).catch((e)=>{
                    console.log(e)
                    setLoading(false)
                })
            }
            else{
                setLoading(false)
            }
        }).catch((e)=>{
            console.log(e)
            setLoading(false)
        })
    }, [])

    useEffect(()=>{
        pixelPageEvent();
        googleTagInitialize();
    },[])

    return (
        <>
            <SEO metaTitle={"Subscription"} metaDescription={_T("We think that flowers shouldn't just be for the big occasions. They're here to brighten the everyday too. Flowers are perfect to lighten your space or gift for friends and loved ones.")} metaKeywords={"Subscription.Flowers.Products"} />
            {
                loading ?
                <SubIndexSkeleton></SubIndexSkeleton>:
                <div id="subscription" className="bg-white">
                    <section id="sub-hero-section">
                        <div className="sub-hero-banner">
                            <img src={locale === "ar" ?  Subbanner_ar : Subbanner} alt="Subscription" />
                        </div>
                    </section>
                    <section id="flower-subscription-section">
                        <div className="flower-sub-container wrapper d-flex justify-between align-items-center flex-wrap">
                            <div className="flower-sub-left">
                                <div className="flower-sub-content mb-3 mt-3">
                                    <h2 className="fs-30 fw-900 text-secondary ff-heading has-arrow-start">{_T("Flower Subscription")}</h2>
                                    <p className="fs-18 fw-300 text-secondary">{_T("We think that flowers shouldn't just be for the big occasions. They're here to brighten the everyday too. Flowers are perfect to lighten your space or gift for friends and loved ones.")}</p>
                                </div>
                            </div>
                            <div className="flower-sub-right">
                                <img src={purpleFlower} alt="Flower Subscription" />
                            </div>
                        </div>
                    </section>
                    <section id="guide-section">
                        <div className="guide-container wrapper">
                            <div className="guide-heading mb-3 d-flex justify-center">
                                <h3 className="fs-24 fw-900 text-secondary text-center ff-heading has-arrow-start">{_T("How it works?")}</h3>
                            </div>
                            <div className="guide-steps-container d-flex justify-between align-items-center flex-wrap">
                                <div className="guide-box">
                                    <div className="guide-box-img text-center">
                                        <img src={img1} alt="Help Icon" />
                                    </div>
                                    <div className="guide-box-text">
                                        <p className="fs-14 fw-400 text-secondary text-center">{_T("Choose from our special collection with our best range of flowers")}</p>
                                    </div>
                                </div>
                                <div className="guide-box">
                                    <div className="guide-box-img text-center">
                                        <img src={img2} alt="Help Icon" />
                                    </div>
                                    <div className="guide-box-text">
                                        <p className="fs-14 fw-400 text-secondary text-center">{_T("Select the starting date, and other subscription details.")}</p>
                                    </div>
                                </div>
                                <div className="guide-box">
                                    <div className="guide-box-img text-center">
                                        <img src={img3} alt="Help Icon" />
                                    </div>
                                    <div className="guide-box-text">
                                        <p className="fs-14 fw-400 text-secondary text-center">{_T("Choose your preferred payment method: Credit or Debit Card")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="sub-product-card-list">
                        <div className="sub-product-card-contaier wrapper">
                            <div className="sub-product-card-heading mb-3">
                                <h3 className="fs-26 fw-900 text-secondary ff-heading has-arrow-start">{_T("Available Subscription Plans")}</h3>
                            </div>

                            {
                                subProduct.length ?
                                <div className="sub-product-card-listing-wrapper">
                                    {
                                        subProduct.map((item, key) => {
                                            return <SubProductCard key={key} item={item} loading={loading} />
                                        })
                                    }
                                </div> : <p>{_T("There are currently no available subscription plans")}</p>
                            } 
                        </div>
                    </section>
                    <section id="sub-faq">
                        <div className="sub-product-faq-container wrapper">
                            <div className="sub-product-faq-heading mb-3">
                                <h3 className="fs-26 fw-900 text-secondary ff-heading has-arrow-start">{_T("Frequently Asked Questions")}</h3>
                            </div>
                            <div className="sub-product-faq-cards mb-3">
                                <p className="fs-18 fw-500 text-secondary">{_T("What is the new subscription from WardStation?")}</p>
                                <p className="fs-16 fw-400 text-secondary">{_T("We think that flowers shouldn't just be for the big occasions. They're here to brighten the everyday too. Flowers are perfect to lighten your space or gift for friends and loved ones.")}</p>
                            </div>
                            <div className="sub-product-faq-cards">
                                <p className="fs-18 fw-500 text-secondary">{_T("What is the new subscription from WardStation?")}</p>
                                <p className="fs-16 fw-400 text-secondary">{_T("We think that flowers shouldn't just be for the big occasions. They're here to brighten the everyday too. Flowers are perfect to lighten your space or gift for friends and loved ones.")}</p>
                            </div>
                        </div>
                    </section>
                </div>
            }
        </>
    )
}

export default Subscription