export default function BreadCrumbTranslation(t){
    let locale = localStorage.getItem("locale");
    if(locale && locale.length){
        locale = locale === "ar" ? "ar" : "en"
    }
    else{
        locale = "en";
    }
    const breadcrumbItems = {
        "Home": {
            "en": "Home",
            "ar": "مسكن",
        },
        "Account":{
            "en": "Account",
            "ar": "حساب",
        },
        "Address":{
            "en": "Address",
            "ar": "عنوان",
        },
        "Occasions":{
            "en": "Occasions",
            "ar": "مناسبات",
        },
        "Orders":{
            "en": "Orders",
            "ar": "طلبات",
        },
        "Payment Methods":{
            "en": "Payment Methods",
            "ar": "طرق الدفع",
        },
        "Profile":{
            "en": "Profile",
            "ar": "حساب تعريفي",
        },
        "Reviews":{
            "en": "Reviews",
            "ar": "المراجعات",
        },
        "Wishlist":{
            "en": "Wishlist",
            "ar": "قائمة الرغبات",
        },
        "Occasion Details":{
            "en": "Occasion Details",
            "ar": "تفاصيل المناسبة",
        },
        "Order Details":{
            "en": "Order Details",
            "ar": "تفاصيل الطلب",
        },
        "Cart":{
            "en": "Cart",
            "ar": "عربة التسوق",
        },
        "Checkout":{
            "en": "Checkout",
            "ar": "الدفع",
        },
        "Contact Us":{
            "en": "Contact Us",
            "ar": "اتصل بنا",
        },
        "Privacy Policy":{
            "en": "Privacy Policy",
            "ar": "سياسة الخصوصية",
        },
        "Store":{
            "en": "Store",
            "ar": "متجر",
        },
        "Products":{
            "en": "Products",
            "ar": "منتجات",
        },
        "Product":{
            "en": "Product",
            "ar": "منتج",
        },
        "Return Policy":{
            "en": "Return Policy",
            "ar": "سياسة العائدات",
        },
        "Terms and Conditions":{
            "en": "Terms and Conditions",
            "ar": "الأحكام والشروط",
        },
        "Terms of Service":{
            "en": "Terms of Service",
            "ar": "شروط الخدمة",
        },
    }
    const result = Object.keys(breadcrumbItems).filter((i)=>{
        return i === t
    })
    return result[0] ? breadcrumbItems[result[0]][locale] : t
}