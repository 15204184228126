import toastr from 'toastr'
export const Toaster = (toastType,title,message) => {
    toastr.options = {
        positionClass: "toast-bottom-right",
        timeOut: 5000,
        extendedTimeOut: 1000,
        closeButton: true,
        debug: false,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "fadeIn",
        hideMethod: "fadeOut",
        showDuration: 300,
        hideDuration: 100
    }
    if (toastType === "info")
        toastr.info(message, title)
    else if (toastType === "warning")
        toastr.warning(message, title)
    else if (toastType === "error")
        toastr.error(message, title)
    else
        toastr.success(message, title)
}