import React, { useRef } from "react";
import cardChip from '../../assets/images/temp/card-chip.svg'
import { cardIcons } from "../../data/payment-cards";
import { deletePaymentCard, updatePaymentCard } from "../../helper/backend-methods";
import { Toaster } from "../../components/Toaster";
import _T from "../../locale/payment";

function PaymentCard(props) {

    const data = props.cardData;
    const deleteLoading = useRef(false)
    const updateLoading = useRef(false)
    function sortDate(month, year) {
        let date = ""
        if (month.length === 1) {
            date = date + "0"
        }
        date = date + month + "/"
        date = date + year.toString().slice(-2)
        return date
    }
    let expiryDate = sortDate(data.Expiry_Month, data.Expiry_year);


    const deleteCard = () => {
        if(!deleteLoading.current){
            deleteLoading.current = true;
            deletePaymentCard("?id="+data.Id).then(({data})=>{
                if(data.status){
                    props.update(props.value + 1)
                    deleteLoading.current = false;
                    Toaster("success",_T("Payment Card Deleted"),_T("Your Card has been deleted!"))
                }
                else{
                    deleteLoading.current = false;
                    Toaster("error",_T("Delete Failed"),_T("Your Card could not be deleted!"))
                }
            }).catch((e) =>{
                Toaster("error",_T("Delete Failed"),_T("Your Card could not be deleted!"))
            })
        }
    }
    const makeDefault = () => {
        if(!updateLoading.current){
            updateLoading.current = true;
            updatePaymentCard("?cardId="+data.Id).then(({data})=>{
                if(data.status){
                    props.update(props.value + 1)
                    updateLoading.current = false;
                    Toaster("success",_T("Payment Card"),_T("Your Card has been updated!"))
                }
                else{
                    updateLoading.current = false;
                    Toaster("error",_T("Update Failed"),_T("Your Card could not be updated!"))
                }
            }).catch((e) =>{
                Toaster("error",_T("Update Failed"),_T("Your Card could not be updated!"))
            })
        }
    }

    return (
        <div className="payment-method-card">
            <div className={`payment-method-card-ui ${data.Selected ? "active": ""}`} onClick={()=> { if(!data.Selected){ makeDefault() } } }>
                <div className="payment-method-card-header">
                    <img className="card-icon" src={cardChip} alt="" />
                    <img className="card-icon" src={cardIcons[data.Scheme.toLowerCase().replaceAll(" ", "-")] ? cardIcons[data.Scheme.toLowerCase().replaceAll(" ", "-")] : cardIcons["undefined"]} alt="" />
                </div>
                <div className="payment-method-card-number">
                    <span>***</span>
                    <span>***</span>
                    <span>***</span>
                    <span>{data.Last_four}</span>
                </div>
                <div className="payment-method-card-footer">
                    <div className="payment-method-card-holder">
                        <span>{_T("Card Holder Name")}</span>
                        <h2>{data.CardHolderName}</h2>
                    </div>
                    <div className="payment-method-card-expiry">
                        <span>{_T("Expiry Date")}</span>
                        <h2>{expiryDate}</h2>
                    </div>
                </div>
            </div>
            <span className="delete-btn bg-flushed" onClick={deleteCard}><i className="ri-delete-bin-line fs-18 mr-2"></i>{_T("Delete")}</span>
        </div>
    )
}

export default PaymentCard