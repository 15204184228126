import NavItems from "./NavItems";
import NavBar from "./Navbar";
import Topbar from "./Topbar";
import "../assets/css/--component-header.css"
import { useContext, useEffect, useState } from "react";
import { getNavItems } from "../helper/backend-methods";
import { GlobalContext } from '../context/GlobalContext';

function Header(){
    const { occasionId, updateActiveOccasion, updateCartCount, updateBrandList } = useContext(GlobalContext);
    const [toggle, setToggle] = useState(false)
    const [topBar, setTopBar ] = useState(false);
    const [navItems, setNavItems] = useState({ CartItemCount: 0, OccasionIdInCart: 0, Stores: [] })

    useEffect(()=>{
        getNavItems().then(({data})=>{
            if(data.status){
                setNavItems(data.result)
                updateActiveOccasion(data.result.OccasionIdInCart)
                updateCartCount(data.result.CartItemCount)
                updateBrandList(data.result.Manufacture)
            }
        }).catch((e)=>{
            console.log(e)
        })
    },[])


    return (
        <header className={`${toggle ? "expanded" : ""} ${topBar ? "topbar-visible": "" }`}>
            <Topbar toggle={topBar} trigger={setTopBar} />
            <NavBar toggle={toggle} trigger={setToggle} data={navItems}/>
            <NavItems toggle={toggle} trigger={setToggle} data={navItems}/>
        </header>
    )
}

export default Header; 