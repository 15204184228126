import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import Carousel from 'react-multi-carousel';
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react';
import calendar from '../../assets/images/calendar.png'
import Skeleton from "react-loading-skeleton";
import { convertDateTimeWithLocale, getLocale } from "../../common";
import moment from "moment";
import _T from "../../locale/home";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { addUpcomingOccasion, getOccasionTypes, updateUpcomingOccasion } from "../../helper/backend-methods";
import { Toaster } from "../../components/Toaster";

function UpcomingOccasion(props) {
    const { title, isLoading, occasions, hasBgImage, hasBgColor, bgImage, bgColor } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [occasionTitle, setOccasionTitle] = useState("")
    const [occasionType, setOccasionType] = useState("")
    const [error, setError] = useState({})
    const [yearlyReminder, setYearlyReminder] = useState(false);
    const [occasionTypesList, setOccasionTypesList] = useState([])
    const [editMode, setEditMode] = useState(false)
    const selectedItemId = useRef(0)

    const xAxisStart = useRef(0)
    const carouselRef = useRef(null);
    const locale = getLocale()
    const isEn = getLocale() === "en" ? true : false;
    const navigate = useNavigate();
    const responsivness = {
        10000: {
            breakpoint: { max: 10000, min: 1400 },
            items: 6,
        },
        1400: {
            breakpoint: { max: 1400, min: 1100 },
            items: 5,
        },
        1100: {
            breakpoint: { max: 1100, min: 900 },
            items: 4,
        },
        900: {
            breakpoint: { max: 900, min: 600 },
            items: 3,
        },
        700: {
            breakpoint: { max: 700, min: 450 },
            items: 2,
            partialVisibilityGutter: 200,
        },
        450: {
            breakpoint: { max: 450, min: 0 },
            items: 2,
            partialVisibilityGutter: 0,
        },
    };

    useEffect(() => {
        getOccasionTypes().then(({ data }) => {
           if (data.status) {
              setOccasionTypesList(data.result)
           }
        }).catch((e) => {
           console.log(e)
        })
     }, [])

    const itemClicked = (e,link) =>{
        if(Math.abs(xAxisStart.current - e.clientX) < 20){
            navigate(link)
        }
        xAxisStart.current = 0
    }
    const createUpdateClicked = (e,occasion) => {
        selectedItemId.current = occasion.Id;
        setStartDate(new Date(occasion.OccasionDate));
        setOccasionTitle(occasion.Name)
        setOccasionType(occasion.Type)
        setError({})
        setYearlyReminder(occasion.YearlyReminder);
        setEditMode(true)
        if(Math.abs(xAxisStart.current - e.clientX) < 20){
            setIsOpen(true)
        }
        xAxisStart.current = 0
    }
    const createNewClicked = (e) => {
        setEditMode(false)
        if(Math.abs(xAxisStart.current - e.clientX) < 20){
            setIsOpen(true)
        }
        xAxisStart.current = 0
    }

    const validateOccasionSubmit = (e) => {
        e.preventDefault();
        const error = { occasionType: false, occasionTitle: false, };
        let flag = false;
        if (occasionType === "") {
           flag = true;
           error.occasionType = true
        }
        if (occasionTitle === "") {
           flag = true;
           error.occasionTitle = true
        }
        setError(error);
        if (!flag) {
           addUpcomingOccasion({ Name: occasionTitle, Type: occasionType, OccasionDate: startDate, YearlyReminder: yearlyReminder }).then(({ data }) => {
              if (data.status) {
                // navigate("/account/occasion/")
                window.location.reload()
                setOccasionTitle("")
                setOccasionType("")
                setIsOpen(false)
                setYearlyReminder(false)
                Toaster("success", _T("Occasion Created"), _T("Your Occasion has been created!"))
              }
              else {
                 Toaster("error",_T("Request Failed"),_T("Your Occasion could not be created!"))
              }
           }).catch((e) => {
              Toaster("error",_T("Request Failed"),_T("Your Occasion could not be created!"))
              console.log(e);
           });
        }
    };

    const validateOccasionUpdate = (e) => {
        e.preventDefault();
        const error = { occasionType: false, occasionTitle: false };
        let flag = false;
        if (occasionType === "") {
            flag = true;
            error.occasionType = true;
        }
        if (occasionTitle === "") {
            flag = true;
            error.occasionTitle = true;
        }
        setError(error);
        if (!flag) {
            updateUpcomingOccasion({ Name: occasionTitle, Type: occasionType, OccasionDate: startDate, YearlyReminder: yearlyReminder, Id: selectedItemId.current }).then(({ data }) => {
                if (data.status) {
                    navigate("/account/occasion/"+selectedItemId.current)
                    setIsOpen(false);
                    Toaster("success",_T("Occasion Updated"),_T("Your Occasion has been updated!"))
                }
                else{
                    Toaster("error",_T("Update Failed"),_T("Your Occasion could not be updated!"))
                }
            }).catch((e) => {
                Toaster("error",_T("Update Failed"),_T("Your Occasion could not be updated!"))
                console.log(e);
            });
        }
    };

    const resetForm = () => {
        selectedItemId.current = 0;
        setStartDate(new Date());
        setOccasionTitle("")
        setOccasionType("")
        setError({})
        setYearlyReminder(false);
    }


    const handlePrevious = () => {
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };
    
    return (
        <section className='occasion-section' style={{ backgroundImage: hasBgImage ? `url(${bgImage})` : 'none', backgroundColor: hasBgColor ? bgColor : '#fff' }}>
            <div className='wrapper d-flex align-items-center justify-between'>
                <div className='section-header mb-4'>
                    <h2 className='ff-heading text-secondary fs-36 fw-900 has-arrow-start'>{title}</h2>
                </div>
                {
                    isEn ?
                    <div className="carousel-controls mb-4">
                        <button onClick={handlePrevious} className='navigation-control navigation-previous'><i className="ri-arrow-left-s-line"></i></button>
                        <button onClick={handleNext} className='navigation-control navigation-next'><i className="ri-arrow-right-s-line"></i></button>
                    </div> :
                    <div className="carousel-controls mb-4">
                        <button onClick={handlePrevious} className='navigation-control navigation-previous'><i className="ri-arrow-right-s-line"></i></button>
                        <button onClick={handleNext} className='navigation-control navigation-next'><i className="ri-arrow-left-s-line"></i></button>
                    </div>
                }
            </div>
            <div className='wrapper occasion-carousal'>
                <Carousel
                    ref={carouselRef}
                    responsive={responsivness}
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite={false}
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={!isEn}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable

                >
                    {
                        isLoading ?
                            [...Array(10)].map((item, key) => (
                                <div className="upcoming-occasion-spacer" key={key}>
                                    <div className="upcoming-occasion-container cursor-pointer">
                                        <Skeleton className="upcoming-occasion-loader"></Skeleton>
                                    </div>
                                </div>
                            )) :
                            [null,...occasions].map((occasion, key) => (
                                occasion ?
                                <div className="upcoming-occasion-spacer personal-occasion" key={key}>
                                    <div className="upcoming-occasion-container">
                                        <div className="upcoming-occasion-header cursor-pointer" onMouseDown={(e)=>{ xAxisStart.current = e.clientX }} onMouseUp={(e)=>itemClicked(e,"/account/occasion/"+occasion.Id)}>
                                            <div className="card-tag">{convertDateTimeWithLocale(moment(occasion.OccasionDate).format("MMM DD, YYYY")) }</div>
                                            <div className="upcoming-occasion-img text-center">
                                                <img src={occasion.ImageUrl ? occasion.ImageUrl : calendar} alt={occasion.Name} onError={(e) => { e.target.onerror = null; e.target.src = calendar }} draggable={false}/>
                                            </div>
                                        </div>
                                        <div className="upcoming-occasion-footer">
                                            <p className="text-secondary fw-500 cursor-pointer" onMouseDown={(e)=>{ xAxisStart.current = e.clientX }} onMouseUp={(e)=>itemClicked(e,"/account/occasion/"+occasion.Id)}>{occasion.Name}</p>
                                            <div className="bottom-content d-flex justify-between">
                                                <div className="occasion-name">
                                                    <span className="fs-14 fw-400 text-gray-400">{occasion.Type}</span>
                                                </div>
                                                <button className="link fs-14 fw-500" onMouseDown={(e)=>{ xAxisStart.current = e.clientX }} onMouseUp={(e)=>createUpdateClicked(e,occasion)}>{_T("Edit")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <div className="upcoming-occasion-spacer personal-occasion" key={key}>
                                    <div className="upcoming-occasion-container" onMouseDown={(e)=>{ xAxisStart.current = e.clientX }} onMouseUp={(e)=>createNewClicked(e)}>
                                        <div className="upcoming-occasion-header cursor-pointer">
                                            <div className="upcoming-occasion-img text-center">
                                                <img src={calendar} alt={"Create Occasion"} draggable={false}/>
                                            </div>
                                        </div>
                                        <div className="upcoming-occasion-footer d-flex align-items-center">
                                            <button className="btn btn-create" type="button"><i className="ri-add-line"></i> </button>
                                            <span className="fs-16 fw-500 text-secondary cursor-pointer">{_T("Create an Occasion")}</span>
                                        </div>
                                    </div>
                                </div>
                            ))
                    }
                </Carousel>
            </div>
            <Modal title={ editMode ? _T("Update Occasion") : _T("Add New Occasion")} isOpen={isOpen} onClose={() => { setIsOpen(false); resetForm(); }} className="occasion-modal">
                <div className="form-content">
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} minDate={new Date()} startDate={startDate} inline showDisabledMonthNavigation />
                </div>
                <div className="form-content mt-2">
                    <label className="fs-14" htmlFor="title">{_T("Occasion Title")}</label>
                    <input type="text" id="title" name="title" placeholder={_T("Occasion Title")} value={occasionTitle} onChange={(e) => setOccasionTitle(e.target.value)} />
                    {error.occasionTitle ? <p className="text-flushed fs-14 fw-400 mt-1 mb-1">{_T("Occasion Title cannot be empty!")}</p> : null}
                </div>
                <div className="form-content mt-2">
                    <label className="fs-14" htmlFor="type">{_T("Occasion Type")}</label>
                    <select name="occasionType" id="type" value={occasionType} onChange={(e) => { setOccasionType(e.target.value) }}>
                        <option disabled={true} value={""}>{_T("Select an Occasion Type")}</option>
                        {occasionTypesList.map((item, index) => {
                            return (<option key={index} value={item.Name}>{item.Name}</option>)
                        })}
                    </select>
                    {error.occasionType ? <p className="text-flushed fs-14 fw-400 mt-1 mb-1">{_T("Please Select an Occasion Type!")}</p> : null}
                </div>
                <div className="form-content mt-2">
                    <input id="yearlyReminder" className="input-checkbox" type="checkbox" checked={yearlyReminder} onChange={() => { setYearlyReminder(!yearlyReminder) }}></input>
                    <label htmlFor="yearlyReminder">{_T("Yearly Reminder")}</label>
                </div>
                <div className="btn-group mt-3">
                    <button className="btn btn-secondary w-100 mb-2" onClick={(e)=> { editMode ? validateOccasionUpdate(e) : validateOccasionSubmit(e)}}>{editMode ? _T("Update Occasion") : _T("Create Occasion")}</button>
                </div>
            </Modal>
        </section>
    )
}
export default React.memo(UpcomingOccasion)