import React,{useState , useEffect} from "react";
import currentOrderIcon from '../../assets/images/cardboard-box.svg'
import passOrderIcon from '../../assets/images/open-delivered-box.svg'
import cancelOrderIcon from '../../assets/images/do-not-tilt.svg'
import '../../assets/css/--page-subscription.css'
import { getSubscriptionList } from "../../helper/backend-methods";
import SubscriptionCard from "./subscription-card";
import Skeleton from "react-loading-skeleton";
import ErrorMsg from "../../components/ErrorMessage";
import { pixelPageEvent, scrollToTop } from "../../common";
import _T from "../../locale/subscription";

function AccountSubscription() {
    const [currentSubOrder, setCurrentSubOrder] = useState([])
    const [pastSubOrder, setPastSubOrder] = useState([])
    const [cancelSubOrder, setCancelSubOrder] = useState([])
    const [orderId, setOrderId] = useState("")
    const [loading, setLoading] = useState(true)
    const [currenLoading, setCurrentLoading] = useState(true)
    const [pastLoading, setPastLoading] = useState(true)
    const [cancelLoading, setCancelLoading] = useState(true)
    const [tab, setTab] = useState(1);

    useEffect(()=>{
        pixelPageEvent();
    },[])

    useEffect(() => {
        document.title = 'Subscription | Wardstation';
        scrollToTop();
        getSubscriptionList({ pageIndex: 0, pageSize: 10, statusFilter: "current" }).then(({ data }) => {
            if (data.status) {
                setCurrentSubOrder(data.result.Data) 
                setOrderId(data.result.Data.Id) 
            }
            setCurrentLoading(false)
        })
        getSubscriptionList({ pageIndex: 0, pageSize: 10, statusFilter: "past" }).then(({ data }) => {
            if (data.status) {
                setPastSubOrder(data.result.Data) 
                setOrderId(data.result.Data.Id) 
            }
            setPastLoading(false)
        })
        getSubscriptionList({ pageIndex: 0, pageSize: 10, statusFilter: "cancelled" }).then(({ data }) => {
            if (data.status) {
                setCancelSubOrder(data.result.Data) 
                setOrderId(data.result.Data.Id) 
            }
            setCancelLoading(false)
        })
    }, [])


    return (
        <section id="account-subscription">
            <div className="account-header">
                <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'>{_T("My Subscription")}</h1>  
            </div>
            <div className="bg-wrapper d-flex justify-between flex-wrap">
                <div className={`tab-item d-flex align-items-center cursor-pointer ${tab === 1 ? "active" : ""}`} onClick={() => { setTab(1) }}><img className='mr-1' src={currentOrderIcon} alt="" /> {_T("Current")}</div>
                <div className={`tab-item d-flex align-items-center cursor-pointer ${tab === 2 ? "active" : ""}`} onClick={() => { setTab(2) }}><img className='mr-1' src={passOrderIcon} alt="" /> {_T("Past")}</div>
                <div className={`tab-item d-flex align-items-center cursor-pointer ${tab === 3 ? "active" : ""}`} onClick={() => { setTab(3) }}><img className='mr-1' src={cancelOrderIcon} alt="" /> {_T("Cancelled")}</div>
            </div>
            {
            tab === 1 ?
                <section>       
                    {
                        currenLoading ?
                        [...Array(5)].map((item,key)=>{
                            return <Skeleton key={key} className='mt-3 w-100' height={"84px"}/>
                        }) :
                        currentSubOrder.length?
                        currentSubOrder.map((item,key)=>{
                            return <SubscriptionCard item={item} key={key} loading={loading} />
                        }): <ErrorMsg text={_T("As of now, there are no pending subscriptions.")}/>
                    }
                </section> : null
            }
            {
                tab === 2 ?
                <section>       
                    {
                        pastLoading ?
                        [...Array(5)].map((item,key)=>{
                            return <Skeleton key={key} className='mt-3 w-100' height={"84px"}></Skeleton>
                        }) :
                        pastSubOrder.length?
                        pastSubOrder.map((item,key)=>{
                            return <SubscriptionCard item={item} key={key} loading={loading} />
                        }):<ErrorMsg text={_T("As of now, there are no past subscriptions.")}/>
                    }
                </section> : null
            }
            {
            tab === 3 ?
                <section>       
                    {
                        cancelLoading ?
                        [...Array(5)].map((item,key)=>{
                            return <Skeleton key={key} className='mt-3 w-100' height={"84px"}></Skeleton>
                        }) :
                        cancelSubOrder.length?
                        cancelSubOrder.map((item,key)=>{
                            return <SubscriptionCard item={item} key={key} loading={loading} />
                        }):<ErrorMsg text={_T("As of now, there are no cancelled subscriptions.")}/>
                    }
                </section> : null
            }
        </section>
    )
}

export default AccountSubscription