import React, { useEffect, useState } from "react";
import OccasionCard from "./occasion-card";
import '../../assets/css/--component-account-occasion.css'
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import Modal from "../../components/Modal";
import { addUpcomingOccasion, getOccasionTypes, getUpcomingOccasion } from "../../helper/backend-methods";
import Skeleton from "react-loading-skeleton";
import ErrorMsg from "../../components/ErrorMessage";
import { googleTagInitialize, pixelPageEvent, scrollToTop } from "../../common";
import { Toaster } from "../../components/Toaster";
import _T from "../../locale/occasion";
function Occasion() {
   const [startDate, setStartDate] = useState(new Date());
   const [isOpen, setIsOpen] = useState(false);
   const [upcomingOccasionList, setUpcomingOccasionList] = useState([])
   const [loading, setLoading] = useState(true)
   const [occasionTitle, setOccasionTitle] = useState("")
   const [occasionType, setOccasionType] = useState("")
   const [error, setError] = useState({})
   const [update, setUpdate] = useState(0)
   const [yearlyReminder, setYearlyReminder] = useState(false);
   const [occasionTypesList, setOccasionTypesList] = useState([])


   useEffect(()=>{
      pixelPageEvent();
      googleTagInitialize();
  },[])

   useEffect(() => {
      getUpcomingOccasion().then(({ data }) => {
         if (data.status) {
            setUpcomingOccasionList(data.result)
            setLoading(false)
         }
      })
   }, [update])

   useEffect(() => {
      document.title = 'Occasions | Wardstation';
      scrollToTop();
      getOccasionTypes().then(({ data }) => {
         if (data.status) {
            setOccasionTypesList(data.result)
         }
      }).catch((e) => {
         console.log(e)
      })
   }, [])


   const togglePopup = () => {
      setIsOpen(!isOpen);
   };

   const validateOccasionSubmit = (e) => {
      e.preventDefault();
      const error = { occasionType: false, occasionTitle: false, };
      let flag = false;
      if (occasionType === "") {
         flag = true;
         error.occasionType = true
      }
      if (occasionTitle === "") {
         flag = true;
         error.occasionTitle = true
      }
      setError(error);
      if (!flag) {
         addUpcomingOccasion({ Name: occasionTitle, Type: occasionType, OccasionDate: startDate, YearlyReminder: yearlyReminder }).then(({ data }) => {
            if (data.status) {
               setUpdate(update + 1)
               setOccasionTitle("")
               setOccasionType("")
               setIsOpen(false)
               setYearlyReminder(false)
               Toaster("success", _T("Occasion Created"), _T("Your Occasion has been created!"))
            }
            else {
               Toaster("error",_T("Request Failed"),_T("Your Occasion could not be created!"))
            }
         }).catch((e) => {
            Toaster("error",_T("Request Failed"),_T("Your Occasion could not be created!"))
         });
      }
   };

   return (
      <section id="occasion-section">
         <div className='account-header d-flex align-items-center justify-between'>
            <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'>{_T("My Occasions")}</h1>
            <button className="btn btn-primary" onClick={togglePopup}>{_T("Add Occasion")}</button>
         </div>
         {
            loading ?
               <div className="upcoming-occasions">
                  {
                     [...Array(6)].map((item, index) => {
                        return <Skeleton key={index} className='mt-3 w-100' height={"300px"} />
                     })
                  }
               </div> :
               upcomingOccasionList.length ?
                  <div className="upcoming-occasions">
                     {
                        upcomingOccasionList.map((item, key) => {
                           return <OccasionCard key={key} Id={item.Id} onUpdate={() => { setUpdate(update + 1) }} occasionName={item.Name} occasionImage={item.ImageUrl} occasionDate={item.OccasionDate} occasionType={item.Type} Reminder={item.YearlyReminder} occasionTypesList={occasionTypesList} />
                        })
                     }
                  </div> : <ErrorMsg text={_T("You haven't created any occasions yet.")} />
         }
         <Modal title={_T("Add New Occasion")} isOpen={isOpen} onClose={() => { setIsOpen(false); }} className="occasion-modal">
            <div className="form-content">
               <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} minDate={new Date()} startDate={startDate} inline showDisabledMonthNavigation />
            </div>
            <div className="form-content mt-2">
               <label className="fs-14" htmlFor="title">{_T("Occasion Title")}</label>
               <input type="text" id="title" name="title" placeholder={_T("Occasion Title")} value={occasionTitle} onChange={(e) => setOccasionTitle(e.target.value)} />
               {error.occasionTitle ? <p className="text-flushed fs-14 fw-400 mt-1 mb-1">{_T("Occasion Title cannot be empty!")}</p> : null}
            </div>
            <div className="form-content mt-2">
               <label className="fs-14" htmlFor="type">{_T("Occasion Type")}</label>
               <select name="occasionType" id="type" value={occasionType} onChange={(e) => { setOccasionType(e.target.value) }}>
                  <option disabled={true} value={""}>{_T("Select an Occasion Type")}</option>
                  {occasionTypesList.map((item, index) => {
                     return (<option key={index} value={item.Name}>{item.Name}</option>)
                  })}
               </select>
               {error.occasionType ? <p className="text-flushed fs-14 fw-400 mt-1 mb-1">{_T("Please Select an Occasion Type!")}</p> : null}
            </div>
            <div className="form-content mt-2">
               <input id="yearlyReminder" className="input-checkbox" type="checkbox" checked={yearlyReminder} onChange={() => { setYearlyReminder(!yearlyReminder) }}></input>
               <label htmlFor="yearlyReminder">{_T("Yearly Reminder")}</label>
            </div>
            <div className="btn-group mt-3">
               <button className="btn btn-secondary w-100 mb-2" onClick={validateOccasionSubmit}>{_T("Create")}</button>
            </div>
         </Modal>
      </section>
   )
}
export default Occasion