function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "Go Home": {
            "en": "Go Home",
            "ar": "اذهب للصفحة الرئيسية"
        },
        "Sign-In": {
            "en": "Sign-In",
            "ar": "تسجيل الدخول"
        },
        "Please use your email address and password to login to your account": {
            "en": "Please use your email address and password to login to your account",
            "ar": "يرجى استخدام عنوان بريدك الإلكتروني وكلمة المرور لتسجيل الدخول إلى حسابك"
        },
        "Email": {
            "en": "Email",
            "ar": "بريد إلكتروني"
        },
        "Password": {
            "en": "Password",
            "ar": "كلمة المرور"
        },
        "Enter password": {
            "en": "Enter password",
            "ar": "أدخل كلمة المرور"
        },
        "Forgot your password?": {
            "en": "Forgot your password?",
            "ar": "نسيت كلمة السر؟"
        },
        "Login": {
            "en": "Login",
            "ar": "تسجيل الدخول"
        },
        "Or continue with": {
            "en": "Or continue with",
            "ar": "أو متابعة"
        },
        "Not registered yet?": {
            "en": "Not registered yet?",
            "ar": "لم يتم التسجيل بعد؟"
        },
        "Create an account": {
            "en": "Create an account",
            "ar": "إنشاء حساب"
        },
        "Referral Code": {
            "en": "Referral Code",
            "ar": "رمز الدعوة"
        },
        "If you have a referral code enter here and press on apply button or you can tap on skip button to proceed without referral code.": {
            "en": "If you have a referral code enter here and press on apply button or you can tap on skip button to proceed without referral code.",
            "ar": "إذا كان لديك رمز دعوة ، أدخل هنا واضغط على تطبيق أو يمكنك النقر فوق ' تخطي '  للمتابعة بدون رمز الدعوة."
        },
        "Enter a Referral Code": {
            "en": "Enter a Referral Code",
            "ar": "ادخل رمز الدعوة"
        },
        "Applied": {
            "en": "Applied",
            "ar": "تم"
        },
        "Apply": {
            "en": "Apply",
            "ar": "تطبيق"
        },
        "Confirm": {
            "en": "Confirm",
            "ar": "تأكيد"
        },
        "Skip": {
            "en": "Skip",
            "ar": "تخطي"
        },
        "Don't have a referral code?": {
            "en": "Don't have a referral code?",
            "ar": "ليس لديك رمز دعوة؟"
        },
        "Please provide a valid referral code": {
            "en": "Please provide a valid referral code",
            "ar": "يرجى تقديم رمز دعوة صالح"
        },
        "Failed to login using google": {
            "en": "Failed to login using google",
            "ar": "فشل تسجيل الدخول باستخدام Google"
        },
        "Account email or password is invalid.": {
            "en": "Account email or password is invalid.",
            "ar": "البريد الإلكتروني أو كلمة المرور غيرصحيحة."
        },
        "Please provide your account password": {
            "en": "Please provide your account password",
            "ar": "يرجى ادخال كلمة المرور"
        },
        "Please provide a valid email address": {
            "en": "Please provide a valid email address",
            "ar": "يرجى تقديم عنوان بريد إلكتروني صالح"
        },
        "Sign-in": {
            "en": "Sign-in",
            "ar": "تسجيل الدخول"
        },
        "Wardstation": {
            "en": "Wardstation",
            "ar": "ورد ستيشن"
        },
        "Sign-up": {
            "en": "Sign-up",
            "ar": "التسجيل"
        },
        "Provide your first name": {
            "en": "Provide your first name",
            "ar": "كتابة الاسم الأول"
        },
        "Provide valid first Name": {
            "en": "Provide valid first Name",
            "ar": "كتابة الاسم الأخير"
        },
        "Provide your last name": {
            "en": "Provide your last name",
            "ar": "قدم اسمك الأخير"
        },
        "Provide valid last name": {
            "en": "Provide valid last name",
            "ar": "كتابة اسم أخير صالح"
        },
        "Provide valid email adress": {
            "en": "Provide valid email address",
            "ar": "كتابة بريد إلكتروني صالح"
        },
        "Provide a valid phone number": {
            "en": "Provide a valid phone number",
            "ar": "كتابة رقم جوال صالح"
        },
        "Provide your valid password": {
            "en": "Provide your valid password",
            "ar": "كتابة كلمة المرور الصحيحة"
        },
        "Password must have minimum 6 characters": {
            "en": "Password must have minimum 6 characters",
            "ar": "يجب أن تحتوي كلمة المرور على أقل من 6 أحرف"
        },
        "An account is already registered with this email.": {
            "en": "An account is already registered with this email.",
            "ar": "تم تسجيل حساب مسبقا بهذا البريد الإلكتروني."
        },
        "An account is already registered with this phone.": {
            "en": "An account is already registered with this phone.",
            "ar": "تم تسجيل حساب مسبقا بهذا الرقم."
        },
        "An account is already registered.": {
            "en": "An account is already registered.",
            "ar": "حساب مسجل بالفعل."
        },
        "Please provide account email address to reset your password.": {
            "en": "Please provide account email address to reset your password.",
            "ar": "يرجى تقديم عنوان بريد إلكتروني لإعادة تعيين كلمة المرور الخاصة بك."
        },
        "Email Address": {
            "en": "Email Address",
            "ar": "عنوان البريد الإلكتروني"
        },
        "An email has been sent containing a link to reset your password": {
            "en": "An email has been sent containing a link to reset your password",
            "ar": "تم إرسال بريد إلكتروني يحتوي على رابط لإعادة تعيين كلمة المرور الخاصة بك"
        },
        "Continue": {
            "en": "Continue",
            "ar": "اكمل"
        },
        "Remembered your password?": {
            "en": "Remembered your password?",
            "ar": "تذكرت كلمة المرور الخاصة بك؟"
        },
        "Reset Password": {
            "en": "Reset Password",
            "ar": "إعادة تعيين كلمة المرور"
        },
        "Please enter your new password to continue using it.": {
            "en": "Please enter your new password to continue using it.",
            "ar": "الرجاء إدخال كلمة المرور الجديدة لمتابعة استخدامها."
        },
        "New Password": {
            "en": "New Password",
            "ar": "كلمة المرور الجديدة"
        },
        "Confirm Password": {
            "en": "Confirm Password",
            "ar": "تأكيد كلمة المرور"
        },
        "Repeat New Password": {
            "en": "Repeat New Password",
            "ar": "أعد كلمة المرور الجديدة"
        },

        "Create Account": {
            "en": "Create Account",
            "ar": "إنشاء حساب"
        },
        "Please provide the following details to create your account.": {
            "en": "Please provide the following details to create your account.",
            "ar": "يرجى تقديم التفاصيل التالية لإنشاء حسابك."
        },
        "First Name": {
            "en": "First Name",
            "ar": "الاسم الأول"
        },
        "Last Name": {
            "en": "Last Name",
            "ar": "اسم العائلة"
        },
        "Phone Number": {
            "en": "Phone Number",
            "ar": "رقم الجوال"
        },
        "Already have an account?": {
            "en": "Already have an account?",
            "ar": "هل لديك حساب؟"
        },
        "Forgot Password": {
            "en": "Forgot Password",
            "ar": "هل نسيت كلمة المرور"
        },
        "Provide a valid new password": {
            "en": "Provide a valid new password",
            "ar": "تقديم كلمة مرور جديدة صالحة"
        },
        "Passwords do not match": {
            "en": "Passwords do not match",
            "ar": "كلمة المرور غير مطابقة"
        },
        "Provide a valid password": {
            "en": "Provide a valid password",
            "ar": "تقديم كلمة مرور صالحة"
        },
        "We could not update your password at this moment. Please try again later!": {
            "en": "We could not update your password at this moment. Please try again later!",
            "ar": "لم نتمكن من تحديث كلمة المرور الخاصة بك في هذه اللحظة. الرجاء معاودة المحاولة في وقت لاحق!"
        },
        "Link Expired": {
            "en": "Link Expired",
            "ar": "انتهت صلاحية الرابط"
        },
        "Your reset password link may have expired or has already been used. Please request a new reset link by going to the": {
            "en": "Your reset password link may have expired or has already been used. Please request a new reset link by going to the",
            "ar": "انتهت صلاحية رابط كلمة مرور أو تم استخدامه بالفعل. يرجى طلب رابط إعادة تعيين كلمة مرور بالانتقال إلى"
        },
        "page.": {
            "en": "page.",
            "ar": "صفحة."
        },
        "Your password has been updated. Please sign-in to continue.": {
            "en": "Your password has been updated. Please sign-in to continue.",
            "ar": "لقد تم تحديث كلمة المرور الخاصة بك. من فضلك سجل دخولك للمتابعة."
        },
        "Enter phone number": {
            "en": "Enter phone number",
            "ar": "ادخل رقم الجوال"
        },
        "No account has been registered under this email.": {
            "en": "No account has been registered under this email.",
            "ar": "لم يتم تسجيل أي حساب بهذا البريد الإلكتروني."
        },
        "Reset Password ": {
            "en": "Reset Password ",
            "ar": "إعادة تعيين كلمة المرور"
        },
        "Sign In to Your Account | Wardstation": {
            "en": "Sign In to Your Account | Wardstation",
            "ar": "مراجعة سلتك | ورد ستيشن"
        },
        "account login, sign in, login, user login, login to account, sign into account": {
            "en": "account login, sign in, login, user login, login to account, sign into account",
            "ar": "سلة التسوق, إدارة السلة, مراجعة السلة, عناصر السلة, إضافة إلى السلة, إزالة من السلة, الخروج"
        },
        "Sign in to your Wardstation account for convenient access to order history, tracking, and personalized recommendations. Fast delivery available across KSA. Order now!": {
            "en": "Sign in to your Wardstation account for convenient access to order history, tracking, and personalized recommendations. Fast delivery available across KSA. Order now!",
            "ar": "قم بمراجعة وإدارة العناصر المحددة في السلة على ورد ستيشن. أضف أو احذف المنتجات ، وانتقل إلى الخروج بسهولة. يتوفر التوصيل السريع في جميع أنحاء المملكة العربية السعودية. اطلب الآن!"
        },
        "Create a new account at Wardstation to enjoy faster checkout, order tracking, and personalized recommendations. Fast delivery available across KSA. Order now!": {
            "en": "Create a new account at Wardstation to enjoy faster checkout, order tracking, and personalized recommendations. Fast delivery available across KSA. Order now!",
            "ar": "قم بتسجيل الدخول إلى حسابك في ورد ستيشن للوصول المريح إلى تاريخ الطلبات وتتبعها والتوصيات المخصصة. يتوفر التوصيل السريع في جميع أنحاء المملكة العربية السعودية. اطلب الآن!"
        },
        "account register, register, create account, sign up, user register, register account": {
            "en": "account register, register, create account, sign up, user register, register account",
            "ar": "تسجيل الدخول للحساب, تسجيل الدخول, تسجيل الدخول, تسجيل الدخول للمستخدم, تسجيل الدخول إلى الحساب, تسجيل الدخول إلى الحساب"
        },
        "Create Your Account | Wardstation": {
            "en": "Create Your Account | Wardstation",
            "ar": "تسجيل الدخول إلى حسابك | ورد ستيشن"
        },
    }
    return translations[text] ? translations[text][locale] : text;
}
export default _T;