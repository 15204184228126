import React from "react"
import _T from "../../locale/transaction-history"

function TransactionHistoryCard(props) {
    const { orderId, orderDate, orderAmount, orderPayment } = props
    return (
        <div className="transaction-history-card">
            <div className="d-flex justify-between history-card-container flex-wrap ">
                <div className="transaction-id">
                    <h2 className="fs-16 text-secondary fw-400">{_T("Order Id")}</h2>
                    <div className="d-flex align-items-center">
                        <p className="fs-16 text-primary fw-500">{orderId}</p>
                    </div>
                </div>
                <div className="transaction-date">
                    <h2 className="fs-16 text-secondary fw-400">{_T("Date")}</h2>
                    <p className="fs-16 text-primary fw-500">{orderDate}</p>
                </div>
                <div className="transaction-amount">
                    <h2 className="fs-16 text-secondary fw-400">{_T("Amount")}</h2>
                    <p className="fs-16 text-primary fw-500 text-en">SAR {orderAmount}</p>
                </div>
                <div className="transaction-payment">
                    <h2 className="fs-16 text-secondary fw-400">{_T("Payment")}</h2>
                    <p className="fs-16 text-primary fw-500 text-capitalize">{_T(orderPayment)}</p>
                </div>
            </div>
        </div>
    )
}
export default TransactionHistoryCard