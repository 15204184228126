import React, { useEffect, useState } from "react";
import "../../assets/css/--component-account-personal-info.css";
import '../../assets/css/--component-account-change-password.css'
import { changePassword } from "../../helper/backend-methods";
import { deleteAccountPermanently, getProfileInfo, updateProfileInfo } from "../../helper/backend-methods";
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import Skeleton from "react-loading-skeleton";
import infoIcon from "../../assets/images/info-icon.png"
import profileImg from '../../assets/images/Profile.png'
import { Toaster } from "../../components/Toaster";
import _T from "../../locale/profile";
import Modal from "../../components/Modal";
import ProcessLoader from "../../components/ProcessLoader";
import { setCurrentUser } from "../../common";
import 'react-phone-number-input/style.css'
function PersonalInfoCard() {
    const [isEditable, setIsEditable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [profileInfo, setProfileInfo] = useState("");
    const [profileInfoLoading, setProfileInfoLoading] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState('');
    const [registerNbr , setRegisterNbr] = useState('')
    const [update, setUpdate] = useState(0)
    const [deleteModal, setDeleteModal] = useState(false)
    const [showLoading, setShowLoading] = useState(false)
    const [phoneFocused, setPhoneFocused] = useState(false)
    const [isSocialAccount, setIsSocialAccount] = useState(true)
    const [showPasswordModal, setShowPaswordModal] = useState(false)
    const [ currentPassword, setCurrentPassword] = useState('')
    const [ newPassword, setNewPassword] = useState('')
    const [ btnLoading, setBtnLoading] = useState(false)
    const [ passwordError, setPasswordError] = useState({currentPassword: "", newPassword: ""})

    useEffect(() => {
        setProfileInfoLoading(true);
        getProfileInfo().then(({ data }) => {
            if (data.status) {
                setProfileInfo(data.result);
                if (data.result.FirstName != null) {
                    let temp = data.result.FirstName
                    if(data.result.LastName && data.result.LastName.length){
                        temp = temp + " "+data.result.LastName;
                    }
                    setFirstName(temp);
                    setCurrentUser({FullName: data.result.FirstName+" "+data.result.LastName, email: data.result.Email, phone: data.result.Phone})
                    setIsSocialAccount(data.result.IsSocialLogin)
                }
                if (data.result.Email != null) {
                    setEmail(data.result.Email);
                }
                if (data.result.Phone != null) {
                    setPhoneNo(data.result.Phone);
                }
                setProfileInfoLoading(false);
            }
        }).catch((e)=>{
            console.log(e)
        });
    }, [update]);

    const validateUpdate = (e) => {
        e.preventDefault();
        const error = { FirstName: false, Phone: false,  };
        let flag = false;
        // var regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
        if (!firstName || firstName.replaceAll(/\s/g, '').length < 1 ) {
            flag = true;
            error.FirstName = true;
        }
        if (!isValidPhoneNumber(phoneNo)) {
            flag = true;
            error.Phone = true;
        }
        setError(error);
        if (!flag) {
            setLoading(true)
            updateProfileInfo({ FirstName: firstName.split(" ")[0], LastName: firstName.split(" ")[1] ? firstName.split(" ")[1] : "", Phone: phoneNo, Gender: null, Email: email }).then(({ data }) => {
                if (data.status) {
                    setIsEditable(false)
                    setProfileInfo((prev)=>{ return {...prev, FirstName: firstName, Phone: phoneNo} })
                    setCurrentUser({FullName: firstName, email: email, phone: phoneNo})
                    Toaster("success",_T("Profile Updated"),_T("Your profile information has been updated!"))
                    setLoading(false)
                    setUpdate((prev)=>{ return prev + 1})
                } else {
                    setRegisterNbr(data.response.ResponseMessage)
                    Toaster("error",_T("Update Failed"),_T("Your profile information could not be updated!"))
                    setLoading(false)
                }
            }).catch((e) => {
                Toaster("error",_T("Update Failed"),_T("Your profile information could not be updated!"))
                setLoading(false)
            });
        }
    };
    
    const validateSubmit = (e)=>{
        e.preventDefault();
        let flag = false
        const errors = {currentPassword: "", newPassword: ""}
        if(!currentPassword || currentPassword.replaceAll(/\s/g, '').length < 6  ){
            flag = true
            errors.currentPassword=_T("Password must have minimum 6 characters")
        }
        if(!newPassword || newPassword.replaceAll(/\s/g, '').length < 6  ){
            flag = true
            errors.newPassword=_T("Password must have minimum 6 characters")
        }

        let userEmail = null
        try {
            userEmail = JSON.parse(localStorage.getItem('userInfo')).email
        } catch (error) {
            flag = true
            Toaster("error", _T("Request Failed"), _T("Your password could not be updated!"))
        }
        setPasswordError(errors)
        if(!flag){
            setBtnLoading(true)
            changePassword({Email: userEmail, OldPassword: currentPassword, NewPassword: newPassword, ConfirmNewPassword: newPassword}).then(({data})=>{
                if(data.status){
                    setBtnLoading(false)
                    Toaster("success", _T("Password Updated"), _T("Your account password has been updated!"))
                    setShowPaswordModal(false)
                    reset();
                }else{
                    if(data.response.ResponseCode === 1609){
                        Toaster("error", _T("Update Failed"), _T("Your new password can not be same as your old password!"))
                    }
                    if(data.response.ResponseCode === 1700){
                        Toaster("error", _T("Update Failed"), _T("Your old password was incorrect!"))
                    }
                    else{
                        Toaster("error", _T("Update Failed"), _T("Your password could not be updated!"))
                    }
                    setBtnLoading(false)
                    setShowPaswordModal(false)
                    reset();
                }
            }).catch(()=>{
                Toaster("error", _T("Update Failed"), _T("Your password could not be updated!"))
                setShowPaswordModal(false)
                setBtnLoading(false);
                reset();
            })
        }
    }
    const reset = () => {
        setError({currentPassword: "", newPassword: ""})
        setBtnLoading(false);
        setCurrentPassword("");
        setNewPassword("");
    }
    const resetValues = () =>{
        setError("")
        setPhoneNo(profileInfo.Phone)
        setFirstName(profileInfo.FirstName)
        setRegisterNbr("")
    }

    const onDeleteAccount = () => {
        deleteAccountPermanently().then(({data})=>{
            if(data.status){
                localStorage.removeItem("authToken")
                localStorage.removeItem("userInfo")
                window.location = "/"
            }
            else{
                Toaster("error",_T("Request Failed"),_T("Your account could not be deleted"))
            }
        }).catch((e)=>{
            Toaster("error",_T("Request Failed"),_T("Your account could not be deleted"))
        })
    }

    return (
        <>
            <ProcessLoader isLoading={showLoading}/>
            <section className="profile-section">
                {
                    !profileInfoLoading ?
                    <>
                        <div className="profile-top-section">
                            <div className="user-top-info d-flex align-items-center">
                                <label htmlFor="profilePic">
                                    <img src={profileImg} className="profile-avatar" alt="" />
                                    <input className="d-none" type={"file"} id="profilePicture" />
                                </label>
                                <div className="user-name ml-3">
                                    <p className="fs-18 fw-400 text-primary m-0">{_T("Welcome,")}</p>
                                    <h2 className="fs-24 fw-500 m-0 text-gray-100 text-capitalize word-wrap">{profileInfo.FirstName} {profileInfo.LastName}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="profile-bottom-section">
                        {
                            isEditable ?
                            <form>
                                <div className="inputfields-container d-flex justify-between flex-wrap mb-4">
                                    <div className="form-content mb-3 ">
                                        <label htmlFor="firstname" className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block">{_T("Full Name")}<span className="text-primary">*</span></label>
                                        <input id="firstname" type="text" value={firstName} placeholder={_T("Enter your full name")} onChange={(e) => setFirstName(e.target.value.replace(/[^a-zA-Z\s]/g, '').slice(0,50))}/>
                                        { error.FirstName ? <p className="text-flushed fs-14 fw-400 mt-1 mb-1">{_T("Please provide your full name")}</p> : null }
                                    </div>
                                    <div className="form-content mb-3">
                                        <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block">{_T("Email")}</label>
                                        <input readOnly={true} id="email" type="email" defaultValue={email} placeholder="example@wardstation.com"/>
                                    </div>
                                    <div className={`mb-3 form-content`}>
                                        <label htmlFor="number" className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block">{_T("Phone No")}</label>
                                        {/* <input id="number" className="dir-escape text-left"  type="text" placeholder={_T("Enter phone number")} value={phoneNo} onChange={handleChange}/> */}
                                        <PhoneInput className="dir-escape text-left" id="number" placeholder="Enter phone number" international defaultCountry="SA" onFocus={()=>{ setPhoneFocused(true) }} onBlur={()=>{ setPhoneFocused(false) }} value={phoneNo} onChange={(e)=>{setPhoneNo(e); }}/>
                                        {
                                            
                                            error.Phone ? <p className="text-flushed fs-14 fw-400 mt-1 mb-1">{_T("Please provide a valid phone number")}</p> : registerNbr.length?<p className="text-flushed fs-14 fw-400 mt-1 mb-1">{_T(registerNbr)}</p> :null
                                        }
                                    </div>
                                </div>
                                <div className="save-cancel d-flex pb-0">
                                    <button type="button" className="cancel-btn btn btn-outline-primary fs-18 mr-2" onClick={() => { setIsEditable(false); resetValues()}}>{_T("Cancel")}</button>
                                    <button className="save-btn btn btn-primary fs-18" type={"button"} disabled={loading} onClick={validateUpdate}>{ loading ? _T("Please Wait!") : _T("Update") }</button>
                                </div>
                            </form> :
                            <div className="d-flex flex-wrap justify-between read-only">
                                <div className="form-content mb-3 ">
                                    <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block">{_T("Full Name")}</label>
                                    <p className="fw-400 text-gray-400 m-0 word-wrap text-overflow-elipse">{profileInfo.FirstName} {profileInfo.LastName}</p>
                                </div>
                                <div className="form-content mb-3 ">
                                    <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block">{_T("Email")}</label>
                                    <p className="fw-400 text-gray-400 m-0 word-wrap text-overflow-elipse">{profileInfo.Email}</p>
                                </div>
                                <div className="form-content mb-3 ">
                                    <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block">{_T("Phone No")}</label>
                                    <p className="fw-400 text-gray-400 m-0 word-wrap dir-escape text-left text-overflow-elipse">{profileInfo.Phone}</p>
                                </div>
                                <div className="profile-footer w-100 mt-4 d-flex align-items-center justify-between">
                                    <button type="button" className="btn btn-primary right" onClick={() => { setIsEditable(true); }}>{_T("Edit Profile")}</button>
                                    {
                                        !isSocialAccount ?
                                        <button className="link fs-14 fw-400 d-flex align-items-center" onClick={()=>{ setShowPaswordModal(true) }}><i className="ri-lock-password-line mr-2 fs-18"></i> {_T("Change Password")}</button> : null
                                    }
                                </div>
                            </div>
                        }
                        

                        <Modal title={_T("Change Password")} isOpen={showPasswordModal} onClose={()=>{ setShowPaswordModal(false); reset() }}>
                            <form onSubmit={validateSubmit}>
                                <div className="bg-wrapper d-flex justify-between flex-wrap">
                                    <div className="password-form-content form-group w-100">
                                        <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block" htmlFor="currentPassword">{_T("Current Password")} <span className="text-flushed">*</span></label>
                                        <input type="text" value={currentPassword} onChange={(e)=>{setCurrentPassword(e.target.value.replaceAll(/\s/g, ''))}} id="currentPassword" name="type" placeholder={_T("Current Password")} />
                                        {passwordError.currentPassword? <p className="text-flushed fs-12 m-0">{passwordError.currentPassword}</p>:<></>}
                                    </div>
                                    <div className="password-form-content form-group w-100">
                                        <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block" htmlFor="newPassword">{_T("New Password")} <span className="text-flushed">*</span></label>
                                        <input type="text" value={newPassword} onChange={(e)=>{setNewPassword(e.target.value.replaceAll(/\s/g, ''))}} id="newPassword" name="type" placeholder={_T("New Password")} />
                                        {passwordError.newPassword? <p className="text-flushed fs-12 m-0">{passwordError.newPassword}</p>:<></>}
                                    </div>
                                </div>
                                <div className="save-cancel d-flex pb-0">
                                    <button type="button" className="cancel-btn btn btn-outline-primary fs-18 mr-2" onClick={() => { setShowPaswordModal(false); reset()}}>{_T("Cancel")}</button>
                                    <button className="btn btn-primary save-btn" disabled={btnLoading} type="submit">{_T("Save Changes")}</button>
                                </div>
                            </form>
                        </Modal>
                        </div>
                    </> :
                    <>
                        <div className="profile-top-section">
                            <div className="user-top-info d-flex align-items-center">
                                <label htmlFor="profilePic"><Skeleton height="150px" width="150px" circle={true} style={{ maxWidth: "399px", width: "100%" }}/></label>
                                <div className="user-name ml-3">
                                    <Skeleton height="27px" width="150px" />
                                    <Skeleton height="36px" width="200px" />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-between read-only pl-3 pt-3">
                            <div className="form-content mb-3 ">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block">{_T("Full Name")}</label>
                                <p className="fw-400 text-gray-400 m-0 word-wrap"><Skeleton height="27px" width="150px" className="mb-1" /></p>
                            </div>
                            <div className="form-content mb-3 ">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block">{_T("Email")}</label>
                                <p className="fw-400 text-gray-400 m-0 word-wrap"><Skeleton height="27px" width="150px" className="mb-1" /></p>
                            </div>
                            <div className="form-content mb-3 ">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block">{_T("Phone No")}</label>
                                <p className="fw-400 text-gray-400 m-0 word-wrap"><Skeleton height="27px" width="150px" className="mb-1" /></p>
                            </div>
                        </div>
                    </>
                }
            </section>
            {
                !profileInfoLoading && !loading && !isEditable ?
                <>
                    <div className="text-right mt-4">
                        <button className="btn btn-danger" onClick={()=>{ setDeleteModal(true) }} type="button">{_T("Delete Account Permanently")}</button>
                    </div>
                    <Modal className="cart-modal" isOpen={deleteModal} modalSize="small">
                        <div className="text-center">
                            <img src={infoIcon} alt="Cart Icon" className="mx-auto" width={40}/>
                            <p className="text-gray-400 fs-16 fw-400 text-center">{_T("Are you sure you want to delete your account permanently?")}</p>
                            <button className="btn btn-primary w-100 mb-2" type="button" onClick={()=>{ setShowLoading(true); onDeleteAccount(); setDeleteModal(false); }}>{_T("Yes, Delete")}</button>
                            <button className="btn btn-secondary w-100" type="button" onClick={()=>{ setDeleteModal(false) }}>{_T("Cancel")}</button>
                        </div>
                    </Modal>
                </> : null
            }
        </>
    );
}
export default PersonalInfoCard;
