import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import '../../assets/css/--page-auth.css'
import AuthBanner from '../../assets/images/login-image.png'
import TopFlower from '../../assets/images/top-flower (1).png'
import BottomFlower from '../../assets/images/bottom-flower .png'
import { forgotPassword } from "../../helper/backend-methods";
import { googleTagInitialize, pixelPageEvent, scrollToTop } from "../../common";
import _T from "../../locale/authentication";

function ForgotPassword() {
    const [useremail, setUseremail] = useState("")
    const [serverError, setServerError] = useState('');
    const [errors, setErrors] = useState({ useremail: "" })
    const [loading, setLoading] = useState(false);
    const [resetComplete, setResetComplete] = useState(false);

    useEffect(()=>{
        document.title = 'Forgot Password | Wardstation';
        scrollToTop();
    },[])

    useEffect(()=>{
        pixelPageEvent();
        googleTagInitialize();
    },[])

    const validateSubmit = (e) => {
        e.preventDefault();
        const errors = { useremail: "" };
        let flag = false;
        if (!useremail || useremail.replaceAll(/\s/g, '').length < 3) {
            errors.useremail = _T("Provide valid email adress");
            flag = true;
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(useremail)) {
            errors.useremail = _T("Provide valid email adress");
            flag = true;
        }
        setErrors(errors);
        if (!flag) {
            setLoading(true)
            setServerError('')
            forgotPassword({ email: useremail }).then(({ data }) => {
                if (data.status) {
                    setResetComplete(true);
                }
                else {
                    setServerError(_T("No account has been registered under this email."))
                    setLoading(false)
                }
            }).catch((e) => {
                setServerError(_T("No account has been registered under this email."))
                setLoading(false)
            })
        }
    }

    return (
        <div className="auth-container d-flex justify-center">
            <div className="auth-left">
                <img className="top-flower" src={TopFlower} alt="" />
                <img className="bottom-flower" src={BottomFlower} alt="" />
                <Link to={"/"} className="btn btn-outline-primary guest-btn"><i className="ri-home-4-line mr-1 fs-16"></i> {_T("Go Home")}</Link>
                <form onSubmit={validateSubmit}>
                    <div className="input-content">
                        <h1 className="fs-36 fw-600 text-secondary mt-0 mb-2">{_T("Forgot Password")}</h1>
                        <p className="fs-16 text-gray-300 fw-400 mt-0 mb-3">{_T("Please provide account email address to reset your password.")}</p>
                        <div className="form-group">
                            <label htmlFor="useremail">{_T("Email Address")}</label>
                            <input id="useremail" readOnly={resetComplete} type="email" name="useremail" onChange={(e) => { setUseremail(e.target.value.slice(0, 50)) }} value={useremail} placeholder="mail@example.com" />
                            {errors.useremail ? <span className="text-flushed fs-12 fw-400">{errors.useremail}</span> : null}
                        </div>
                        { serverError.length ? <p className="fs-14 fw-400 text-flushed text-center">{serverError}</p> : null }
                        { resetComplete ? <p className="text-flushed fs-14 fw-400 text-secondary text-left">{_T("An email has been sent containing a link to reset your password")}</p> : null }
                        <div className="auth-btn">
                            {
                                resetComplete ?
                                <Link to="/login" className="btn btn-secondary w-100">{_T("Sign-In")}</Link> :
                                <button type="submit" disabled={loading} className="btn btn-secondary w-100">{_T("Continue")}</button>
                            }
                        </div>
                        {
                            resetComplete ? null :
                            <div className="create-new-account text-center text-secondary">
                                <span className="text-gray-200">{_T("Remembered your password?")} <Link to={"/login"} className="link-secondary">{_T("Sign-In")}</Link></span>
                            </div>
                        }
                    </div>
                </form>
            </div>
            <div className="auth-right" style={{ backgroundImage: `url(${AuthBanner})`}}></div>
        </div>
    )
}

export default ForgotPassword