import ReactPixel from 'react-facebook-pixel';
export const getLocale = () => {
    let locale = localStorage.getItem("locale");
    const currentPath = window.location.pathname;
    if (currentPath.includes('/ar/')) {
        localStorage.setItem("locale", "ar");
        document.body.classList.add('RTL');
        return "ar";
    }
    if (currentPath.includes('/en/')) {
        localStorage.setItem("locale", "en");
        document.body.classList.remove('RTL');
        return "en";
    }

    if(locale && locale.length){
        if(locale === "ar"){
            locale = "ar"
            document.body.classList.add('RTL');
        }
        else if(locale === "en"){
            locale = "en"
            document.body.classList.remove('RTL');
        }
        else{
            locale = "en"
            localStorage.setItem("locale","en");
            document.body.classList.remove('RTL');
        }
    }
    else{
        locale = "en";
        localStorage.setItem("locale","en");
        document.body.classList.remove('RTL');
    }
    return locale;
}



export const convertDateTimeWithLocale = (dateString) => {
    let locale = getLocale()
    const monthsMapping = {
        'January': 'يناير',
        'February': 'فبراير',
        'March': 'مارس',
        'April': 'أبريل',
        'May': 'مايو',
        'June': 'يونيو',
        'July': 'يوليو',
        'August': 'أغسطس',
        'September': 'سبتمبر',
        'October': 'أكتوبر',
        'November': 'نوفمبر',
        'December': 'ديسمبر',
        'Jan': 'يناير',
        'Feb': 'فبراير',
        'Mar': 'مارس',
        'Apr': 'أبريل',
        'Jun': 'يونيو',
        'Jul': 'يوليو',
        'Aug': 'أغسطس',
        'Sep': 'سبتمبر',
        'Oct': 'أكتوبر',
        'Nov': 'نوفمبر',
        'Dec': 'ديسمبر'
    };
    if(locale === "ar"){
        const numberPattern = /\d+/g;
        const dateParts = dateString.split(' ');
        const convertedParts = dateParts.map(part => {
            if (monthsMapping[part]) {
                return monthsMapping[part];
            } else {
                return part.replace(numberPattern, function (match) {
                    return match
                        .split('')
                        .map(digit => String.fromCharCode(digit.charCodeAt(0) + 1584))
                        .join('');
                });
            }
        });
        return convertedParts.join(' ').replaceAll("th","").replaceAll("st","").replaceAll("rd","").replaceAll("nd","");
    }
    else{
        return dateString;
    }
}

export const isAuthenticated = () => {
    let token = localStorage.getItem("authToken");
    if(token && token.length > 10){
        return true;
    }
    return false;
}
export const getActiveOccasion = () => {
    let occasion = localStorage.getItem("activeOccasion");
    if(occasion && !isNaN(occasion)){
        return parseInt(occasion);
    }
    return false;
}
export const getActiveStore = () => {
    let store = localStorage.getItem("activeStore");
    if(store){
        try {
            return JSON.parse(store)
        } catch (error) {
            return null;
        }
    }
    return null;
}
export const updateActiveStore = (store) => {
    let storeObject = store;
    if(store){
        try {
            store = JSON.stringify(store);
            localStorage.setItem("activeStore",store);
            return store
        } catch (error) {
            return null;
        }
    }
    return storeObject;
}
export const getCurrentUser = () => {
    let userObject = localStorage.getItem("userInfo");
    if(userObject){
        try {
            return JSON.parse(userObject);
        } catch (error) {
            return null;
        }
    }
    return null;
}
export const setCurrentUser = (profile) => {
    const fullname = profile.FullName;
    const email = profile.email;
    const phone = profile.phone;
    localStorage.setItem("userInfo",JSON.stringify({ email: email, fullname: fullname, phone: phone }))
}

export const scrollToTop = (top=0) => {
    window.scrollTo({top: top, behavior: 'smooth'});
}

export const trackPermission = (type) => {
    const consent = localStorage.getItem("consent");
    if(type === "page"){
        try {
            return JSON.parse(consent).page;
        } catch (error) {
            return false;
        }
    }
    if(type === "tracking"){
        try {
            return JSON.parse(consent).tracking;
        } catch (error) {
            return false;
        }
    }
    if(type === "identity"){
        try {
            return JSON.parse(consent).identity;
        } catch (error) {
            return false;
        }
    }
}

export const pixelTrackEvent = (name,data) => {
    if(process.env.REACT_APP_ENABLE_FACEBOOK_PIXEL && process.env.REACT_APP_ENABLE_FACEBOOK_PIXEL === "YES"){
        ReactPixel.track(name, data);
    }
}
export const pixelPageEvent = () => {
    if(process.env.REACT_APP_ENABLE_FACEBOOK_PIXEL && process.env.REACT_APP_ENABLE_FACEBOOK_PIXEL === "YES"){
        ReactPixel.pageView();
    }
}
export const googleTagInitialize = () => {
    if(process.env.REACT_APP_ENABLE_GTAG && process.env.REACT_APP_ENABLE_GTAG === "YES"){
        if(window.gtag){
            window.gtag('config', `${process.env.REACT_APP_GTAG_ID}`);
        }
    }
}
export const formatPrice = (p) => {
    let price = p.replaceAll(",","");
    price = parseFloat(price);
    return price%1 === 0 ? price : price.toFixed(2)
}