import React from "react";
import '../assets/css/--component-profile-sidebar.css'
import { NavLink } from "react-router-dom";
import userImg from '../assets/images/Frame.svg'
import pinLocation from '../assets/images/Frame(1).svg'
import order from '../assets/images/Frame(2).svg'
import sub from '../assets/images/Frame(3).svg'
import occasion from '../assets/images/Frame(4).svg'
import payment from '../assets/images/Frame(5).svg'
import changePassword from '../assets/images/Frame(6).svg'
import logout from '../assets/images/Frame(7).svg'
import _T from "../locale/profile";


function ProfileSideBar(){
    return(
        <aside className="profile-siebar">
            <div className="sidebar-navigation">
                <NavLink to={"/account/profile"}><i className="ri-user-line"></i>{_T("Personal Information")}</NavLink>
                <NavLink to={"/account/addresses"}><i className="ri-map-pin-line"></i>{_T("My Address")}</NavLink>
                <NavLink to={"/account/orders"}><i className="ri-list-unordered"></i>{_T("My Orders")}</NavLink>
                <NavLink to={"/account/subscription"}> <i className="ri-star-line"></i>{_T("My Subscriptions")}</NavLink>
                <NavLink to={"/account/favourites"}> <i className="ri-heart-line"></i>{_T("Favourites")}</NavLink>
                <NavLink to={"/account/occasion"}><i className="ri-cake-2-line"></i>{_T("My Occasion")}</NavLink>
                <NavLink to={"/account/payment-method"}><i className="ri-bank-card-line"></i>{_T("Payment")}</NavLink>
                <NavLink to={"/account/transaction-history"}><i className="ri-file-chart-line"></i>{_T("Transaction History")}</NavLink>
                <NavLink to={"/account/logout"}><i className="ri-shut-down-line text-flushed"></i>{_T("Logout")}</NavLink>
            </div>
        </aside>
    )
}

export default ProfileSideBar