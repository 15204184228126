import axios from "axios"
import { v4 as uuidv4 } from 'uuid';
import { getActiveStore, getLocale } from "../common";
// import { analyticsIdentityEvent, analyticsTrackEvent } from "../components/Analytics";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_2 = process.env.REACT_APP_BASE_URL_2;

let LANG_URL = getLocale()+'/';

const API_URL = BASE_URL+LANG_URL;
const API_URL_2 = BASE_URL_2+LANG_URL;

var axiosApi = axios.create({baseURL: API_URL})
var axiosApi2 = axios.create({baseURL: API_URL_2})

axiosApi.interceptors.response.use(function (response) {
  const statusCode = response.data.response.ResponseCode;
  if(statusCode === 1500 || statusCode === 1500){
    localStorage.removeItem("authToken")
    localStorage.removeItem("guestToken")
    localStorage.removeItem("userInfo")
    window.location = "/"
    return response;
  }
  else{
    return response;
  }
}, function (error) {
  return Promise.reject(error);
});

const generateKey = () => {
  const key = localStorage.getItem("identifier")
  if(key && key.length){
    return "deviceId="+key;
  }
  else{
    const identifier = uuidv4()
    localStorage.setItem("identifier",identifier)
    localStorage.removeItem("cart");
    // analyticsIdentityEvent("Entered as Guest",{ screen_name: "Welcome", guest_id: identifier })
    return "deviceId="+identifier;
  }
}
export async function fetchUserToken(url) {
  const guestToken = localStorage.getItem("guestToken")
  if(!guestToken){
    return await axiosApi.get(url+"?"+generateKey());
  }
}


export async function apiWrapper(callback , url, data = {},config = {}){
  const accessToken = localStorage.getItem("authToken")
  const guestToken = localStorage.getItem("guestToken")
  if(accessToken && accessToken.length){
    return callback(url,data,config, "Bearer "+accessToken)
  }
  else if(guestToken && guestToken.length){
    return callback(url,data,config, "Bearer "+guestToken)
  }
  else{
    if(!guestToken){
      return fetchUserToken("api/auth/authToken").then((response)=>{
        localStorage.setItem("guestToken",response.data.result.Token.AccessToken)
        return callback(url,data,config, "Bearer "+response.data.result.Token.AccessToken); 
      })
    }
  }
}


export async function get(url,params = {}, config = {}, token) {
  const store = getActiveStore();
  axiosApi.defaults.headers.common["operationwarehouseid"] = store ? store.WarehouseId : 4;
  axiosApi.defaults.headers.common["Authorization"] = await token;
  axiosApi.defaults.headers.common["Platform"] = "Web";
  let p = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  return axiosApi.get(url+"?"+p, { ...config });
}

export async function getNew(url,params = {}, config = {}, token) {
  const store = getActiveStore();
  axiosApi2.defaults.headers.common["operationwarehouseid"] = store ? store.WarehouseId : 4;
  axiosApi2.defaults.headers.common["Authorization"] = await token;
  axiosApi2.defaults.headers.common["Platform"] = "Web";
  let p = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  return axiosApi2.get(url+"?"+p, { ...config });
}

export async function post(url, data = {}, config = {}, token) {
  const store = getActiveStore();
  axiosApi.defaults.headers.common["operationwarehouseid"] = store ? store.WarehouseId : 4;
  axiosApi.defaults.headers.common["Authorization"] = await token;
  axiosApi.defaults.headers.common["Platform"] = "Web";
  return axiosApi.post(url, { ...data }, { ...config });
}

export async function postNew(url, data = {}, config = {}, token) {
  const store = getActiveStore();
  axiosApi2.defaults.headers.common["operationwarehouseid"] = store ? store.WarehouseId : 4;
  axiosApi2.defaults.headers.common["Authorization"] = await token;
  axiosApi2.defaults.headers.common["Platform"] = "Web";
  return axiosApi2.post(url, { ...data }, { ...config });
}

export async function postForm(url, formData, config = {}, token) {
  const store = getActiveStore();
  axiosApi.defaults.headers.common["operationwarehouseid"] = store ? store.WarehouseId : 4;
  axiosApi.defaults.headers.common["Authorization"] = await token;
  axiosApi.defaults.headers.common["Platform"] = "Web";
  let configuration = { headers: { 'content-type': 'multipart/form-data' } }
  return axiosApi.post(url, formData, configuration);
}

export async function put(url, data, config = {}, token) {
  const store = getActiveStore();
  axiosApi.defaults.headers.common["operationwarehouseid"] = store ? store.WarehouseId : 4;
  axiosApi.defaults.headers.common["Authorization"] = await token;
  axiosApi.defaults.headers.common["Platform"] = "Web";
  return axiosApi.put(url, { ...data }, { ...config });
}

export async function putForm(url, formData, config = {}, token) {
  const store = getActiveStore();
  axiosApi.defaults.headers.common["operationwarehouseid"] = store ? store.WarehouseId : 4;
  axiosApi.defaults.headers.common["Authorization"] = await token;
  axiosApi.defaults.headers.common["Platform"] = "Web";
  let configuration = { headers: { 'content-type': 'multipart/form-data' } }
  return axiosApi.put(url, formData, configuration);
}

export async function del(url, data = {}, config = {}, token) {
  const store = getActiveStore();
  axiosApi.defaults.headers.common["operationwarehouseid"] = store ? store.WarehouseId : 4;
  axiosApi.defaults.headers.common["Authorization"] = await token;
  axiosApi.defaults.headers.common["Platform"] = "Web";
  return await axiosApi.delete(url, { data : { ...data } , ...config });
}
