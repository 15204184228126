function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "FAQs": {
            "en": "FAQs",
            "ar": "الاسئلة الشائعة"
        },
        "Information about WardStation": {
            "en": "Information about WardStation",
            "ar": "معلومات عن ورد ستيشن"
        },
        "What is WardStation?": {
            "en": "What is WardStation?",
            "ar": "ماهو ورد ستيشن؟"
        },
        "WardStation is an online flowers platform that sells flower arrangements and was established with a mission of 'Bringing your feelings to life'. It takes just few clicks from our platform to place an order have it delivered within a couple of hours.": {
            "en": "WardStation is an online flowers platform that sells flower arrangements and was established with a mission of 'Bringing your feelings to life'. It takes just few clicks from our platform to place an order have it delivered within a couple of hours.",
            "ar": "ورد ستيشن عبارة عن منصة أزهار الكترونية ، تم تأسيس المنصة تحت شعار ' مشاعرك توصل في لحظتها ' قد يستغرق الأمر بضع نقرات داخل المنصة لتقديم الطلب وسيتم تسليمه في غضون ساعات."
        },
        "What does WardStation do?": {
            "en": "What does WardStation do?",
            "ar": "ماذا يقدم ورد ستيشن؟"
        },
        "WardStation sources and designs the best flower arrangements and displays them over our platform for customers to choose from. Once a bouquet or the gifting is selected, WardStation prepares the arrangemetns and does the last mile delivery of it.": {
            "en": "WardStation sources and designs the best flower arrangements and displays them over our platform for customers to choose from. Once a bouquet or the gifting is selected, WardStation prepares the arrangemetns and does the last mile delivery of it.",
            "ar": "يوفر ورد ستيشن مصادر وتصاميم لأفضل تنسيقات الزهور وعرضها للعملاء للاختيار من بينها ، بمجرد تحديد المنتج سنقوم نحن في ورد ستيشن بإعداد الترتيبات اللازمة وتسليمها للعميل في الوقت المحدد."
        },
        "Payment / Contact": {
            "en": "Payment / Contact",
            "ar": "الدفع / الاتصال"
        },
        "How long does the online payment refund process take?": {
            "en": "How long does the online payment refund process take?",
            "ar": "كم من الوقت تستغرق عملية استرداد المدفوعات عبر الإنترنت؟"
        },
        "The duration of the refund process varies depending on the payment method you used.": {
            "en": "The duration of the refund process varies depending on the payment method you used.",
            "ar": "تختلف مدة عملية الاسترداد اعتمادًا على طريقة الدفع التي استخدمتها."
        },
        "How can I contact WardStation?": {
            "en": "How can I contact WardStation?",
            "ar": "كيفية التواصل بتطبيق ورد ستيشن؟"
        },
        "You can contact us anytime through live chat or email or call our hotline number during our working hours Sunday to Thursday 9AM-9PM.": {
            "en": "You can contact us anytime through live chat or email or call our hotline number during our working hours Sunday to Thursday 9AM-9PM.",
            "ar": "يمكنك الاتصال بنا في أي وقت من خلال الدردشة المباشرة أو البريد الإلكتروني أو الاتصال برقم الخط الساخن الخاص بنا خلال ساعات عملنا من الأحد إلى الخميس من 9 صباحًا إلى 9 مساءً."
        },
        "Orders / Returns": {
            "en": "Orders / Returns",
            "ar": "الطلبات / المرتجعات"
        },
        "If I placed an order, how long does it take to receive the order?": {
            "en": "If I placed an order, how long does it take to receive the order?",
            "ar": "إذا قمت بتقديم طلب ، كم من الوقت سيستغرق استلام الطلب؟"
        },
        "WardStation offers each delivery within 2 hours.": {
            "en": "WardStation offers each delivery within 2 hours.",
            "ar": "يقدم ورد ستيشن كل توصيل في غضون ساعتين."
        },
        "Can I do one order with different dates?": {
            "en": "Can I do one order with different dates?",
            "ar": "هل يمكنني تقديم طلب واحد بتواريخ مختلفة؟"
        },
        "Indeed you may. You can now have multiple delivery dates in one order which will serve you better especially for subscriptions.": {
            "en": "Indeed you may. You can now have multiple delivery dates in one order which will serve you better especially for subscriptions.",
            "ar": "في الواقع يمكنك ذلك. يمكنك الآن الحصول على تواريخ تسليم متعددة بترتيب واحد مما يخدمك بشكل أفضل خاصة بالنسبة للاشتراكات."
        },
        "Can I track my orders?": {
            "en": "Can I track my orders?",
            "ar": "هل يمكنني تتبع طلباتي؟"
        },
        "WardStation is now the 1st and only online flowers platform in the region that enables users to track their bouquets from their phones.": {
            "en": "WardStation is now the 1st and only online flowers platform in the region that enables users to track their bouquets from their phones.",
            "ar": "يمكنك من خلال التطبيق تتبع الطلب."
        },
        "I forgot to add an item to my order, what do I do?": {
            "en": "I forgot to add an item to my order, what do I do?",
            "ar": "نسيت إضافة عنصر إلى طلبي ، ماذا أفعل؟"
        },
        "Contact us if you need to add, remove or change items from your order.": {
            "en": "Contact us if you need to add, remove or change items from your order.",
            "ar": "اتصل بنا إذا كنت بحاجة إلى إضافة عناصر أو إزالتها أو تغييرها من طلبك."
        },
        "Address": {
            "en": "Address",
            "ar": "العنوان"
        },
        "Can I have multiple addresses into my account?": {
            "en": "Can I have multiple addresses into my account?",
            "ar": "هل يمكنني الحصول على عناوين متعددة في حسابي؟"
        },
        "Yes.You can add as many addresses as you need to a single account. Go to Saved Addresses and click on 'Add New Address' button.": {
            "en": "Yes. You can add as many addresses as you need to a single account. Go to Saved Addresses and click on 'Add New Address' button.",
            "ar": "نعم. يمكنك إضافة العديد من العناوين إلى حساب واحد. انتقل إلى العناوين المحفوظة وانقر فوق الزر 'إضافة عنوان جديد'."
        },
        "Home": {
            "en": "Home",
            "ar": "الصفحة الرئيسية"
        },
        "Find answers to common inquiries about orders, products, and services at Wardstation. If you have further questions, feel free to contact our customer support. Fast delivery available across KSA. Order now!": {
            "en": "Find answers to common inquiries about orders, products, and services at Wardstation. If you have further questions, feel free to contact our customer support. Fast delivery available across KSA. Order now!",
            "ar": "ابحث عن إجابات على الاستفسارات الشائعة حول الطلبات والمنتجات والخدمات في ورد ستيشن. إذا كان لديك مزيد من الأسئلة ، فلا تتردد في الاتصال بدعم العملاء لدينا. يتوفر التوصيل السريع في جميع أنحاء المملكة العربية السعودية. اطلب الآن!"
        },
        "FAQ, frequently asked questions, help, support, customer service, inquiries": {
            "en": "FAQ, frequently asked questions, help, support, customer service, inquiries",
            "ar": "الأسئلة الشائعة, الأسئلة المتكررة, المساعدة, الدعم, خدمة العملاء, الاستفسارات"
        },
        "Frequently Asked Questions | Wardstation": {
            "en": "Frequently Asked Questions | Wardstation",
            "ar": "الأسئلة الشائعة | ورد ستيشن"
        },
    }


    return translations[text] ? translations[text][locale] : text;
}
export default _T;