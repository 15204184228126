import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/css/--component-breadcrumbs.css"
import BreadCrumbTranslation from "../data/breadcrumbs-locale";
import { getLocale, scrollToTop } from "../common";

function Breadcrumbs({pageTile,breadcrumbItems=[]}){
    const [language, setLanguage] = useState(getLocale())
    useEffect(()=>{
        scrollToTop();
        if(language === "ar"){
            document.title = pageTile.length ? `${BreadCrumbTranslation(pageTile,language)} | ${BreadCrumbTranslation('Wardstation',language)}` : BreadCrumbTranslation('Wardstation',language)
        }
        else{
            document.title = pageTile.length ? `${BreadCrumbTranslation(pageTile,"en")} | ${BreadCrumbTranslation('Wardstation',"en")}` : BreadCrumbTranslation('Wardstation',"en")
        }
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },[])
    return (
        <div className="breadcrumbs">
            <div className="wrapper">
                {
                    breadcrumbItems.map((e,key)=>(
                        e.link.length ? <Link key={key} to={e.link}>{BreadCrumbTranslation(e.title,language)}</Link> : <span key={key}>{BreadCrumbTranslation(e.title,language)}</span>
                    ))
                }
            </div>
        </div>
    )
}

export default Breadcrumbs;