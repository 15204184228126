import React from "react";
import { useState ,useContext } from "react";
import { addToCart, updateWishlist } from "../../helper/backend-methods";
import { Link, NavLink } from "react-router-dom";
import cartImage from "../../assets/images/cart-image.png"
import Modal from "../../components/Modal"
import infoIcon from "../../assets/images/info-icon.png"
import clickIcon from "../../assets/images/click-icon.png"
import { useEffect } from "react";
import { Toaster } from "../../components/Toaster";
import { GlobalContext } from "../../context/GlobalContext";
import _T from "../../locale/favourite";
import { pixelTrackEvent } from "../../common";

function FavouriteCard(props) {

    const product = props.product
    const productId = product.Id;
    const {cartCount , updateCartCount} = useContext(GlobalContext)
    const [inStock, setInStock] = useState( product.StockDetails && product.StockDetails === "Out of stock" ? false : true )
    const [loadingButton, setLoadingButton] = useState(false)
    const [cartModal, setCartModal] = useState(false);
    const [alternativeProductModal, setAlternativeProductModal] = useState(false);
    const [showAlternativeOption, setShowAlternativeOption] = useState(false);
    const [selectedColorAlternative, setSelectedColorAlternative] = useState(false);

    useEffect(() => {
        if (product.StockDetail && product.StockDetail === "Out of stock"){
            setInStock(false)
        }
        else{
            setInStock(true)
        }
    }, [])

    const addItemToCart = () => {
        setLoadingButton(true)
        setAlternativeProductModal(false);
        setShowAlternativeOption(false);
        let alternativeSelection = false;
        if(selectedColorAlternative && product.Colors){
            const selectedAlternativeVariant = product.Colors.filter((item)=> item.AssociatedProductId === selectedColorAlternative)[0]
            alternativeSelection = {
                "AlternateColor": selectedAlternativeVariant.Name,
                "IsAlternateAvailable": true,
                "ProductId": selectedAlternativeVariant.AssociatedProductId,
                "Send": false
            }
        }
        addToCart({Quantity : 1 , ProductId: productId, ...(selectedColorAlternative && {AlternateVariation: alternativeSelection}) }).then(({data})=>{
            setSelectedColorAlternative(false);
            if(data.status){
                pixelTrackEvent("AddToCart",{ content_ids: [productId], content_type: 'product', value: product.Price, currency: 'SAR' })
                updateCartCount(cartCount+1)
                setCartModal(true)
            }
            setLoadingButton(false)
        }).catch((e)=>{
            console.log(e)
            setLoadingButton(false)
        })
    }

    const [IsInWishlist, setIsInWishlist] = useState(true);
    const RemoveWishlist = () => {
        updateWishlist({ ProductId: product.Id, Add: false }).then(({data})=>{
            if(data.status){
                props.setUpdate((prev)=>{ return prev + 1 })
                setIsInWishlist(false)
            }
        }).catch((e)=>{
            Toaster("error",_T("Request Failed"),_T("Could not remove the product from wishlist!"))
        })
    }
   
    
    return (
    
     IsInWishlist && <div className="favourite-card position-relative">
        
        { product.IsAttachable ? <span className='product-customizable'>{_T("Customizable")}</span> : <></> }
        <div className="fav-card-container d-flex flex-wrap align-items-center bg-wrapper">
            <div className="fav-image">
                <img src={product.DefaultPictureModel.ImageUrl} alt="product"></img>
            </div>
            <div className="fav-description">
                <h2 className="title fw-500 fs-16 m-0 text-secondary">{product.Name ? product.Name.slice(0,20) : ""}</h2>
                <p className="text-primary fs-16 fw-500 m-0">{(product.StockDetail && (product.StockDetail === "Out of stock" || product.StockDetail === "Low stock")) ? product.StockDetail : _T("In Stock")}</p>
                <h2 className="price fw-400 fs-16 m-0 text-secondary text-en">SAR {product.Price}</h2>
            </div>

            <div className="fav-buttons mt-4 ">
                {
                    product.CanSubscribe ?
                    <Link to={"/subscription/product/"+productId} className="product-action btn btn-primary padded w-100">{_T("Add to Cart")}</Link> :
                    <button className="product-action btn btn-primary w-100 padded" disabled={ !inStock || loadingButton } onClick={()=>{loadingButton? console.log("loading") : product.HasColor ? (function(){setAlternativeProductModal(true); })() : addItemToCart() }}> { inStock ? <><i className="ri-shopping-cart-line mr-1"></i>{ loadingButton ? _T("Please Wait!") : _T("Add to Cart")}</> : _T("Out of Stock") }</button>
                }
            </div>
            <span className="position-absolute heart-icon d-flex justify-center align-items-center"><i className={"ri-heart-fill text-secondary"} onClick={RemoveWishlist}></i> </span>
        </div>
        <Modal className="cart-modal" isOpen={cartModal} modalSize="small">
                <div className="text-center">
                    <img src={cartImage} alt="Cart Icon" className="mx-auto" width={110}/>
                    <p className="text-gray-400 fs-16 fw-400 text-center">{_T("The item has been added to your shopping cart")}</p>
                    <button className="btn btn-primary w-100 mb-2" type="button" onClick={()=>{ setCartModal(false);  }}>{_T("Continue Shopping")}</button>
                    <NavLink className="btn btn-secondary w-100" to={"/cart"}>{_T("Checkout")}</NavLink>
                </div>
            </Modal>


            <Modal className="select-alternative-modal" isOpen={alternativeProductModal} modalSize="small" onClose={()=>{ setAlternativeProductModal(false); setShowAlternativeOption(false); setSelectedColorAlternative(false); }} >
                {
                    !showAlternativeOption ?
                    <div className="text-center">
                        <img src={infoIcon} alt="Info Icon" className="mx-auto" width={50}/>
                        <p className="text-gray-400 fs-16 fw-400 text-center mb-0">{_T("If the item is not available")}</p>
                        <p className="text-gray-400 fs-16 fw-400 text-center mt-0">{_T("What would you like to do?")}</p>
                        <button className="btn btn-primary w-100 mb-2" type="button" onClick={()=>{ addItemToCart() }}>{_T("Remove Item")}</button>
                        <button className="btn btn-secondary w-100" type="button" onClick={()=>{ setShowAlternativeOption(true) }}>{_T("Select Alternative")}</button>
                    </div> :
                    <div className="text-center">
                        <img src={clickIcon} alt="Click Icon" className="mx-auto" width={50}/>
                        <p className="text-gray-400 fs-16 fw-400 text-center">{_T("Select Alternative Color")}</p>
                        {
                            product.HasColor && product.Colors ?
                            <div className="product-color-selector">
                                <div className="color-options">
                                    {
                                         product.Colors.map((color,index)=>{
                                            return <span onClick={()=>{ setSelectedColorAlternative(color.AssociatedProductId) }} className={`color-option ${color.AssociatedProductId === selectedColorAlternative ? "selected" : ""} `} key={index} style={{ backgroundColor: color.ColorSquaresRgb }}></span>
                                        })
                                    }
                                </div>
                            </div> : <></>
                        }
                        <button className="btn btn-secondary w-100" disabled={!selectedColorAlternative} type="button" onClick={addItemToCart}>{_T("Confirm")}</button>
                    </div>
                }
            </Modal>
        
        </div>
        
        )
    
}
export default FavouriteCard