import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import _T from "../../locale/subscription";
import { convertDateTimeWithLocale } from "../../common";

function SubscriptionCard(props) {
    return (
        <div className="sub-order-card-container bg-wrapper overflow-hidden d-flex justify-between flex-wrap mt-4 pt-0 pl-0 pr-0 pb-0">
            <div className='sub-card-header d-flex justify-between flex-wrap align-items-center w-100' style={{ padding: "1rem"}}>
                <div className='sub-order-card start-date'>
                    <p className='label mb-0 mt-0 fs-16 text-secondary'>{_T("Start Date:")}</p>
                    <p className='value mb-0 mt-0 fs-16 text-primary'>{convertDateTimeWithLocale(moment(props.item.StartDateUTC).format("MMM Do YYYY"))}</p>
                </div>
                <div className='sub-order-card end-date'>
                    <p className='label mb-0 mt-0 fs-16 text-secondary'>{_T("End Date:")}</p>
                    <p className='value mb-0 mt-0 fs-16 text-primary'>{convertDateTimeWithLocale(moment(props.item.EndDateUTC).format("MMM Do YYYY"))}</p>
                </div>
                <div className='sub-order-card period'>
                    <p className='label mb-0 mt-0 fs-16 text-secondary'>{_T("Period:")}</p>
                    <p className='value mb-0 mt-0 fs-16 text-primary'>{_T(props.item.PeriodType)}</p>
                </div>
                <div className='sub-order-card delivery'>
                    <p className='label mb-0 mt-0 fs-16 text-secondary'>{_T("Deliveries:")}</p>
                    <p className='value mb-0 mt-0 fs-16 text-primary'>{props.item.TotalDeliveries}</p>
                </div>
                <div className='sub-order-card-view-btn text-right'>
                    <Link to={"/account/subscription/"+props.item.Id} className='btn btn-primary padded'>{_T("View Details")}</Link >
                </div>
            </div>
        </div>
    )
}
export default SubscriptionCard
