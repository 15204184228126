function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "Store | Online Flowers and Gifts | Wardstation": {
            "en": "Store | Online Flowers and Gifts | Wardstation",
            "ar": "متجر | متجر الزهور عبر الإنترنت | ورد ستيشن"
        },
        "Explore Wardstation's online store for a wide range of beautiful flowers and thoughtful gifts. Find stunning bouquets, delicious chocolates, and delightful cakes to show love and appreciation for any occasion. With quick delivery available across KSA, experience the convenience of shopping with Wardstation. Order now!": {
            "en": "Explore Wardstation's online store for a wide range of beautiful flowers and thoughtful gifts. Find stunning bouquets, delicious chocolates, and delightful cakes to show love and appreciation for any occasion. With quick delivery available across KSA, experience the convenience of shopping with Wardstation. Order now!",
            "ar": "استكشف متجر ورد ستيشن عبر الإنترنت للعثور على مجموعة واسعة من الزهور الجميلة والهدايا العميقة المعنى. ابحث عن باقات رائعة، وشوكولاتة لذيذة، وكعك شهي لتظهر الحب والتقدير في أي مناسبة. مع التوصيل السريع المتاح في جميع أنحاء المملكة العربية السعودية، اختبر سهولة التسوق مع ورد ستيشن. اطلب الآن!"
        },
        "flower shop, gift shop, flowers delivery, chocolates, cakes, Wardstation, KSA, online gifts shop": {
            "en": "flower shop, gift shop, flowers delivery, chocolates, cakes, Wardstation, KSA, online gifts shop",
            "ar": "متجر زهور، متجر هدايا، توصيل الزهور، شوكولاتة، كعك، ورد ستيشن، المملكة العربية السعودية، متجر هدايا عبر الإنترنت"
        },
        "Add to Cart": {
            "en": "Add to Cart",
            "ar": "أضف إلى السلة"
        },
        "Add to Occasion:": {
            "en": "Add to Occasion:",
            "ar": "أضف إلى المناسبة:"
        },
        "Facebook": {
            "en": "Facebook",
            "ar": "فيسبوك"
        },
        "Twitter": {
            "en": "Twitter",
            "ar": "تويتر"
        },
        "Whatsapp": {
            "en": "Whatsapp",
            "ar": "واتساب"
        },
        "I need to know more about the following product": {
            "en": "I need to know more about the following product",
            "ar": "أحتاج لمعرفة المزيد عن المنتج التالي"
        },
        "product": {
            "en": "product",
            "ar": "منتج"
        },
        "Description": {
            "en": "Description",
            "ar": "الوصف"
        },
        "Specification": {
            "en": "Specification",
            "ar": "تخصيص"
        },
        "Brand:": {
            "en": "Brand:",
            "ar": "ماركة:"
        },
        "Attribute:": {
            "en": "Attribute:",
            "ar": "متغير:"
        },
        "Alert!": {
            "en": "Alert!",
            "ar": "تنبيه!"
        },
        "This product will take": {
            "en": "This product will take",
            "ar": "سيستغرق هذا المنتج"
        },
        "days for delivery": {
            "en": "days for delivery",
            "ar": "أيام للتوصيل"
        },
        "The item has been added to your selected occasion": {
            "en": "The item has been added to your selected occasion",
            "ar": "تمت إضافة المنتج إلى المناسبة المختارة"
        },
        "Continue Shopping": {
            "en": "Continue Shopping",
            "ar": "مواصلة التسوق"
        },
        "View Occasion": {
            "en": "View Occasion",
            "ar": "عرض المناسبة"
        },
        "The item has been added to your shopping cart": {
            "en": "The item has been added to your shopping cart",
            "ar": "تمت إضافة المنتج إلى عربة التسوق"
        },
        "Checkout": {
            "en": "Checkout",
            "ar": "الدفع"
        },
        "If the item is not available": {
            "en": "If the item is not available",
            "ar": "إذا كان المنتج غير متوفر"
        },
        "What would you like to do?": {
            "en": "What would you like to do?",
            "ar": "ماذا تريد ان تفعل؟"
        },
        "Remove Item": {
            "en": "Remove Item",
            "ar": "إزالة المنتج"
        },
        "Select Alternative": {
            "en": "Select Alternative",
            "ar": "حدد البديل"
        },
        "Select Alternative Color": {
            "en": "Select Alternative Color",
            "ar": "حدد اللون البديل"
        },
        "Confirm": {
            "en": "Confirm",
            "ar": "تأكيد"
        },
        "Home": {
            "en": "Home",
            "ar": "الصفحة الرئيسية"
        },
        "Products": {
            "en": "Products",
            "ar": "المنتجات"
        },
        "Product": {
            "en": "Product",
            "ar": "منتج"
        },
        "Showing": {
            "en": "Showing",
            "ar": "تظهر"
        },
        "of": {
            "en": "of",
            "ar": "ل"
        },
        "items": {
            "en": "items",
            "ar": "المنتجات"
        },
        "Filter": {
            "en": "Filter",
            "ar": "فلتر"
        },
        "Filters": {
            "en": "Filters",
            "ar": "الفلتر"
        },
        "Show items": {
            "en": "Show items",
            "ar": "عرض المنتجات"
        },
        "Sort By:": {
            "en": "Sort By:",
            "ar": "ترتيب حسب:"
        },
        "Most Recent": {
            "en": "Most Recent",
            "ar": "الأحدث"
        },
        "Least Recent": {
            "en": "Least Recent",
            "ar": "الحديث"
        },
        "Lowest Price": {
            "en": "Lowest Price",
            "ar": "أقل سعر"
        },
        "Highest Price": {
            "en": "Highest Price",
            "ar": "أعلى سعر"
        },
        "No Products Found": {
            "en": "No Products Found",
            "ar": "لم يتم العثور على منتجات"
        },
        "Fetching Products...": {
            "en": "Fetching Products...",
            "ar": "احضار المنتجات..."
        },
        "Show More": {
            "en": "Show More",
            "ar": "أظهر المزيد"
        },
        "There are no active filters": {
            "en": "There are no active filters",
            "ar": "لايوجد فلتر نشط"
        },
        "Categories": {
            "en": "Categories",
            "ar": "الفئات"
        },
        "Clear": {
            "en": "Clear",
            "ar": "مسح"
        },
        "Colors": {
            "en": "Colors",
            "ar": "الألوان"
        },
        "Brands": {
            "en": "Brands",
            "ar": "الماركات"
        },
        "Occasions": {
            "en": "Occasions",
            "ar": "المناسبات"
        },
        "Filter by price": {
            "en": "Filter by price",
            "ar": "تصفية حسب السعر"
        },
        "Apply": {
            "en": "Apply",
            "ar": "تطبيق"
        },
        "Please Wait!": {
            "en": "Please Wait!",
            "ar": "انتظر من فضلك!"
        },
        "Out of Stock": {
            "en": "Out of Stock",
            "ar": "نفدت الكمية"
        },
        "View Product": {
            "en": "View Product",
            "ar": "عرض المنتج"
        },
        "(VAT included)": {
            "en": "(VAT included)",
            "ar": "(شامل ضريبة القيمة المضافة)"
        },
        "Select a Color:": {
            "en": "Select a Color:",
            "ar": "اختر لون"
        },
        "Share:": {
            "en": "Share:",
            "ar": "مشاركة:"
        },
        "Recommended Products": {
            "en": "Recommended Products",
            "ar": "المنتجات الموصى بها"
        },
        "No description available": {
            "en": "No description available",
            "ar": "لا يوجد وصف متاح"
        },
        "Customizable": {
            "en": "Customizable",
            "ar": "قابل للتخصيص"
        },
        "See All": {
            "en": "See All",
            "ar": "عرض الكل"
        },
        "Okay": {
            "en": "Okay",
            "ar": "موافق"
        },
        "Enter the text you want on your cake": {
            "en": "Enter the text you want on your cake",
            "ar": "أدخل النص الذي تريده على الكيكة"
        },
        "Please wait!": {
            "en": "Please wait!",
            "ar": "انتظر من فضلك!"
        },
        "Upload Picture": {
            "en": "Upload Picture",
            "ar": "تحميل الصورة"
        },
        "Upload a picture you want to print on this cake": {
            "en": "Upload a picture you want to print on this cake",
            "ar": "قم بتحميل صورة تريد طباعتها على هذه الكيكة"
        },
        "Off": {
            "en": "Off",
            "ar": "عن"
        },
        "Select Size of the Cake": {
            "en": "Select Size of the Cake",
            "ar": "اختر حجم الكيكة"
        },
        "Select Color of your Cake": {
            "en": "Select Color of your Cake",
            "ar": "اختر لون الكيكة"
        },
        "Select Stuffing of your Cake": {
            "en": "Select Stuffing of your Cake",
            "ar": "اختر حشوة الكيكة"
        },
        "Cake Text": {
            "en": "Cake Text",
            "ar": "نص الكيكة"
        }
    }

    return translations[text] ? translations[text][locale] : text;
}
export default _T;