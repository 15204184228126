function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "My Saved Address": {
            "en": "My Saved Address",
            "ar": "عنواني المحفوظة"
        },
        "Add Address": {
            "en": "Add Address",
            "ar": "اضف عنوان"
        },
        "Selected Area": {
            "en": "Selected Area",
            "ar": "منطقة مختارة"
        },
        "Please select a location within": {
            "en": "Please select a location within",
            "ar": "الرجاء تحديد موقع"
        },
        "Recipient Name": {
            "en": "Recipient Name",
            "ar": "اسم المستلم"
        },
        "please provide recipient name": {
            "en": "please provide recipient name",
            "ar": "يرجى تقديم اسم المستلم"
        },
        "Recipient Mobile": {
            "en": "Recipient Mobile",
            "ar": "رقم هاتف المستلم"
        },
        "please provide valid recipient mobile": {
            "en": "please provide valid recipient mobile",
            "ar": "يرجى تقديم رقم هاتف صالح"
        },
        "Recipient Area": {
            "en": "Recipient Area",
            "ar": "منطقة المستلم"
        },
        "Select Recipient Area": {
            "en": "Select Recipient Area",
            "ar": "حدد منطقة المستلم"
        },
        "Recipient Address": {
            "en": "Recipient Address",
            "ar": "عنوان المستلم"
        },
        "You don't know the address?": {
            "en": "You don't know the address?",
            "ar": "أنت لا تعرف العنوان؟"
        },
        "We will contact the recipient and get their address": {
            "en": "We will contact the recipient and get their address",
            "ar": "سوف نتصل بالمستلم ونحصل على عنوانه"
        },
        "Delivery time might be impacted in case the recipient cannot be reached": {
            "en": "Delivery time might be impacted in case the recipient cannot be reached",
            "ar": "قد يتأثر وقت التسليم في حالة عدم الوصول إلى المستلم"
        },
        "Update Address": {
            "en": "Update Address",
            "ar": "تحديث العنوان"
        },
        "Add New Address": {
            "en": "Add New Address",
            "ar": "أضف عنوانًا جديدًا"
        },
        "Save Address": {
            "en": "Save Address",
            "ar": "حفظ العنوان"
        },
        "You haven't created any addresses": {
            "en": "You haven't created any addresses",
            "ar": "لم تنشئ أي عناوين"
        },
        "Address Updated": {
            "en": "Address Updated",
            "ar": "عنوان محدث"
        },
        "Your address has been updated!": {
            "en": "Your address has been updated!",
            "ar": "تم تحديث عنوانك!"
        },
        "Update Failed": {
            "en": "Update Failed",
            "ar": "فشل التحديث"
        },
        "Your address could not be updated!": {
            "en": "Your address could not be updated!",
            "ar": "لا يمكن تحديث عنوانك!"
        },
        "Address Created": {
            "en": "Address Created",
            "ar": "العنوان الذي تم إنشاؤه"
        },
        "Your address has been created!": {
            "en": "Your address has been created!",
            "ar": "تم إنشاء عنوانك!"
        },
        "Request Failed": {
            "en": "Request Failed",
            "ar": "الطلب فشل"
        },
        "Your address could not be created!": {
            "en": "Your address could not be created!",
            "ar": "لا يمكن إنشاء عنوانك!"
        },
        "Your addresses could not be fetched!": {
            "en": "Your addresses could not be fetched!",
            "ar": "لا يمكن اظهارعناوينك!"
        },
        "Store areas could not be fetched!": {
            "en": "Store areas could not be fetched!",
            "ar": "لا يمكن اظهار منطقة المتجر!"
        },
        "Address Deleted": {
            "en": "Address Deleted",
            "ar": "العنوان محذوف"
        },
        "Your address has been deleted!": {
            "en": "Your address has been deleted!",
            "ar": "تم حذف عنوانك!"
        },
        "Delete Failed": {
            "en": "Delete Failed",
            "ar": "فشل حذف"
        },
        "Your address could not be deleted!": {
            "en": "Your address could not be deleted!",
            "ar": "لا يمكن حذف عنوانك!"
        },
        "Address Selected": {
            "en": "Address Selected",
            "ar": "العنوان المحدد"
        },
        "Your address has been selected as default!": {
            "en": "Your address has been selected as default!",
            "ar": "تم اختيار عنوانك على أنه الأساسي!"
        },
        "Your address could not be marked as default!": {
            "en": "Your address could not be marked as default!",
            "ar": "لا يمكن تمييز عنوانك على أنه أساسي!"
        },
        "Store area could not be selected!": {
            "en": "Store area could not be selected!",
            "ar": "لا يمكن اختيار منطقة المتجر!"
        },
        "Update": {
            "en": "Update",
            "ar": "تحديث"
        },
        "Default": {
            "en": "Default",
            "ar": "الأساسي"
        },
        "Selected": {
            "en": "Selected",
            "ar": "المحدد"
        },
        "Set as Default": {
            "en": "Set as Default",
            "ar": "تعيين كأساسي"
        },
        "Select Address": {
            "en": "Select Address",
            "ar": "حدد العنوان"
        },
        "Select Area": {
            "en": "Select Area",
            "ar": "حدد المنطقة"
        },
        "Search Here": {
            "en": "Search Here",
            "ar": "ابحث هنا"
        },
        "Enter name here": {
            "en": "Enter name here",
            "ar": "أدخل الاسم هنا"
        },
        "Enter phone number": {
            "en": "Enter phone number",
            "ar": "أدخل رقم الهاتف"
        },
        "Please Enter Address": {
            "en": "Please Enter Address",
            "ar": "الرجاء إدخال العنوان"
        }

    }

    return translations[text] ? translations[text][locale] : text;
}
export default _T;