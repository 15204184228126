function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "Shop Now": {
            "en": "Shop Now",
            "ar": "تسوق الآن"
        },
        "Explore Categories": {
            "en": "Explore Categories",
            "ar": "استكشاف الفئات"
        },
        "Upcoming Occasions": {
            "en": "Upcoming Occasions",
            "ar": "المناسبات القادمة"
        },
        "Edit": {
            "en": "Edit",
            "ar": "تعديل"
        },
        "Shop By Brands": {
            "en": "Shop By Brands",
            "ar": "تسوق من الماركات"
        },
        "Shop By Occasions": {
            "en": "Shop By Occasions",
            "ar": "تسوق من المناسبات"
        },
        "Create an Occasion": {
            "en": "Create an Occasion",
            "ar": "إنشاء مناسبة"
        },
        "View Product": {
            "en": "View Product",
            "ar": "عرض المنتج"
        },
        "Add New Occasion": {
            "en": "Add New Occasion",
            "ar": "أضف مناسبة جديدة"
        },
        "Occasion Title": {
            "en": "Occasion Title",
            "ar": "عنوان المناسبة"
        },
        "Occasion Title cannot be empty!": {
            "en": "Occasion Title cannot be empty!",
            "ar": "لا يمكن أن يكون عنوان المناسبة فارغًا!"
        },
        "Occasion Type": {
            "en": "Occasion Type",
            "ar": "نوع المناسبة"
        },
        "Select an Occasion Type": {
            "en": "Select an Occasion Type",
            "ar": "حدد نوع مناسبة"
        },
        "Please Select an Occasion Type!": {
            "en": "Please Select an Occasion Type!",
            "ar": "الرجاء تحديد نوع مناسبة!"
        },
        "Yearly Reminder": {
            "en": "Yearly Reminder",
            "ar": "تذكير سنوي"
        },
        "Create Occasion": {
            "en": "Create Occasion",
            "ar": "إنشاء مناسبة"
        },
        "Update Occasion": {
            "en": "Update Occasion",
            "ar": "تحديث مناسبة"
        },
        "Occasion Created": {
            "en": "Occasion Created",
            "ar": "تم إنشاء المناسبة"
        },
        "Your Occasion has been created!": {
            "en": "Your Occasion has been created!",
            "ar": "لقد تم إنشاء مناسبة!"
        },
        "Your Occasion could not be created!": {
            "en": "Your Occasion could not be created!",
            "ar": "لا يمكن إنشاء مناسبتك!"
        },
        "Request Failed": {
            "en": "Request Failed",
            "ar": "الطلب فشل"
        },
        "Occasion Updated": {
            "en": "Occasion Updated",
            "ar": "تم تحديث المناسبة"
        },
        "Your Occasion has been updated!": {
            "en": "Your Occasion has been updated!",
            "ar": "تم تحديث مناسبتك!"
        },
        "Update Failed": {
            "en": "Update Failed",
            "ar": "فشل التحديث"
        },
        "Your Occasion could not be updated!": {
            "en": "Your Occasion could not be updated!",
            "ar": "لا يمكن تحديث مناسبتك!"
        },
        "This website tracks your activities to enhance your shopping experience. The website tracks your activities, such as page views, product searches, and purchases, to provide you with a personalized experience and better understand how you use the website. All data collected is anonymous and will be used solely for the purpose of improving the website and it's services. By clicking 'Accept,' you consent to the collection and use of this information.": {
            "en": "This website tracks your activities to enhance your shopping experience. The website tracks your activities, such as page views, product searches, and purchases, to provide you with a personalized experience and better understand how you use the website. All data collected is anonymous and will be used solely for the purpose of improving the website and its services. By clicking 'Accept,' you consent to the collection and use of this information.",
            "ar": "يتتبع هذا الموقع أنشطتك لتعزيز تجربة التسوق الخاصة بك. يتتبع موقع الويب أنشطتك ، مثل مشاهدات الصفحة ، وعمليات البحث عن المنتجات ، والمشتريات ، لتزويدك بتجربة مخصصة وفهم أفضل لكيفية استخدامك للموقع. جميع البيانات التي تم جمعها مجهولة وسيتم استخدامها فقط لغرض تحسين موقع الويب والخدمات. من خلال النقر فوق 'قبول' ، فإنك توافق على جمع هذه المعلومات واستخدامها."
        },
        "Accept": {
            "en": "Accept",
            "ar": "قبول"
        },
        "Manage Preference": {
            "en": "Manage Preference",
            "ar": "إدارة التفضيلات"
        },
        "Please configure the options to enable or disable your tracking preferences on the website.": {
            "en": "Please configure the options to enable or disable your tracking preferences on the website.",
            "ar": "يرجى اختيار تمكين أو تعطيل تفضيلات التتبع الخاصة بك على الموقع."
        },
        "Allow tracking my identity on the website": {
            "en": "Allow tracking my identity on the website",
            "ar": "السماح بتتبع هويتي على الموقع الإلكتروني"
        },
        "Allow tracking my interactions on the website": {
            "en": "Allow tracking my interactions on the website",
            "ar": "السماح بتتبع تفاعلاتي على الموقع الإلكتروني"
        },
        "Allow tracking my page visits on the website": {
            "en": "Allow tracking my page visits on the website",
            "ar": "السماح بتتبع زيارات صفحتي على الموقع الإلكتروني"
        },
        "Update Region": {
            "en": "Update Region",
            "ar": "تحديث المنطقة"
        },
        "See All": {
            "en": "See All",
            "ar": "اظهار الكل"
        },
        "flowers shop, flowers jeddah, flowers for flowers, flower shop jeddah, gifts birthday gifts, flower bouquet, shop for flowers, flowers in flower shop, chocolates, cakes": {
            "en": "flowers shop, flowers jeddah, flowers for flowers, flower shop jeddah, gifts birthday gifts, flower bouquet, shop for flowers, flowers in flower shop, chocolates, cakes",
            "ar": "محل ورد, باقة ورد, محل ورد قريب مني, محل هدايا , بوكية ورد , محلات هدايا, باقات الورد, توصيل ورد, متجر هدايا, توصيل هدايا, محل ورد قريب من موقعي, شوكولاتة, كيك"
        },
        "Online Shop for Beautiful Flowers and Gifts | Wardstation": {
            "en": "Online Shop for Beautiful Flowers and Gifts | Wardstation",
            "ar": "متجر اونلاين لشراء الورد و الهدايا | ورد ستيشن"
        },
        'Discover a wide selection of beautiful flowers and heartfelt gifts at Wardstation. From stunning bouquets, chocolates and cakes to a variety of unique presents, find the perfect expression of love and appreciation for all occasions. Fast delivery available across KSA. Order now!': {
            "en": 'Discover a wide selection of beautiful flowers and heartfelt gifts at Wardstation. From stunning bouquets, chocolates and cakes to a variety of unique presents, find the perfect expression of love and appreciation for all occasions. Fast delivery available across KSA. Order now!',
            "ar": 'اكتشف مجموعة واسعة من الورود الجميلة والهدايا  في ورد ستيشن. من الباقات الرائعة والشوكولاتة والكيك إلى مجموعة متنوعة من الهدايا الفريدة ، ابحث عن التعبير المثالي عن الحب والامتنان لجميع المناسبات. يتوفر التوصيل السريع في جميع أنحاء المملكة العربية السعودية. اطلب الآن!'
        }
    }

    return translations[text] ? translations[text][locale] : text;
}
export default _T;