import React from "react";
import "../../assets/css/--component-account-transaction-history.css"
import TransactionHistoryCard from "./transaction-history-card";
import { getTransactionHistory } from "../../helper/backend-methods";
import { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import moment from 'moment/moment';
import { convertDateTimeWithLocale, googleTagInitialize, pixelPageEvent, scrollToTop } from "../../common";
import _T from "../../locale/transaction-history";
import ErrorMsg from "../../components/ErrorMessage";
function TransactionHistory(params) {
    const [transactionHistoryData, setTransactionHistoryData] = useState({})
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        pixelPageEvent();
        googleTagInitialize();
    },[])


    useEffect(() => {
        document.title = 'Transactions | Wardstation';
        scrollToTop();
        getTransactionHistory({ pageIndex: 0, pageSize: 10 }).then(({ data }) => {
            if (data.status) {
                setTransactionHistoryData(data.result.Data.Transactions)
                setLoading(false)
            }
        }).catch(e => {
            console.log(e)
        })
    }, [])

    return (
        <section id="account-transactions">
            <div className="account-header">
                <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'>{_T("Transaction History")}</h1>
            </div>
            {
                !loading ?
                    transactionHistoryData.length ?
                        transactionHistoryData.map((item, index) => {
                            return <TransactionHistoryCard orderId={item.OrderId} orderDate={convertDateTimeWithLocale(moment(item.Timestamp).format("MMM Do YYYY"))} orderAmount={item.Total} orderPayment={item.PaymentType} key={index} />
                        }) :
                        <ErrorMsg text={_T("There are currently no transaction records for your account.")}/>
                    :
                    [...Array(6)].map((item, key) => {
                        return <Skeleton key={key} className='mt-3 w-100' height={"82px"} />
                    })
            }
        </section>
    )
}
export default TransactionHistory