import React, { useEffect, useState } from 'react';
import "../../assets/css/--page-additional.css";
import '../../assets/css/--page-blog.css';
import { getBlogById } from '../../helper/backend-methods';
import { useParams } from 'react-router-dom';
import BreadCrumbs from "../../components/BreadCrumbs"
import { convertDateTimeWithLocale } from '../../common';
import moment from 'moment/moment';
import Skeleton from 'react-loading-skeleton';
import _T from '../../locale/blog';
import SEO from '../../components/SEO';

const BlogDetails = () => {
    let params = useParams();
    let blogId = params.id;
    const [loading, setLoading] = useState(true)
    const [isCopy, setIsCopy] = useState(false)
    const [blogDetails, setBlogDetails] = useState([])
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        { title: _T("Home"), link: "/" },
        { title: _T("Blogs"), link: "/blogs" },
        { title: _T("Blog"), link: "" },
    ]);

    useEffect(() => {
        getBlogById({ blogId: blogId }).then(({ data }) => {
            if (data.status) {
                setBlogDetails(data.result)
                setLoading(false)
                let b = data.result.Title && data.result.Title.length ? data.result.Title : _T("Blog");
                setBreadcrumbItems([{ title: _T("Home"), link: "/" }, { title: _T("Blogs"), link: "/blogs" }, { title: b, link: "" }])
            }
        }).catch((e) => {
            console.log(e)
        })
    }, [])

    const copyToClipboard = () => {
        window.navigator.clipboard.writeText(window.location.href);
        setIsCopy(true)
        setTimeout(() => {
            setIsCopy(false);
        }, 1000);
    };
    

    return (
        <section className="blog-details">
            <BreadCrumbs pageTile="Blog" breadcrumbItems={breadcrumbItems} />
            {!loading ? (
                <div className='additional-page'>
                    <SEO metaTitle={blogDetails.MetaTitle ? blogDetails.MetaTitle : blogDetails.Title} metaDescription={blogDetails.MetaDescription ? blogDetails.MetaDescription : blogDetails.BodyOverview ? blogDetails.BodyOverview : "Wardstation Blog"} metaKeywords={blogDetails.MetaKeywords ? blogDetails.MetaKeywords : blogDetails.Title.split(" ").join(".")} />
                    <div className='wrapper'>
                        <div className='page-content'>
                            <h2>{blogDetails.Title}</h2>
                            <img src={blogDetails.PictureUrl} alt="img" className="banner-image" />
                            <div className="details-container">
                                <div className="left">
                                    <p><i className="ri-time-line mr-1"></i>{convertDateTimeWithLocale(moment(blogDetails.CreatedOnUtc).format("MMM Do YYYY"))}</p>
                                </div>
                                <div className="right">
                                    <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(blogDetails.Title)}&url=${encodeURIComponent(window.location.href)}`}>
                                        <i className="ri-twitter-x-line social-ico mr-3"></i>
                                    </a>
                                    <a target="_blank" rel="noreferrer" href={`http://www.facebook.com/share.php?u=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(blogDetails.Title)}`}>
                                        <i className="ri-facebook-fill social-ico mr-2"></i>
                                    </a>
                                    {
                                        !isCopy ?
                                            <i className="ri-file-copy-line social-ico ml-2" onClick={copyToClipboard}></i> : <i className="ri-check-line social-ico ml-2"></i>
                                    }
                                </div>
                            </div>
                            <div className='blog-bodyElement' dangerouslySetInnerHTML={{ __html: blogDetails.Body }} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className='additional-page'>
                    <div className='wrapper'>
                        <div className='page-content'>
                            <Skeleton height={50} borderRadius={15} />
                            <Skeleton borderRadius={15} className='blog-banner-skeleton mt-3' />
                            <div className="details-container">
                                <div className="left">
                                    <Skeleton height={40} width={160} borderRadius={15} className='mt-3' />
                                </div>
                                <div className="right">
                                    <Skeleton height={40} width={160} borderRadius={15} className='mt-3' />
                                </div>
                            </div>
                            <div className='mt-3'>
                                <Skeleton borderRadius={15} className='mb-1' />
                                <Skeleton borderRadius={15} className='mb-1' />
                                <Skeleton borderRadius={15} className='mb-1' />
                                <Skeleton borderRadius={15} className='mb-1' />
                                <Skeleton borderRadius={15} className='mb-1' />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default BlogDetails;
