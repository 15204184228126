function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "Occasions": {
            "en": "Occasions",
            "ar": "المناسبات"
        },
        "Wardstation": {
            "en": "Wardstation",
            "ar": "ورد ستيشن"
        },
        "Occasion Created": {
            "en": "Occasion Created",
            "ar": "تم إنشاء مناسبة"
        },
        "Your Occasion has been created!": {
            "en": "Your Occasion has been created!",
            "ar": "تم إنشاء مناسبةك!"
        },
        "Request Failed": {
            "en": "Request Failed",
            "ar": "الطلب فشل"
        },
        "Your Occasion could not be created!": {
            "en": "Your Occasion could not be created!",
            "ar": "لا يمكن إنشاء مناسبتك!"
        },
        "My Occasions": {
            "en": "My Occasions",
            "ar": "مناسباتي"
        },
        "Add Occasion": {
            "en": "Add Occasion",
            "ar": "أضف مناسبة"
        },
        "You haven't created any occasions yet.": {
            "en": "You haven't created any occasions yet.",
            "ar": "لم تنشئ أي مناسبات بعد."
        },
        "Add New Occasion": {
            "en": "Add New Occasion",
            "ar": "أضف مناسبة جديدة"
        },
        "Occasion Title": {
            "en": "Occasion Title",
            "ar": "عنوان المناسبة"
        },
        "Occasion Title cannot be empty!": {
            "en": "Occasion Title cannot be empty!",
            "ar": "لا يمكن أن يكون عنوان المناسبة فارغًا!"
        },
        "Occasion Type": {
            "en": "Occasion Type",
            "ar": "نوع المناسبة"
        },
        "Select an Occasion Type": {
            "en": "Select an Occasion Type",
            "ar": "حدد نوع المناسبة"
        },
        "Please Select an Occasion Type!": {
            "en": "Please Select an Occasion Type!",
            "ar": "الرجاء تحديد نوع مناسبة!"
        },
        "Yearly Reminder": {
            "en": "Yearly Reminder",
            "ar": "تذكير سنوي"
        },
        "Create": {
            "en": "Create",
            "ar": "إنشاء"
        },
        "Occasion Updated": {
            "en": "Occasion Updated",
            "ar": "تم تحديث المناسبة"
        },
        "Your Occasion has been updated!": {
            "en": "Your Occasion has been updated!",
            "ar": "تم تحديث مناسبتك!"
        },
        "Update Failed": {
            "en": "Update Failed",
            "ar": "فشل التحديث"
        },
        "Your Occasion could not be updated!": {
            "en": "Your Occasion could not be updated!",
            "ar": "لا يمكن تحديث مناسبتك!"
        },
        "Edit": {
            "en": "Edit",
            "ar": "تعديل"
        },
        "Update Occasion": {
            "en": "Update Occasion",
            "ar": "تحديث المناسبة"
        },
        "Occasion Type cannot be empty!": {
            "en": "Occasion Type cannot be empty!",
            "ar": "لا يمكن أن يكون نوع المناسبة فارغًا!"
        },
        "Delete Occasion": {
            "en": "Delete Occasion",
            "ar": "حذف المناسبة"
        },
        "Items": {
            "en": "Items",
            "ar": "المنتجات"
        },
        "Orders": {
            "en": "Orders",
            "ar": "الطلبات"
        },
        "You currently have no orders associated with this occasion.": {
            "en": "You currently have no orders associated with this occasion.",
            "ar": "ليس لديك حاليًا أي طلبات مرتبطة بهذه المناسبة."
        },
        "You currently have no products associated with this occasion.": {
            "en": "You currently have no products associated with this occasion.",
            "ar": "ليس لديك حاليًا أي منتجات مرتبطة بهذه المناسبة."
        },
        "Occasion Deleted": {
            "en": "Occasion Deleted",
            "ar": "المناسبة المحذوفة"
        },
        "Your Occasion has been deleted!": {
            "en": "Your Occasion has been deleted!",
            "ar": "تم حذف مناسبتك!"
        },
        "Delete Failed": {
            "en": "Delete Failed",
            "ar": "فشل الحذف"
        },
        "Your Occasion could not be deleted!": {
            "en": "Your Occasion could not be deleted!",
            "ar": "لا يمكن حذف مناسبتك!"
        },
        "Status": {
            "en": "Status",
            "ar": "الحالة"
        },
        "Order No": {
            "en": "Order No",
            "ar": "رقم الطلب"
        },
        "Order Date": {
            "en": "Order Date",
            "ar": "تاريخ الطلب"
        },
        "The item has been added to your shopping cart": {
            "en": "The item has been added to your shopping cart",
            "ar": "تمت إضافة المنتجات إلى عربة التسوق الخاصة بك"
        },
        "Continue Shopping": {
            "en": "Continue Shopping",
            "ar": "مواصلة التسوق"
        },
        "Checkout": {
            "en": "Checkout",
            "ar": "الدفع"
        },
        "Please Wait!": {
            "en": "Please Wait!",
            "ar": "انتظر من فضلك!"
        },
        "Add to Cart": {
            "en": "Add to Cart",
            "ar": "أضف إلى السلة"
        },
        "Out of Stock": {
            "en": "Out of Stock",
            "ar": "نفدت الكمية"
        },
        "If the item is not available": {
            "en": "If the item is not available",
            "ar": "إذا كان المنتج غير متوفر"
        },
        "What would you like to do?": {
            "en": "What would you like to do?",
            "ar": "ماذا تريد ان تفعل؟"
        },
        "Remove Item": {
            "en": "Remove Item",
            "ar": "إزالة المنتج"
        },
        "Select Alternative": {
            "en": "Select Alternative",
            "ar": "حدد البديل"
        },
        "Select Alternative Color": {
            "en": "Select Alternative Color",
            "ar": "حدد اللون البديل"
        },
        "selected": {
            "en": "selected",
            "ar": "المحدد"
        },
        "Confirm": {
            "en": "Confirm",
            "ar": "تأكيد"
        },
        "Product Removed": {
            "en": "Product Removed",
            "ar": "تم إزالة المنتج"
        },
        "Product has been removed from the occasion!": {
            "en": "Product has been removed from the occasion!",
            "ar": "تمت إزالة المنتج من هذه المناسبة!"
        },
        "Removed Failed": {
            "en": "Removed Failed",
            "ar": "فشلت إزالة"
        },
        "Product hasn't been removed from the occasion!": {
            "en": "Product hasn't been removed from the occasion!",
            "ar": "لم تتم إزالة المنتج من هذه المناسبة!"
        },

        "Assigned": {
            "en": "Assigned",
            "ar": "تم التعيين",
        },
        "OnHold": {
            "en": "On Hold",
            "ar": "قيد الانتظار",
        },
        "Packed": {
            "en": "Packed",
            "ar": "معبأ",
        },
        "OutForDelivery": {
            "en": "Out For Delivery",
            "ar": "في طريقه إلى الوصول",
        },
        "Pending": {
            "en": "Pending",
            "ar": "معلق",
        },
        "Confirmed": {
            "en": "Confirmed",
            "ar": "مؤكد",
        },
        "Picked": {
            "en": "Picked",
            "ar": "تم التقاطه",
        },
        "Shipped": {
            "en": "Shipped",
            "ar": "تم شحنه",
        },
        "NotCreated": {
            "en": "Not Created",
            "ar": "غير تم إنشاؤه",
        },
        "Complete": {
            "en": "Complete",
            "ar": "مكتمل"
        },
        "Order Completed": {
            "en": "Order Completed",
            "ar": "تم اكتمال الطلب"
        },
        "Delivered": {
            "en": "Delivered",
            "ar": "تم التوصيل"
        },
    }

    return translations[text] ? translations[text][locale] : text;
}
export default _T;