function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "Shopping Cart": {
            "en": "Shopping Cart",
            "ar": "عربة التسوق"
        },
        "Address": {
            "en": "Address",
            "ar": "العنوان"
        },
        "Payment": {
            "en": "Payment",
            "ar": "الدفع"
        },
        "Your Address": {
            "en": "Your Address",
            "ar": "عنوانك"
        },
        "Selected": {
            "en": "Selected",
            "ar": "المحدد"
        },
        "Coupon": {
            "en": "Coupon",
            "ar": "قسيمة"
        },
        "Error Code - ": {
            "en": "Error Code - ",
            "ar": "رمز الخطأ -"
        },
        "Apply": {
            "en": "Apply",
            "ar": "تطبيق"
        },
        "Add Your Touch": {
            "en": "Add Your Touch",
            "ar": "أضف اللمسة الخاص بك"
        },
        "Badge Details": {
            "en": "Badge Details",
            "ar": "تفاصيل الشارة"
        },
        "Add badge": {
            "en": "Add badge",
            "ar": "أضف شارة"
        },
        "Add card": {
            "en": "Add card",
            "ar": "إضافة بطاقة"
        },
        "Add Card": {
            "en": "Add Card",
            "ar": "إضافة بطاقة"
        },
        "Ok": {
            "en": "Ok",
            "ar": "حسناً",
        },
        "Gift Card": {
            "en": "Gift Card",
            "ar": "بطاقة هدية"
        },
        "Add your message": {
            "en": "Add your message",
            "ar": "أضف رسالتك"
        },
        "Order Summary": {
            "en": "Order Summary",
            "ar": "ملخص الطلب"
        },
        "Sub Total": {
            "en": "Sub Total",
            "ar": "مجموع"
        },
        "Inclusive of VAT": {
            "en": "Inclusive of VAT",
            "ar": "شامل ضريبة القيمة المضافة"
        },
        "Total": {
            "en": "Total",
            "ar": "المجموع"
        },
        "Previous": {
            "en": "Previous",
            "ar": "سابق"
        },
        "Proceed to Delivery": {
            "en": "Proceed to Delivery",
            "ar": "انتقل إلى التوصيل"
        },
        "Select a Delivery Date": {
            "en": "Select a Delivery Date",
            "ar": "حدد تاريخ التوصيل"
        },
        "Your cart is empty!": {
            "en": "Your cart is empty!",
            "ar": "عربة التسوق فارغة!"
        },
        "Looks like you haven't made your choice yet. Get started by adding items.": {
            "en": "Looks like you haven't made your choice yet. Get started by adding items.",
            "ar": "يبدو أنك لم تختار بعد. ابدأ بإضافة المنتجات."
        },
        "Go to Store": {
            "en": "Go to Store",
            "ar": "اذهب إلى المتجر"
        },
        "You haven't created any addresses": {
            "en": "You haven't created any addresses",
            "ar": "لم تنشئ أي عناوين"
        },
        "Please select an address to proceed": {
            "en": "Please select an address to proceed",
            "ar": "الرجاء تحديد عنوان للمتابعة"
        },
        "Please provide a valid coupon code": {
            "en": "Please provide a valid coupon code",
            "ar": "يرجى تقديم رمز قسيمة صالح"
        },
        "Recipient": {
            "en": "Recipient",
            "ar": "المستلم"
        },
        "Enter Recipient Name": {
            "en": "Enter Recipient Name",
            "ar": "أدخل اسم المستلم"
        },
        "Message": {
            "en": "Message",
            "ar": "الرسالة"
        },
        "Write your message...": {
            "en": "Write your message...",
            "ar": "اكتب رسالتك..."
        },
        "From": {
            "en": "From",
            "ar": "من"
        },
        "Gift card message is required to proceed": {
            "en": "Gift card message is required to proceed",
            "ar": "رسالة بطاقة الهدايا مطلوبة للمتابعة"
        },
        "Send Anonymously": {
            "en": "Send Anonymously",
            "ar": "إرسال بدون اسم"
        },
        "Share Your Feelings With": {
            "en": "Share Your Feelings With",
            "ar": "شارك مشاعرك مع"
        },
        "Paste a link to a song/video": {
            "en": "Paste a link to a song/video",
            "ar": "الصق رابطًا لأغنية/فيديو"
        },
        "Upload Picture": {
            "en": "Upload Picture",
            "ar": "تحميل الصورة"
        },
        "Preview Gift Card": {
            "en": "Preview Gift Card",
            "ar": "معاينة بطاقة الهدايا"
        },
        "Image Selected": {
            "en": "Image Selected",
            "ar": "الصورة المحددة"
        },
        "Proceed to Payment": {
            "en": "Proceed to Payment",
            "ar": "الإنتقال للدفع"
        },
        "Checkout": {
            "en": "Checkout",
            "ar": "الدفع"
        },
        "Please select an address to proceed.": {
            "en": "Please select an address to proceed.",
            "ar": "الرجاء تحديد عنوان للمتابعة."
        },
        "Your order can not be delivered before": {
            "en": "Your order can not be delivered before",
            "ar": "لايمكن توصيل طلبك قبل"
        },
        ", as one of the items in your cart requires time for preparation.": {
            "en": ", as one of the items in your cart requires time for preparation.",
            "ar": "، لأن أحد العناصر الموجودة في عربة التسوق تتطلب وقتًا للإعداد."
        },
        "Pick a date": {
            "en": "Pick a date",
            "ar": "اختر موعدًا"
        },
        "Calendar": {
            "en": "Calendar",
            "ar": "التقويم"
        },
        "Select a Delivery Time": {
            "en": "Select a Delivery Time",
            "ar": "انتقل إلى التوصيل"
        },
        "Payment Method": {
            "en": "Payment Method",
            "ar": "طريقة الدفع"
        },
        "Please provide the following details to add a new card": {
            "en": "Please provide the following details to add a new card",
            "ar": "يرجى تقديم التفاصيل التالية لإضافة بطاقة جديدة"
        },
        "Add New Card": {
            "en": "Add New Card",
            "ar": "أضف بطاقة جديدة"
        },
        "Card Number": {
            "en": "Card Number",
            "ar": "رقم البطاقة"
        },
        "Card number not valid": {
            "en": "Card number not valid",
            "ar": "رقم البطاقة غير صالح"
        },
        "Card expiry not valid": {
            "en": "Card expiry not valid",
            "ar": "انتهاء صلاحية البطاقة غير صالح"
        },
        "Card Expiry": {
            "en": "Card Expiry",
            "ar": "انتهاء صلاحية البطاقة"
        },
        "Card": {
            "en": "Card",
            "ar": "بطاقة"
        },
        "not valid": {
            "en": "not valid",
            "ar": "غير صالح"
        },
        "Please provide payment information to proceed.": {
            "en": "Please provide payment information to proceed.",
            "ar": "يرجى تقديم معلومات الدفع للمتابعة."
        },
        "Please provide your card": {
            "en": "Please provide your card",
            "ar": "يرجى تقديم بطاقتك"
        },
        "to proceed with your payment.": {
            "en": "to proceed with your payment.",
            "ar": "للمضي قدما في دفعتك."
        },
        "Finish Checkout": {
            "en": "Finish Checkout",
            "ar": "الانتهاء من الدفع"
        },
        "Saved Cards": {
            "en": "Saved Cards",
            "ar": "البطاقات المحفوظة"
        },
        "Please Wait": {
            "en": "Please Wait",
            "ar": "انتظر من فضلك"
        },
        "Please wait while your payment is being processed": {
            "en": "Please wait while your payment is being processed",
            "ar": "يرجى الانتظار أثناء معالجة دفعتك"
        },
        "Order Date": {
            "en": "Order Date",
            "ar": "تاريخ الطلب"
        },
        "Payment Failed! The selected delivery city is unavailable.": {
            "en": "Payment Failed! The selected delivery city is unavailable.",
            "ar": "فشلت عملية الدفع! المدينة المحددة للتوصيل غير متوفرة."
        },
        "Order No": {
            "en": "Order No",
            "ar": "رقم الطلب"
        },
        "View Details": {
            "en": "View Details",
            "ar": "عرض التفاصيل"
        },
        "Expected Delivery": {
            "en": "Expected Delivery",
            "ar": "الوقت المتوقع للتوصيل"
        },
        "Status": {
            "en": "Status",
            "ar": "الحالة"
        },
        "Products": {
            "en": "Products",
            "ar": "المنتجات"
        },
        "Quantity": {
            "en": "Quantity",
            "ar": "الكمية"
        },
        "Alternative Color": {
            "en": "Alternative Color",
            "ar": "لون بديل"
        },
        "Free Gift": {
            "en": "Free Gift",
            "ar": "هدية مجانية"
        },
        "(Inclusive of VAT)": {
            "en": "(Inclusive of VAT)",
            "ar": "(بما في ذلك ضريبة القيمة المضافة)"
        },
        "Delivery Charges": {
            "en": "Delivery Charges",
            "ar": "رسوم التوصيل"
        },
        "VAT Included": {
            "en": "VAT Included",
            "ar": "شامل ضريبة القيمة المضافة"
        },
        "Delivery Address": {
            "en": "Delivery Address",
            "ar": "عنوان التوصيل"
        },
        "Delivery Date and Time": {
            "en": "Delivery Date and Time",
            "ar": "تاريخ ووقت التوصيل"
        },
        "Delivery Date": {
            "en": "Delivery Date",
            "ar": "تاريخ التوصيل"
        },
        "Delivery Time": {
            "en": "Delivery Time",
            "ar": "موعد التوصيل"
        },
        "Payments Details": {
            "en": "Payments Details",
            "ar": "تفاصيل الدفع"
        },
        "Continue Shopping": {
            "en": "Continue Shopping",
            "ar": "مواصلة التسوق"
        },
        "Customizable Products": {
            "en": "Customizable Products",
            "ar": "منتجات قابلة للتخصيص"
        },
        "Thank you for placing the Order": {
            "en": "Thank you for placing the Order",
            "ar": "شكرا لك على تقديم الطلب"
        },
        "Price": {
            "en": "Price",
            "ar": "السعر"
        },
        "Add new card": {
            "en": "Add new card",
            "ar": "أضف بطاقة جديدة"
        },
        "Credit / Debit Card": {
            "en": "Credit / Debit Card",
            "ar": "بطاقة الائتمان / الخصم"
        },
        "Change": {
            "en": "Change",
            "ar": "تغيير"
        },
        "Preview your badge": {
            "en": "Preview your badge",
            "ar": "معاينة شارتك"
        },
        "Remove": {
            "en": "Remove",
            "ar": "إزالة"
        },
        "Add Badge": {
            "en": "Add Badge",
            "ar": "أضف شارة"
        },
        "Preview your gift card": {
            "en": "Preview your gift card",
            "ar": "معاينة بطاقة الهدية الخاصة بك"
        },
        "Sign-In to Continue": {
            "en": "Sign-In to Continue",
            "ar": "تسجيل الدخول للمتابعة"
        },
        "Please Wait...": {
            "en": "Please Wait...",
            "ar": "انتظر من فضلك..."
        },
        "Payment Failed": {
            "en": "Payment Failed",
            "ar": "عملية الدفع فشلت"
        },
        "Your payment cannot be processed with the current card. We've remove it from saved cards, please add a new one to continue. Apologies for the inconvenience.": {
            "en": "Your payment cannot be processed with the current card. We've remove it from saved cards, please add a new one to continue. Apologies for the inconvenience.",
            "ar": "تعذر معالجة الدفع باستخدام البطاقة الحالية. لقد تمت إزالتها من البطاقات المحفوظة، يرجى إضافة بطاقة جديدة للمتابعة. نعتذر عن الإزعاج."
        },
        "We are Sorry to inform your that we have remove your card due to card session expired In order to place an oder via creadit/Debit card you have to add card again": {
            "en": "We are Sorry to inform your that we have remove your card due to card session expired In order to place an oder via creadit/Debit card you have to add card again",
            "ar": "نأسف لإبلاغك بأننا قمنا بإزالة بطاقتك بسبب إنتهاء صلاحية جلسة البطاقة . من أجل تقديم طلب عبر البطاقة / الخصم , يجب عليك إضافة البطاقة مرة أخرى"
        },
        "Continue": {
            "en": "Continue",
            "ar": "اكمل"
        },
        "card_already_exist": {
            "en": "Card already exists",
            "ar": "البطاقة موجودة بالفعل",
        },
        "Name": {
            "en": "Name",
            "ar": "الاسم"
        },
        "Enter Name": {
            "en": "Enter Name",
            "ar": "أدخل الاسم"
        },
        "Enter Date": {
            "en": "Enter Date",
            "ar": "أدخل التاريخ"
        },
        "Date": {
            "en": "Date",
            "ar": "التاريخ"
        },
        "Font": {
            "en": "Font",
            "ar": "الخط"
        },
        "Select Font": {
            "en": "Select Font",
            "ar": "حدد الخط"
        },
        "Save Badge Details": {
            "en": "Save Badge Details",
            "ar": "حفظ تفاصيل الشارة"
        },
        "Scan with Love": {
            "en": "Scan with Love",
            "ar": "امسح"
        },
        "Update / Edit Gift Card": {
            "en": "Update / Edit Gift Card",
            "ar": "تحديث / تعديل بطاقة الهدايا"
        },
        "Save Gift Card": {
            "en": "Save Gift Card",
            "ar": "حفظ بطاقة الهدايا"
        },
        "Payment Confirmation": {
            "en": "Payment Confirmation",
            "ar": "تأكيد الدفع"
        },
        "Please provide a name": {
            "en": "Please provide a name",
            "ar": "الرجاء تقديم اسم"
        },
        "Please select a date": {
            "en": "Please select a date",
            "ar": "الرجاء تحديد موعد"
        },
        "Please select a font": {
            "en": "Please select a font",
            "ar": "الرجاء تحديد خط"
        },
        "Your image could not be uploaded.": {
            "en": "Your image could not be uploaded.",
            "ar": "لا يمكن تحميل صورتك"
        },
        "Your video could not be uploaded.": {
            "en": "Your video could not be uploaded.",
            "ar": "لا يمكن تحميل الفيديو الخاص بك"
        },
        "Please provide a valid link": {
            "en": "Please provide a valid link",
            "ar": "يرجى تقديم رابط صالح"
        },
        "Allowed formats are mp4, mov, avi, 3gp": {
            "en": "Allowed formats are mp4, mov, avi, 3gp",
            "ar": "الصيغ المسموح بها هي mp4 ، mov ، avi ، 3gp"
        },
        "Allowed formats are jpeg, jpg, png": {
            "en": "Allowed formats are jpeg, jpg, png",
            "ar": "الصيغ المسموح بها هي JPEG ، JPG ، PNG"
        },
        "Select Area": {
            "en": "Select Area",
            "ar": "حدد المنطقة"
        },
        "Please select a date and time slot.": {
            "en": "Please select a date and time slot.",
            "ar": "الرجاء تحديد فترة التاريخ والوقت"
        },
        "No time slots are available for this date.": {
            "en": "No time slots are available for this date.",
            "ar": "لا توجد فترات زمنية متاحة لهذا التاريخ"
        },
        "Expiry": {
            "en": "Expiry",
            "ar": "انتهاء الصلاحية"
        },
        "Alternative Color:": {
            "en": "Alternative Color:",
            "ar": "لون بديل:"
        },
        "Thank You": {
            "en": "Thank You",
            "ar": "شكرا"
        },
        "You have successfully subscribed to the product": {
            "en": "You have successfully subscribed to the product",
            "ar": "لقد تم الاشتراك لهذا المنتج"
        },
        "Your subscription ID is": {
            "en": "Your subscription ID is",
            "ar": "معرف الاشتراك الخاص بك هو"
        },
        "Subscription Details": {
            "en": "Subscription Details",
            "ar": "تفاصيل الاشتراك"
        },
        "January": {
            "en": "January",
            "ar": "يناير"
        },
        "February": {
            "en": "February",
            "ar": "فبراير"
        },
        "March": {
            "en": "March",
            "ar": "مارس"
        },
        "April": {
            "en": "April",
            "ar": "أبريل"
        },
        "May": {
            "en": "May",
            "ar": "مايو"
        },
        "June": {
            "en": "June",
            "ar": "يونيو"
        },
        "July": {
            "en": "July",
            "ar": "يوليو"
        },
        "August": {
            "en": "August",
            "ar": "أغسطس"
        },
        "September": {
            "en": "September",
            "ar": "سبتمبر"
        },
        "October": {
            "en": "October",
            "ar": "أكتوبر"
        },
        "November": {
            "en": "November",
            "ar": "نوفمبر"
        },
        "December": {
            "en": "December",
            "ar": "ديسمبر"
        },
        "Stuffing": {
            "en": "Stuffing",
            "ar": "حشوة"
        },
        "Color": {
            "en": "Color",
            "ar": "لون"
        },
        "Size": {
            "en": "Size",
            "ar": "مقاس"
        },
        "Update": {
            "en": "Update",
            "ar": "تحديث"
        },
        "Your mobile number is required in order to process your payment. Please update your mobile number from your profile": {
            "en": "Your mobile number is required in order to process your payment. Please update your mobile number from your profile",
            "ar": "رقم الهاتف مطلوب لمعالجة دفعتك. يرجى تحديث رقم هاتفك من ملف التعريف الخاص بك"
        },
        "Pending": {
            "en": "Pending",
            "ar": "معلق",
        },
        "Review and manage your selected items in the cart at Wardstation. Add or remove products, and proceed to checkout with ease. Fast delivery available across KSA. Order now!": {
            "en": "Review and manage your selected items in the cart at Wardstation. Add or remove products, and proceed to checkout with ease. Fast delivery available across KSA. Order now!",
            "ar": "قم بمراجعة وإدارة العناصر المحددة في السلة على ورد ستيشن. أضف أو احذف المنتجات ، وانتقل إلى الخروج بسهولة. يتوفر التوصيل السريع في جميع أنحاء المملكة العربية السعودية. اطلب الآن!",
        },
        "shopping cart, manage cart, review cart, cart items, add to cart, remove from cart, checkout": {
            "en": "shopping cart, manage cart, review cart, cart items, add to cart, remove from cart, checkout",
            "ar": "سلة التسوق, إدارة السلة, مراجعة السلة, عناصر السلة, إضافة إلى السلة, إزالة من السلة, الخروج",
        },
        "Review Your Cart | Wardstation": {
            "en": "Review Your Cart | Wardstation",
            "ar": "مراجعة سلتك | ورد ستيشن",
        },
    }

    return translations[text] ? translations[text][locale] : text;
}
export default _T;