import React, { useEffect } from 'react';
import Sidebar from '../../components/Profile-SideBar'
import Breadcrumbs from "../../components/BreadCrumbs";
import { useState } from "react";
import Navbar from '../../components/Header'
import Footer from '../../components/Footer'
import "../../assets/css/--component-account.css";
import Maintenance from "../../components/Maintenance";
function Layout(props){
    const [pageTitle,setPageTitle] = useState("Account")
    const [breadcrumbItems,setBreadcrumbItems] = useState([
        { title: "Home", link: "/" },
        { title: "Account", link: "" },
    ])

    const configurePageTitle = (value) => {
        setPageTitle(value)
    }
    const configureBreadcrumb = (value) => {
        setBreadcrumbItems(value)
    }
    return (
        props.maintenance ? <Maintenance/> :
        <>
            <Navbar/>
            {/* <Breadcrumbs pageTile={pageTitle} breadcrumbItems={breadcrumbItems}/> */}
            <section id="account">
                <div className="wrapper d-flex justify-between">
                    <Sidebar/>
                    <div className='account-body'>
                        <>{React.cloneElement(props.children, {methods: [configurePageTitle,configureBreadcrumb]})}</>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default Layout; 