import React, { useEffect, useRef, useState} from "react";
import { useParams } from "react-router-dom";
import { getProductsByCategory } from "../../helper/backend-methods";
import Skeleton from 'react-loading-skeleton'
import BreadCrumbs from "../../components/BreadCrumbs";
import ProductCard from '../../components/Product-Card-01'
import '../../assets/css/--page-product-catalog.css'
import BannerEn from '../../assets/images/store-banner.jpg'
import BannerAr from '../../assets/images/store-banner-ar.jpg'
import _T from "../../locale/product";
import { googleTagInitialize, pixelPageEvent, scrollToTop } from "../../common";
import Error404 from "../../components/Error404";
import { getLocale } from "../../common";
import SEO from "../../components/SEO";

function CategoryProducts(){
    const locale = getLocale()
    let params = useParams();
    const categoryID = params.id
    const [ meta, setMeta] = useState({ MetaTitle: null, MetaKeywords: null, MetaDescription: null });
    const [ btnLoading, setBtnLoading] = useState(false);
    const [ products, setProducts ] = useState([])
    const [ totalProducts, setTotalProducts ] = useState(0)
    const [ pageTotal ,setPageTotal ] = useState(0)
    const [ pageIndex ,setPageIndex ] = useState(0)
    const [ pageSize ,setPageSize ] = useState(32)
    const [ update ,setUpdate ] = useState(0)
    const [ loading, setLoading] = useState(true);
    const [ productLoading, setProductLoading] = useState(true);
    const [tagProducts, setTagProducts] = useState("");
    const [ error, setError] = useState(false);
    const appendMore = useRef(false)

    const breadcrumbItems = [
        { title: _T("Home"), link: "/" },
        { title: _T("Products"), link: "" },
    ];

    useEffect(()=>{
        pixelPageEvent();
        googleTagInitialize();
    },[])

    useEffect(()=>{
        setTimeout(() => {
            scrollToTop();
        }, 1000);
    },[categoryID])

    useEffect(()=>{
        setLoading(true)
        fetchProducts(categoryID);
    },[categoryID])

    useEffect(()=>{
        if(update){
            fetchProducts(categoryID)
        }
    },[update])

    const fetchProducts = (category) => {
        if(!appendMore.current){
            setProductLoading(true);
        }
        if(category){
            getProductsByCategory({pageIndex: pageIndex, pageSize:pageSize, ...( category && { categoryId : category } )}).then(({data})=>{
                if(data.status){
                    if(appendMore.current){
                        if(data.result.Data && data.result.Data.Products && data.result.Data.Products.length){
                            setProducts([...products, ...data.result.Data.Products])
                            setTagProducts(data.result);
                        }
                    }
                    else{
                        setPageTotal(data.result.Pages)
                        setProducts(data.result.Data.Products)
                        setTagProducts(data.result);
                        setMeta({ MetaTitle: data.result.MetaTitle, MetaKeywords: data.result.MetaKeywords, MetaDescription: data.result.MetaDescription })
                    }
                    setTotalProducts(data.result.Count)
                    setProductLoading(false)
                    setBtnLoading(false)
                    setLoading(false)
                    appendMore.current = false;
                }
                else{
                    setLoading(false)
                    setError(true)
                }
            }).catch((e)=>{
                setLoading(false)
                setError(true)
            })
        }
        else{
            setLoading(false)
            setError(true)
        }
      
    }


    const changePageSize = (e) => {
        setPageIndex(0);
        setPageSize(parseInt(e.target.value));
        setUpdate(update+1)
    }

    const loadMore = () => {
        appendMore.current = true;
        setPageIndex(pageIndex + 1)
        setBtnLoading(true)
        setUpdate(update+1)
    }

    function sanitizeHtml(input) {
        const doc = new DOMParser().parseFromString(input, 'text/html');
        return doc.body.textContent || "";
    }

    return(
        <main className="product-store-data">
            { !error ? <BreadCrumbs pageTile="Store" breadcrumbItems={breadcrumbItems} /> : null }
            { !loading ? <SEO metaTitle={meta.MetaTitle ? meta.MetaTitle : "Category | Wardstation"} metaDescription={meta.MetaDescription ? meta.MetaDescription : "Category Products"} metaKeywords={meta.metaKeywords ? meta.metaKeywords : "Category.Products"} /> : null }
            <section id="productCatalog" className="pt-4 pb-4">
                { 
                    loading ?
                    <div className="wrapper d-flex justify-between">
                        <div className="products-container">
                            <Skeleton height="350px" className="w-100 mb-2" />
                            <Skeleton height="60px" className="w-40 mb-2" />
                            <Skeleton height="40px" className="w-100 mb-2" />
                            <Skeleton height="80px" className="w-100 mb-5" />
                            <div className="products-wrapper">
                                {
                                    [...Array(6)].map((x,i)=>(
                                        <div className="single-product product-style-01 p-0 border-none dummy" key={i}>
                                            <Skeleton height="300px" style={{width: "100%"}} />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div> :
                    !error ?
                    <div className={"wrapper"}>
                        {
                            (locale==='en' && tagProducts.BannerPicturUrl) || (locale==='ar' && tagProducts.BannerPicturUrl_Ar) ?
                            <div className="product-banner">
                                <img className="product-banner-img" src={locale==='en' ? tagProducts.BannerPicturUrl : tagProducts.BannerPicturUrl_Ar } onError={(e)=>{e.target.onerror=null; e.target.src=locale === "en" ? BannerEn : BannerAr }} alt={"Category Banner" }/>
                            </div> : <></>
                        }
                        <div className="products-container">
                            <h2 className='ff-heading text-secondary fs-36 fw-900 text-capitalize'>{tagProducts.TagName}</h2>
                            <p className="mt-0 mb-3 fs-18 fw-400 text-gray-500">{sanitizeHtml(tagProducts.TagDescription===null ? " " : tagProducts.TagDescription)}</p>
                            <div className="sort-filter d-flex justify-between align-items-center flex-wrap">
                                <div className="item-showing">
                                    <p className="m-0 fs-14 fw-500 text-gray-500">{_T("Showing")} { products.length ? 1 : 0 } - { products.length } {_T("of")} {totalProducts} {_T("items")}</p>
                                </div>
                                <div className="products-sort">
                                    <div className="form-group mr-2">
                                        <label htmlFor="pageSize" className="fs-14 fw-500 text-gray-500">{_T("Show items")}</label>
                                        <select className="pageSize" id="pageSize" value={pageSize} onChange={changePageSize} >
                                            <option value="32">32</option>
                                            <option value="64">64</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {
                                !productLoading ?
                                products.length ?
                                <div className={"products-wrapper"}>
                                    {
                                        products.map((item,index)=>{
                                            return <ProductCard key={index} item={item} setToggleClass={()=>{}}/>
                                        })
                                    }
                                </div> : 
                                <p className="btn btn-alternative d-flex align-items-center m-0 w-100"><i className="ri-information-line fs-22 mr-2"></i>{_T("No Products Found")}</p> :
                                <div className="products-wrapper">
                                    {
                                        [...Array(16)].map((x,i)=>(
                                            <div className="single-product product-style-01 p-0 border-none dummy" key={i}>
                                                <Skeleton height="300px" style={{width: "100%"}} />
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {
                                pageIndex < pageTotal-1 ?
                                <div className="products-pagination d-flex align-items-center justify-center mt-3 mb-1">
                                    {
                                        btnLoading || loading ?
                                        <button className="btn btn-outline-primary" type="button">{_T("Fetching Products...")}</button> :
                                        <button className="btn btn-outline-primary" type="button" onClick={loadMore} >{_T("Show More")}</button>
                                    }
                                </div> : 
                                <div className="products-pagination d-flex align-items-center justify-center mt-3 mb-1">
                                    {
                                        btnLoading || loading ?
                                        <button className="btn btn-outline-primary" type="button">{_T("Fetching Products...")}</button> :
                                        null
                                    }
                                </div>
                            }
                        </div>
                    </div> :
                    <Error404/>
                }
            </section>
        </main>
    )
}
export default CategoryProducts