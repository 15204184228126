import { Link } from "react-router-dom"
import CartImage from "../../assets/images/cart-image.png"
import _T from "../../locale/cart"
const SubscriptionComplete = ({subscriptionId}) => {
    return(
        <div className="cart-empty-container">
            <div className="section-content text-center">
                <img src={CartImage} alt="Cart Empty"/>
                <h2 className="fs-22 fw-500 text-secondary">{_T("Thank You")}</h2>
                <p className="fs-16 fw-400 text-gray-500 mb-0">{_T("You have successfully subscribed to the product")}</p>
                <p className="fs-14 fw-400 text-gray-500 mt-0">{_T("Your subscription ID is")} {subscriptionId}</p>
                <Link to={"/account/subscription/"+subscriptionId} className="btn btn-secondary">{_T("Subscription Details")}</Link>
            </div>
        </div>
    )
}
export default SubscriptionComplete