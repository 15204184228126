import React, {useEffect, useState } from "react";
import '../../assets/css/--component-account-address.css'
import _T from "../../locale/address";

function AddressCard(props) {
    const { methodDelete, methodDefault, item, editableCard } = props
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(false);
    }, [item.Id, editableCard]);

    const markAddressDefault = (Id) => {
        setLoading(true)
        methodDefault(Id)
    }

    const deleteAddress = (Id) => {
        setLoading(true)
        methodDelete(Id)
    }

    const enableEditMode = (Id) => {
        setLoading(true)
        props.methodUpdate(Id)
    }


    return (
        <div className={"address-card-wrapper"}>
            <div className="address-card">
                <div className="address-card-details">
                    <h3 className="address-header mt-1 mb-2 d-flex align-items-center"><i className="ri-map-pin-line fs-20 mr-2"></i> {item.FirstName && item.FirstName.length ? item.FirstName+", " : ""}{item.PhoneNumber}</h3>
                    <p className="address-body m-0 d-inline-block word-wrap">{item.Address1 && item.Address1.length ? item.Address1 : ""}{item.Address2 && item.Address2.length ? ", "+item.Address2 : ""} </p>
                </div>
                {
                    editableCard ?
                    <button className="edit-btn" onClick={() => { enableEditMode(item.Id) }} ><i className="ri-settings-3-line fs-20 mr-2"></i>{_T("Update")}</button> : <></>
                }
                {
                    item.Default ?
                    <div className="address-card-tag">{editableCard ? _T("Default") : _T("Selected")}</div> :
                    <button disabled={loading} onClick={() => { markAddressDefault(item.Id) }} className="default-btn">{editableCard ? _T("Set as Default") : _T("Select Address")}</button>
                }
                {
                    editableCard ?
                    <span disabled={loading} onClick={() => { deleteAddress(item.Id) }} className="delete-btn"><i className="ri-delete-bin-line fs-18"></i></span>: <></>
                }
            </div>
        </div>
    )
}
export default AddressCard