import React, { useEffect, useRef, useState} from "react";
import { useSearchParams } from "react-router-dom";
import { getAllProduct, getProductFilters } from "../../helper/backend-methods";
import Skeleton from 'react-loading-skeleton'
import ProductSidebar from '../Products/Product-Sidebar'
import BreadCrumbs from "../../components/BreadCrumbs";
import ProductCard from '../../components/Product-Card-01'
import storeBannerEn from '../../assets/images/store-banner.jpg'
import storeBannerAr from '../../assets/images/store-banner-ar.jpg'
import '../../assets/css/--page-product-listing.css'
import _T from "../../locale/product";
import { googleTagInitialize, pixelPageEvent, scrollToTop } from "../../common";
import SEO from "../../components/SEO";

function Products(){
    const [ search, setSearch] = useSearchParams();
    const [ toggleClass, setToggleClass ] = useState(false);
    const [ sortBy, setSortBy] = useState(16);
    const [ btnLoading, setBtnLoading] = useState(false);
    const [ showSidebar, setShowSidebar ] = useState(false)
    const [ filters, setFilters ] = useState(null)
    const [ products, setProducts ] = useState([])
    const [ totalProducts, setTotalProducts ] = useState(0)
    const [ pageIndex ,setPageIndex ] = useState(0)
    const [ pageSize ,setPageSize ] = useState(32)
    const [ pageTotal ,setPageTotal ] = useState(0)
    const [ update ,setUpdate ] = useState(0)
    const [ loading, setLoading] = useState(true);
    const [ filterLoading, setFilterLoading] = useState(true);
    const [ productLoading, setProductLoading] = useState(true);
    const appendMore = useRef(false)

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar)
    }
    const breadcrumbItems = [
        { title: _T("Home"), link: "/" },
        { title: _T("Products"), link: "" },
    ];

    useEffect(()=>{
        setTimeout(() => {
            scrollToTop();
        }, 1000);
        pixelPageEvent();
        googleTagInitialize();
    },[])
    
    useEffect(()=>{
        const {category,brand,tag,occasion,keyword} = getAdditionalParams()
        fetchProducts(category,brand,tag,occasion,keyword);
    },[search])

    useEffect(()=>{
        const {category,brand,occasion,keyword} = getAdditionalParams()
        fetchFilters(category,brand,occasion,keyword);
    },[search])

    useEffect(()=>{
        if(update){
            const {tag} = getAdditionalParams();
            let activeCategories = null;
            let activeBrands = null;
            let activeOccasions = null;
            let activeColors = null;
            let minPrice = null;
            let maxPrice = null;
            let keyword =null;
            if(filters){
                activeCategories = filters.Categories.filter((item)=>item.marked).map((i)=>i.Id)
                activeBrands = filters.Manufacturer.filter((item)=>item.marked).map((i)=>i.Id)
                activeOccasions = filters.publicOccasions.filter((item)=>item.marked).map((i)=>i.Id)
                activeColors = filters.Colors.filter((item)=>item.marked).map((i)=>i.Name)
                minPrice = filters.ActiveMin
                maxPrice = filters.ActiveMax
                keyword = filters.Keyword
            }
            fetchProducts(activeCategories,activeBrands,tag,activeOccasions,keyword,activeColors,minPrice,maxPrice)
        }
    },[update])

    const getAdditionalParams = () => {
        let category = null;
        let brand = null;
        let tag = null;
        let occasion = null;
        let keyword = null;
        if(search && search.get('category') && !isNaN(search.get('category'))){
            category = parseInt(search.get('category'));
        }
        if(search && search.get('brand') && !isNaN(search.get('brand'))){
            brand = parseInt(search.get('brand'));
        }
        if(search && search.get('tag') && !isNaN(search.get('tag'))){
            tag = parseInt(search.get('tag'));
        }
        if(search && search.get('occasion') && !isNaN(search.get('occasion'))){
            occasion = parseInt(search.get('occasion'));
        }
        if(search && search.get('keyword') && search.get('keyword').length > 2 ){
            keyword = search.get('keyword');
        }
        return { category: category ? [category] : null, brand: brand ? [brand] : null, tag: tag, occasion: occasion ? [occasion] : null, keyword: keyword};
    }

    const fetchProducts = (category,brand,tag,occasion,keyword,color=null,min=null,max=null) => {
        if(!appendMore.current){
            setProductLoading(true);
        }
        let activeCategories = []
        if(category && category.length){
            activeCategories = category
        }
        if(occasion && occasion.length){
            activeCategories = [...activeCategories,...occasion]
        }
        getAllProduct({pageIndex: pageIndex, pageSize:pageSize, OrderBy: sortBy, ...( activeCategories && activeCategories.length && { SearchCategoryId: [...activeCategories]} ), ...( brand && brand.length && { SearchManufacturerId: [...brand]} ), ...( color && color.length && { Colors: [...color]} ), ...( tag && { ProductTagId : tag } ), ...( keyword && { Keyword : keyword } ), ...( min!==null && { MinPrice : min } ), ...( max!==null && { MaxPrice : max } )}).then(({data})=>{
            if(appendMore.current){
                if(data.result.Data && data.result.Data.length){
                    setProducts([...products, ...data.result.Data])
                }
            }
            else{
                setProducts(data.result.Data)
            }
            setTotalProducts(data.result.Count)
            setPageTotal(data.result.Pages)
            setProductLoading(false)
            setBtnLoading(false)
            setLoading(false)
            appendMore.current = false;
        }).catch((e)=>{
            console.log(e);
        })
    }

    const fetchFilters = (category,brand,occasion,keyword) => {
        getProductFilters().then(({data})=>{
            if(data.status){
                let apiFilters = {...data.result};
                // let apiFilters = {...data.result, publicOccasions: null};

                apiFilters = { ...apiFilters, Categories: apiFilters.Categories && apiFilters.Categories.length ? apiFilters.Categories.map((item)=>{
                    return category ? item.Id === category[0] ? { ...item, marked: true } : { ...item, marked: false } : { ...item, marked: false }
                }) : []}
                apiFilters = { ...apiFilters, Manufacturer: apiFilters.Manufacturer && apiFilters.Manufacturer.length ? apiFilters.Manufacturer.map((item)=>{
                    return brand ? item.Id === brand[0] ? { ...item, marked: true } : { ...item, marked: false } : { ...item, marked: false }
                }) : []}
                apiFilters = { ...apiFilters, publicOccasions: apiFilters.publicOccasions && apiFilters.publicOccasions.length ? apiFilters.publicOccasions.map((item)=>{
                    return occasion ? item.Id === occasion[0] ? { ...item, marked: true } : { ...item, marked: false } : { ...item, marked: false }
                }) : []}
                apiFilters = { ...apiFilters, Colors: apiFilters.Colors && apiFilters.Colors.length ? apiFilters.Colors.map((item)=>{
                    return { ...item, marked: false }
                }) : []}
                apiFilters["ActiveMax"] = apiFilters.MaxProductPrice
                apiFilters["ActiveMin"] = apiFilters.MinProductPrice
                apiFilters["Keyword"] = keyword
                setFilters(apiFilters)
                setFilterLoading(false)
            }
        }).catch((e)=>{
            setFilterLoading(false)
        })
    }


    const changePageSize = (e) => {
        setPageIndex(0);
        setPageSize(parseInt(e.target.value));
        setUpdate(update+1)
    }

    const changeSortingOrder = (e) => {
        setSortBy(e.target.value)
        setPageIndex(0);
        setUpdate(update+1)
    }
    const applyFilters = (updateFilters) => {
        setFilters(updateFilters);
        setUpdate(update+1)
    }
    const loadMore = () => {
        appendMore.current = true;
        setPageIndex(pageIndex + 1)
        setBtnLoading(true)
        setUpdate(update+1)
    }

    return(
        <main className="product-store">
            {/* <div className="product-listing-banner">
                <img src={storeBannerEn} alt="Store"/>
            </div> */}
            <BreadCrumbs pageTile="Store" breadcrumbItems={breadcrumbItems} />
            <SEO metaTitle={_T("Store | Online Flowers and Gifts | Wardstation")} metaDescription={_T("Explore Wardstation's online store for a wide range of beautiful flowers and thoughtful gifts. Find stunning bouquets, delicious chocolates, and delightful cakes to show love and appreciation for any occasion. With quick delivery available across KSA, experience the convenience of shopping with Wardstation. Order now!")} metaKeywords={_T("flower shop, gift shop, flowers delivery, chocolates, cakes, Wardstation, KSA, online gifts shop")} />
            <section id="productList">
                { 
                    loading ?
                    <div className="wrapper d-flex justify-between">
                        <aside className="product-sidebar">
                            <Skeleton height="1100px" style={{width: "100%"}} />
                        </aside>
                        <div className="products-container">
                            <Skeleton height="80px" className="w-100 mb-5" />
                            <div className="products-wrapper">
                                {
                                    [...Array(6)].map((x,i)=>(
                                        <div className="single-product product-style-01 p-0 border-none dummy" key={i}>
                                            <Skeleton height="300px" style={{width: "100%"}} />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div> :
                    <div className={ showSidebar ? "wrapper d-flex justify-between sidebar-open" : "wrapper d-flex justify-between"}>
                        {/* <Sidebar FilterOptions={filters} update={updateFilters} toggle={toggleSidebar} content={content}/> */}
                        {
                            filterLoading ?
                            <Skeleton height="1100px" style={{width: "100%"}} /> :
                            <ProductSidebar filterOptions={filters} updateFilters={applyFilters} toggle={toggleSidebar}/>
                        }
                        <div className="products-container">
                            <div className="sort-filter d-flex justify-between align-items-center flex-wrap">
                                <div className="item-showing">
                                    <p className="m-0 fs-14 fw-500 text-gray-500">{_T("Showing")} { products.length ? 1 : 0 } - { products.length } {_T("of")} {totalProducts} {_T("items")}</p>
                                </div>
                                <div className="products-filter-handle">
                                    <button className="btn-filter" type="buton" onClick={()=>{ toggleSidebar() }} ><i className="ri-equalizer-line mr-3"></i> {_T("Filter")}</button>
                                </div>
                                <div className="products-sort">
                                    <div className="form-group mr-2">
                                        <label htmlFor="pageSize" className="fs-14 fw-500 text-gray-500">{_T("Show items")}</label>
                                        <select className="pageSize" id="pageSize" value={pageSize} onChange={changePageSize} >
                                            <option value="32">32</option>
                                            <option value="64">64</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="productSort" className="fs-14 fw-500 text-gray-500">{_T("Sort By:")}</label>
                                        <select className="productSort" id="productSort" value={sortBy} onChange={changeSortingOrder}>
                                            <option value="16">{_T("Most Recent")}</option>
                                            <option value="17">{_T("Least Recent")}</option>
                                            <option value="10">{_T("Lowest Price")}</option>
                                            <option value="11">{_T("Highest Price")}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                                {
                                    !productLoading ?
                                    products.length ?
                                    <div className={"products-wrapper"}>
                                        {
                                            products.map((item,index)=>{
                                                return <ProductCard key={index} item={item} setToggleClass={setToggleClass}/>
                                            })
                                        }
                                    </div> : 
                                    <p className="btn btn-alternative d-flex align-items-center m-0 w-100"><i className="ri-information-line fs-22 mr-2"></i>{_T("No Products Found")}</p> :
                                    <div className="products-wrapper">
                                        {
                                            [...Array(16)].map((x,i)=>(
                                                <div className="single-product product-style-01 p-0 border-none dummy" key={i}>
                                                    <Skeleton height="300px" style={{width: "100%"}} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                                {
                                    totalProducts > products.length ?
                                    <div className="products-pagination d-flex align-items-center justify-center mt-3">
                                        {
                                            btnLoading || loading ?
                                            <button className="btn btn-outline-primary" type="button">{_T("Fetching Products...")}</button> :
                                            <button className="btn btn-outline-primary" type="button" onClick={loadMore} >{_T("Show More")}</button>
                                        }
                                    </div> : <></>
                                }
                        </div>
                    </div>
                }
            </section>
        </main>
    )
}
export default Products