
import PersonalInfo from '../pages/Account/account-personal-info';
import SignIn from '../pages/Authentication/Sign-In';
import SignUp from '../pages/Authentication/Sign-Up';
import Home from '../pages/Home/'
import ProductDetail from '../pages/Products/Product-Details';
import Products from '../pages/Products/Product-List';
import Address from '../pages/Account/account-address'
import Occasion from '../pages/Account/account-occasion';
import PaymentMethod from '../pages/Account/account-payment-method';
// import ChangePassword from '../pages/Account/account-change-password';
import CartMain from '../pages/Cart/cart-main-page';
import UserOrders from '../pages/Account/account-orders';
import OrderDetail from '../pages/Account/order-details';
import CartCheckout from '../pages/Cart/cart-checkout';
import TransactionHistory from '../pages/Account/account-transaction-history';
import Favourites from '../pages/Account/account-favourites';
import Subscription from '../pages/Subscription';
import SubProductDetailCard from '../pages/Subscription/Sub-Product-Detail-Card';
import AccountSubscription from '../pages/Account/account-subscription';
import AboutUs from '../pages/About Us';
import PrivacyPolicy from '../pages/Privacy Policy';
import TermsAndConditions from '../pages/Terms & Conditions';
import SubscriptionDetailCard from '../pages/Account/subscription-detail-card';
import OccasionDetails from '../pages/Account/occasion-details';
import Logout from '../pages/Account/account-logout';
import ForgotPassword from '../pages/Authentication/Forgot-Password';
import ResetPassword from '../pages/Authentication/Reset-Password';
import Faqs from '../pages/Faqs';
import TaggedProducts from '../pages/Tagged Products';
import CategoryProducts from '../pages/Category Products';
import BrandProducts from '../pages/Brand Products';
import BlogComponent from '../pages/Blog';
import BlogDetails from '../pages/Blog/blog-details';

const baseRoutes = [
	{ path: "/login", element: SignIn },
	{ path: "/register", element: SignUp },
	{ path: "/forgot-password", element: ForgotPassword },
	{ path: "/:locale/reset-password/:token/:guid", element: ResetPassword },
	{ path: "/reset-password/:token/:guid", element: ResetPassword },
]
const protectedRoutes = [
	{ path: "/account/profile", element: PersonalInfo },
	{ path: "/account/addresses", element: Address },
	{ path: "/account/occasion", element: Occasion },
	{ path: "/account/payment-method", element: PaymentMethod },
	// { path: "/account/change-password", element: ChangePassword },
	{ path: "/account/orders", element: UserOrders },
	{ path: "/account/orders/:id", element: OrderDetail },
	{ path: "/account/subscription", element: AccountSubscription },
	{ path: "/account/subscription/:id", element: SubscriptionDetailCard },
	{ path: "/account/transaction-history", element: TransactionHistory },
	{ path: "/account/favourites", element: Favourites },
	{ path: "/account/occasion/:id", element:OccasionDetails},
	{ path: "/account/logout", element: Logout },
]
const publicRoutes = [
	{ path: "/", element: Home },
	{ path: "/products", element: Products },
	{ path: "/tagged-products/:id/:title", element: TaggedProducts },
	{ path: "/occasion-products/:id/:title", element: CategoryProducts },
	{ path: "/category-products/:id/:title", element: CategoryProducts },
	{ path: "/brand-products/:id/:title", element: BrandProducts },
	{ path: "/product/:id", element: ProductDetail },
	{ path: "/product/:id/:title", element: ProductDetail },
	{ path: "/cart", element: CartMain },
	{ path: "/cart/checkout", element: CartCheckout },
	{ path: "/subscription", element: Subscription },
	{ path: "/subscription/product/:id", element: SubProductDetailCard },
	{ path: "/subscription/product/:id/:title", element: SubProductDetailCard },
	{ path: "/about", element: AboutUs },
	{ path: "/privacy-policy", element: PrivacyPolicy },
	{ path: "/en/privacy-policy", element: PrivacyPolicy, hideHeaderFooter : true },
	{ path: "/ar/privacy-policy", element: PrivacyPolicy, hideHeaderFooter : true },
	{ path: "/terms-and-conditions", element : TermsAndConditions},
	{ path: "/en/terms-and-conditions", element : TermsAndConditions, hideHeaderFooter : true},
	{ path: "/ar/terms-and-conditions", element : TermsAndConditions, hideHeaderFooter : true},
	{ path: "/frequently-asked-questions", element : Faqs},
	{ path: "/en/frequently-asked-questions", element : Faqs, hideHeaderFooter : true},
	{ path: "/ar/frequently-asked-questions", element : Faqs, hideHeaderFooter : true},
	{ path: "/blogs", element: BlogComponent },
	{ path: "/blog/:id", element: BlogDetails },
	{ path: "/blog/:id/:title", element: BlogDetails },
]


export { protectedRoutes, publicRoutes, baseRoutes };