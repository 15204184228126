function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "Page Not Found": {
            "en": "Page Not Found",
            "ar": "الصفحة غير موجودة"
        },
        "We are very sorry for inconvenience. It seems you are trying to access a page that either has been deleted or does not exist.": {
            "en": "We are very sorry for inconvenience. It seems you are trying to access a page that either has been deleted or does not exist.",
            "ar": "نحن نأسف جدا للإزعاج. يبدو أنك تحاول الوصول إلى صفحة تم حذفها أو غير موجودة."
        },
        "Visit Home Page": {
            "en": "Visit Home Page",
            "ar": "قم بزيارة الصفحة الرئيسية"
        }
    }


    return translations[text] ? translations[text][locale] : text;
}
export default _T;