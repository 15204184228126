import _T from "../locale/header"

export default function Topbar(props) {
    const {toggle,trigger} = props

    return(
        <div className={`${toggle ? "active" : ""} nav-topbar`}>
            <div className="wrapper d-flex align-items-center justify-center">
                <a href="tel:00966554255420" target="_blank" rel="noreferrer" className="text-en d-inline-block fs-14 fw-400 text-light pt-1 pb-1 decoration-none mx-3"><i className="ri-headphone-line fs-16"></i> +966 554 255 420</a>
                <a href="mailto:support@wardstation.com" target="_blank" rel="noreferrer" className="text-en d-inline-block fs-14 fw-400 text-light pt-1 pb-1 decoration-none mx-3"><i className="ri-mail-open-line fs-16"></i> support@wardstation.com</a>
                {/* <button className="text-btn cursor-pointer" onClick={()=>{trigger(false)}} type="button"><i className="ri-close-line text-light fs-16 fw-400"></i></button> */}
            </div>
        </div>
    )
}