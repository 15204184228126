// Authentication
export const LOGIN = "api/auth/login"
export const REGISTER = "api/auth/register"
export const SOCIAL_LOGIN = "api/auth/socialLogin"
export const CHECK_REFERRAL = "api/auth/checkReferralCode"
export const POST_REFERRAL = "api/auth/postReferralCode"
export const FORGOT_PASSWORD = "api/auth/passwordRecovery"
export const RESET_PASSWORD = "api/auth/passwordReclaim"

//Home page
export const GETNAVITEMS = "api/home/getNavItems"
export const HOMEBANNER = "api/Home/webbanner"
export const CATMAN = "api/Home/catman"
export const WEBHOMETAGED = "api/Home/webhometagged";
export const PER_OCCASIONS = "api/Home/pocca";

//PRODUCTS

export const GETPRODUCTFILTER = "api/product/getAllFilters"
export const GETALLPRODUCT = "api/Product/getAllProductWithFilters"
export const GETPRODUCTDETAIL = "api/product/GetProductByIdWithRelatedProducts"
export const UPDATEWISHLIST = "api/WishList/post"
export const ADDTOOCCASION = "api/customer/postOccasionCartItems"
export const UPLOAD_MEDIA = "api/cart/uploadVideo"
export const GET_PRODUCTS_BY_TAG = "api/product/getByTagId"
export const GET_PRODUCTS_BY_CATEGORY = "api/product/getByCategoryId"
export const GET_PRODUCTS_BY_BRAND = "api/product/getByManufactureId"


//CART

export const ADDPRODUCTCART = "api/cart/post"
export const GETCARTPRODUCT = "api/cart/get"
export const DELCARTPRODUCT = "api/cart/delete"
export const APPLYCOUPONCODE = "api/cart/applyCoupon"
export const REMOVECOUPON = "api/cart/removeCoupon"
export const UPDATECART = "api/cart/update"
export const ADDGIFTCARD = "api/cart/AddGiftCard"
export const GETGIFTCARD = "api/cart/getGiftCard"
export const REMOVE_GIFT_CARD = "api/cart/removeGiftCard"
export const GET_FONTS = "api/cart/getAllFonts"
export const ADD_BADGE_INFO = "api/cart/addBadgeInfo"
export const REMOVE_BADGE_INFO = "api/cart/deleteBadge"
export const GETAVAILABLESLOT = "api/customer/AvailableSlots"
export const GETAVAILABLESLOTBYDATE = "api/customer/AvailableSlotsByDate"
export const ADD_NEW_CARD = "api/Checkout/AddCardDetails"
export const CART_CHECKOUT = "api/Order/Checkout"
export const LAST_ORDER = "api/Order/checkLastOrderStatus"

//PROFILE
export const GETPROFILEINFO = "api/customer/get"
export const CHANGEPASSWORD = "api/auth/changePassword"
export const GETORDERLIST = "api/Order/OrderHistory"
export const GETORDERDETAILS = "api/Order/trackOrder"
export const CANCEL_ORDER = "api/Order/cancelOrder"
export const CANCEL_ORDER_REASON = "api/order/getOrderReason"
export const ADD_CANCEL_ORDER_REASON = "api/order/addOrderReason"
export const RE_ORDER = "api/cart/reOrder"
export const GETADDRESSLIST = "api/customer/getAddressList"
export const DELETEADDRESS = "api/customer/DeleteAddress"
export const ADD_NEW_ADDRESS = "api/customer/postAddress"
export const UPDATE_ADDRESS = "api/customer/updateAddress"
export const GETPAYMENTCARDDETAILS = "api/customer/Cards"
export const GETCARDDETAILS = "api/customer/Cards"
export const DELETE_CARD = "api/customer/Card"
export const SET_CARD_DEFAULT = "api/Checkout/SetDefaultCard"
export const GET_ORDER_STATUS = "api/Order/getStatusforSecurePayments"
export const UPDATEPROFILEINFO = "api/customer/post"
export const GETTRANSACTIONHISTORY = "api/customer/Wallet"
export const GETUPCOMINGOCCASION = "api/customer/GetOccasionList"
export const ADDUPCOMINGOCCASION = "api/customer/postOccasion"
export const UPDATEUPCOMINGOCCASION = "api/customer/updateOccasion"
export const DELETEUPCOMINGOCCASION = "/api/customer/DeleteOccasion"
export const GETOCCASIONTYPES = "api/customer/getOccasionTypes"
export const GETFAVORITES = "api/WishList/wishlistGrocery"
export const GETSUBSCRIPTIONLIST = "api/Order/subscriptions"
export const GETSUBSCRIPTIONORDERDETAIL = "api/Order/getSubscriptionOrders"
export const GETOCCASIONBYID = "api/customer/getOccasionById"
export const GET_OCCASION_ORDERS = "api/Order/getOrdersForOccasion"
export const DELOCCASIONCARTITEM = "api/customer/deleteOccasionCartItems"
export const UPDATE_DEFAULT_ADDRESS = "api/customer/UpdateDefaultAddress"
export const GET_AREAS = "api/home/getAreasByOperationWarehouseId"
export const UPDATE_AREA = "api/customer/post"
export const GET_OPERATION_WAREHOUSE = "api/Home/getOperationWarehouse"
export const DELETE_ACCOUNT = "api/customer/DeleteCustomerAccount"
export const CANCEL_SUPSCRIPTION = "api/order/cancelSubscription"

//BLOG
export const GET_BLOG = "api/contentManagement/getAllBlogs"
export const BLOG_BYID = "api/contentManagement/getBlogById"


