import React, { useEffect, useState } from "react";
import "../../assets/css/--page-cart-checkout.css";
import flowerImage from "../../assets/images/flower.png";
import moment from 'moment/moment';
import { Link } from "react-router-dom";
import { cardIcons } from "../../data/payment-cards";
import _T from "../../locale/cart";
import { convertDateTimeWithLocale } from "../../common";
function CartCheckout({checkout}) {
    const [loading,setLoading] = useState(true);
    const [summary,setSummary] = useState(null);
    const [cartItems,setCartItems] = useState(null);
    const [defaultAddress, setDefaultAddress] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [dateTime, setDateTime] = useState(null);
    const [vouchers, setVouchers] = useState(null);
    const [data,setData] = useState(null);

    useEffect(()=>{
        if(checkout){
            setData(checkout)
            setSummary(checkout.summary)
            setCartItems(checkout.item)
            setDefaultAddress(checkout.address.find((a)=>a.Default))
            setPaymentMethod(checkout.cards.find((c)=>c.Selected))
            setDateTime({ date: checkout.dateSlot.Date, time: checkout.timeSlot.StartTime+" - "+checkout.timeSlot.EndTime })
            setVouchers(checkout.coupon)
            setLoading(false)
        }
    },[checkout])
    return (
        !loading ?
        <div className="cart-checkout">
            <div className="text-center checkout-main-header">
                <img src={flowerImage} alt="checkout" className="checkout-icon"/>
                <h2 className="ff-heading fw-600">{_T("Thank you for placing the Order")}</h2>
            </div>
            <div className="checkout-container">
                <div className="checkout-left">
                    <div className="checkout-header">
                        <div className="header-item order-id">
                            <h5 className="text-primary">{_T("Order No")}</h5>
                            <p>{data.checkout.OrderNumber}</p>
                        </div>
                        <div className="header-item order-date">
                            <h5 className="text-primary">{_T("Order Date")}</h5>
                            <p>{convertDateTimeWithLocale(moment().format("MMM DD, YYYY"))}</p>
                        </div>
                        <div className="header-item expected-delivery">
                            <h5 className="text-primary">{_T("Expected Delivery")}</h5>
                            <p>{convertDateTimeWithLocale(moment(data.checkout.ExpectedDate).format("MMM DD, YYYY"))}</p>
                        </div>
                        <div className="header-item order-status">
                            <h5 className="text-primary">{_T("Status")}</h5>
                            <p>{_T("Pending")}</p>
                        </div>
                        <div className="header-action">
                            <Link to={"/account/orders/"+data.checkout.Id} className="btn btn-primary">{_T("View Details")}</Link>
                        </div>
                    </div>
                    <div className="checkout-body">
                        <div className="checkout-product-list">
                            <div className="checkout-product-header">
                                <div className="header-item header-title">{_T("Products")}</div>
                                <div className="header-item header-qty">{_T("Quantity")}</div>
                                <div className="header-item header-price">{_T("Price")}</div>
                            </div>
                            <div className="checkout-product-body">
                                {
                                    cartItems.map((item,index)=>{
                                        return <div key={index} className={`checkout-product-row ${item.Product.IsFreeProductAdded ? "free-product" : ""}`}>
                                            <div className="data-item data-title-image-color">
                                                <div className="product-image">
                                                    <img src={item.Product.PicturURL} alt="Product"></img>
                                                </div>
                                                <div className="product-title-color">
                                                    <span className="product-title">{item.Product.Name}</span>
                                                    {
                                                        item.Product.IsFreeProductAdded ? <p className="short-description">{item.Product.ShortDescription}</p> : null
                                                    }
                                                    {
                                                        item.CartAlternateProductDTO && item.CartAlternateProductDTO.length ?
                                                        <div className="product-color">
                                                            <div>{_T("Alternative Color")}</div>
                                                            <span className="color-option" style={{ backgroundColor: item.CartAlternateProductDTO[0].Color.ColorSquaresRgb }}></span>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="data-item data-qty text-en">{ item.Product.Quantity }</div>
                                            <div className="data-item data-price text-en">SAR {item.Product.Total}</div>
                                            { item.Product.IsFreeProductAdded ? <div className="data-item data-gift"><span className="product-tag"><i className="ri-gift-line"></i> {_T("Free Gift")}</span></div> : null }
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className="checkout-summary">
                            <div className="summary-item item-subtotal">
                                <h3 className="label">{_T("Sub Total")}<span>{_T("(Inclusive of VAT)")}</span></h3>
                                <h3 className="price text-en">SAR {summary.subTotal.toFixed(2)}</h3>
                            </div>
                            {
                                summary.discount ?
                                <div className="summary-item item-discount">
                                    <h3 className="label">{_T("Discount")}</h3>
                                    <h3 className="price text-en">SAR {summary.discount.toFixed(2)}</h3>
                                </div> : null
                            }
                            <div className="summary-item item-vat">
                                <h3 className="label">{_T("VAT Included")}</h3>
                                <h3 className="price text-en">SAR {summary.vat.toFixed(2)}</h3>
                            </div>
                            <div className="summary-item item-delivery">
                                <h3 className="label">{_T("Delivery Charges")}</h3>
                                <h3 className="price text-en">SAR {summary.shipping.toFixed(2)}</h3>
                            </div>
                            <div className="summary-item item-total">
                                <h3 className="label">{_T("Total")}</h3>
                                <h3 className="price text-en">SAR {summary.total.toFixed(2)}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="checkout-right">
                    {
                        defaultAddress ?
                        <div className="item-card delivery-address">
                            <div className="card-header">
                                <i className="ri-truck-line"></i> 
                                <h3 className="data-title">{_T("Delivery Address")}</h3>
                            </div>
                            <div className="card-body">
                                <p>{defaultAddress.FirstName && defaultAddress.FirstName.length ? defaultAddress.FirstName+", " : ""}{defaultAddress.PhoneNumber}<br/>{defaultAddress.Address1 && defaultAddress.Address1.length ? defaultAddress.Address1 : ""}{defaultAddress.Address2 && defaultAddress.Address2.length ? ", "+defaultAddress.Address2 : ""}</p>
                            </div>
                        </div>
                        : null
                    }
                    <div className="item-card delivery-datetime">
                        <div className="card-header">
                            <i className="ri-time-line"></i> 
                            <h3 className="data-title">{_T("Delivery Date and Time")}</h3>
                        </div>
                        <div className="card-body">
                            <div className="delivery-date">
                                <h4><i className="ri-calendar-2-line"></i>{_T("Delivery Date")}</h4>
                                <p>{ convertDateTimeWithLocale(moment(dateTime.date).format("DD MMM YYYY")) }</p>
                            </div>
                            <div className="delivery-time">
                                <h4><i className="ri-timer-2-line"></i>{_T("Delivery Time")}</h4>
                                <p>{ dateTime.time }</p>
                            </div>
                        </div>
                    </div>
                    <div className="item-card delivery-payment">
                        <div className="card-header">
                            <i className="ri-bank-card-line"></i> 
                            <h3 className="data-title">{_T("Payments Details")}</h3>
                        </div>
                        <div className="card-body">
                            <div className="payment-info">
                                <p>{paymentMethod.Scheme} •••• •••• •••• {paymentMethod.Last_four}</p>
                                <img src={cardIcons[paymentMethod.Scheme.toLowerCase().replaceAll(" ", "-")] ? cardIcons[paymentMethod.Scheme.toLowerCase().replaceAll(" ", "-")] : cardIcons["undefined"]} alt="Payment Card"></img>
                            </div>
                        </div>
                    </div>
                    <Link to={"/products"} className="btn btn-primary w-100 mt-3">{_T("Continue Shopping")}</Link>
                </div>
            </div>
        </div> :
        <div className="payment-loading text-center">
            <div className="payment-loading-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <h2 className="fs-22 fw-500 text-secondary">{_T("Please Wait")}</h2>
            <p className="fs-16 fw-400 text-gray-400">{_T("Please wait while your payment is being processed")}</p>
        </div>
    );
}
export default CartCheckout;
