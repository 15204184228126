import React, { useEffect } from "react";
import "../../assets/css/--page-additional.css";
import BreadCrumbs from "../../components/BreadCrumbs"
import { googleTagInitialize, pixelPageEvent, scrollToTop } from "../../common";
import _T from "../../locale/faqs";
import SEO from "../../components/SEO";

function Faqs() {
    useEffect(() => {
        scrollToTop();
    }, []);
    useEffect(()=>{
        pixelPageEvent();
        googleTagInitialize();
    },[])

    const breadcrumbItems = [
        { title: _T("Home"), link: "/" },
        { title: _T("FAQs"), link: "" },
    ]
    return (
        <section className="additional-page">
            <div className="page-header text-center underline">
                <h1 className="has-arrow-start ff-heading has-arrow-white fw-900">{_T("FAQs")}</h1>
            </div>
            <BreadCrumbs pageTile="FAQs" breadcrumbItems={breadcrumbItems} />
            <SEO metaTitle={_T("Frequently Asked Questions | Wardstation")} metaDescription={_T("Find answers to common inquiries about orders, products, and services at Wardstation. If you have further questions, feel free to contact our customer support. Fast delivery available across KSA. Order now!")} metaKeywords={_T("FAQ, frequently asked questions, help, support, customer service, inquiries")} />
            <div className="wrapper">
                <div className="page-content">
                    <div>
                        <h2 className="text-primary">{_T("Information about WardStation")}</h2>
                    </div>
                    <div>
                        <h3>{_T("What is WardStation?")}</h3>
                        <p>{_T("WardStation is an online flowers platform that sells flower arrangements and was established with a mission of 'Bringing your feelings to life'. It takes just few clicks from our platform to place an order have it delivered within a couple of hours.")}</p>
                    </div>
                    <div>
                        <h3>{_T("What does WardStation do?")}</h3>
                        <p>{_T("WardStation sources and designs the best flower arrangements and displays them over our platform for customers to choose from. Once a bouquet or the gifting is selected, WardStation prepares the arrangemetns and does the last mile delivery of it.")}</p>
                    </div>
                    <div>
                        <h2 className="text-primary">{_T("Payment / Contact")}</h2>
                    </div>
                    <div>
                        <h3>{_T("How long does the online payment refund process take?")}</h3>
                        <p>{_T("The duration of the refund process varies depending on the payment method you used.")}</p>
                    </div>
                    <div>
                        <h3>{_T("How can I contact WardStation?")}</h3>
                        <p>{_T("You can contact us anytime through live chat or email or call our hotline number during our working hours Sunday to Thursday 9AM-9PM.")}</p>
                    </div>
                    <div>
                        <h2 className="text-primary">{_T("Orders / Returns")}</h2>
                    </div>
                    <div>
                        <h3>{_T("If I placed an order, how long does it take to receive the order?")}</h3>
                        <p>{_T("WardStation offers each delivery within 2 hours.")}</p>
                    </div>
                    <div>
                        <h3>{_T("Can I do one order with different dates?")}</h3>
                        <p>{_T("Indeed you may. You can now have multiple delivery dates in one order which will serve you better especially for subscriptions.")}</p>
                    </div>
                    <div>
                        <h3>{_T("Can I track my orders?")}</h3>
                        <p>{_T("WardStation is now the 1st and only online flowers platform in the region that enables users to track their bouquets from their phones.")}</p>
                    </div>
                    <div>
                        <h3>{_T("I forgot to add an item to my order, what do I do?")}</h3>
                        <p>{_T("Contact us if you need to add, remove or change items from your order.")}</p>
                    </div>
                    <div>
                        <h2 className="text-primary">{_T("Address")}</h2>
                    </div>
                    <div>
                        <h3>{_T("Can I have multiple addresses into my account?")}</h3>
                        <p>{_T("Yes.You can add as many addresses as you need to a single account. Go to Saved Addresses and click on 'Add New Address' button.")}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Faqs;