function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "My Subscription": {
            "en": "My Subscription",
            "ar": "اشتراكي"
        },
        "Current": {
            "en": "Current",
            "ar": "الاشتراك الحالي"
        },
        "Past": {
            "en": "Past",
            "ar": "الاشتراك السابق"
        },
        "Cancelled": {
            "en": "Cancelled",
            "ar": "الاشتراك الملغي"
        },
        "As of now, there are no pending subscriptions.": {
            "en": "As of now, there are no pending subscriptions.",
            "ar": "اعتبارا من الآن ، لا توجد اشتراكات معلقة."
        },
        "As of now, there are no past subscriptions.": {
            "en": "As of now, there are no past subscriptions.",
            "ar": "اعتبارا من الآن ، لا توجد اشتراكات سابقة."
        },
        "As of now, there are no cancelled subscriptions.": {
            "en": "As of now, there are no cancelled subscriptions.",
            "ar": "اعتبارا من الآن ، لا توجد اشتراكات ملغاة."
        },
        "Start Date:": {
            "en": "Start Date:",
            "ar": "تاريخ البدء:"
        },
        "End Date:": {
            "en": "End Date:",
            "ar": "تاريخ الانتهاء:"
        },
        "Period:": {
            "en": "Period:",
            "ar": "الفترة:"
        },
        "Deliveries:": {
            "en": "Deliveries:",
            "ar": "التوصيل:"
        },
        "View Details": {
            "en": "View Details",
            "ar": "عرض التفاصيل"
        },
        "Subscription Details": {
            "en": "Subscription Details",
            "ar": "تفاصيل الاشتراك"
        },
        "product": {
            "en": "product",
            "ar": "منتج"
        },
        "Id:": {
            "en": "Id:",
            "ar": "بطاقة تعريف:"
        },
        "Remaining:": {
            "en": "Remaining:",
            "ar": "المتبقي:"
        },
        "Intervals:": {
            "en": "Intervals:",
            "ar": "الفترات:"
        },
        "Your subscription plan start from": {
            "en": "Your subscription plan start from",
            "ar": "تبدأ خطة الاشتراك الخاصة بك من"
        },
        "Payment:": {
            "en": "Payment:",
            "ar": "الدفع:"
        },
        "Subscribed Product": {
            "en": "Subscribed Product",
            "ar": "منتج مشترك"
        },
        "Quantity": {
            "en": "Quantity",
            "ar": "الكمية"
        },
        "Total": {
            "en": "Total",
            "ar": "المجموع"
        },
        "Order No:": {
            "en": "Order No:",
            "ar": "رقم الطلب:"
        },
        "Orders": {
            "en": "Orders",
            "ar": "الطلبات"
        },
        "Date:": {
            "en": "Date:",
            "ar": "التاريخ:"
        },
        "Status:": {
            "en": "Status:",
            "ar": "الحالة:"
        },
        "Color:": {
            "en": "Color:",
            "ar": "لون:"
        },
        "Red": {
            "en": "Red",
            "ar": "أحمر"
        },
        "Starting From": {
            "en": "Starting From",
            "ar": "بدءا من"
        },
        "Out of Stock": {
            "en": "Out of Stock",
            "ar": "نفدت الكمية"
        },
        "Subscription Period:": {
            "en": "Subscription Period:",
            "ar": "فترة الاشتراك:"
        },
        "Weekly": {
            "en": "Weekly",
            "ar": "أسبوعي"
        },
        "Monthly": {
            "en": "Monthly",
            "ar": "شهري"
        },
        "For subscription, deliveries should be more than one.": {
            "en": "For subscription, deliveries should be more than one.",
            "ar": "للاشتراك ، يجب أن تكون عمليات التوصيل أكثر من عملية واحدة."
        },
        "Below are the example dates only. Actual delivery dates may vary depending on your delivery slot selection": {
            "en": "Below are the example dates only. Actual delivery dates may vary depending on your delivery slot selection",
            "ar": "فيما يلي مثال تواريخ فقط. قد تختلف تواريخ التسليم الفعلية حسب اختيار فترة التوصيل الخاصة بك"
        },
        "Share:": {
            "en": "Share:",
            "ar": "مشاركة:"
        },
        "Facebook": {
            "en": "Facebook",
            "ar": "فيسبوك"
        },
        "Twitter": {
            "en": "Twitter",
            "ar": "تويتر"
        },
        "Description": {
            "en": "Description",
            "ar": "الوصف"
        },
        "Dimensions": {
            "en": "Dimensions",
            "ar": "الأبعاد"
        },
        "Remove items from Cart?": {
            "en": "Remove items from Cart?",
            "ar": "إزالة العناصر من العربة؟"
        },
        "If you want to subscribe this product, your previously added items will be automatically removed from your cart.": {
            "en": "If you want to subscribe this product, your previously added items will be automatically removed from your cart.",
            "ar": "إذا كنت ترغب في الاشتراك في هذا المنتج ، فسيتم إزالة العناصر المضافة مسبقًا تلقائيًا من عربة التسوق الخاصة بك."
        },
        "Yes, Remove": {
            "en": "Yes, Remove",
            "ar": "نعم ، أزل"
        },
        "/ delivery": {
            "en": "/ delivery",
            "ar": "/ توصيل"
        },
        "(VAT included)": {
            "en": "(VAT included)",
            "ar": "(شملت ضريبة القيمة المضافة)"
        },
        "Subscribed": {
            "en": "Subscribed",
            "ar": "مشترك"
        },
        "Subscribe": {
            "en": "Subscribe",
            "ar": "اشترك"
        },
        "Product": {
            "en": "Product",
            "ar": "منتج"
        },
        "Subscription": {
            "en": "Subscription",
            "ar": "الاشتراك"
        },
        "Home": {
            "en": "Home",
            "ar": "الصفحة الرئيسية"
        },
        "Flower Subscription": {
            "en": "Flower Subscription",
            "ar": "اشتراك الورود"
        },
        "We think that flowers shouldn't just be for the big occasions. They're here to brighten the everyday too. Flowers are perfect to lighten your space or gift for friends and loved ones.": {
            "en": "We think that flowers shouldn't just be for the big occasions. They're here to brighten the everyday too. Flowers are perfect to lighten your space or gift for friends and loved ones.",
            "ar": "نعتقد أن الزهور لا ينبغي أن تكون فقط للمناسبات الكبيرة. إنها موجودة لإشراقة كل يوم. الزهور مثالية لتزيين مساحتك أولإهدائها للأصدقاء والأحباء."
        },
        "How it works?": {
            "en": "How it works?",
            "ar": "الطريقة؟"
        },
        "Choose from our special collection with our best range of flowers": {
            "en": "Choose from our special collection with our best range of flowers",
            "ar": "اختر من مجموعتنا الخاصة  من أفضل الزهور لدينا"
        },
        "Select the starting date, and other subscription details.": {
            "en": "Select the starting date, and other subscription details.",
            "ar": "حدد تاريخ البدء ، وتفاصيل الاشتراك الأخرى."
        },
        "Choose your preferred payment method: Credit or Debit Card": {
            "en": "Choose your preferred payment method: Credit or Debit Card",
            "ar": "اختر طريقة الدفع المفضلة لديك: بطاقة الائتمان أو الخصم"
        },
        "Available Subscription Plans": {
            "en": "Available Subscription Plans",
            "ar": "خطط الاشتراك المتاحة"
        },
        "There are currently no available subscription plans": {
            "en": "There are currently no available subscription plans",
            "ar": "لا توجد حاليًا خطط اشتراك متاحة"
        },
        "Frequently Asked Questions": {
            "en": "Frequently Asked Questions",
            "ar": "الاسئلة الشائعة"
        },
        "What is the new subscription from WardStation?": {
            "en": "What is the new subscription from WardStation?",
            "ar": "ما هو الاشتراك الجديد من وردستيشن؟"
        },
        "Months": {
            "en": "Months",
            "ar": "شهور",
        },
        "Weeks": {
            "en": "Weeks",
            "ar": "أسابيع",
        },
        "Assigned": {
            "en": "Assigned",
            "ar": "تم التعيين",
        },
        "OnHold": {
            "en": "On Hold",
            "ar": "قيد الانتظار",
        },
        "Packed": {
            "en": "Packed",
            "ar": "معبأ",
        },
        "OutForDelivery": {
            "en": "Out For Delivery",
            "ar": "في طريقه إلى الوصول",
        },
        "Pending": {
            "en": "Pending",
            "ar": "معلق",
        },
        "Confirmed": {
            "en": "Confirmed",
            "ar": "مؤكد",
        },
        "Picked": {
            "en": "Picked",
            "ar": "تم التقاطه",
        },
        "Shipped": {
            "en": "Shipped",
            "ar": "تم شحنه",
        },
        "Delivered": {
            "en": "Delivered",
            "ar": "تم التسليم",
        },
        "Order Cancelled": {
            "en": "Order Cancelled",
            "ar": "تم الغاء الطلب",
        },
        "NotCreated": {
            "en": "Not Created",
            "ar": "غير تم إنشاؤه",
        },
        "Credit Card": {
            "en": "Credit Card",
            "ar": "بطاقة إئتمان",
        },
        "Card": {
            "en": "Card",
            "ar": "بطاقة"
        },
        "Debit Card": {
            "en": "Debit Card",
            "ar": "بطاقة ائتمان"
        },
        "Apple Pay": {
            "en": "Apple Pay",
            "ar": "ابل باي"
        },
        "Subscription Cancelled": {
            "en": "Subscription Cancelled",
            "ar": "تم إلغاء الاشتراك"
        },
        "Your subscription has been cancelled": {
            "en": "Your subscription has been cancelled",
            "ar": "تم إلغاء اشتراكك"
        },
        "Subscription could not be cancelled": {
            "en": "Subscription could not be cancelled",
            "ar": "لا يمكن إلغاء الاشتراك"
        },
        "Request Failed": {
            "en": "Request Failed",
            "ar": "الطلب فشل"
        },
        "Cancel Subscription": {
            "en": "Cancel Subscription",
            "ar": "إلغاء الاشتراك"
        }
    }

    return translations[text] ? translations[text][locale] : text;
}
export default _T;