function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "Welcome,": {
            "en": "Welcome,",
            "ar": "مرحباً،"
        },
        "Full Name": {
            "en": "Full Name",
            "ar": "الاسم الكامل"
        },
        "Please provide your full name": {
            "en": "Please provide your full name",
            "ar": "يرجى تقديم اسمك الكامل"
        },
        "Email": {
            "en": "Email",
            "ar": "بريد إلكتروني"
        },
        "Phone No": {
            "en": "Phone No",
            "ar": "رقم الهاتف"
        },
        "Please provide a valid phone number": {
            "en": "Please provide a valid phone number",
            "ar": "الرجاء تقديم رقم هاتف صالح"
        },
        "Phone_already_registered": {
            "en": "Phone already registered",
            "ar": "الهاتف مسجل بالفعل"
        },
        "Edit Profile": {
            "en": "Edit Profile",
            "ar": "تعديل الملف الشخصي"
        },
        "Personal Information": {
            "en": "Personal Information",
            "ar": "معلومات شخصية"
        },
        "My Address": {
            "en": "My Address",
            "ar": "عنواني"
        },
        "My Orders": {
            "en": "My Orders",
            "ar": "طلباتي"
        },
        "My Subscriptions": {
            "en": "My Subscriptions",
            "ar": "اشتراكاتي"
        },
        "Favourites": {
            "en": "Favourites",
            "ar": "المفضلة"
        },
        "My Occasion": {
            "en": "My Occasion",
            "ar": "مناسباتي"
        },
        "Payment": {
            "en": "Payment",
            "ar": "الدفع"
        },
        "Transaction History": {
            "en": "Transaction History",
            "ar": "تاريخ المعاملات"
        },
        "Logout": {
            "en": "Logout",
            "ar": "تسجيل خروج"
        },
        "Please wait while we're logging you out": {
            "en": "Please wait while we're logging you out",
            "ar": "يرجى الانتظار أثناء تسجيلنا لك"
        },
        "Cancel": {
            "en": "Cancel",
            "ar": "إلغاء"
        },
        "Update": {
            "en": "Update",
            "ar": "تحديث"
        },
        "Please Wait!": {
            "en": "Please Wait!",
            "ar": "انتظر من فضلك!"
        },
        "Profile Updated": {
            "en": "Profile Updated",
            "ar": "تحديث الملف الشخصي"
        },
        "Your profile information has been updated!": {
            "en": "Your profile information has been updated!",
            "ar": "تم تحديث معلومات ملفك الشخصي!"
        },
        "Update Failed": {
            "en": "Update Failed",
            "ar": "فشل التحديث"
        },
        "Your profile information could not be updated!": {
            "en": "Your profile information could not be updated!",
            "ar": "لا يمكن تحديث معلومات ملفك الشخصي!"
        },
        "Enter your full name": {
            "en": "Enter your full name",
            "ar": "أدخل اسمك الكامل"
        },
        "Enter phone number": {
            "en": "Enter phone number",
            "ar": "أدخل رقم الهاتف"
        },
        "Change Password": {
            "en": "Change Password",
            "ar": "تغيير كلمة المرور"
        },
        "Current Password": {
            "en": "Current Password",
            "ar": "كلمة السر الحالية"
        },
        "New Password": {
            "en": "New Password",
            "ar": "كلمة المرور الجديدة"
        },
        "Password must have minimum 6 characters": {
            "en": "Password must have minimum 6 characters",
            "ar": "يجب أن تحتوي كلمة المرور على 6 أحرف على الأقل"
        },
        "Your old password was incorrect!": {
            "en": "Your old password was incorrect!",
            "ar": " كلمة المرور القديمة غير صحيحة!"
        },
        "Your new password can not be same as your old password!": {
            "en": "Your new password can not be same as your old password!",
            "ar": "لا يمكن أن تكون كلمة المرور الجديدة الخاصة بك هي نفس كلمة المرور القديمة!"
        },
        "Your password could not be updated!": {
            "en": "Your password could not be updated!",
            "ar": "لا يمكن تحديث كلمة المرور الخاصة بك!"
        },
        "Password Updated": {
            "en": "Password Updated",
            "ar": "تم تحديث كلمة السر"
        },
        "Save Changes": {
            "en": "Save Changes",
            "ar": "حفظ التغييرات"
        },
        "Request Failed": {
            "en": "Request Failed",
            "ar": "الطلب فشل"
          },
          "Your account could not be deleted": {
            "en": "Your account could not be deleted",
            "ar": "لا يمكن حذف حسابك"
          },
          "Delete Account Permanently": {
            "en": "Delete Account Permanently",
            "ar": "حذف الحساب بشكل دائم"
          },
          "Are you sure you want to delete your account permanently?": {
            "en": "Are you sure you want to delete your account permanently?",
            "ar": "هل أنت متأكد من أنك تريد حذف حسابك بشكل دائم؟"
          },
          "Cancel": {
            "en": "Cancel",
            "ar": "إلغاء"
          },
          "Yes, Delete": {
            "en": "Yes, Delete",
            "ar": "نعم، حذف"
          }
    }

    return translations[text] ? translations[text][locale] : text;
}
export default _T;