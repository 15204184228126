import React, { useState } from "react";
import { Link } from "react-router-dom";
import '../../assets/css/--component-product-02.css'
import _T from "../../locale/subscription";
import { getLocale, isAuthenticated, pixelTrackEvent } from "../../common";
import { updateWishlist } from "../../helper/backend-methods";

function SubProductCard({item}){
    const productId = item.Id;
    const [IsInWishlist, setIsInWishlist] = useState(item ? item.IsInWishlist : false);
    const isAuthorized = isAuthenticated();
    const locale = getLocale();

    const addRemoveWishlist = () => {
        updateWishlist({ ProductId: productId, Add: !IsInWishlist }).then(({data})=>{
            if(data.status){
                if(IsInWishlist){
                    pixelTrackEvent("AddToWishlist",{ content_ids: [productId], content_type: 'product', value: item.ProductPrice.Price, currency: 'SAR' })
                }        
                setIsInWishlist(!IsInWishlist)
            }
        }).catch((e)=>{
            console.log(e)
        })
    }

    return(
        <div className="product-card product-style-02">
            { item.StockDetails === "Out of stock" ? <span className="product-tag">{_T("Out of stock")}</span> : <></>}

            {
                isAuthorized ?
                <button className="product-wishlist cursor-pointer" onClick={()=>{ addRemoveWishlist() }}>
                    { IsInWishlist ? <i className="ri-heart-3-fill text-secondary fs-18"></i>: <i className="ri-heart-3-line text-secondary fs-18"></i> }
                </button> : null
            }
            <div className="product-img-container">
                <Link to={`/subscription/product/${item.Id}/${item.Name ? item.Name.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : _T("product")}`} className="d-inline-block">
                    <img className="product-img" src={item.DefaultPictureModel.ImageUrl} alt={item.Name} />
                </Link>
            </div>
            <div className="product-footer">
                <div className="product-name">
                    <Link to={`/subscription/product/${item.Id}/${item.Name ? item.Name.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : _T("product")}`} className='product-title decoration-none d-inline-block' > <p className="fs-16 fw-400 text-secondary p-0 m-0">{item.Name}</p></Link>
                </div>
                {/* <div className="product-description"> */}
                    {/* <p className="fs-14 fw-400 text-gray-200 m-0">{item.ShortDescription && item.ShortDescription.length > 60 ? item.ShortDescription.slice(0,57) + "..." : item.ShortDescription}</p> */}
                    {/* <p className="fs-14 fw-400 text-gray-200 m-0">{item.ShortDescription}</p> */}
                {/* </div> */}
                <div className="product-foot-content d-flex justify-between align-items-center">
                    <div className="price d-flex align-items-center">
                        <div className="current-price">
                            <span className="text-secondary fs-16 fw-600 text-en">SAR {item.ProductPrice.Price}</span>
                        </div>
                        {
                            item.ProductPrice.OldPrice?
                            <div className="old-price ml-1">
                                <span className="fs-16 text-secondary fw-400 text-en" style={{ textDecoration: 'line-through' }}>SAR {item.ProductPrice.OldPrice}</span>
                            </div> : null
                        }
                    </div>
                    <div className="add-to-cart">
                        { 
                        item.StockDetails === "Out of stock" ?
                        <button disabled={true} className="add-to-cart-btn-disabled font-escape dir-escape"><i className={`ri-arrow-${locale === "en" ? "right": "left"}-line fs-18 font-escape dir-escape`}></i></button>:
                        <Link to={"/subscription/product/"+item.Id} className="add-to-cart-btn font-escape dir-escape"><i className={`ri-arrow-${locale === "en" ? "right": "left"}-line fs-18 font-escape dir-escape`}></i></Link>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubProductCard