import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import _T from "../locale/footer";

function SEO(props){
    const [metaData,setMetaData] = useState({ title:"Wardstation", description: "", keywords: "", ogTitle: "", ogType: "", ogUrl: "", ogImage: "", twTitle: "", twDescription: "", twImage: "" })
    useEffect(()=>{
        const mTitle = props.metaTitle ? props.metaTitle : "Wardstation";
        const mDescription = props.metaDescription ? props.metaDescription : "";
        const mKeywords = props.metaKeywords ? props.metaKeywords : "";

        const ogTitle = props.ogTitle ? props.ogTitle : "";
        const ogType = props.ogType ? props.ogType : "";
        const ogUrl = props.ogUrl ? props.ogUrl : "";
        const ogImage = props.ogImage ? props.ogImage : "";

        const twTitle = props.twTitle ? props.twTitle : "";
        const twDescription = props.twDescription ? props.twDescription : "";
        const twImage = props.twImage ? props.twImage : "";

        setMetaData({ title: mTitle, description: mDescription, keywords: mKeywords, ogTitle: ogTitle, ogType: ogType, ogUrl: ogUrl, ogImage: ogImage, twTitle: twTitle, twDescription: twDescription, twImage: twImage })
    },[props]);

    return (
        process.env.REACT_APP_ENABLE_SEO && process.env.REACT_APP_ENABLE_SEO === "YES" ?
        <Helmet>
            <title>{metaData.title}</title>
            <meta name="description" content={metaData.description} />
            <meta name="keywords" content={metaData.keywords.split(".").join(',')} />
            {/* social media meta tags - facebook  */}
            { metaData.ogTitle ? <meta property="og:title" content={metaData.ogTitle} /> : null }
            { metaData.ogType ? <meta property="og:type" content={metaData.ogType} /> : null }
            { metaData.ogUrl ? <meta property="og:url" content={metaData.ogUrl} /> : null }
            { metaData.ogImage ? <meta property="og:image" content={metaData.ogImage} /> : null }
            {/* social media meta tags - twitter */}
            { metaData.twTitle ? <meta name="twitter:title" content={metaData.twTitle}/> : null }
            { metaData.twTitle ? <meta name="twitter:description" content={metaData.twDescription}/> : null }
            { metaData.twTitle ? <meta name="twitter:image" content={metaData.twImage}/> : null }
        </Helmet> : null
    )
}
export default SEO;