import { AnalyticsBrowser } from '@segment/analytics-next'
import { getLocale, trackPermission } from '../common/index'
export const analytics = AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_SEGMENT_ANALYTICS_WRITE_KEY })

export const analyticsPageEvent = (title) => {
    const permission = trackPermission("page");
    let locale = getLocale()
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar-SA" : "en-US"
    }
    if(permission){
        analytics.page("Screen Viewed",{ screen_name: title, locale: locale ? locale : "en-US"});
    }
}
export const analyticsTrackEvent = (title,object) => {
    const permission = trackPermission("tracking");
    let locale = getLocale()
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar-SA" : "en-US"
    }
    if(permission){
        analytics.track(title, {...object,locale: locale ? locale : "en-US"});
    }
}
export const analyticsIdentityEvent = (id,object) => {
    const permission = trackPermission("identity");
    let locale = getLocale()
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar-SA" : "en-US"
    }
    if(permission){
        analytics.identify(id, object);
    }
}