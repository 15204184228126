import React, { useState, useEffect } from "react";
import cartImg from '../../assets/images/temp/Cart_Product.png'
import { delCartProduct, getCartProducts, updateCart } from "../../helper/backend-methods";
import Skeleton from "react-loading-skeleton";
import CartProduct from "./cart-products";
import _T from "../../locale/cart";

function ShoppingCart(props) {
    const {items,update,setUpdate,loading,isSubscription, triggerLoading} = props;
    return (
        loading ? 
        <Skeleton className="w-100" height={358}/> : 
        <div className="shopping-cart-container">
            <div className="shopping-cart-content">
                <div className="heading d-flex align-items-center mb-3">
                    <i className="ri-shopping-bag-line fs-20 text-secondary"></i>
                    <p className="m-0 fs-18 fw-500 text-secondary ml-1">{_T("Shopping Cart")}</p>
                </div>
                <span className="underline"></span>
                {
                    items.map((item, key) => {
                        return <CartProduct setCurrentStep={props.setCurrentStep} key={key} item={item} loading={loading} update={update} setUpdate={setUpdate} isSubscription={isSubscription} triggerLoading={triggerLoading}/>
                    })
                }
            </div>
        </div> 
    )
}
export default ShoppingCart