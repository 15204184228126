import Hero from "./Hero-Section";
import Category from "./Category-Section";
import Subscription from "./Subscription-Banner";
import { useEffect, useState } from "react";
import { getCatMan, getHomeBanner, getPerOccasions, getWebHomeTagged } from "../../helper/backend-methods";
import ProductSlider from "./Product-Slider-01";
import { getLocale, googleTagInitialize, isAuthenticated, pixelPageEvent, scrollToTop, setCurrentUser } from "../../common";
import PromoBanner from "./Promo-Banner";
import Skeleton from "react-loading-skeleton";
import Brand from "./Brand-Section";
import UpcomingOccasion from './Upcoming-occasion'
import '../../assets/css/--page-home.css'
import '../../assets/css/--arrow-buttons.css'
import ShopOccasion from "./Shop-Occasion";
import _T from "../../locale/home";
import BannerSlider from "./Banner-Slider";
import ProductGrid from "./Product-Grid";
import SEO from "../../components/SEO";

function Index(props) {
    const locale = getLocale()
    const [banners, setBanners] = useState([])
    const [sliderBanners, setSliderBanners] = useState([])
    const [subscriptionBanner, setSubscriptionBanner] = useState(null)
    const [occasionBanner, setOccasionBanner] = useState(null)
    const [category, setCategory] = useState([])
    const [tagProducts, setTagProducts] = useState([])
    const [brands, setBrands] = useState([])
    const [shopOccasion, setShopOccasion] = useState([])
    const [personalOccasion, setPersonalOccasion] = useState([])
    const [loadingBanner, setLoadingBanner] = useState(true)
    const [loadingCatMan, setLoadingCatMan] = useState(true)
    const [loadingPocca, setLoadingPocca] = useState(true)
    const [loadingTagged, setLoadingTagged] = useState(true)
    const isLoggedIn = isAuthenticated()

    useEffect(() => {
        scrollToTop();
        document.title = 'Wardstation';
        pixelPageEvent();
        googleTagInitialize();
    }, [])

    useEffect(() => {
        getHomeBanner().then(({data}) =>{
            if(data.status){
                setBanners(data.result.Banners)
                setCurrentUser(data.result.Profile)
                setOccasionBanner(data.result.OccasionBanner)
                setSliderBanners(data.result.SliderBanner)
                setLoadingBanner(false)
            }
        }).catch((e)=>{
            console.log(e)
        })
    },[])

    useEffect(() => {
        getCatMan({catLimit:0 , manLimit:0}).then(({data}) => {
            if(data.status){
                setCategory(data.result.Categories)
                setBrands(data.result.Manufacturers)
                setLoadingCatMan(false)
            }
        }).catch((e) =>{
            console.log(e)
        })
    },[])

    useEffect(() => {
        getWebHomeTagged().then(({data}) => {
            if(data.status){
                let occasions = data.result.TaggedCategories;
                if (occasions && occasions.length && occasions.find((i) => i.TagName === "Occasions")) {
                    occasions = occasions.find((i) => i.TagName === "Occasions")
                    occasions = occasions && occasions.Categories && occasions.Categories.length ? occasions.Categories : []
                }
                else {
                    occasions = [];
                }
                setShopOccasion(occasions)
                setTagProducts(data.result.TaggedProducts)
                setSubscriptionBanner(data.result.Subscription.SubscriptionBanner)
                setLoadingTagged(false)
            }
        }).catch((e) =>{
            console.log(e)
        })
    },[])

    useEffect(() => {
        getPerOccasions().then(({data}) => {
            if(data.status){
                setPersonalOccasion(data.result.PersonalOccasions)
                setLoadingPocca(false)
            }
        }).catch((e) => {
            console.log(e)
        })
    },[])

    return (
        <main>
            <SEO 
                metaTitle={_T("Online Shop for Beautiful Flowers and Gifts | Wardstation")} 
                metaDescription={_T("Discover a wide selection of beautiful flowers and heartfelt gifts at Wardstation. From stunning bouquets, chocolates and cakes to a variety of unique presents, find the perfect expression of love and appreciation for all occasions. Fast delivery available across KSA. Order now!")} 
                metaKeywords={_T("flowers shop, flowers jeddah, flowers for flowers, flower shop jeddah, gifts birthday gifts, flower bouquet, shop for flowers, flowers in flower shop, chocolates, cakes")} 
                ogTitle={_T("Online Shop for Beautiful Flowers and Gifts | Wardstation")} 
                ogType="website"
                ogUrl="https://wardstation.com"
                ogImage="https://wardstation.com/static/media/logo.fba93da058d63cfea2ce.png"
                twTitle={_T("Online Shop for Beautiful Flowers and Gifts | Wardstation")}
                twDescription={_T("Discover a wide selection of beautiful flowers and heartfelt gifts at Wardstation. From stunning bouquets, chocolates and cakes to a variety of unique presents, find the perfect expression of love and appreciation for all occasions. Fast delivery available across KSA. Order now!")} 
                twImage="https://wardstation.com/static/media/logo.fba93da058d63cfea2ce.png"
            />
            <Hero heroBanner={banners} isLoading={loadingBanner} />
            <Category category={category} isLoading={loadingCatMan} />

            {
                tagProducts[0] && tagProducts[0].Products.length ?
                    <ProductSlider className={"slider"} data={tagProducts[0]} showSeeAll={true} isLoading={loadingTagged} hasBgImage={false} hasBgColor={true} bgImage="" bgColor={"#fef8e9"} /> : <></>
            }

            {
                <BannerSlider slides={sliderBanners} isLoading={loadingBanner} />
            }

            
             {/* create grid */}
            {
                tagProducts[1] && tagProducts[1].Products.length ?
                    <ProductGrid className={""} data={tagProducts[1]} showSeeAll={true} isLoading={loadingTagged} hasBgImage={false} hasBgColor={false} bgImage={null} bgColor={""} /> : <></>
            }

            <Brand brand={brands} isLoading={loadingCatMan} />

            

            {
                tagProducts[2] && tagProducts[2].Products.length ?
                    <ProductSlider className={"slider"} data={tagProducts[2]} showSeeAll={true} isLoading={loadingTagged} hasBgImage={true} hasBgColor={true} bgImage={null} bgColor={"#E6F8EB"} isPrimary={true}/> : <></>
            }

                <ShopOccasion occasions={shopOccasion} isLoading={loadingTagged} hasBgImage={false} hasBgColor={false} />

            {
                tagProducts[3] && tagProducts[3].Products.length ?
                    <ProductSlider className={"slider"} data={tagProducts[3]} showSeeAll={true} isLoading={loadingTagged} hasBgImage={false} hasBgColor={false} bgImage={null} bgColor={""} /> : <></>
            }

            {
                loadingBanner ?
                    <section className={"promo-banner-section"}>
                        <div className="wrapper">
                            <Skeleton className="promo-banner w-100" />
                        </div>
                    </section> :
                    occasionBanner && (occasionBanner.PictureURL_Ar || occasionBanner.PictureURL) ?
                        <PromoBanner promoImageURL={locale === "ar" ? occasionBanner.PictureURL_Ar : occasionBanner.PictureURL} promoLink={occasionBanner.Link} className="" isLoading={loadingBanner} /> : <></>
            }

            {
                tagProducts[4] && tagProducts[4].Products.length ?
                    <ProductSlider className={"slider"} data={tagProducts[4]} showSeeAll={true} isLoading={loadingTagged} hasBgImage={true} hasBgColor={true} bgImage={null} bgColor={""} /> : <></>
            }

            {
                isLoggedIn ?
                    <UpcomingOccasion isPersonal={true} title={_T("Upcoming Occasions")} occasions={personalOccasion} isLoading={loadingPocca} hasBgImage={true} hasBgColor={true} bgImage={null} bgColor={"#F8FFE9"} /> : null
            }
            

            {
                <Subscription isLoading={loadingTagged} data={subscriptionBanner} />
            }

            {
                tagProducts[5] && tagProducts[5].Products.length ?
                    <ProductSlider className={"slider"} data={tagProducts[5]} showSeeAll={true} isLoading={loadingTagged} hasBgImage={true} hasBgColor={true} bgImage={null} bgColor={""} /> : <></>
            }

            {
                tagProducts[6] && tagProducts[6].Products.length ?
                    <ProductSlider className={"slider"} data={tagProducts[6]} showSeeAll={true} isLoading={loadingTagged} hasBgImage={true} hasBgColor={true} bgImage={null} bgColor={""} /> : <></>
            }
            {
                tagProducts[7] && tagProducts[7].Products.length ?
                    <ProductSlider className={"slider"} data={tagProducts[7]} showSeeAll={true} isLoading={loadingTagged} hasBgImage={true} hasBgColor={true} bgImage={null} bgColor={""} /> : <></>
            }
            {
                tagProducts[8] && tagProducts[8].Products.length ?
                    <ProductSlider className={"slider"} data={tagProducts[8]} showSeeAll={true} isLoading={loadingTagged} hasBgImage={true} hasBgColor={true} bgImage={null} bgColor={""} /> : <></>
            }
            {
                tagProducts[9] && tagProducts[9].Products.length ?
                    <ProductSlider className={"slider"} data={tagProducts[9]} showSeeAll={true} isLoading={loadingTagged} hasBgImage={true} hasBgColor={true} bgImage={null} bgColor={""} /> : <></>
            }
            {
                tagProducts[10] && tagProducts[10].Products.length ?
                    <ProductSlider className={"slider"} data={tagProducts[10]} showSeeAll={true} isLoading={loadingTagged} hasBgImage={true} hasBgColor={true} bgImage={null} bgColor={""} /> : <></>
            }

            {/* <DownloadAppBanner/> */}

        </main>
    )
}

export default Index;