import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MultiRangeSlider from "multi-range-slider-react";
import { useEffect } from "react";
import _T from "../../locale/product";

function ProductSidebar({ toggle, filterOptions, updateFilters }) {
    const [priceMaxAllowed, setPriceMaxAllowed] = useState(0)
    const [priceMaxSet, setPriceMaxSet] = useState(0)
    const [priceMinAllowed, setPriceMinAllowed] = useState(0)
    const [priceMinSet, setPriceMinSet] = useState(0)

    
    useEffect(()=>{
        setPriceMaxAllowed(filterOptions.MaxProductPrice)
        setPriceMaxSet(filterOptions.MaxProductPrice)
        setPriceMinAllowed(filterOptions.MinProductPrice)
        setPriceMinSet(filterOptions.MinProductPrice)
    },[])

    const handleRange = (e) => {
        setPriceMinSet(parseInt(e.minValue));
        setPriceMaxSet(parseInt(e.maxValue));
    };

    const applyPriceRange = () => {
        let apiFilters = filterOptions;
        apiFilters.ActiveMax = priceMaxSet;
        apiFilters.ActiveMin = priceMinSet;
        updateFilters(apiFilters)
    }

    const handleFilters = (e,value,type) => {
        let apiFilters = filterOptions;
        if(type === "category"){
            const category = parseInt(value);
            apiFilters = { ...apiFilters, Categories: apiFilters.Categories.map((item)=>{
                return item.Id === category ? { ...item, marked: e } : item
            })}
        }
        if(type === "brand"){
            const brand = parseInt(value);
            apiFilters = { ...apiFilters, Manufacturer: apiFilters.Manufacturer.map((item)=>{
                return item.Id === brand ? { ...item, marked: e } : item
            })}
        }
        if(type === "occasion"){
            const occasion = parseInt(value);
            apiFilters = { ...apiFilters, publicOccasions: apiFilters.publicOccasions.map((item)=>{
                return item.Id === occasion ? { ...item, marked: e } : item
            })}
        }
        if(type === "color"){
            const color = value;
            apiFilters = { ...apiFilters, Colors: apiFilters.Colors.map((item)=>{
                return item.Name === color ? { ...item, marked: e } : item
            })}
        }
        if(type === "keyword"){
            const keyword = value;
            apiFilters = { ...apiFilters, Keyword: keyword}
        }
        updateFilters(apiFilters)
    }

    const clearGroupFilters = (type) => {
        let apiFilters = filterOptions;
        if(type === "category" || type === "all"){
            apiFilters = { ...apiFilters, Categories: apiFilters.Categories.map((item)=>{
                return { ...item, marked: false }
            })}
        }
        if(type === "brand" || type === "all"){
            apiFilters = { ...apiFilters, Manufacturer: apiFilters.Manufacturer.map((item)=>{
                return { ...item, marked: false }
            })}
        }
        if(type === "occasion" || type === "all"){
            apiFilters = { ...apiFilters, publicOccasions: apiFilters.publicOccasions.map((item)=>{
                return { ...item, marked: false }
            })}
        }
        if(type === "color" || type === "all"){
            apiFilters = { ...apiFilters, Colors: apiFilters.Colors.map((item)=>{
                return { ...item, marked: false }
            })}
        }
        if(type === "keyword" || type === "all"){
            apiFilters = { ...apiFilters, Keyword: null }
        }
        updateFilters(apiFilters)
    }

    return (
        <aside className="product-sidebar">
            <div className="product-sidebar-overlay" onClick={() => { toggle() }}></div>
            <button className="product-sidebar-close" onClick={() => { toggle() }}><i className="ri-close-line"></i></button>
            <div className="product-sidebar-wrapper">
                <div className="sidebar-inner-container sidebar-current-filters mb-4">
                    <div className="sidebar-section-header d-flex align-items-start justify-between">
                        <h4 className="sidebar-heading fs-16 fw-500 text-gray-500">{_T("Filters")}</h4>
                        {
                            (filterOptions.Keyword && filterOptions.Keyword.length) || (filterOptions.Categories.filter((c)=>c.marked).length) || (filterOptions.Colors.filter((c)=>c.marked).length) || (filterOptions.Manufacturer.filter((c)=>c.marked).length) || (filterOptions.publicOccasions.filter((c)=>c.marked).length) ?
                            <button className="link fs-14 mt-2" type="button" onClick={()=>{ clearGroupFilters("all") }}>{_T("Clear")}</button> : null
                        }
                    </div>
                    {
                        (filterOptions.Keyword && filterOptions.Keyword.length) || (filterOptions.Categories.filter((c)=>c.marked).length) || (filterOptions.Colors.filter((c)=>c.marked).length) || (filterOptions.Manufacturer.filter((c)=>c.marked).length) || (filterOptions.publicOccasions.filter((c)=>c.marked).length) ?
                        <div className="current-filters">
                            {
                                filterOptions.Keyword && filterOptions.Keyword.length ?
                                <span className="fs-14 text-gray-500 fw-500 d-inline-flex align-items-center bg-alternative pl-2 pr-3 pt-1 pb-1 mr-2 mb-2"><i className="ri-close-line fs-12 mr-2 cursor-pointer" onClick={()=>{ clearGroupFilters("keyword") }}></i>{filterOptions.Keyword}</span> : null
                            }
                            {
                                filterOptions.Categories.filter((c)=>c.marked).map((c,i)=>{
                                    return <span key={"selectedCategory"+i} className="fs-14 text-gray-500 fw-500 d-inline-flex align-items-center bg-alternative pl-2 pr-3 pt-1 pb-1 mr-2 mb-2"><i className="ri-close-line fs-12 mr-2 cursor-pointer" onClick={()=>{ handleFilters(false,c.Id,"category") }}></i>{c.Name}</span>
                                })
                            }
                            {
                                filterOptions.Colors.filter((c)=>c.marked).map((c,i)=>{
                                    return <span key={"selectedColor"+i} className="fs-14 text-gray-500 fw-500 d-inline-flex align-items-center bg-alternative pl-2 pr-3 pt-1 pb-1 mr-2 mb-2"><i className="ri-close-line fs-12 mr-2 cursor-pointer" onClick={()=>{ handleFilters(false,c.Name,"color") }}></i>{c.Name}</span>
                                })
                            }
                            {
                                filterOptions.Manufacturer.filter((c)=>c.marked).map((c,i)=>{
                                    return <span key={"selectedManufacturer"+i} className="fs-14 text-gray-500 fw-500 d-inline-flex align-items-center bg-alternative pl-2 pr-3 pt-1 pb-1 mr-2 mb-2"><i className="ri-close-line fs-12 mr-2 cursor-pointer" onClick={()=>{ handleFilters(false,c.Id,"brand") }}></i>{c.Name}</span>
                                })
                            }
                            {
                                filterOptions.publicOccasions.filter((c)=>c.marked).map((c,i)=>{
                                    return <span key={"selectedOccsion"+i} className="fs-14 text-gray-500 fw-500 d-inline-flex align-items-center bg-alternative pl-2 pr-3 pt-1 pb-1 mr-2 mb-2"><i className="ri-close-line fs-12 mr-2 cursor-pointer" onClick={()=>{ handleFilters(false,c.Id,"occasion") }}></i>{c.Name}</span>
                                })
                            }
                        </div> : <p className="m-0 text-gray-400 fs-14">{_T("There are no active filters")}</p>
                    }
                </div>
                {
                    filterOptions.Categories.length ?
                    <div className="sidebar-inner-container sidebar-categories mb-4">
                        <div className="sidebar-section-header d-flex align-items-start justify-between">
                            <h4 className="sidebar-heading fs-16 fw-500 text-gray-500">{_T("Categories")}</h4>
                            { filterOptions.Categories.filter((i)=>i.marked).length ? <button onClick={()=>{clearGroupFilters("category")}} className="link fs-14 mt-2" type="button">{_T("Clear")}</button> : <></> }
                        </div>
                        <div className={"sidebar-list-filters"}>
                            {
                                filterOptions.Categories.map((item,key)=>{
                                    return <div className="mb-3 d-flex justify-between" key={"categories"+key}>
                                        <input type={"checkbox"} id={"categories"+key} className="input-checkbox" checked={item.marked} onChange={(e)=>{ handleFilters(e.target.checked,item.Id,"category") }}/>
                                        <label htmlFor={"categories"+key} className="fs-14 fw-500 text-gray-500 text-capitalize">{item.Name}</label>
                                    </div>
                                })
                            }
                        </div>
                    </div> : <></>
                }
                {
                    filterOptions.Colors.length ?
                    <div className="sidebar-inner-container sidebar-colors mb-4">
                        <div className="sidebar-section-header d-flex align-items-start justify-between">
                            <h4 className="sidebar-heading fs-16 fw-500 text-gray-500">{_T("Colors")}</h4>
                            { filterOptions.Colors.filter((i)=>i.marked).length ? <button onClick={()=>{clearGroupFilters("color")}} className="link fs-14 mt-2" type="button">{_T("Clear")}</button> : <></> }
                        </div>
                        <div className={"sidebar-list-filters"}>
                            {
                                filterOptions.Colors.map((item,key)=>{
                                    return <div className="mb-3 d-flex justify-between" key={"colors"+key}>
                                        <input type={"checkbox"} id={"colors"+key} className="input-checkbox" checked={item.marked} onChange={(e)=>{ handleFilters(e.target.checked,item.Name,"color") }}/>
                                        <label htmlFor={"colors"+key} className="fs-14 fw-500 text-gray-500 text-capitalize">{item.Name}</label>
                                    </div>
                                })
                            }
                        </div>
                    </div> : <></>
                }
                {
                    filterOptions.Manufacturer.length ?
                    <div className="sidebar-inner-container sidebar-manufacturer mb-4">
                        <div className="sidebar-section-header d-flex align-items-start justify-between">
                            <h4 className="sidebar-heading fs-16 fw-500 text-gray-500">{_T("Brands")}</h4>
                            { filterOptions.Manufacturer.filter((i)=>i.marked).length ? <button onClick={()=>{clearGroupFilters("brand")}} className="link fs-14 mt-2" type="button">{_T("Clear")}</button> : <></> }
                        </div>
                        <div className={"sidebar-list-filters"}>
                            {
                                filterOptions.Manufacturer.map((item,key)=>{
                                    return <div className="mb-3 d-flex justify-between" key={"manufacturer"+key}>
                                        <input type={"checkbox"} id={"manufacturer"+key} className="input-checkbox" checked={item.marked} onChange={(e)=>{ handleFilters(e.target.checked,item.Id,"brand") }}/>
                                        <label htmlFor={"manufacturer"+key} className="fs-14 fw-500 text-gray-500 text-capitalize">{item.Name}</label>
                                    </div>
                                })
                            }
                        </div>
                    </div> : <></>
                }
                {
                    filterOptions.publicOccasions.length ?
                    <div className="sidebar-inner-container sidebar-occasion mb-4">
                        <div className="sidebar-section-header d-flex align-items-start justify-between">
                            <h4 className="sidebar-heading fs-16 fw-500 text-gray-500">{_T("Occasions")}</h4>
                            { filterOptions.publicOccasions.filter((i)=>i.marked).length ? <button onClick={()=>{clearGroupFilters("occasion")}} className="link fs-14 mt-2" type="button">{_T("Clear")}</button> : <></> }
                        </div>
                        <div className={"sidebar-list-filters"}>
                            {
                                filterOptions.publicOccasions.map((item,key)=>{
                                    return <div className="mb-3 d-flex justify-between" key={"occasion"+key}>
                                        <input type={"checkbox"} id={"occasion"+key} className="input-checkbox" checked={item.marked} onChange={(e)=>{ handleFilters(e.target.checked,item.Id,"occasion") }}/>
                                        <label htmlFor={"occasion"+key} className="fs-14 fw-500 text-gray-500 text-capitalize">{item.Name}</label>
                                    </div>
                                })
                            }
                        </div>
                    </div> : <></>
                }
                <div className="sidebar-inner-container sidebar-price">
                    <div className="sidebar-section-header d-flex align-items-center justify-between">
                        <h4 className="sidebar-heading fs-16 fw-500 text-gray-500">{_T("Filter by price")} (SAR)</h4>
                        {/* {activeMin !== priceMinAllowed || activeMax !== priceMaxAllowed ? <button onClick={() => { clearGroupFilters("price") }} className="link fs-14" type="button">{content.clearAll}</button> : <></>} */}
                    </div>
                    <div className="price-filter mb-5 LTR text-left">
                        <MultiRangeSlider
                            min={priceMinAllowed}
                            max={priceMaxAllowed}
                            step={1}
                            ruler={false}
                            label={true}
                            preventWheel={false}
                            minValue={priceMinSet}
                            maxValue={priceMaxSet}
                            labels={["SAR", "SAR"]}
                            onInput={(e) => { handleRange(e); }}
                        />
                    </div>
                    {/* <button className="btn btn-outline-secondary w-100 justify-center" type="button" onClick={() => { setActiveMin(priceMinSet); setActiveMax(priceMaxSet); setRefresh(refresh + 1); }}>{content.apply}</button> */}
                    <button className="btn btn-outline-secondary w-100 justify-center" type="button" onClick={applyPriceRange}>{_T("Apply")}</button>
                </div>
            </div>
        </aside>
    )
}

export default ProductSidebar