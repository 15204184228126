import React from 'react'
import OrderCard from './order-card';
import '../../assets/css/--component-account-orders.css'
import { useEffect } from 'react';
import { getOrderDetails, getOrderList } from '../../helper/backend-methods';
import { useState } from 'react';
import currentOrderIcon from '../../assets/images/cardboard-box.svg'
import passOrderIcon from '../../assets/images/open-delivered-box.svg'
import cancelOrderIcon from '../../assets/images/do-not-tilt.svg'
import Skeleton from 'react-loading-skeleton';
import ErrorMsg from '../../components/ErrorMessage';
import { googleTagInitialize, pixelPageEvent, scrollToTop } from '../../common';
import _T from '../../locale/order';

function UserOrders() {
    const [currentOrder, setCurrentOrder] = useState([])
    const [pastOrder, setPastOrder] = useState([])
    const [cancelOrder, setCancelOrder] = useState([])
    const [loading, setLoading] = useState(true)
    const [currenLoading, setCurrentLoading] = useState(true)
    const [pastLoading, setPastLoading] = useState(true)
    const [cancelLoading, setCancelLoading] = useState(true)
    const [tab, setTab] = useState(1);

    useEffect(()=>{
        pixelPageEvent();
        googleTagInitialize();
    },[])

    useEffect(() => {
        document.title = 'Orders | Wardstation';
        scrollToTop();
        getOrderList({ pageIndex: 0, pageSize: 10, statusFilter: "current" }).then(({ data }) => {
            if (data.status) {
                setCurrentOrder(data.result.Data)
            }
            else{
                setCurrentOrder([]) 
            }
            setCurrentLoading(false)
        })
        getOrderList({ pageIndex: 0, pageSize: 10, statusFilter: "past" }).then(({ data }) => {
            if (data.status) {
                setPastOrder(data.result.Data) 
            }
            else{
                setPastOrder([]) 
            }
            setPastLoading(false)
        })
        getOrderList({ pageIndex: 0, pageSize: 10, statusFilter: "cancelled" }).then(({ data }) => {
            if (data.status) {
                setCancelOrder(data.result.Data) 
            }
            else{
                setCancelOrder([]) 
            }
            setCancelLoading(false)
        })
    }, [])
    return (
        <section id="myOrders">
            <div className='account-header d-flex align-items-center justify-between'>
                <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'>{_T("My Orders")}</h1>
            </div>
            <div className="bg-wrapper d-flex justify-between flex-wrap">
                <div className={`tab-item d-flex align-items-center cursor-pointer ${tab===1 ? "active" : ""}`} onClick={()=>{setTab(1)}}><img className='mr-1' src={currentOrderIcon} alt="" />{_T("Current")}</div>
                <div className={`tab-item d-flex align-items-center cursor-pointer ${tab===2 ? "active" : ""}`} onClick={()=>{setTab(2)}}><img className='mr-1' src={passOrderIcon} alt="" /> {_T("Past")}</div>
                <div className={`tab-item d-flex align-items-center cursor-pointer ${tab===3 ? "active" : ""}`} onClick={()=>{setTab(3)}}><img className='mr-1' src={cancelOrderIcon} alt="" /> {_T("Cancelled")}</div>
            </div>
            {
                tab === 1 ?
                <section>       
                    {
                        currenLoading ?
                        [...Array(5)].map((item,key)=>{
                            return <Skeleton key={key}  className='mt-3 w-100' height={"84px"}/>
                        }) :
                        currentOrder.length?
                        currentOrder.map((item,key)=>{
                            return <OrderCard item={item} key={key} loading={loading} />
                        }): <ErrorMsg text={_T("You currently have no pending orders in your account.")}/>
                    }
                </section> : <></>
            }
            {
                tab === 2 ?
                <section>       
                    {
                        pastLoading ?
                        [...Array(5)].map((item,key)=>{
                            return <Skeleton key={key}  className='mt-3 w-100' height={"84px"}/>
                        }) :
                        pastOrder.length?
                        pastOrder.map((item,key)=>{
                            return <OrderCard item={item} key={key} loading={loading} />
                        }):<ErrorMsg text={_T("You currently have no past orders in your account.")}/>
                    }
                </section> : <></>
            }
            {
                tab === 3 ?
                <section>       
                    {
                        cancelLoading ?
                        [...Array(5)].map((item,key)=>{
                            return <Skeleton key={key} className='mt-3 w-100' height={"84px"}/>
                        }) :
                        cancelOrder.length?
                        cancelOrder.map((item,key)=>{
                            return <OrderCard item={item} key={key} loading={loading} />
                        }):<ErrorMsg text={(_T("You currently have no cancelled orders in your account."))}/>
                    }
                </section> : <></>
            }
        </section>
    )
}
export default UserOrders;