import React, { useState, useEffect, useContext } from "react";
import logo from "../assets/images/logo.png";
import master from "../assets/images/payment-master.png";
import visa from "../assets/images/payment-visa.png";
import pay from "../assets/images/payment-apple.png";
import mada from "../assets/images/payment-mada.png";
import mobileApp from "../assets/images/mobileApp.png";
import googlePlay from "../assets/images/google-play-download.png";
import appStore from "../assets/images/app-store-download.png";
import "../assets/css/--component-footer.css";
import { Link } from "react-router-dom";
import _T from "../locale/footer";
import { GlobalContext } from "../context/GlobalContext";
import { getLocale } from "../common";
import Skeleton from "react-loading-skeleton";
// import translations from '../data/language.json'
// import { analyticsTrackEvent } from "./Analytics";

function Footer(props) {

    const { brandList } = useContext(GlobalContext);
    const locale = getLocale();
    // const [ content, setContent ] = useState(translations.Footer["en"])
    // useEffect(()=>{
    //     const lang = localStorage.getItem("LANG")
    //     if(lang && lang.length){
    //         setContent(lang === "ar" ? translations.Footer["ar"] : translations.Footer["en"])
    //     }
    //   }, [])

    return (
        <footer>
            <div className="wrapper footer-general d-flex justify-between">
                <div className="footer-column">
                    <Link className="footer-logo d-inline-block" to={"/"}><img src={logo} alt="WardEcommerce" /></Link>
                    <p className="footer-paragraph text-secondary fw-400 fs-14">{_T("WardStation is an online flowers and gifting company that pride itselves on creating a seamless experience for its customers & making sending flowers & gifts as enjoyable as receiving them.")}</p>
                    {/* <div className="d-inline-flex align-items-center download-btn">
                        <a href="https://ctoys.onelink.me/X7MG/kkt6j4zc" target={"_blank"} rel="noreferrer" onClick={() => { analyticsTrackEvent("CTA Clicked", { cta_name: "Download on Google Play", screen_name: "Home Page" }) }} className="d-inline-block"><img src={googlePlay} alt="Download on Google Play" /></a>
                        <a href="https://ctoys.onelink.me/X7MG/j2eoayvf" target={"_blank"} rel="noreferrer" onClick={() => { analyticsTrackEvent("CTA Clicked", { cta_name: "Download on App Store", screen_name: "Home Page" }) }} className="d-inline-block"><img src={appStore} alt="Download on App Store" /></a>
                    </div> */}
                </div>
                <div className="footer-column">
                    <h4 className="footer-title">{_T("Brands")}</h4>
                    <ul className="footer-links">
                        {
                            brandList && brandList.length ?
                            brandList.slice(0,4).map((b,i)=>{
                                return <li className="footer-link" key={i}><Link to={`/brand-products/${b.Id}/${b.Name ? b.Name.replace(/[^a-zA-Z0-9\s\u0600-\u06FF]/g, '').trim().replace(/\s+/g, '-').toLowerCase() : _T("brands")}`}>{ locale === "en" ? b.Name : b.NameAr }</Link></li>
                            }) :
                            [...Array(4)].map((b,i)=>{
                                return <li className="footer-link" key={i}><Skeleton height={12} width={80}></Skeleton></li>
                            })
                        }
                    </ul>
                </div>
                <div className="footer-column">
                    <h4 className="footer-title">{_T("Useful links")}</h4>
                    <ul className="footer-links">
                        <li className="footer-link"><Link to={"/about"}>{_T("About Us")}</Link></li>
                        <li className="footer-link"><Link to={"/blogs"}>{_T("Blogs")}</Link></li>
                        <li className="footer-link"><Link to={"/frequently-asked-questions"}>{_T("FAQs")}</Link></li>
                        <li className="footer-link"><Link to={"/privacy-policy"}>{_T("Privacy Policy")}</Link></li>
                        <li className="footer-link"><Link to={"/terms-and-conditions"}>{_T("Terms & Conditions")}</Link></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h4 className="footer-title">{_T("Follow Us")}</h4>
                    <div className="footer-social-links text-primary fs-26">
                        <a className="link" rel="noreferrer" target="_blank" href="https://www.facebook.com/wardstation.co"><i className="ri-facebook-fill mr-3 text-primary"></i></a>
                        <a className="link" rel="noreferrer" target="_blank" href="https://www.instagram.com/wardstation.co"><i className="ri-instagram-fill mr-3 text-primary"></i></a>
                        <a className="link" rel="noreferrer" target="_blank" href="https://www.tiktok.com/@wardstation.co"><i className="ri-tiktok-fill mr-3 text-primary"></i></a>
                        <a className="link" rel="noreferrer" target="_blank" href="https://twitter.com/wardstation"><i className="ri-twitter-fill mr-3 text-primary"></i></a>
                        <a className="link" rel="noreferrer" target="_blank" href="https://www.pinterest.com/wardstationsa/"><i className="ri-pinterest-fill mr-3 text-primary"></i></a>
                    </div>
                    <h4 className="footer-title">{_T("Payment Methods")}</h4>
                    <div className="footer-payment-method">
                        <a href="#"><img src={master} className="paymentCard-img" alt="master-card"/></a>
                        <a href="#"><img src={visa} className="paymentCard-img" alt="visa-card"/></a>
                        <a href="#"><img src={pay} className="paymentCard-img" alt="pay-card"/></a>
                        <a href="#"><img src={mada} className="paymentCard-img" alt="mada-card"/></a>
                    </div>
                </div>
                <div className="footer-column">
                    <div className="footer-mobile-app">
                        <img src={mobileApp} className="image-mobiileApp" alt="Mobile App"/>
                    </div>
                    <h4 className="footer-title">{_T("Download Our App")}</h4>
                    <div className="footer-download-btn">
                        <a href="https://play.google.com/store/apps/details?id=com.aym.wardstation" target={"_blank"} rel="noreferrer" className="d-inline-block mr-1"><img src={googlePlay} alt="Download on Google Play" /></a>
                        <a href="https://apps.apple.com/sa/app/wardstation-%D9%88%D8%B1%D8%AF-%D8%B3%D8%AA%D9%8A%D8%B4%D9%86/id1630942688" target={"_blank"} rel="noreferrer" className="d-inline-block"><img src={appStore} alt="Download on App Store" /></a>
                    </div>
                </div>
            </div>
            <div className="bg-primary footer-credits">
                <div className="wrapper text-center pt-1 pb-1">
                    <small className="text-light">{_T("WardStation")} &copy; {new Date().getFullYear()}, {_T("All rights reserved")}</small>
                </div>
            </div>
        </footer>
    )
}

export default Footer; 