function _T(text) {
    let locale = localStorage.getItem("locale");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    const translations = {
        "Blogs | Flowers and Gifts Shop | Wardstation": {
            "en": "Blogs | Flowers and Gifts Shop | Wardstation",
            "ar": "مقالات | ورد ستيشن"
        },
        "Explore insightful articles and latest updates on flowers, gifts, and celebrations on Wardstation's blog. Stay informed and inspired with our valuable content.": {
            "en": "Explore insightful articles and latest updates on flowers, gifts, and celebrations on Wardstation's blog. Stay informed and inspired with our valuable content.",
            "ar": "استكشف المقالات المفيدة وأحدث التحديثات حول الزهور والهدايا والاحتفالات على مدونة ورد ستيشن. ابق على اطلاع وملهم مع محتوانا القيم."
        },
        "flowers, gifts, celebrations, blog, articles, Wardstation": {
            "en": "flowers, gifts, celebrations, blog, articles, Wardstation",
            "ar": "زهور، هدايا، احتفالات، مدونة، مقالات، وارد ستيشن"
        },
        "Home": {
            "en": "Home",
            "ar": "الصفحة الرئيسية"
        },
        "Blogs": {
            "en": "Blogs",
            "ar": "المدونات"
        },
        "Blog": {
            "en": "Blog",
            "ar": "مدونة"
        },
        "post": {
            "en": "post",
            "ar": "مشاركة"
        },
        "Blogs not found": {
            "en": "Blogs not found",
            "ar": "المدونات غير موجودة"
        }
    }


    return translations[text] ? translations[text][locale] : text;
}
export default _T;