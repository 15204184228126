import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "../../assets/css/--page-product-detail.css"
import "react-multi-carousel/lib/styles.css";
import noImage from "../../assets/images/no-image.jpg"
import infoIcon from "../../assets/images/info-icon.png"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { addToCart, getProductDetails, updateWishlist } from "../../helper/backend-methods";
import moment from "moment";
import ScrollContainer from "react-indiana-drag-scroll";
import { convertDateTimeWithLocale, googleTagInitialize, isAuthenticated, pixelPageEvent, pixelTrackEvent, scrollToTop } from "../../common";
import Breadcrumbs from "../../components/BreadCrumbs";
import Modal from "../../components/Modal";
import { GlobalContext } from "../../context/GlobalContext";
import _T from "../../locale/subscription";
import Error404 from "../../components/Error404";
import SEO from "../../components/SEO";

function SubProductDetailCard() {
    let params = useParams();
    const productID = params.id
    const { cartCount,updateCartCount } = useContext(GlobalContext);
    const [images, setImages] = useState([]);
    const isAuthorized = isAuthenticated();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true)
    const [quantity, setQuantity] = useState(1);
    const [quantityError, setQuantityError] = useState(false);
    const [IsInWishlist, setIsInWishlist] = useState(false);
    const [selectedButton, setSelectedButton] = useState('weekly');
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedMonthlyDates, setSelectedMonthlyDates] = useState([]);
    const [tab, setTab] = useState(1);
    const [warningModal, setWarningModal] = useState(false);
    const [notFound , setNotFound]= useState(false);
    const navigate = useNavigate();
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        { title: _T("Home"), link: "/" },
        { title: _T("Subscription"), link: "/subscription" },
        { title: _T("Product"), link: "" },
    ]);

    useEffect(()=>{
        pixelPageEvent();
        googleTagInitialize();
    },[])

    useEffect(() => {
        scrollToTop();
        getProductDetails({ productId: productID }).then(({ data }) => {
            if (data.status) {
                let newImages = []
                let p = data.result.product
                if (p.PictureModels.length) {
                    p.PictureModels.map((img) => {
                        newImages.push({
                            original: img.FullSizeImageUrl,
                            thumbnail: img.ImageUrl,
                        })
                    })
                }
                else {
                    newImages = [{ original: noImage, thumbnail: noImage }]
                }
                setIsInWishlist(p.IsInWishlist);
                setImages([...newImages])
                setProduct(p)
                setLoading(false)
            }
            else{
                if(data.response.ResponseCode === 404){
                    setNotFound(true);
                }
            }
        }).catch((e) => {
            console.log(e);
        })
    }, [])

    useEffect(() => {
        if(quantityError){
            setQuantityError(false);
        }
        // Calculate the dates based on quantity and selected option
        if (selectedButton === 'weekly') {
            const dates = Array.from({ length: quantity }, (_, index) =>
                moment().add(index, "weeks").format("MMM DD, YYYY")
            );
            setSelectedDates(dates);
            setSelectedMonthlyDates([]); // Clear monthly dates
        } else if (selectedButton === 'monthly') {
            const dates = Array.from({ length: quantity }, (_, index) =>
                moment().add(index, "months").format("MMM DD, YYYY")
            );
            setSelectedMonthlyDates(dates);
            setSelectedDates([]); // Clear weekly dates
        } else {
            setSelectedDates([]);
            setSelectedMonthlyDates([]);
        }
    }, [selectedButton, quantity]);

    const addRemoveWishlist = () => {
        updateWishlist({ ProductId: productID, Add: !IsInWishlist }).then(({data})=>{
            if(data.status){
                setIsInWishlist(!IsInWishlist)
                if(IsInWishlist){
                    pixelTrackEvent("AddToWishlist",{ content_ids: [productID], content_type: 'product', value: product.ProductPrice.Price, currency: 'SAR' })
                }
            }
        }).catch((e)=>{
            console.log(e)
        })
    }

    const validateSubmit = () => {
        if(quantity < 2){
            setQuantityError(true);
        }
        else if(cartCount > 0){
            setQuantityError(false);
            setWarningModal(true)
        }
        else{
            addSubscriptionToCart()
        }
        
    }

    const addSubscriptionToCart = () => {
        setWarningModal(false);
        setQuantityError(false);
        addToCart({PeriodType: selectedButton === "weekly" ? "Weeks" : "Months", Intervals: 0, TotalDeliveries : quantity, Quantity : quantity , ProductId: productID, Subscription: true }).then(({data})=>{
            if(data.status){
                updateCartCount(1);
                pixelTrackEvent("AddToCart",{ content_ids: [productID], content_type: 'product', value: product.ProductPrice.Price, currency: 'SAR' })
                navigate("/cart")
            }
        }).catch((e)=>{
            console.log(e)
        })
    }

    return (
        notFound?
        <Error404/>:
        loading ?
        <section id="productSingle" className="pb-4">
            <Breadcrumbs pageTile="Subscription" breadcrumbItems={breadcrumbItems} />
            <div className="wrapper d-flex justify-between flex-wrap">
                <div className="single-product-gallery">
                    <Skeleton height={window.innerWidth < 1025 ? "250px" : "500px"} />
                </div>
                <div className="single-product-content">
                    <Skeleton height="50px" style={{ maxWidth: "500px", width: "100%" }} />
                    <Skeleton height="20px" style={{ maxWidth: "200px", width: "100%", marginBottom: "20px" }} />
                    <Skeleton height="30px" style={{ maxWidth: "300px", width: "100%", marginBottom: "30px" }} />
                    <Skeleton count={5} />
                    <Skeleton height="50px" style={{ maxWidth: "500px", width: "100%", marginTop: "50px", marginBottom: "30px" }} />
                    <Skeleton count={3} style={{ maxWidth: "150px", width: "100%" }} />

                </div>
            </div>
        </section> :
        <section id="productSingle" className="pb-4">
            <Breadcrumbs pageTile="Subscription" breadcrumbItems={breadcrumbItems} />
            <SEO metaTitle={product.MetaTitle ? product.MetaTitle : product.Name} metaDescription={product.MetaDescription ? product.MetaDescription : product.ShortDescription} metaKeywords={product.MetaKeywords ? product.MetaKeywords : product.Name.split(" ").join(".")} />
            <div className="wrapper d-flex justify-between flex-wrap">
                <div className="single-product-gallery">
                    <ReactImageGallery items={images} thumbnailPosition={"bottom"} showPlayButton={false} showBullets={false} />
                </div>
                <div className="single-product-content">
                    <div className="single-product-header d-inline-flex justify-between">
                        <h1 className="single-product-title fw-900 mt-0 mb-3 mr-2 fs-38 text-secondary ff-heading">{product.Name}</h1>
                    </div>
                    <div className="d-flex align-items-center">
                        <h2 className="single-product-price text-secondary fw-400 fs-24 m-0"><small className="d-block text-gray-400 fs-12 fw-400">{_T("Starting From")}</small><span className="text-secondary text-en">SAR <span className="text-primary text-en">{product.ProductPrice.Price ? product.ProductPrice.Price : ""}<small className="text-gray-400 fs-12 fw-400"> {_T("/ delivery")}</small> <small className="d-block text-gray-400 fs-12 fw-400">{_T("(VAT included)")}</small></span></span></h2>
                        {
                            product.IsDiscounted ?
                            <h2 className="single-product-old-price text-gray-300 fw-500 fs-20 mt-0 mb-0 ml-2 text-en"><s>{product.ProductPrice.OldPrice}</s></h2> : null
                        }
                        {
                            product.IsDiscounted ?
                            <span className="tag">{product.DiscountPercentage}%</span> : null
                        }
                    </div>
                    <div className="d-flex align-items-center justify-between mb-4">
                        <p className="m-0 text-secondary fs-14 fw-400">{product.StockDetails && product.StockDetails === "Out of stock" ? <span className="text-flushed">Out of Stock</span> : null}</p>
                    </div>
                    <p className="fs-14 fw-500 text-secondary">{_T("Subscription Period:")}</p>
                    <div className="sub-product-date-selection">
                        <button type="button" className={`btn ${selectedButton === 'weekly' ? 'btn-secondary' : 'btn-outline-secondary'} mr-2`} onClick={() => setSelectedButton('weekly')}>{_T("Weekly")}</button>
                        <button type="button" className={`btn ${selectedButton === 'monthly' ? 'btn-secondary' : 'btn-outline-secondary'}`} onClick={() => setSelectedButton('monthly')}>{_T("Monthly")}</button>
                    </div>
                    <div className="divider mt-4 mb-4"></div>
                    <p className="single-product-short-description fs-16 fw-400 text-gray-400">{product.ShortDescription}</p>
                    <div className="d-flex mb-3">
                        {
                            (product.StockDetails && product.StockDetails === "Out of stock") || product.Subscribed ?
                            null :
                            <div className="product-quantity-container d-inline-flex align-items-center mr-3">
                                <button className="product-quantity-btn" onClick={() => { quantity <= 1 ? setQuantity(1) : setQuantity(quantity - 1) }} >-</button>
                                <input className="product-quantity-field" value={quantity} placeholder="1" readOnly />
                                <button className="product-quantity-btn" onClick={() => { product.StockDetails && product.StockDetails !== "Out of stock" ? quantity >= product.StockQuantity ? setQuantity(product.StockQuantity) : setQuantity(quantity + 1) : quantity >= 100 ? setQuantity(100) : setQuantity(quantity + 1) }} >+</button>
                            </div>
                        }
                        {
                            product.StockDetails && product.StockDetails === "Out of stock" ?
                            <button type={"button"} className="btn btn-disabled mr-3">{_T("Out of Stock")}</button> :
                            product.Subscribed ?
                            <Link to={"/account/subscription/"+product.SubscriptionId} className="btn btn-primary mr-3">{_T("Subscribed")}</Link> :
                            <button type={"button"} onClick={validateSubmit} className="btn btn-primary mr-3">{_T("Subscribe")}</button>
                        }
                        {
                            isAuthorized ?
                            <button className="wishlist-custom-btn cursor-pointer" onClick={addRemoveWishlist}>
                                { IsInWishlist ?  < i className="ri-heart-3-fill text-secondary"></i>  :  < i className="ri-heart-3-line text-secondary"></i>  }
                            </button> : null
                        }
                    </div>
                    { quantityError ? <p className="fs-14 fw-400 text-flushed m-0">{_T("For subscription, deliveries should be more than one.")}</p> :null }
                    <div className="divider mt-4 mb-4"></div>
                    {
                        selectedButton === 'weekly' && selectedDates.length ?
                        <div className="sub-product-dates-container">
                            <p className="selected-dates-label text-gray-500 fs-14 mt-0 mb-2">{_T("Below are the example dates only. Actual delivery dates may vary depending on your delivery slot selection")}</p>
                            <ScrollContainer className="scroll-container" horizontal={true} vertical={false}>
                                <div className="selected-dates-list d-inline-flex">
                                    {selectedDates.map((date, index) => (
                                        <p key={index} className="sub-product-dates-list text-center" draggable={false}>{convertDateTimeWithLocale(date)}</p>
                                    ))}
                                </div>
                            </ScrollContainer>
                        </div> : null
                    }
                    {
                        selectedButton === 'monthly' && selectedMonthlyDates.length ?
                        <div className="sub-product-dates-container">
                            <p className="selected-dates-label text-gray-500 fs-14 mt-0 mb-2">{_T("Below are the example dates only. Actual delivery dates may vary depending on your delivery slot selection")}</p>
                            <ScrollContainer className="scroll-container" horizontal={true} vertical={false}>
                                <div className="selected-dates-list d-inline-flex">
                                    {selectedMonthlyDates.map((date, index) => (
                                        <p key={index} className="sub-product-dates-list text-center" draggable={false}>{date}</p>
                                    ))}
                                </div>
                            </ScrollContainer>
                        </div> : null
                    }

                    <h4 className="single-product-SKU text-gray-500 fw-500 fs-14 mt-0 mb-2 dir-switch"><span className="mr-2">SKU:</span>{product.Sku}</h4>
                    <div className="d-flex align-items-center fs-14 flex-wrap">
                        <span className="mr-2">{_T("Share:")}</span>
                        <a href={`http://www.facebook.com/share.php?u=${encodeURIComponent(window.location.href)}&title=${product.Name}`} target="_blank" className="link">{_T("Facebook")}</a>,&nbsp;
                        <a href={`https://twitter.com/intent/tweet?text=${product.Name}&url=${encodeURIComponent(window.location.href)}`} target="_blank" className="link">{_T("Twitter")}</a>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="single-product-tabs">
                    <div className="tab-headings d-inline-flex align-items-center">
                        <h4 className={tab === 1 ? "single-heading active" : "single-heading"} onClick={() => { setTab(1) }}>{_T("Description")}</h4>
                        <h4 className={tab === 2 ? "single-heading active" : "single-heading"} onClick={() => { setTab(2) }}>{_T("Dimensions")}</h4>
                    </div>
                    <div className={tab === 1 ? "single-content" : "single-content d-none"}>
                        {
                            product.FullDescription && product.FullDescription.length > 4 ? <div dangerouslySetInnerHTML={{__html: product.FullDescription}} ></div> : <div>{_T("No description available")}</div>
                        }
                    </div>
                    <div className={tab === 2 ? "single-content" : "single-content d-none"} >
                        <div className="additional-info d-flex align-items-center mb-2">
                            <div className="dimensions-img">
                                <div className="d-flex align-items-center">
                                    <div className="img-width">
                                        <img src={product.SizeIconImageUrl} alt="" />
                                        <p className="text-center m-0">{product.Width}Cm</p>
                                    </div>
                                    <p>{product.Height}Cm</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal className="info-occasion-modal" isOpen={warningModal} modalSize="small" onClose={()=>{ setWarningModal(false) }}>
                <div className="text-center">
                    <img src={infoIcon} alt="Info Icon" className="mx-auto" width={50}/>
                    <h4 className="text-gray-500 fs-18 fw-400 mb-0 mt-2">{_T("Remove items from Cart?")}</h4>
                    <p className="text-gray-400 fs-16 fw-400 text-center mt-2">{_T("If you want to subscribe this product, your previously added items will be automatically removed from your cart.")}</p>
                    <button className="btn btn-primary w-100 mb-2" type="button" onClick={addSubscriptionToCart}>{_T("Yes, Remove")}</button>
                </div>
            </Modal>
        </section>
    )
}

export default SubProductDetailCard