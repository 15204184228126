import Carousel from 'react-multi-carousel'
import { Link } from 'react-router-dom'
import 'react-multi-carousel/lib/styles.css';
import { useRef } from 'react';
import Skeleton from 'react-loading-skeleton'
import _T from '../../locale/home';
import { getLocale } from '../../common';
export default function Hero(props) {
    const isLoading = props.isLoading
    const heroBanners = props.heroBanner
    const locale = getLocale()
    const carouselRef = useRef(null);
    const handlePrevious = () => {
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    return (
        <section className='hero-section'>
            <div className='wrapper'>
                {
                    isLoading ?
                        <Skeleton className='single-slide-loader' style={{ maxWidth: "100%", width: "100%" }} />
                        :
                        <Carousel
                            ref={carouselRef}
                            arrows={false} renderButtonGroupOutside={false}
                            responsive={{ desktop: { breakpoint: { max: 10000, min: 100 }, items: 1 }, }}
                            sliderclassName=""
                            containerClass="banner-container-with-dots"
                            dotListClass="banner-carousel-bullets"
                            showDots={true}
                            slidesToSlide={1}
                            swipeable
                        >
                            {

                                heroBanners.map((slide, key) => (
                                    <div key={key} className='single-slide w-100'>
                                        <img draggable={false} src={`${locale === "en" ? slide.PictureURL : slide.PictureURL_Ar}`} className='w-100 hero-banner' alt="" />
                                        <div className='wrapper'>
                                            <div className='slide-content'>
                                                {
                                                    slide.Title && slide.Title.length ?
                                                        <h2 className='ff-heading text-secondary fw-900 slide-heading has-arrow-start'>{slide.Title}</h2> : <></>
                                                }
                                                {
                                                    slide.Description && slide.Description.length ?
                                                        <p className='fs-16 fw-400 text-gray-500 slide=paragraph'>{slide.Description}</p> : <></>
                                                }
                                                {
                                                    slide.Link && slide.Link.length ?
                                                        <Link to={slide.Link} className="btn btn-secondary slide-action">{_T("Shop Now")}</Link> : <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </Carousel>
                }
            </div>
                {
                    heroBanners.length?
                        locale === 'ar' ?
                            <div className="banner-carousel-controls mb-4">
                                <button onClick={handlePrevious} className='banner-navigation-control banner-navigation-previous'><i className="ri-arrow-right-s-line"></i></button>
                                <button onClick={handleNext} className='banner-navigation-control banner-navigation-next'><i className="ri-arrow-left-s-line"></i></button>
                            </div>
                            :
                            <div className="banner-carousel-controls mb-4">
                                <button onClick={handlePrevious} className='banner-navigation-control banner-navigation-previous'><i className="ri-arrow-left-s-line"></i></button>
                                <button onClick={handleNext} className='banner-navigation-control banner-navigation-next'><i className="ri-arrow-right-s-line"></i></button>
                            </div>

                    :
                    <></>
                }
        </section>
    )
}